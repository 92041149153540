export enum StockInspectionTypes {

  //GRID
  GET_STOCK_INSPECTION_LIST = "@@stock_inspection/GET_STOCK_INSPECTION_LIST",
  GET_STOCK_INSPECTION_LIST_SUCCESS = "@@stock_inspection/GET_STOCK_INSPECTION_LIST_SUCCESS",
  GET_STOCK_INSPECTION_LIST_FAIL = "@@stock_inspection/GET_STOCK_INSPECTION_LIST_FAIL",

  DELETE_STOCK_INSPECTION = "@@stock_inspection/DELETE_STOCK_INSPECTION_MODELS",
  DELETE_STOCK_INSPECTION_SUCCESS = "@@stock_inspection/DELETE_STOCK_INSPECTION_SUCCESS",
  DELETE_STOCK_INSPECTION_FAIL = "@@stock_inspection/DELETE_STOCK_INSPECTION_FAIL",

  //FILTER
  FILTER_PAGE_LOADING = "@@stock_inspection/FILTER_PAGE_LOADING",
  FILTER_PAGE_SUCCESS = "@@stock_inspection/FILTER_PAGE_SUCCESS",
  FILTER_PAGE_ERROR = "@@stock_inspection/FILTER_PAGE_ERROR",

  RESET_STOCK_INSPECTION = "@@stock_inspection/RESET_STOCK_INSPECTION",

  //UPDATE
  UPDATE_STOCK_INSPECTION = "@@stock_inspection/UPDATE_STOCK_INSPECTION",
  UPDATE_STOCK_INSPECTION_SUCCESS = "@@stock_inspection/UPDATE_STOCK_INSPECTION_SUCCESS",
  UPDATE_STOCK_INSPECTION_FAIL = "@@stock_inspection/UPDATE_STOCK_INSPECTION_FAIL",

  // POST
  POST_STOCK_INSPECTION = "@@stock_inspection/POST_STOCK_INSPECTION",
  POST_STOCK_INSPECTION_SUCCESS = "@@stock_inspection/POST_STOCK_INSPECTION_SUCCESS",
  POST_STOCK_INSPECTION_FAIL = "@@stock_inspection/POST_STOCK_INSPECTION_FAIL",

  // GET BY ID
  GET_STOCK_INSPECTION_BY_ID = "@@stock_inspection/GET_STOCK_INSPECTION_BY_ID",
  GET_STOCK_INSPECTION_BY_ID_SUCCESS = "@@stock_inspection/GET_STOCK_INSPECTION_BY_ID_SUCCESS",
  GET_STOCK_INSPECTION_BY_ID_FAIL = "@@stock_inspection/GET_STOCK_INSPECTION_BY_ID_FAIL",

  GET_PART_SKU_LIST = "@@stock_inspection/GET_PART_SKU_LIST",
  GET_PART_SKU_LIST_SUCCESS = "@@stock_inspection/GET_PART_SKU_LIST_SUCCESS",
  GET_PART_SKU_LIST_FAIL = "@@stock_inspection/GET_PART_SKU_LIST_FAIL",

  GET_AUDIO_BRAND_DROPDOWN_LIST = "@@stock_inspection/GET_AUDIO_BRAND_DROPDOWN_LIST",
  GET_AUDIO_BRAND_DROPDOWN_LIST_SUCCESS = "@@stock_inspection/GET_AUDIO_BRAND_DROPDOWN_LIST_SUCCESS",
  GET_AUDIO_BRAND_DROPDOWN_LIST_FAIL = "@@stock_inspection/GET_AUDIO_BRAND_DROPDOWN_LIST_FAIL",

  //supplier
  GET_SUPPLIER_DROPDOWN = '@@stock_inspection/GET_SUPPLIER_DROPDOWN',
  GET_SUPPLIER_DROPDOWN_SUCCESS = '@@stock_inspection/GET_SUPPLIER_DROPDOWN_SUCCESS',
  GET_SUPPLIER_DROPDOWN_ERROR = '@@stock_inspection/GET_SUPPLIER_DROPDOWN_ERROR',


  GET_INITIATED_BY_DROPDOWN = "@@stock_inspection/GET_INITIATED_BY_DROPDOWN",
  GET_INITIATED_BY_DROPDOWN_SUCCESS = "@@stock_inspection/GET_INITIATED_BY_DROPDOWN_SUCCESS",
  GET_INITIATED_BY_DROPDOWN_ERROR = "@@stock_inspection/GET_INITIATED_BY_DROPDOWN_ERROR",


  GET_SUPPLIER_PART_SKU_DETAILS = "@@stock_inspection/GET_SUPPLIER_PART_SKU_DETAILS",
  GET_SUPPLIER_PART_SKU_DETAILS_SUCCESS = "@@stock_inspection/GET_SUPPLIER_PART_SKU_DETAILS_SUCCESS",
  GET_SUPPLIER_PART_SKU_DETAILS_ERROR = "@@stock_inspection/GET_SUPPLIER_PART_SKU_DETAILS_ERROR",

  GET_AUDIO_BRAND_PART_SKU_DETAILS = "@@stock_inspection/GET_AUDIO_BRAND_PART_SKU_DETAILS",
  GET_AUDIO_BRAND_PART_SKU_DETAILS_SUCCESS = "@@stock_inspection/GET_AUDIO_BRAND_PART_SKU_DETAILS_SUCCESS",
  GET_AUDIO_BRAND_PART_SKU_DETAILS_ERROR = "@@stock_inspection/GET_AUDIO_BRAND_PART_SKU_DETAILS_ERROR",

  GET_STOCK_LOCATION_DROPDOWN = "@@stock_inspection/GET_STOCK_LOCATION_DROPDOWN",
  GET_STOCK_LOCATION_DROPDOWN_SUCCESS = "@@stock_inspection/GET_STOCK_LOCATION_DROPDOWN_SUCCESS",
  GET_STOCK_LOCATION_DROPDOWN_ERROR = "@@stock_inspection/GET_STOCK_LOCATION_DROPDOWN_ERROR",

  GET_SCAN_PART_SKU = "@@stock_inspection/GET_SCAN_PART_SKU",
  GET_SCAN_PART_SKU_WITH_LOCATION = "@@stock_inspection/GET_SCAN_PART_SKU_WITH_LOCATION",
  GET_SCAN_PART_SKU_WITH_LOCATION_INSPECTION = "@@stock_inspection/GET_SCAN_PART_SKU_WITH_LOCATION_INSPECTION",
  GET_SCAN_PART_SKU_SUCCESS = "@@stock_inspection/GET_SCAN_PART_SKU_SUCCESS",
  GET_SCAN_PART_SKU_ERROR = "@@stock_inspection/GET_SCAN_PART_SKU_ERROR",

  CLEAR_ERROR_MESSAGE = "@@stock_inspection/CLEAR_ERROR_MESSAGE",
  CLEAR_VALUES = "@@stock_inspection/CLEAR_VALUES",

  // sku status
  SKUS_STATUS = "@@stock_inspection/SKUS_STATUS",
  SKUS_STATUS_SUCCESS = "@@stock_inspection/SKUS_STATUS_SUCCESS",
  SKUS_STATUS_FAIL = "@@stock_inspection/SKUS_STATUS_FAIL",

  // get SKU details with status
  GET_SKU_NUMBER_WITH_STATUS = "@@stock_inspection/GET_SKU_NUMBER_WITH_STATUS",
  GET_SKU_NUMBER_WITH_STATUS_SUCCESS = "@@stock_inspection/GET_SKU_NUMBER_WITH_STATUS_SUCCESS",
  GET_SKU_NUMBER_WITH_STATUS_FAIL = "@@stock_inspection/GET_SKU_NUMBER_WITH_STATUS_FAIL",

  //  SENT EMAIL TO STOCK INPSECTION

  SENT_EMAIL_TO_STOCK_INSPECTION = "@@stock_inspection/SENT_EMAIL_TO_STOCK_INSPECTION",
  SENT_EMAIL_TO_STOCK_INSPECTION_SUCCESS = "@@stock_inspection/SENT_EMAIL_TO_STOCK_INSPECTION_SUCCESS",
  SENT_EMAIL_TO_STOCK_INSPECTION_FAIL = "@@stock_inspection/SENT_EMAIL_TO_STOCK_INSPECTION_FAIL",

  DOWNLOAD_STOCK_INSPECTION = "@@stock_inspection/DOWNLOAD_STOCK_INSPECTION",
  DOWNLOAD_STOCK_INSPECTION_SUCCESS = "@@stock_inspection/DOWNLOAD_STOCK_INSPECTION_SUCCESS",
  DOWNLOAD_STOCK_INSPECTION_FAIL = "@@stock_inspection/DOWNLOAD_STOCK_INSPECTION_FAIL",

  DOWNLOAD_UNSCANNED_SKU_LOADING = "@@stock_inspection/DOWNLOAD_UNSCANNED_SKU_LOADING",
  DOWNLOAD_UNSCANNED_SKU_SUCCESS = "@@stock_inspection/DOWNLOAD_UNSCANNED_SKU_SUCCESS",
  DOWNLOAD_UNSCANNED_SKU_FAIL = "@@stock_inspection/DOWNLOAD_UNSCANNED_SKU_FAIL",
}
