import { Dashboard } from "./actiontype"

export interface DashboardState {
  Marketoverview: []
  error: object | null
  WallentBalanceData: []
  InvestedData: []
  dealerCount: any
  supplierCount: any
  franchiseeCount: any
  customerCount: any
  jobsheetStatus: any[]
  dealerList: any[]
  audioPartList: any[];
  stockAdjustmentCount: any[];
  jobsheetTrends:any[];
  purchaseOrderData:any[];
  jobsheetGraphDetail:any[];
  invoiceCount : any[];
  receivedPaymentCount : any[];
  paymentPendingCount : any[];
  franchiseAppointmentDetail : any[];
  eventLoading : boolean;
  franchiseNotScheduledDetail:any;
}

const INIT_STATE = {
  Marketoverview: [],
  error: {},
  WallentBalanceData: [],
  InvestedData: [],
  dealerCount: "",
  supplierCount: "",
  franchiseeCount: "",
  customerCount: "",
  jobsheetStatus: [],
  dealerList: [],
  audioPartList: [],
  stockAdjustmentCount: [],
  jobsheetTrends:[],
  purchaseOrderData:[],
  jobsheetGraphDetail:[],
  invoiceCount : [],
  receivedPaymentCount :[],
  paymentPendingCount : [],
  franchiseAppointmentDetail : [],
  eventLoading:false,
  franchiseNotScheduledDetail:[]
}

const dashboard = (state = INIT_STATE, action: any) => {
  switch (action.type) {

    case Dashboard.GET_MARKET_OVERVIEW:
    case Dashboard.GET_WALLENT_BALANCE:
    case Dashboard.GET_Invested_Overview:
    case Dashboard.GET_DEALER_COUNT:
    case Dashboard.GET_SUPPLIER_COUNT:
    case Dashboard.GET_FRANCHISEE_COUNT:
    case Dashboard.GET_CUSTOMER_COUNT:
    case Dashboard.GET_JOBSHEET_STATUS_COUNT:
    case Dashboard.GET_DEALER_LIST:
    case Dashboard.GET_STOCK_ADJUSTMENT_DASHBOARD_DETAILS_LOADING:
    case Dashboard.GET_PURCHASE_ORDER_DASHBOARD_DETAILS_LOADING:
    case Dashboard.GET_JOBSHEET_GRAPH_DASHBOARD_DETAILS_LOADING:
    case Dashboard.GET_INVOICE_COUNT_DASHBOARD_LOADING:
    case Dashboard.GET_RECEIVED_PAYMENT_COUNT_LOADING:
    case Dashboard.GET_PENDINGPAYMENT_COUNT_LOADING:
      return {
        ...state,
        loading: true,
        error: "",
      }
    case Dashboard.GET_FRANCHISE_APPOINTMENT_DETAIL_LOADING:
      return {
        ...state,
        eventLoading: true,
        error: "",
      }
    case Dashboard.GET_MARKET_OVERVIEW_SUCCESS:
      return {
        ...state,
        Marketoverview: action.payload,
        loading:false
      }

    case Dashboard.GET_WALLENT_BALANCE_SUCCESS:
      return {
        ...state,
        WallentBalanceData: action.payload,
        loading:false
      }

    case Dashboard.GET_Invested_Overview_SUCCESS:
      return {
        ...state,
        InvestedData: action.payload,
        loading:false
      }

    case Dashboard.GET_DEALER_COUNT_SUCCESS:
      return {
        ...state,
        dealerCount: action.payload.data,
        loading:false
      }
    case Dashboard.GET_SUPPLIER_COUNT_SUCCESS:
      return {
        ...state,
        supplierCount: action.payload.data,
        loading:false
      }
    case Dashboard.GET_FRANCHISEE_COUNT_SUCCESS:
      return {
        ...state,
        franchiseeCount: action.payload.data,
        loading:false
      }
    case Dashboard.GET_CUSTOMER_COUNT_SUCCESS:
      return {
        ...state,
        customerCount: action.payload.data,
        loading:false
      }
    case Dashboard.GET_JOBSHEET_STATUS_COUNT_SUCCESS:
      return {
        ...state,
        jobsheetStatus: action.payload.data.statusList,
        dealerList: action.payload.data.dealerList,
        audioPartList: action.payload.data.audioPartList,
        loading:false
      }

    case Dashboard.GET_STOCK_ADJUSTMENT_DASHBOARD_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        stockAdjustmentCount: action.payload.data
      }

    case Dashboard.GET_INVOICE_COUNT_DASHBOARD_SUCCESS:
      
      return {
        ...state,
        loading: false,
        invoiceCount :action.payload.data 
      }

    case Dashboard.GET_PURCHASE_ORDER_DASHBOARD_DETAILS_SUCCESS:
      return {
        ...state,
        loading : false,
        purchaseOrderData : action.payload.data,
      }  
 
    case Dashboard.GET_JOBSHEET_GRAPH_DASHBOARD_DETAILS_SUCCESS:
      return {
        ...state,
        loading : false,
        jobsheetGraphDetail : action.payload.data,
      } 

    case Dashboard.GET_RECEIVED_PAYMENT_COUNT_SUCCESS:
      return {
        ...state,
        loading : false,
        receivedPaymentCount : action.payload.data,
      }  

    case Dashboard.GET_PENDINGPAYMENT_COUNT_SUCCESS:
      return {
        ...state,
        loading : false,
        paymentPendingCount : action.payload.data,
      }  

    case Dashboard.GET_FRANCHISE_APPOINTMENT_DETAIL_SUCCESS:
      
      return {
        ...state,
        eventLoading : false,
        franchiseAppointmentDetail : action.payload.items,
        franchiseNotScheduledDetail : action.payload.NotScheduled,
      }  

    case Dashboard.GET_CUSTOMER_COUNT_FAIL:
    case Dashboard.GET_DEALER_COUNT_FAIL:
    case Dashboard.GET_DEALER_LIST_FAIL:
    case Dashboard.GET_FRANCHISEE_COUNT_FAIL:
    case Dashboard.GET_JOBSHEET_STATUS_COUNT_FAIL:
    case Dashboard.GET_STOCK_ADJUSTMENT_DASHBOARD_DETAILS_ERROR:
    case Dashboard.GET_SUPPLIER_COUNT_FAIL:
    case Dashboard.GET_WALLENT_BALANCE_FAIL:
    case Dashboard.GET_Invested_Overview_FAIL:
    case Dashboard.GET_MARKET_OVERVIEW_FAIL:
    case Dashboard.GET_MARKET_OVERVIEW_FAIL:
    case Dashboard.GET_PURCHASE_ORDER_DASHBOARD_DETAILS_ERROR:
    case Dashboard.GET_JOBSHEET_GRAPH_DASHBOARD_DETAILS_ERROR:
    case Dashboard.GET_INVOICE_COUNT_DASHBOARD_ERROR:
    case Dashboard.GET_RECEIVED_PAYMENT_COUNT_ERROR:
    case Dashboard.GET_PENDINGPAYMENT_COUNT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case Dashboard.GET_FRANCHISE_APPOINTMENT_DETAIL_ERROR:
      return {
        ...state,
        eventLoading: false,
        error: action.payload,
      }
    case Dashboard.GET_JOBSHEET_TREND_COUNT_SUCCESS:
      return {
        ...state,
        jobsheetTrends: action.payload.data
      }


    default:
      return state
  }
}

export default dashboard
