import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { StockInspectionTypes } from "./actionTypes";
import { API } from "src/util/api";
import { createStockInspectionError, createStockInspectionSuccess, deleteStockInspectionFromListError, deleteStockInspectionFromListSuccess, downloadStockInspectionError, downloadStockInspectionSuccess, downloadUnUsedSkusError, downloadUnUsedSkusSuccess, getAudioBrandDropdownError, getAudioBrandDropdownSuccess, getAudioBrandPartSkuDetailsError, getAudioBrandPartSkuDetailsSuccess, getInitiatedByDropdownError, getInitiatedByDropdownSuccess, getPartSkuListBYIDError, getPartSkuListBYIDSuccess, getPostPartSkuError, getPostPartSkuSuccess, getSkuNumberWithStatusFail, getSkuNumberWithStatusSuccess, getStockInspectionBYIDError, getStockInspectionBYIDSuccess, getStockInspectionList, getStockInspectionListError, getStockInspectionListSuccess, getStockLocationDropdownError, getStockLocationDropdownSuccess, getSupplierDropdownError, getSupplierDropdownSuccess, getSupplierPartSkuDetailsError, getSupplierPartSkuDetailsSuccess, sentMailToStockInspectionError, sentMailToStockInspectionSuccess, skuStatusError, skuStatusSuccess, updateStockInspectionError, updateStockInspectionSuccess } from "./action";
import { StockInspection } from "src/models/pages/stockinspectionmodel";



function* getStockInspectionListAsync(action: any) {

  try {
    const response: Promise<any> = yield call(
      API.getStockInspectionListAPI, {
      ...action.lazyState,
    }
    );
    yield put(getStockInspectionListSuccess(response));
  } catch (error) {
    yield put(getStockInspectionListError(error));
  }
}

function* deleteStockInspectionAsync(action: any) {

  try {
    const response: Promise<any> = yield call(
      API.deleteStockInspectionApi, { id: action.payload.id, undo: action.payload.undo }
    );
    yield put(deleteStockInspectionFromListSuccess(response));
    yield put(getStockInspectionList(action.payload.lazyState));
  } catch (error) {
    yield put(deleteStockInspectionFromListError(error));
  }
}



// create parts
function* createStockInspectionAsync({ payload }: any) {
  try {
    const response: StockInspection = yield call(API.createStockInspection, payload);
    yield put(createStockInspectionSuccess(response,payload));

  } catch (error) {
    // yield put(CreateMelUserError(error));
    yield put(createStockInspectionError(error));
  }
}

//  update parts
function* updateStockInspectionAsync({
  payload: { stockInspection, stockInspectionId },
}: any): Generator<any, void, any> {
  try {
    const { data } = yield call(API.updateStockInspection, stockInspection, stockInspectionId);
    yield put(updateStockInspectionSuccess(data));

  } catch (error) {
    yield put(updateStockInspectionError(error));
  }
}

function* fetchStockInspectionById({ payload }: any): Generator<any, void, any> {

  try {
    let { data } = yield call(API.getStockInspectionByIDAPI, payload);
    yield put(getStockInspectionBYIDSuccess(data));

  } catch (error) {
    yield put(getStockInspectionBYIDError(error));
  }
}
function* fetchPartSkuListById({ payload }: any): Generator<any, void, any> {

  try {
    let { data } = yield call(API.getStockInspectionPartSkuByIDAPI, payload);
    yield put(getPartSkuListBYIDSuccess(data));

  } catch (error) {
    yield put(getPartSkuListBYIDError(error));
  }
}

function* fetchAudioBrandDropdown({ payload }: any): Generator<any, void, any> {

  try {
    let { data } = yield call(API.getStockInspectionAudioBrandListFromMasterManagement);
    yield put(getAudioBrandDropdownSuccess(data));

  } catch (error) {
    yield put(getAudioBrandDropdownError(error));
  }
}
function* fetchStockLocationDropdown({ payload }: any): Generator<any, void, any> {

  try {
    let { data } = yield call(API.getStockLocationFromMasterManagement,'');
    yield put(getStockLocationDropdownSuccess(data));

  } catch (error) {
    yield put(getStockLocationDropdownError(error));
  }
}

function* getSupplierDropdownAsync() {
  try {
    const response: Promise<any> = yield call(API.getSupplierListFromMasterManagement,'');
    yield put(getSupplierDropdownSuccess(response));
  } catch (err) {
    yield put(getSupplierDropdownError(err));
  }
}

function* getInitiatedBYDropdownAsync() {
  try {
    const response: Promise<any> = yield call(API.getInitiatedByDropdownApiforStockInspection);
    yield put(getInitiatedByDropdownSuccess(response));
  } catch (err) {
    yield put(getInitiatedByDropdownError(err));
  }
}
function* getSupplierPartSkuDetailsAsync({ payload, stockLocationId }: any) {

  try {
    const response: Promise<any> = yield call(API.getSupplierPartSkuDetailApi, payload, stockLocationId);
    yield put(getSupplierPartSkuDetailsSuccess(response));
  } catch (err) {
    yield put(getSupplierPartSkuDetailsError(err));
  }
}

function* getAudioBrandPartSkuDetailsAsync({ payload, stockLocationId }: any) {

  try {
    const response: Promise<any> = yield call(API.getAudioBrandPartSkuDetailApi, payload, stockLocationId);
    yield put(getAudioBrandPartSkuDetailsSuccess(response));
  } catch (err) {
    yield put(getAudioBrandPartSkuDetailsError(err));
  }
}

function* getScanPartSkuDetailsAsync({ payload }: any) {
  try {
    const response: Promise<any> = yield call(API.getScanPartSkuStockInspection, payload);
    yield put(getPostPartSkuSuccess(response));
  } catch (err) {
    yield put(getPostPartSkuError(err));
  }
}

function* getScanPartSkuWIthLocationDetailsAsync({ payload }: any) {
  try {
    const response: Promise<any> = yield call(API.getScanPartSkuWithLocationStockInspection, payload);
    yield put(getPostPartSkuSuccess(response));
  } catch (err) {
    yield put(getPostPartSkuError(err));
  }
}

function* getSkuNumbersWithStatus({ payload, isSupplier, isEdit }: any) {
  try {

    if(!isEdit){
      let { raw } = yield call(API.checkPendingInspection,payload, isSupplier);
      
      if(raw){

        const response: Promise<any> = yield call(API.getSkuNumberWithStatus, payload, isSupplier);
        yield put(getSkuNumberWithStatusSuccess(response, payload?.status));  
      }
    }else{
        const response: Promise<any> = yield call(API.getSkuNumberWithStatus, payload, isSupplier);
        yield put(getSkuNumberWithStatusSuccess(response, payload?.status));  
    }
    
    
  } catch (err) {
    yield put(getSkuNumberWithStatusFail(err));
  }
}

function* getSkuStatus({ payload }: any) {
  let query = payload.reduce((acc: string, each: { barCode: string; }, index: number) => {
    index === 0 ? acc += `?skuNos=${each.barCode}` : acc += `&skuNos=${each.barCode}`;
    return acc;
  }, "");
  try {
    const response: Promise<any> = yield call(API.getSkuStatuses, query);
    yield put(skuStatusSuccess(response));
  } catch (err) {
    yield put(skuStatusError(err));
  }
}

function* sentMailToStockInspectionAsync(action : any) {
    try {
      const response : Promise<any> = yield call(API.sentMailToStockInspection,action.payload )
      yield put(sentMailToStockInspectionSuccess(response));
    } catch (error) {
      yield put(sentMailToStockInspectionError(error));
    }
  }

function* downloadStockInspectionAsync(action : any) {
    try {
      const response : Promise<any> = yield call(API.downloadStockInspectionAPI,action.payload )
      yield put(downloadStockInspectionSuccess(response));
    } catch (error) {
      yield put(downloadStockInspectionError(error));
    }
  }

function* downloadUnUsedSkuAsync(action : any) {
    try {
      const response : Promise<any> = yield call(API.downloadUnusedSkuAPI,action.payload )
      yield put(downloadUnUsedSkusSuccess(response));
    } catch (error) {
      yield put(downloadUnUsedSkusError(error));
    }
  }

function* stockInspectionSaga() {
  yield takeLatest(StockInspectionTypes.GET_STOCK_INSPECTION_LIST, getStockInspectionListAsync);
  yield takeLatest(StockInspectionTypes.DELETE_STOCK_INSPECTION, deleteStockInspectionAsync);
  yield takeLatest(StockInspectionTypes.POST_STOCK_INSPECTION, createStockInspectionAsync);
  yield takeLatest(StockInspectionTypes.GET_STOCK_INSPECTION_BY_ID, fetchStockInspectionById);
  yield takeLatest(StockInspectionTypes.UPDATE_STOCK_INSPECTION, updateStockInspectionAsync);
  yield takeLatest(StockInspectionTypes.GET_PART_SKU_LIST, fetchPartSkuListById);
  yield takeLatest(StockInspectionTypes.GET_AUDIO_BRAND_DROPDOWN_LIST, fetchAudioBrandDropdown);
  yield takeLatest(StockInspectionTypes.GET_STOCK_LOCATION_DROPDOWN, fetchStockLocationDropdown);
  yield takeLatest(StockInspectionTypes.GET_SUPPLIER_DROPDOWN, getSupplierDropdownAsync);
  yield takeLatest(StockInspectionTypes.GET_INITIATED_BY_DROPDOWN, getInitiatedBYDropdownAsync);
  yield takeLatest(StockInspectionTypes.GET_SUPPLIER_PART_SKU_DETAILS, getSupplierPartSkuDetailsAsync);
  yield takeLatest(StockInspectionTypes.GET_AUDIO_BRAND_PART_SKU_DETAILS, getAudioBrandPartSkuDetailsAsync);
  yield takeLatest(StockInspectionTypes.GET_SCAN_PART_SKU, getScanPartSkuDetailsAsync);
  yield takeLatest(StockInspectionTypes.SKUS_STATUS, getSkuStatus);
  yield takeLatest(StockInspectionTypes.GET_SCAN_PART_SKU_WITH_LOCATION, getScanPartSkuWIthLocationDetailsAsync);
  yield takeEvery(StockInspectionTypes.GET_SKU_NUMBER_WITH_STATUS, getSkuNumbersWithStatus);
  yield takeEvery(StockInspectionTypes.SENT_EMAIL_TO_STOCK_INSPECTION, sentMailToStockInspectionAsync);
  yield takeEvery(StockInspectionTypes.DOWNLOAD_STOCK_INSPECTION, downloadStockInspectionAsync);
  yield takeEvery(StockInspectionTypes.DOWNLOAD_UNSCANNED_SKU_LOADING, downloadUnUsedSkuAsync);
}

export default stockInspectionSaga;