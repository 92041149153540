export enum FranchiseeType {

    //Grid
    GET_FRANCHISEE_LIST_LOADING = "@@franchisee/GET_FRANCHISEE_LIST_LOADING",
    GET_FRANCHISEE_LIST_SUCCESS = "@@franchisee/GET_FRANCHISEE_LIST_SUCCESS",
    GET_FRANCHISEE_LIST_ERROR = "@@franchisee/GET_FRANCHISEE_LIST_ERROR",

    //Delete Grid
    DELETE_FRANCHISEE_LOADING = "@@franchisee/DELETE_FRANCHISEE_LOADING",
    DELETE_FRANCHISEE_SUCCESS = "@@franchisee/DELETE_FRANCHISEE_SUCCESS",
    DELETE_FRANCHISEE_ERROR = "@@franchisee/DELETE_FRANCHISEE_ERROR",

    //reset franchisee data form
    RESET_FRANCHISEE = "@@franchisee/RESET_FRANCHISEE",

    //reset franchisee data form
    CLEAR_FRANCHISEE_MESSAGE = "@@franchisee/CLEAR_FRANCHISEE_MESSAGE",

    //Create Franchisee
    CREATE_FRANCHISEE_LOADING = "@@franchisee/CREATE_FRANCHISEE_LOADING",
    CREATE_FRANCHISEE_SUCCESS = "@@franchisee/CREATE_FRANCHISEE_SUCCESS",
    CREATE_FRANCHISEE_ERROR = "@@franchisee/CREATE_FRANCHISEE_ERROR",

    //Update Franchisee
    UPDATE_FRANCHISEE_LOADING = "@@franchisee/UPDATE_FRANCHISEE_LOADING",
    UPDATE_FRANCHISEE_SUCCESS = "@@franchisee/UPDATE_FRANCHISEE_SUCCESS",
    UPDATE_FRANCHISEE_ERROR = "@@franchisee/UPDATE_FRANCHISEE_ERROR",

    //getFranchise details by id
    GET_FRANCHISEEBYID_LOADING = "@@franchisee/GET_FRANCHISEEBYID_LOADING",
    GET_FRANCHISEEBYID_SUCCESS = "@@franchisee/GET_FRANCHISEEBYID_SUCCESS",
    GET_FRANCHISEEBYID_ERROR = "@@franchisee/GET_FRANCHISEEBYID_ERROR",

    // Franchise image

    FRANCHISEE_FILE_UPLOAD_LOADING = "@@franchisee/FRANCHISEE_FILE_UPLOAD_LOADING",
    FRANCHISEE_FILE_UPLOAD_SUCCESS = "@@franchisee/FRANCHISEE_FILE_UPLOAD_SUCCESS",
    FRANCHISEE_FILE_UPLOAD_ERROR = "@@franchisee/FRANCHISEE_FILE_UPLOAD_ERROR",

    FRANCHISEE_FILE_FETCH_LOADING = "@@franchisee/FRANCHISEE_FILE_FETCH_LOADING",
    FRANCHISEE_FILE_FETCH_SUCCESS = "@@franchisee/FRANCHISEE_FILE_FETCH_SUCCESS",
    FRANCHISEE_FILE_FETCH_ERROR = "@@franchisee/FRANCHISEE_FILE_FETCH_ERROR",

    FRANCHISEE_FILE_DELETE_LOADING = "@@franchisee/FRANCHISEE_FILE_DELETE_LOADING",
    FRANCHISEE_FILE_DELETE_SUCCESS = "@@franchisee/FRANCHISEE_FILE_DELETE_SUCCESS",
    FRANCHISEE_FILE_DELETE_ERROR = "@@franchisee/FRANCHISEE_FILE_DELETE_ERROR",


    FRANCHISEE_FILE_CLEAR = "@@franchisee/FRANCHISEE_FILE_CLEAR",

    //documnet upload

    FRANCHISEE_DOCUMENT_UPLOADED_LOADING = "@@franchisee/FRANCHISEE_DOCUMENT_UPLOADED_LOADING",
    FRANCHISEE_DOCUMENT_UPLOADED_SUCCESS = "@@franchisee/FRANCHISEE_DOCUMENT_UPLOADED_SUCCESS",
    FRANCHISEE_DOCUMENT_UPLOADED_ERROR = "@@franchisee/FRANCHISEE_DOCUMENT_UPLOADED_ERROR",

    //get document list
    FRANCHISEE_GET_UPLOADED_DOCUMENTED_LOADING = "@@franchisee/FRANCHISEE_GET_UPLOADED_DOCUMENTED_LOADING",
    FRANCHISEE_GET_UPLOADED_DOCUMENTED_SUCCESS = "@@franchisee/FRANCHISEE_GET_UPLOADED_DOCUMENTED_SUCCESS",
    FRANCHISEE_GET_UPLOADED_DOCUMENTED_ERROR = "@@franchisee/FRANCHISEE_GET_UPLOADED_DOCUMENTED_ERROR",

    //delete document list

    FRANCHISE_UPLOADED_DOCUMENT_DELETE_LOADING = "@@franchisee/FRANCHISE_UPLOADED_DOCUMENT_DELETE_LOADING",
    FRANCHISE_UPLOADED_DOCUMENT_DELETE_SUCCESS = "@@franchisee/FRANCHISE_UPLOADED_DOCUMENT_DELETE_SUCCESS",
    FRANCHISE_UPLOADED_DOCUMENT_DELETE_ERROR = "@@franchisee/FRANCHISE_UPLOADED_DOCUMENT_DELETE_ERROR",

    //generate document link

    FRANCHISE_GENERATE_DOCUMENT_LINK_LOADING = "@@franchisee/FRANCHISE_GENERATE_DOCUMENT_LINK_LOADING",
    FRANCHISE_GENERATE_DOCUMENT_LINK_SUCCESS = "@@franchisee/FRANCHISE_GENERATE_DOCUMENT_LINK_SUCCESS",
    FRANCHISE_GENERATE_DOCUMENT_LINK_ERROR = "@@franchisee/FRANCHISE_GENERATE_DOCUMENT_LINK_ERROR",


  FRANCHISE_SET_PHONE_LOADING="@@franchisee/FRANCHISE_SET_PHONE_LOADING",
  FRANCHISE_SET_PHONE_SUCCESS="@@franchisee/FRANCHISE_SET_PHONE_SUCCESS",
  FRANCHISE_SET_PHONE_ERROR="@@franchisee/FRANCHISE_SET_PHONE_ERROR",

  FRANCHISE_SET_EMAIL_LOADING="@@franchisee/FRANCHISE_SET_EMAIL_LOADING",
  FRANCHISE_SET_EMAIL_SUCCESS="@@franchisee/FRANCHISE_SET_EMAIL_SUCCESS",
  FRANCHISE_SET_EMAIL_ERROR="@@franchisee/FRANCHISE_SET_EMAIL_ERROR",

  SEND_OTP_FRANCHISE ="@@franchisee/SEND_OTP_FRANCHISE",
  SEND_OTP_FRANCHISE_SUCCESS ="@@franchisee/SEND_OTP_FRANCHISE_SUCCESS",
  SEND_OTP_FRANCHISE_ERROR ="@@franchisee/SEND_OTP_FRANCHISE_ERROR",

  RESEND_FRANCHISE_OTP = "@@franchisee/RESEND_FRANCHISE_OTP",
  RESEND_FRANCHISE_OTP_SUCCESS = "@@franchisee/RESEND_FRANCHISE_OTP_SUCCESS",
  RESEND_FRANCHISE_OTP_ERROR = "@@franchisee/RESEND_FRANCHISE_OTP_ERROR",
  
  RESET_FRANCHISE_OTP_MESSAGE = "@@franchisee/RESET_FRANCHISE_OTP_MESSAGE",

  GET_FRANCHISE_COMPANY_ADDRESS_LOCATION_LOADING="@@franchisee/GET_FRANCHISE_COMPANY_ADDRESS_LOCATION_LOADING",
  GET_FRANCHISE_COMPANY_ADDRESS_LOCATION_SUCCESS="@@franchisee/GET_FRANCHISE_COMPANY_ADDRESS_LOCATION_SUCCESS",
  GET_FRANCHISE_COMPANY_ADDRESS_LOCATION_ERROR="@@franchisee/GET_FRANCHISE_COMPANY_ADDRESS_LOCATION_ERROR",

  GET_FRANCHISE_HOME_ADDRESS_LOCATION_LOADING="@@franchisee/GET_FRANCHISE_HOME_ADDRESS_LOCATION_LOADING",
  GET_FRANCHISE_HOME_ADDRESS_LOCATION_SUCCESS="@@franchisee/GET_FRANCHISE_HOME_ADDRESS_LOCATION_SUCCESS",
  GET_FRANCHISE_HOME_ADDRESS_LOCATION_ERROR="@@franchisee/GET_FRANCHISE_HOME_ADDRESS_LOCATION_ERROR",

  GET_FRANCHISE_CONFIRMATION_SKU_LOADING="@@franchisee/GET_FRANCHISE_CONFIRMATION_SKU_LOADING",
  GET_FRANCHISE_CONFIRMATION_SKU_SUCCESS="@@franchisee/GET_FRANCHISE_CONFIRMATION_SKU_SUCCESS",
  GET_FRANCHISE_CONFIRMATION_SKU_ERROR="@@franchisee/GET_FRANCHISE_CONFIRMATION_SKU_ERROR",

  GET_FRANCHISE_CONSUMPTION_SKU_LOADING="@@franchisee/GET_FRANCHISE_CONSUMPTION_SKU_LOADING",
  GET_FRANCHISE_CONSUMPTION_SKU_SUCCESS="@@franchisee/GET_FRANCHISE_CONSUMPTION_SKU_SUCCESS",
  GET_FRANCHISE_CONSUMPTION_SKU_ERROR="@@franchisee/GET_FRANCHISE_CONSUMPTION_SKU_ERROR",
}