import { CarBrandsTypes } from "./actionTypes";
import { carBrandsInitialState } from "src/models/pages/carBrandInitialState";




const initialState: carBrandsInitialState = {
  error: "",
  loading: false,
  message: "",
  paginatorCount: 0,
  carBrandsDetails: null,
  carBrandsList: {
    values: [],
    totalRecords: 0
  },
  vehicleBrandDropdown:""
};


const carBrandReducer = (state = initialState, action: any) => {
  switch (action.type) {

    // Grid

    case CarBrandsTypes.GET_CAR_BRANDS_LIST:
    case CarBrandsTypes.UPDATE_CAR_BRANDS:
    case CarBrandsTypes.POST_CAR_BRANDS:
    case CarBrandsTypes.GET_CAR_BRANDS_BY_ID:
    case CarBrandsTypes.DELETE_CAR_BRANDS:
    case CarBrandsTypes.GET_VEHICLE_BRAND_DROPDOWN_LOADING:
      return {
        ...state,
        error: "",
        message: "",
        loading: true,
        paginatorCount: 0
      };

    case CarBrandsTypes.GET_CAR_BRANDS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        carBrandsList: {
          values: action.payload.data.items,
          totalRecords: action.payload.data.totalCount,
        },
        paginatorCount: action.payload.data.items?.length
      };

    case CarBrandsTypes.UPDATE_CAR_BRANDS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "Vehicle Model is updated successfully"
      };

    case CarBrandsTypes.POST_CAR_BRANDS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "Vehicle Model is created successfully"
      };

    case CarBrandsTypes.GET_CAR_BRANDS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        carBrandsDetails: action.payload
      };

    case CarBrandsTypes.GET_VEHICLE_BRAND_DROPDOWN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        vehicleBrandDropdown: action.payload
      };

    case CarBrandsTypes.GET_CAR_BRANDS_LIST_FAIL:
    case CarBrandsTypes.DELETE_CAR_BRANDS_FAIL:
    case CarBrandsTypes.UPDATE_CAR_BRANDS_FAIL:
    case CarBrandsTypes.POST_CAR_BRANDS_FAIL:
    case CarBrandsTypes.GET_CAR_BRANDS_BY_ID_FAIL:
    case CarBrandsTypes.GET_VEHICLE_BRAND_DROPDOWN_FAIL:
      return {
        ...state,
        loading: false,
        message: "",
        error: action.payload
      };

    case CarBrandsTypes.DELETE_CAR_BRANDS_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.undo ? "Vehicle Model restored successfully" : "Vehicle Model deleted successfully",
      }

    case CarBrandsTypes.RESET_CAR_BRANDS:
      return {
        ...state,
        error: "",
        loading: false,
        message: "",
      }

    case CarBrandsTypes.CLEAR_CAR_BRANDS:
      return {
        ...state,
        ...initialState
      }

    default:
      return { ...state };
  }
};

export default carBrandReducer;
