import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
// import { franchisee } from 'src/models/pages/franchiseeModel';
import { API } from 'src/util/api';
import { FranchiseeType } from './actionTypes';
import { createFranchiseeError, createFranchiseeSucess, deleteFranchiseeError, deleteFranchiseeImageError, deleteFranchiseeImageSuccess, deleteFranchiseeSucess, deleteUploadedeDocumentError, deleteUploadedeDocumentSuccess, franchiseCompanyAddressLatLagError, franchiseCompanyAddressLatLagSuccess, franchiseHomeAddressLatLagError, franchiseHomeAddressLatLagSuccess, generatedocumentLinkError, generatedocumentLinkSucces, getFranchiseConfirmationSkuError, getFranchiseConfirmationSkuSuccess, getFranchiseConsumptionSkuError, getFranchiseConsumptionSkuSuccess, getFranchiseeByIdError, getFranchiseeByIdSuccess, getFranchiseeError, getFranchiseeImageError, getFranchiseeImageSuccess, getFranchiseeListSuccess, getuploadedeDocumentinFranchiseError, getuploadedeDocumentinFranchiseSuccess, resendOtpFranchiseError, resendOtpFranchiseSuccess, sendFranchiseOtpError, sendFranchiseOtpSuccess, setFranchiseEmailIdError, setFranchiseEmailIdSuccess, setFranchiseMobileNumberError, setFranchiseMobileNumberSuccess, updateFranchiseeError, updateFranchiseeLoading, updateFranchiseeSuccess, uploadDocumentedinFranchiseeError, uploadDocumentedinFranchiseeSuccess, uploadFranchiseeFileError, uploadFranchiseeFileSuccess } from './action';


//worker function

//getFranchiseeList

function* getFranchiseeListAsync (action : any) {
    try {
        const response :Promise<any> = yield call(API.getFranchiseeListAPI,{ ...action.lazyState })
        yield put(getFranchiseeListSuccess(response))
    } catch (error) {
        yield put(getFranchiseeError(error))
    }
}


//create Franchisee
function* createFranchiseeAsync ({payload} : any) {
   try {
     const response : Promise<any> = yield call(API.createFranchisee , payload) ;
    yield put (createFranchiseeSucess(response))
   } catch (error) {
     yield put(createFranchiseeError(error))
   }
}

//get franchisee details by id

function* getInduvidualFranchisee ({payload} : any) {
  try {
     const response : Promise<any> = yield call(API.getIndividualFranchisee,payload)
     yield put(getFranchiseeByIdSuccess(response))
  } catch (error) {
    yield put(getFranchiseeByIdError(error))
  }
}

//update franchisee 

function* updateAsyncFranchisee (action : any) {
  try {
    const response : Promise<any> = yield call(API.updateFranchiseeApi,{
      ...action.payload},{id : action.id});
    yield put(updateFranchiseeSuccess())
    // yield put(getFranchiseeListSuccess([response]))
  } catch (error) {
    yield put(updateFranchiseeError(error))
  }
}

//delete franchisee

function* deleteAsyncFranchisee (action : any) {
   try {
     const response : Promise<any> = yield call(API.deleteFranchiseApi, {id : action.payload.id , undo:action.payload.undo });
     yield put(deleteFranchiseeSucess(response,action.payload.undo));
    //  yield put(getFranchiseeListSuccess(payload.lazyState)) ;
   } catch (error) {
    
     yield put(deleteFranchiseeError(error))
   }
}





function* getFranchiseImageAsync({ payload }: any) {
  try {
    const response: Promise<any> = yield call(API.fetchFranchiseImage, payload)
      yield put(getFranchiseeImageSuccess(response));
  } catch (error) {
    yield put(getFranchiseeImageError(error));
  }
}

function* deleteFranchiseImageAsync({ payload }: any) {
  try {
    const response: Promise<any> = yield call(API.deleteFranchiseImage, payload)

    yield put(deleteFranchiseeImageSuccess(response));
  } catch (error) {
    yield put(deleteFranchiseeImageError(error));
  }
}

//update customer
function* uploadFranchiseFileAsync(action: any) {
  try {

    const response: Promise<any>= yield call(API.uploadFranchiseImage, action.payload, { id: action.id });
      yield put(uploadFranchiseeFileSuccess(response))
    
  } catch (error) {
    yield put(uploadFranchiseeFileError(error))
  }
}

//upload document franchisee

function* uploadDocumentFranchiseeAsync(action : any){
  try {
    const response : Promise<any> = yield call(API.uploadFranchiseDocumentApi,action.payload,{id :action.id ,fileType : action.filetype})
    yield put(uploadDocumentedinFranchiseeSuccess(response))
  } catch (error) {
    yield put(uploadDocumentedinFranchiseeError(error))
  }
}

//getuploaded list
function* uploadDocumentGetFranchiseeAsync(action : any){
  try {
    const response : Promise<any> = yield call(API.getuploadFranchiseDocumentApi,action.payload)
    yield put(getuploadedeDocumentinFranchiseSuccess(response))
  } catch (error) {
    yield put(getuploadedeDocumentinFranchiseError(error))
  }
}

//delete uploaded list
function* uploadDocumentDeleteFranchiseeAsync(action : any){
  try {
    const response : Promise<any> = yield call(API.deleteuploadFranchiseDocumentApi,action.payload)
    yield put(deleteUploadedeDocumentSuccess(response))
  } catch (error) {
    yield put(deleteUploadedeDocumentError(error))
  }
}

//delete uploaded list
function* generateLinkAsync(action : any){
  try {
    const response : Promise<any> = yield call(API.generatedocumentLinkApi,action.payload)
    yield put(generatedocumentLinkSucces(response))
  } catch (error) {
    yield put(generatedocumentLinkError(error))
  }
}

function* setFranchiseMobileAsync(action: any) {
  debugger
  try {
    const response: Promise<any> = yield call(API.setCustomerPhoneApi, action.payload.id,action.payload.number);
    yield put(setFranchiseMobileNumberSuccess({response:response,number:action.payload.number}))
  } catch (error) {
    yield put(setFranchiseMobileNumberError(error))
  }
}

function* setFranchiseEmailAsync(action: any) {
  debugger
  try {
    const response: Promise<any> = yield call(API.setCFranchiseEmailApi, action.payload.id,action.payload.email);
    yield put(setFranchiseEmailIdSuccess(response))
  } catch (error) {
    yield put(setFranchiseEmailIdError(error))
  }
}

function* sendOtpAsync(action: any) {
  debugger
  try {
    const response: Promise<any> = yield call(API.sendCustomerOtp, action.payload.id,action.payload.code,action.payload.phoneNUmber);
    yield put(sendFranchiseOtpSuccess(response))
  } catch (error) {
    yield put(sendFranchiseOtpError(error))
  }
}

function* resendOtpAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.setCustomerPhoneApi, action.payload,action.number);
    yield put(resendOtpFranchiseSuccess({response:response,number:action.number}))
  } catch (error) {
    yield put(resendOtpFranchiseError(error))
  }
}



function* getlatLngCompanyAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getLatlngApi, action.payload);
    yield put(franchiseCompanyAddressLatLagSuccess(response, action.payload));
  } catch (error) {
    yield put(franchiseCompanyAddressLatLagError(error));
  }
}

function* getlatLngHomeAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getLatlngApi, action.payload);
    yield put(franchiseHomeAddressLatLagSuccess(response, action.payload));
  } catch (error) {
    yield put(franchiseHomeAddressLatLagError(error));
  }
}

function* getConformationSkuAsync(action:any) {
  try {
    const response: Promise<any> = yield call( API.getConformationSkuForfranchiseApi,action.payload)
    const response2: Promise<any> = yield call( API.getConformationSkuMailForfranchiseApi,action.payload)

    yield put(getFranchiseConfirmationSkuSuccess(response));
  } catch (error) {
    yield put(getFranchiseConfirmationSkuError(error));
  }
}

function* getConsumptionSkuAsync({payload}:any) {
  try {
    const response: Promise<any> = yield call( API.getConsumptionSkuForfranchiseApi,payload)
    const response2: Promise<any> = yield call( API.getConsumtionSkuMailForfranchiseApi,payload)

    yield put(getFranchiseConsumptionSkuSuccess(response));
  } catch (error) {
    yield put(getFranchiseConsumptionSkuError(error));
  }
}
//watcher function

function* franchiseeSaga() {
  yield takeLatest(FranchiseeType.GET_FRANCHISEE_LIST_LOADING , getFranchiseeListAsync);
  yield takeLatest(FranchiseeType.CREATE_FRANCHISEE_LOADING , createFranchiseeAsync);
  yield takeEvery(FranchiseeType.GET_FRANCHISEEBYID_LOADING, getInduvidualFranchisee);
  yield takeLatest(FranchiseeType.UPDATE_FRANCHISEE_LOADING, updateAsyncFranchisee);
  yield takeLatest(FranchiseeType.DELETE_FRANCHISEE_LOADING , deleteAsyncFranchisee);   
  yield takeLatest(FranchiseeType.FRANCHISEE_FILE_UPLOAD_LOADING, uploadFranchiseFileAsync);
  yield takeLatest(FranchiseeType.FRANCHISEE_FILE_FETCH_LOADING, getFranchiseImageAsync);
  yield takeLatest(FranchiseeType.FRANCHISEE_FILE_DELETE_LOADING, deleteFranchiseImageAsync);
  yield takeLatest(FranchiseeType.FRANCHISEE_DOCUMENT_UPLOADED_LOADING,uploadDocumentFranchiseeAsync);
  yield takeLatest(FranchiseeType.FRANCHISEE_GET_UPLOADED_DOCUMENTED_LOADING,uploadDocumentGetFranchiseeAsync);
  yield takeLatest(FranchiseeType.FRANCHISE_UPLOADED_DOCUMENT_DELETE_LOADING,uploadDocumentDeleteFranchiseeAsync);
  yield takeLatest(FranchiseeType.FRANCHISE_GENERATE_DOCUMENT_LINK_LOADING,generateLinkAsync);
  yield takeLatest(FranchiseeType.FRANCHISE_SET_PHONE_LOADING,setFranchiseMobileAsync);
  yield takeLatest(FranchiseeType.FRANCHISE_SET_EMAIL_LOADING,setFranchiseEmailAsync);
  yield takeLatest(FranchiseeType.SEND_OTP_FRANCHISE,sendOtpAsync);
  yield takeLatest(FranchiseeType.RESEND_FRANCHISE_OTP,resendOtpAsync);
  yield takeLatest(FranchiseeType.GET_FRANCHISE_COMPANY_ADDRESS_LOCATION_LOADING,getlatLngCompanyAsync);
  yield takeLatest(FranchiseeType.GET_FRANCHISE_HOME_ADDRESS_LOCATION_LOADING,getlatLngHomeAsync);
  yield takeLatest(FranchiseeType.GET_FRANCHISE_CONFIRMATION_SKU_LOADING,getConformationSkuAsync);
  yield takeLatest(FranchiseeType.GET_FRANCHISE_CONSUMPTION_SKU_LOADING,getConsumptionSkuAsync);

}

export default franchiseeSaga