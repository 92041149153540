import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Card, CardBody, Col, Container, Label, Row } from 'reactstrap';
import Breadcrumb from 'src/components/Common/Breadcrumb';

import InputCom from 'src/components/Common/Input';
import { useToast } from 'src/components/Common/ReactToaster';
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';
import Button from 'src/components/UI/Button';
import CheckboxInput from 'src/components/UI/CheckboxInput';
import { ROUTER } from 'src/constants/routes';
import FeatherIcon from "feather-icons-react";
import { clearFranchiseeMessage, createFranchiseeLoading, deleteFranchiseeImageLoading, deleteUploadedeDocumentLoading, franchiseCompanyAddressLatLagLoading, franchiseHomeAddressLatLagLoading, getFranchiseeByIdLoading, getFranchiseeImageLoading, getuploadedeDocumentinFranchiseLoading, resendOtpFranchise, resetFranchisee, resetOtpFranchiseMessage, sendFranchiseOtpLoading, setFranchiseEmailIdLoading, setFranchiseMobileNumberLoading, updateFranchiseeLoading, uploadDocumentedinFranchiseeLoading, uploadFranchiseeFileLoading, } from 'src/store/actions';
import { RootReducerState } from 'src/store/reducers';
import * as Yup from "yup";
import SubUserList from '../SubUserManagement/SubUserList';
import { FILE_UPLOAD_SIZE, HomeErrorMessage, errorMessage } from 'src/constants/commonConstants';
import DeleteModal from '../Calendar/DeleteModal';
import Dropzone from 'react-dropzone';
import { formatDateTOYYYYMMDD, getLocationLatLng, onDropRejectedFileHandler } from 'src/helpers/common_helpers';
import ImagePreviewer from 'src/components/Common/ImagePreviewer/ImagePreviewer';
import { franchiseImageDeleteSuccess, franchisedocumentDeleteSuccess } from 'src/store/franchisee/reducer';
import moment from 'moment';
import { Chips } from 'primereact/chips';
import OtpScreen from '../Customers/OtpScreen';
import { Dialog } from 'primereact/dialog';
import { APPENV_URL } from 'src/util/appUrlEnv';

const AddEditFranchisee: React.FC = () => {
  const { error, message, franchiseeDetails, loading, franchiseImage, getUploadedList, franchisePhoneMessage, franchiseMailMessage, editedMobileNumber, createdData, otpmessage, updatedmessage,companyLatLng,homeLatLng } = useSelector((state: RootReducerState) => state.franchiseeReducer);

  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [customError, setcustomError] = useState(false);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const [modifiedFranchise, setModifiedFranchise] = useState<any>({});
  const [panCardFile, setPancardFile] = useState<any>([]);
  const [gstFile, setGstFile] = useState<any>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [address, setAddress] = useState<any>("");
  const [companyAddress, setCompanyAddress] = useState<any>("");
  const [latitude, setLatitude] = useState<any>(null);
  const [longitude, setLongitude] = useState<any>(null);
  const [mapSelectedPlace, setMapSelectedPlace] = useState("");
  const [apiKey, setApiKey] = useState<string | null>(null);

  const { id }: any = useParams();
  const { showToast } = useToast();
  const navigate = useNavigate();


  useEffect(() => {
    const loginToken = localStorage.getItem('token');
    const fetchApiKey = async () => {
      try {
        const response = await fetch(APPENV_URL.AppUrl + "/" + `mel-setting-management/gcp-setting`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json; text/plain",
            "Authorization": `Bearer ${loginToken}`
          },
        });
        if (response.ok) {
          const data = await response.json();
          setApiKey(data.mapKey);
        }
      } catch (error) {
        console.error("Error fetching the API key", error);
      }
    };

    fetchApiKey();
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getFranchiseeByIdLoading(id));
      dispatch(getFranchiseeImageLoading(id));
    }

  }, [id]);
  useEffect(() => {
    return () => {
      dispatch(resetFranchisee());
      setLatitude("");
      setLongitude("");
      setAddress("");
      setCompanyAddress("");
    };
  }, []);

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);
  useEffect(() => {
    if (id) {
      dispatch(getFranchiseeImageLoading(id));
      dispatch(getuploadedeDocumentinFranchiseLoading(id));
    }
  }, [id]);

  useEffect(() => {
    if (!id) {
      getLocationLatLng(setLatitude, setLongitude);
    }
  }, []);
  useEffect(() => {
    if (createdData) {
      debugger;
      dispatch(setFranchiseMobileNumberLoading(createdData?.user?.phoneNumber, createdData?.user?.id));

    }
  }, [createdData]);

  useEffect(() => {
    if (modifiedFranchise.companyAddress) {
      setCompanyAddress(modifiedFranchise.companyAddress);
      dispatch(franchiseCompanyAddressLatLagLoading(modifiedFranchise.companyAddress))
    }
    if (modifiedFranchise.address) {
      dispatch(franchiseHomeAddressLatLagLoading(modifiedFranchise.address))
    }
  }, [modifiedFranchise]);

  useEffect(() => {
    if (franchiseeDetails && id) {
      const modifiedfield: any = {
        name: franchiseeDetails?.user?.name,
        userName: franchiseeDetails?.user?.userName,
        email: franchiseeDetails?.user?.email,
        phoneNumber: franchiseeDetails?.user?.phoneNumber,
        sendSms: franchiseeDetails?.user?.extraProperties?.SendSms,
        sendEmail: franchiseeDetails?.user?.extraProperties?.SendEmail,
        sendWhatsapp: franchiseeDetails?.user?.extraProperties?.SendWhatsapp,
        gstNumber: franchiseeDetails?.user?.extraProperties?.GstNumber,
        companyName: franchiseeDetails?.companyName,
        address: franchiseeDetails?.user?.extraProperties?.Address,
        isActive: franchiseeDetails?.user?.isActive,
        isVerfied: franchiseeDetails?.user?.extraProperties?.IsVerfied,
        lockoutEnabled: franchiseeDetails?.user?.lockoutEnabled,
        userId: franchiseeDetails?.user?.id,
        proprietorName: franchiseeDetails?.proprietorName,
        companyAddress: franchiseeDetails?.companyAddress,
        alternateAddress: franchiseeDetails?.alternateAddress,
        surname: franchiseeDetails?.user?.surname,
        alternateNumber : franchiseeDetails?.user?.extraProperties?.alternateNumber,
        city : franchiseeDetails?.city,
        region : franchiseeDetails?.region,
        pincode : franchiseeDetails?.pincode
      };
      setModifiedFranchise(modifiedfield);
    }
  }, [franchiseeDetails]);


  const preventSpecialChar = (event: any) => {
    if ((event.which >= 106 && event.which <= 111) || (event.which >= 187 && event.which <= 191) || event.which === 69) {
      event.preventDefault();
    }
  };

  ///new work

  const initialvalue = {
    name: "",
    userName: "",
    email: "",
    password: "",
    confirmpassword: "",
    phoneNumber: "",
    sendSms: false,
    sendEmail: false,
    sendWhatsapp: false,
    gstNumber: "",
    companyName: "",
    address: "",
    isVerfied: false,
    isActive: false,
    lockoutEnabled: false,
    userId: "",
    proprietorName: [],
    panCardNo: "",
    homeAddress: "",
    alternateAddress: "",
    surname: "",
    companyAddress: "",
    alternateNumber:"",
    city:"",
    region:"",
    pincode:"",
  };

  const editInitialValue = {
    name: modifiedFranchise.name ? modifiedFranchise.name : "",
    userName: modifiedFranchise.userName ? modifiedFranchise.userName : "",
    email: modifiedFranchise.email ? modifiedFranchise.email : "",
    phoneNumber: modifiedFranchise.phoneNumber ? modifiedFranchise.phoneNumber : "",
    sendSms: modifiedFranchise.sendSms ? modifiedFranchise.sendSms : false,
    sendEmail: modifiedFranchise.sendEmail ? modifiedFranchise.sendEmail : false,
    sendWhatsapp: modifiedFranchise.sendWhatsapp ? modifiedFranchise.sendWhatsapp : false,
    companyName: modifiedFranchise.companyName ? modifiedFranchise.companyName : "",
    address: modifiedFranchise.address ? modifiedFranchise.address : "",
    isVerfied: modifiedFranchise.isVerfied ? modifiedFranchise.isVerfied : false,
    isActive: modifiedFranchise.isActive ? modifiedFranchise.isActive : false,
    lockoutEnabled: modifiedFranchise.lockoutEnabled ? modifiedFranchise.lockoutEnabled : false,
    userId: modifiedFranchise.userId,
    proprietorName: modifiedFranchise.proprietorName ? modifiedFranchise.proprietorName : [],
    companyAddress: modifiedFranchise.companyAddress ? modifiedFranchise.companyAddress : "",
    alternateAddress: modifiedFranchise.alternateAddress ? modifiedFranchise.alternateAddress : "",
    surname: modifiedFranchise?.surname ? modifiedFranchise.surname : "",
    gstNumber: franchiseeDetails?.user?.extraProperties?.GstNumber ? franchiseeDetails?.user?.extraProperties?.GstNumber : "",
    panCardNo: franchiseeDetails?.user?.extraProperties?.PANCardNo ? franchiseeDetails?.user?.extraProperties?.PANCardNo : "",
    weddingDate: franchiseeDetails?.user?.extraProperties?.WeddingDate ? formatDateTOYYYYMMDD(franchiseeDetails?.user?.extraProperties?.WeddingDate) : "",
    dateOfBirth: franchiseeDetails?.user?.extraProperties?.DateOfBirth ? formatDateTOYYYYMMDD(franchiseeDetails?.user?.extraProperties?.DateOfBirth) : "",
    alternateNumber :franchiseeDetails?.user?.extraProperties?.AlternateNumber ? franchiseeDetails?.user?.extraProperties?.AlternateNumber: "",
    city :franchiseeDetails?.city ? franchiseeDetails?.city: "",
    region :franchiseeDetails?.region ? franchiseeDetails?.region: "",
    pincode :franchiseeDetails?.pincode ? franchiseeDetails?.pincode: "",
  };
  const [dobError, setDobError] = useState("");
  const [weddingDateError, setWeddingDateError] = useState("");
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(`Name is required`),
    userName: Yup.string().required(`User name is required`),
    email: Yup.string()
      .required('Email is required')
      .email('Enter a valid email')
      .test('valid-email', 'Enter a valid email', function (value) {
        if (!value) return true; // Let Yup handle the "required" validation
        return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(value);
      }),
    phoneNumber: Yup.string().required(`Mobile number is required`).matches(/^\d{10}$/, 'Mobile number must be 10 digits'),
    // password: Yup.string().required(`Password is required`),
    // confirmpassword: Yup.string().required('Confirm password is required')
    //   .oneOf([Yup.ref('password')], 'Passwords must match'),
    // gstNumber: Yup.string().matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/, 'Invalid GST number').required('GST number is required'),
    gstNumber: Yup.string().matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/, 'Invalid GST number'),
    companyName: Yup.string().required(`Company Name is required`),
    panCardNo: Yup.string().matches(/^[A-Z]{5}[0-9]{4}[A-Z]$/, 'PAN number must be valid'),
    // address: Yup.string().matches(/[\s\S]*\b\d{6}\b$/, 'Enter with valid pincode Ex:-(chennai 600028)').required("Address is required"),
    dateOfBirth: Yup.date().test('not-after-today', "Date of birth must not be today or future date", function (value) {
      const currentDate = moment();
      const { dateOfBirth } = this.parent;

      if (dateOfBirth) {
        if (value && moment(value).isSame(currentDate, 'day') || value && moment(value).isAfter(currentDate)) {
          return false;
        }
      }
      setDobError("");
      return true;
    }),
    weddingDate: Yup.date().test('after-date-of-birth', "Wedding date must not be same or before dob", function (value) {
      const { dateOfBirth } = this.parent;
      const dobDate = moment(dateOfBirth);
      if (dateOfBirth) {
        if (value && moment(value).isSame(dateOfBirth, 'day') || value && moment(value).isBefore(dateOfBirth)) {
          return false;
        }
      }
      setWeddingDateError("");
      return true;
    }),
    address: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    region: Yup.string().required("Region is required"),
    pincode: Yup.number()
    .required("Pincode is required")
    .test(
      "len",
      "Pincode must be exactly 6 digits",
      (val:any) => val && val.toString().length === 6
    )
    // companyAddress: Yup.string().matches(/[\s\S]*\b\d{6}\b$/, 'Enter with valid pincode Ex:-(chennai 600028)'),
    // alternateAddress: Yup.string().matches(/[\s\S]*\b\d{6}\b$/, 'Enter with valid pincode Ex:-(chennai 600028)'),

  });

  const EditValidation = Yup.object().shape({
    name: Yup.string().required(`Name is required`),
    userName: Yup.string().required(`User name is required`),
    email: Yup.string()
      .required('Email is required')
      .email('Enter a valid email')
      .test('valid-email', 'Enter a valid email', function (value) {
        if (!value) return true;
        return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(value);
      }),
    // proprietorName: Yup.array()
    // .of(Yup.string().required('Proprietor name is required'))
    // .min(1, 'At least one proprietor name is required'),
    dateOfBirth: Yup.date().test('not-after-today', "Date of birth must not be today or future date", function (value) {
      const currentDate = moment();
      const { dateOfBirth } = this.parent;

      if (dateOfBirth) {
        if (value && moment(value).isSame(currentDate, 'day') || value && moment(value).isAfter(currentDate)) {
          return false;
        }
      }
      setDobError("");
      return true;
    }),
    weddingDate: Yup.date().test('after-date-of-birth', "Wedding date must not be same or before dob", function (value) {
      const { dateOfBirth } = this.parent;
      const dobDate = moment(dateOfBirth);
      if (dateOfBirth) {
        if (value && moment(value).isSame(dateOfBirth, 'day') || value && moment(value).isBefore(dateOfBirth)) {
          return false;
        }
      }
      setWeddingDateError("");
      return true;
    }),

    phoneNumber: Yup.string().required(`Mobile number is required`).matches(/^\d{10}$/, 'Mobile number must be 10 digits'),
    gstNumber: Yup.string().matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/, 'Invalid GST number'),
    // .required('GST number is required'),
    panCardNo: Yup.string().matches(/^[A-Z]{5}[0-9]{4}[A-Z]$/, 'PAN number must be valid'),
    companyName: Yup.string().required(`Company Name is required`),
    address: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    region: Yup.string().required("Region is required"),
    pincode: Yup.number()
    .required("Pincode is required")
    .test(
      "len",
      "Pincode must be exactly 6 digits",
      (val:any) => val && val.toString().length === 6
    )
    // alternateAddress: Yup.string().matches(/[\s\S]*\b\d{6}\b$/, 'Enter with valid pincode Ex:-(chennai 600028)'),
  });

  useEffect(() => {
    setAddress(modifiedFranchise.address);
  }, [modifiedFranchise]);
  const createOrUpdateFunction = (values: any, isCreate: boolean, id?: string) => {
    debugger;
    if (isSubmitting) return;

    // if (!address) {
    //   return setcustomError(true)
    // } 
    // if (!companyAddress) {
    //   return setcustomError(true)
    // }

    const modifiedData: any = {
      companyName: values.companyName,
      proprietorName: values.proprietorName ? values.proprietorName : null,
      companyAddress: values.companyAddress,
      alternateAddress: values.alternateAddress,
      city:values.city,
      region:values.region,
      pincode:String(values.pincode),
      user: {
        name: values.name,
        userName: values.userName,
        surname: values.surname,
        email: values.email,
        dateOfBirth: values.dateOfBirth,
        weddingDate: values.weddingDate,
        panCardNo: values.panCardNo,
        homeAddress: values.homeAddress,
        phoneNumber: id ? null : values.phoneNumber.toString(),
        gstNumber: values.gstNumber,
        password: values.password,
        address: values.address,
        sendSms: values.sendSms,
        sendEmail: values.sendEmail,
        sendWhatsapp: values.sendWhatsapp,
        isActive: values.isActive,
        lockoutEnabled: values.lockoutEnabled,
        isVerfied: values.isVerfied,
        alternateNumber : values?.alternateNumber,
        city:values.city,
        region:values.region,
        pincode:String(values.pincode),
      }
    };
    if (!isCreate) {
      modifiedData['user']['id'] = values.userId;
    }
    const action = isCreate ? createFranchiseeLoading : updateFranchiseeLoading;
    setIsSubmitting(true);
    dispatch(action(modifiedData, id));
    if (selectedFiles.length > 0) {
      dispatch(uploadFranchiseeFileLoading(selectedFiles[0], id));
    }
    if (gstFile?.length > 0) {
      dispatch(uploadDocumentedinFranchiseeLoading(gstFile[0], id, "GstNumber"));
    }
    if (panCardFile?.length > 0) {
      dispatch(uploadDocumentedinFranchiseeLoading(panCardFile[0], id, "pancard"));
    }
  };

  const handleSubmit = (values: any) => {
    debugger;
    if (isSubmitting) return;
    if (!id) {
      createOrUpdateFunction(values, true);
    } else {

      createOrUpdateFunction(values, false, id);
    }
    setIsSubmitting(true);
  };

  const hidePopup = () => {
    debugger;
    setVisible(false);
    setIsSubmitting(false);
    dispatch(clearFranchiseeMessage());
  };

  const FranchiseSubUser = () => {
    navigate(ROUTER.SUB_USER_CREATE + "/" + id, { state: franchiseeDetails });
  };

  useEffect(() => {
    if (error) {
      setIsSubmitting(false);
      setVisible(true);
    }

  }, [error]);

  useEffect(() => {
    if (message) {
      showToast(message, { type: "success" });
      if (message != franchiseImageDeleteSuccess && message != franchisedocumentDeleteSuccess) {
        // navigate(ROUTER.FRANCHISEE_GRID)
        dispatch(clearFranchiseeMessage());
      } else {
        dispatch(clearFranchiseeMessage());
        if (id) {
          dispatch(getFranchiseeImageLoading(id));
          dispatch(getuploadedeDocumentinFranchiseLoading(id));
        }
      }
      // setLatitude("")
      // setLongitude("")
      // setAddress("")
    }
    // return () => {
    //   // dispatch(resetFranchisee())
    // }
  }, [message]);
  useEffect(() => {
    if (updatedmessage) {
      showToast(updatedmessage, { type: "success" });
      navigate(ROUTER.FRANCHISEE_GRID);
      dispatch(clearFranchiseeMessage());
      setLatitude("");
      setLongitude("");
      setAddress("");
      setCompanyAddress("");
    }


    // return () => {
    //   dispatch(resetFranchisee())
    // }
  }, [updatedmessage]);
  useEffect(() => {
    if (otpmessage) {
      setOpenOtp(false);
      showToast(otpmessage, { type: "success" });
      navigate(ROUTER.FRANCHISEE_GRID);
      dispatch(clearFranchiseeMessage());
    }
  }, [otpmessage]);
  const [openOtp, setOpenOtp] = useState<any>(false);
  useEffect(() => {
    if (franchisePhoneMessage) {
      showToast(franchisePhoneMessage, { type: "success" });
      setPhoneEdit(false);
      setOpenOtp(true);
    }

  }, [franchisePhoneMessage]);
  const [otpValue, setOtpValue] = useState<any>(null);
  const handleOtpSubmit = () => {

    if (id) {

      dispatch(sendFranchiseOtpLoading(otpValue, franchiseeDetails.user?.id, editedMobileNumber));
    } else {
      debugger;
      dispatch(sendFranchiseOtpLoading(otpValue, createdData?.user?.id, createdData?.user?.phoneNumber));
    }
    // setOpenOtp(false)
  };
  const hideOtpPopup = () => {
    setOpenOtp(false);
    dispatch(resetOtpFranchiseMessage());
    if (id) {
      dispatch(getFranchiseeByIdLoading(id));
    }
    setOtpValue("");
  };
  const openOtpScreen = () => {
    return (
      <>
        <OtpScreen visible={openOtp} onHide={hideOtpPopup} onSubmit={handleOtpSubmit} otpValue={otpValue} setOtpValue={setOtpValue} />
      </>
    );
  };

  useEffect(() => {
    if (franchiseMailMessage && id) {
      showToast(franchiseMailMessage, { type: "success" });
      setEmailEdit(false);
      dispatch(getFranchiseeByIdLoading(id));
      dispatch(getFranchiseeImageLoading(id));
      dispatch(getuploadedeDocumentinFranchiseLoading(id));
    }

  }, [franchiseMailMessage]);

  const handleReset = (resetForm: any) => {
    if (id) {
      navigate(ROUTER.FRANCHISEE_GRID);
    } else {
      resetForm();
    }
  };
  function handleAcceptedFiles(files: any) {
    let filesModified = files.map((file: any) => {
      return Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
        isImage: file.type.startsWith('image/')
      });
    }
    );
    if (filesModified[0]?.isImage) {
      if (filesModified[0]?.size <= FILE_UPLOAD_SIZE) {
        setselectedFiles(files);
      } else {
        showToast("File size exceeds 5MB", { type: "error" });
        setselectedFiles([]);
      }
    } else {
      showToast("Please upload the image file", { type: "error" });
      setselectedFiles([]);
    }

  }

  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }



  const removeIndex = () => {
    setDeleteModal(false);
    if (franchiseImage) {
      dispatch(deleteFranchiseeImageLoading(id));
    } else if (selectedFiles.length > 0) {
      setselectedFiles([]);
    }
  };

  const deleteConfirmationHandler = () => {
    setDeleteModal(true);
  };

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);


  const assigntheValue = (uploadFile: any) => {
    const filesArray = Array.from(uploadFile);
    const allowedTypes = ["application/pdf", "image/jpeg", "image/png", "image/gif"];
    if (uploadFile && allowedTypes.includes(uploadFile[0].type)) {
      setGstFile(filesArray);
    } else {
      showToast(`Please Upload Pdf or Image File`, { type: "error" });
      setGstFile([]);
    }
  };

  const assignPanValue = (uploadFile: any) => {
    const allowedTypes = ["application/pdf", "image/jpeg", "image/png", "image/gif"];
    const filesArray = Array.from(uploadFile);
    if (uploadFile && allowedTypes.includes(uploadFile[0].type)) {
      setPancardFile(filesArray);
    } else {
      showToast(`Please Upload Pdf or Image File`, { type: "error" });
      setPancardFile([]);
    }
  };

  const removepanFile = (document?: any) => {
    if (document?.id) {
      dispatch(deleteUploadedeDocumentLoading(document?.id));
    } else {
      setPancardFile([]);
    }
  };


  const removeGstFile = (document?: any) => {
    if (document?.id) {
      dispatch(deleteUploadedeDocumentLoading(document?.id));
    } else {
      setGstFile([]);
    }
  };

  const hidePopupForCustomValidation = () => {
    setcustomError(false);
    setIsSubmitting(false);
  };

  const dialogContainerCustomError = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={customError} onHide={hidePopupForCustomValidation} />
      </>
    );
  };
  const [phoneEdit, setPhoneEdit] = useState<boolean>(false);
  const [emailEdit, setEmailEdit] = useState<boolean>(false);

  const handleResendOtp = (number: any) => {
    dispatch(resendOtpFranchise(franchiseeDetails?.user?.id, number));
  };


  const [Number, setNumber] = useState<any>("");
  const onHide = () => {
    setPhoneEdit(false);
  };
  const handleNumberSubmit = (values: any) => {

    setPhoneEdit(false);
    dispatch(setFranchiseMobileNumberLoading(values.phoneNumber, franchiseeDetails?.user?.id));


  };
  const EditValidationNumber = Yup.object().shape({

    phoneNumber: Yup.string()
      .required(`Mobile number is required`)
      .matches(/^\d{10}$/, "Mobile number must be 10 digits"),

  });
  const PhoneNumber = () => {
    return (
      <>
        <Dialog
          header="Edit Mobile Number"
          visible={phoneEdit}
          maximizable
          style={{ width: '400px' }}
          onHide={onHide}
        >
          <div >
            <Formik
              onSubmit={handleNumberSubmit}
              validationSchema={EditValidationNumber}
              initialValues={{
                phoneNumber: Number
              }}
              enableReinitialize
            >
              {({
                values,
                handleChange,
                touched,
                errors,
                handleBlur,
                resetForm,
                reset,
                ...rest
              }: any) => {
                return (
                  <div style={{ maxWidth: "400px" }}>
                    <Form onChange={handleChange}>
                      <InputCom
                        labelName="Mobile"
                        inputclassName="form-control"
                        labelClassName="form-Label"
                        type="number"
                        name="phoneNumber"
                        onBlur={handleBlur}
                        value={values.phoneNumber}
                        onChange={handleChange}
                        onKeyDown={preventSpecialChar}
                        disabled={id && !phoneEdit ? true : false}
                        isRequired={true}
                        invalid={
                          touched["phoneNumber"] &&
                            errors["phoneNumber"]
                            ? true
                            : false
                        }
                      />
                      <div className="d-flex float-end mt-2 me-0">
                        <Button
                          className="secondary-btn me-2 mt-2"
                          label={"Cancel"}
                          type="button"
                          onClick={() => setPhoneEdit(false)}
                        />
                        <Button
                          className="btn-primary mt-2 btn-primary-shadow"
                          label={"Update"}
                          type="submit"
                        />
                      </div>
                    </Form>
                  </div>
                );
              }
              }
            </Formik>
          </div>
        </Dialog>
      </>
    );
  };


  const onHideEmail = () => {
    setEmailEdit(false);
  };
  const [Email, setEmail] = useState<any>("");
  const handleNumberEmailSubmit = (values: any) => {
    setEmailEdit(false);
    dispatch(setFranchiseEmailIdLoading(values.email, franchiseeDetails?.user?.id));
  };
  const EditValidationEmail = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Enter a valid email")
      .test("valid-email", "Enter a valid email", function (value) {
        if (!value) return true; // Let Yup handle the "required" validation
        return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(value);
      }),
  });
  const EmailEdit = () => {
    return (
      <>
        <Dialog
          header="Edit Email"
          visible={emailEdit}
          maximizable
          style={{ width: '400px' }}
          onHide={onHideEmail}
        >
          <div >
            <Formik
              onSubmit={handleNumberEmailSubmit}
              validationSchema={EditValidationEmail}
              initialValues={{
                email: Email
              }}
              enableReinitialize
            >
              {({
                values,
                handleChange,
                touched,
                errors,
                handleBlur,
                resetForm,
                reset,
                ...rest
              }: any) => {
                return (
                  <div style={{ maxWidth: "400px" }}>
                    <Form onChange={handleChange}>
                      <InputCom
                        labelName="Email"
                        inputclassName="form-control"
                        labelClassName="form-Label"
                        type="text"
                        name="email"
                        onBlur={handleBlur}
                        value={values.email}
                        onChange={(e) => {
                          let event = { ...e };
                          event.target.value =
                            e.target.value.toLocaleLowerCase();
                          handleChange(event);
                        }}
                        isRequired={true}
                        disabled={id && !emailEdit ? true : false}
                        invalid={
                          touched["email"] && errors["email"]
                            ? true
                            : false
                        }
                      />
                      <div className="d-flex float-end mt-2 me-0">
                        <Button
                          className="secondary-btn me-2 mt-2"
                          label={"Cancel"}
                          type="button"
                          onClick={() => setEmailEdit(false)}
                        />
                        <Button
                          className="btn-primary mt-2 btn-primary-shadow"
                          label={"Update"}
                          type="submit"
                        />
                      </div>
                    </Form>
                  </div>
                );
              }
              }
            </Formik>
          </div>
        </Dialog>
      </>
    );
  };

  const handleAddress = (event:any) => {
    if (event.key === 'Enter' || event.key === "Tab") {
      event.preventDefault()
      if(event.target.value){ 
        dispatch(franchiseCompanyAddressLatLagLoading(event.target.value))
      }
    }
  };
  const handleHomeAddress = (event:any) => {
    if (event.key === 'Enter' || event.key === "Tab") {
      event.preventDefault()
      if(event.target.value){ 
        dispatch(franchiseHomeAddressLatLagLoading(event.target.value))
      }
    }
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => {
          removeIndex();
        }}
        onCloseClick={() => {
          setDeleteModal(false);
        }}
      />
      {visible && dialogContainer(error)}
      {/* {customError && dialogContainerCustomError(!address ? HomeErrorMessage : errorMessage)} */}
      {customError && dialogContainerCustomError(!mapSelectedPlace ? HomeErrorMessage : errorMessage)}
      {phoneEdit && PhoneNumber()}
      {emailEdit && EmailEdit()}
      {openOtp && openOtpScreen()}
      {openOtp && <div className='balckscreen'></div>}
      <div className="page-content">
        <Container fluid>
          <Breadcrumb titlePath={ROUTER.FRANCHISEE_GRID} title="Franchise" breadcrumbItem={id ? "Edit Franchise" : "Create Franchise"} />
          <Row>
            <Card>
              <CardBody>
                <React.Fragment>
                  <Formik
                    onSubmit={handleSubmit}
                    initialValues={!id ? initialvalue : editInitialValue}
                    validationSchema={!id ? validationSchema : EditValidation}
                    enableReinitialize
                  >
                    {({
                      values,
                      handleChange,
                      touched,
                      errors,
                      handleBlur,
                      resetForm,
                      reset,
                      ...rest
                    }: any) => {
                      return (
                        <Form onChange={handleChange}>
                          <Row>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Name"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="name"
                                onBlur={handleBlur}
                                value={values.name}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["name"] && errors["name"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="User Name"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="userName"
                                onBlur={handleBlur}
                                value={values.userName}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["userName"] && errors["userName"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Surname"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="surname"
                                onBlur={handleBlur}
                                value={values.surname}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={
                                  touched["surname"] && errors["surname"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>

                            {/* <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Email"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="email"
                                onBlur={handleBlur}
                                value={values.email}
                                onChange={(e) => {
                                  let event = { ...e }
                                  event.target.value = e.target.value.toLocaleLowerCase()
                                  handleChange(event)
                                }}
                                isRequired={true}
                                invalid={
                                  touched["email"] && errors["email"]
                                    ? true
                                    : false
                                }
                              />
                            </Col> */}
                            <Col lg={3} className="mb-2">
                              <div
                                className=""
                                style={{ position: "relative" }}
                              >
                                <InputCom
                                  labelName="Email"
                                  inputclassName="form-control"
                                  labelClassName="form-Label"
                                  type="text"
                                  name="email"
                                  onBlur={handleBlur}
                                  value={values.email}
                                  onChange={(e) => {
                                    let event = { ...e };
                                    event.target.value =
                                      e.target.value.toLocaleLowerCase();
                                    handleChange(event);
                                  }}
                                  isRequired={true}
                                  disabled={id && !emailEdit ? true : false}
                                  invalid={
                                    touched["email"] && errors["email"]
                                      ? true
                                      : false
                                  }
                                />
                                {!emailEdit && id && (
                                  <FeatherIcon
                                    icon="edit"
                                    className={`icon-lg right-icon`}
                                    style={{
                                      position: "absolute",
                                      right: "5px",
                                      top: "38px",
                                    }}
                                    onClick={() => { setEmail(values.email); setEmailEdit(true); }}
                                  />
                                )}

                              </div>
                            </Col>
                            {/* <><Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Password"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="password"
                                name="password"
                                onBlur={handleBlur}
                                value={values.password}
                                onChange={handleChange}
                                isRequired={id ? false : true}
                                invalid={
                                  touched["password"] && errors["password"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                              <Col lg={3} className="mb-2">
                                <InputCom
                                  labelName="Confirm Password"
                                  inputclassName="form-control"
                                  labelClassName="form-Label"
                                  type="password"
                                  name="confirmpassword"
                                  onBlur={handleBlur}
                                  value={values.confirmpassword}
                                  onChange={handleChange}
                                  isRequired={id ? false : true}
                                  invalid={
                                    touched["confirmpassword"] &&
                                      errors["confirmpassword"]
                                      ? true
                                      : false
                                  }
                                />
                              </Col></> */}

                            {/* <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Phone"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="number"
                                name="phoneNumber"
                                onBlur={handleBlur}
                                value={values.phoneNumber}
                                onChange={handleChange}
                                isRequired={true}
                                onKeyDown={preventSpecialChar}
                                invalid={
                                  touched["phoneNumber"] &&
                                    errors["phoneNumber"]
                                    ? true
                                    : false
                                }
                              />
                            </Col> */}
                            <Col lg={3} className="mb-2">
                              <div
                                className=""
                                style={{ position: "relative" }}
                              >
                                <InputCom
                                  labelName="Mobile"
                                  inputclassName="form-control"
                                  labelClassName="form-Label"
                                  type="number"
                                  name="phoneNumber"
                                  onBlur={handleBlur}
                                  value={values.phoneNumber}
                                  onChange={handleChange}
                                  onKeyDown={preventSpecialChar}
                                  disabled={id && !phoneEdit ? true : false}
                                  isRequired={true}
                                  invalid={
                                    touched["phoneNumber"] &&
                                      errors["phoneNumber"]
                                      ? true
                                      : false
                                  }
                                />
                                {!phoneEdit && id && (
                                  <FeatherIcon
                                    icon="edit"
                                    className={`icon-lg right-icon`}
                                    style={{
                                      position: "absolute",
                                      right: "5px",
                                      top: "38px",
                                    }}
                                    onClick={() => { setNumber(values.phoneNumber); setPhoneEdit(true); }}
                                  />
                                )}

                              </div>
                            </Col>

                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Alternate Number"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="alternateNumber"
                                onBlur={handleBlur}
                                value={values.alternateNumber}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={
                                  touched["alternateNumber"] && errors["alternateNumber"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>

                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Company Name"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="companyName"
                                onBlur={handleBlur}
                                value={values.companyName}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["companyName"] && errors["companyName"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <Label htmlFor="proprietorName">Proprietor Name
                                {/* <span className="text-danger ms-1">*</span> */}
                              </Label>
                              <Chips id="proprietorName" value={values.proprietorName} onChange={handleChange} />
                              {touched.proprietorName && errors.proprietorName && <span style={{
                                color: "#FD625E", fontSize: "12.25px", position: "absolute", left: "15px",
                                bottom: "0"
                              }}>{errors.proprietorName}</span>}
                              <p style={{ fontSize: "12px", marginTop: "-10px" }}>Please press the Enter key after entering the Name.</p>
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="GST Number"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="gstNumber"
                                onBlur={handleBlur}
                                uploadIcon={getUploadedList?.filter((data: any) => data.fileType === "GstNumber")?.length > 0 || !id ? false : true}
                                fileName="gstupload"
                                fileValue={values.gstfileValue}
                                filehandleChange={(e: any) => {
                                  handleChange(e);
                                  assigntheValue(e.target.files);
                                }}
                                value={values.gstNumber}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={
                                  touched["gstNumber"] &&
                                    errors["gstNumber"]
                                    ? true
                                    : false
                                }
                              />
                              {gstFile?.length > 0 && <div className="Aadhar_details_preview" id="Aadhar_details_preview">
                                <span className="file_name">{gstFile[0]?.name}</span>
                                <span className="trash_icon">
                                  <FeatherIcon onClick={removeGstFile} icon="trash-2" className={`icon-sm right-icon pointer`} />
                                </span>
                              </div>}
                              {getUploadedList?.length > 0 && (
                                <div>
                                  {getUploadedList
                                    ?.filter((data: any) => data.fileType === "GstNumber")
                                    .map((data: any) => (
                                      <div className="Aadhar_details_preview" id="Aadhar_details_preview" key={data.id}>
                                        <span className="file_name">{data?.name}</span>
                                        <span className="trash_icon">
                                          <FeatherIcon
                                            onClick={() => removeGstFile(data)}
                                            icon="trash-2"
                                            className={`icon-sm right-icon pointer`}
                                          />
                                        </span>
                                      </div>
                                    ))}
                                </div>
                              )}
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="PAN Number"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="panCardNo"
                                onBlur={handleBlur}
                                panIcon={getUploadedList?.filter((data: any) => data.fileType === "pancard")?.length > 0 || !id ? false : true}
                                panValue={values.panValue}
                                panName="panupload"
                                value={values.panCardNo}
                                filehandleChange={(e: any) => {
                                  handleChange(e);
                                  assignPanValue(e.target.files);
                                }}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={
                                  touched["panCardNo"] && errors["panCardNo"]
                                    ? true
                                    : false
                                }
                              />
                              {panCardFile?.length > 0 && <div className="Aadhar_details_preview" id="Aadhar_details_preview">
                                <span className="file_name">{panCardFile[0]?.name}</span>
                                <span className="trash_icon">
                                  <FeatherIcon onClick={removepanFile} icon="trash-2" className={`icon-sm right-icon pointer`} />
                                </span>
                              </div>}
                              {getUploadedList?.length > 0 && (
                                <div>
                                  {getUploadedList
                                    ?.filter((data: any) => data.fileType === "pancard")
                                    .map((data: any) => (
                                      <div className="Aadhar_details_preview" id="Aadhar_details_preview" key={data.id}>
                                        <span className="file_name">{data?.name}</span>
                                        <span className="trash_icon">
                                          <FeatherIcon
                                            onClick={() => removepanFile(data)}
                                            icon="trash-2"
                                            className={`icon-sm right-icon pointer`}
                                          />
                                        </span>
                                      </div>
                                    ))}
                                </div>
                              )}
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Date of Birth"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="date"
                                name="dateOfBirth"
                                onBlur={handleBlur}
                                value={values.dateOfBirth}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={
                                  touched["dateOfBirth"] &&
                                    errors["dateOfBirth"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Wedding Date"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="date"
                                name="weddingDate"
                                onBlur={handleBlur}
                                value={values.weddingDate}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={
                                  touched["weddingDate"] &&
                                    errors["weddingDate"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="City"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="city"
                                onBlur={handleBlur}
                                value={values.city}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["city"] && errors["city"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Region"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="region"
                                onBlur={handleBlur}
                                value={values.region}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["region"] && errors["region"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Pincode"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="number"
                                name="pincode"
                                onBlur={handleBlur}
                                value={values.pincode}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["pincode"] && errors["pincode"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={6} className="mb-2 d-flex gap-3 mt-3 flex-wrap" >
                              <CheckboxInput
                                name="sendSms"
                                label="Send SMS"
                                value={values["sendSms"]}
                                onChange={handleChange}
                                {...rest}
                              />

                              <CheckboxInput
                                name="sendEmail"
                                label="Send Email"
                                value={values["sendEmail"]}
                                onChange={handleChange}
                                {...rest}
                              />

                              <CheckboxInput
                                name="sendWhatsapp"
                                label="Whatsapp"
                                value={values["sendWhatsapp"]}
                                onChange={handleChange}
                                {...rest}
                              />
                              <CheckboxInput
                                name="lockoutEnabled"
                                label="Lockout Enabled"
                                value={values["lockoutEnabled"]}
                                onChange={handleChange}
                                {...rest}
                              />
                              <CheckboxInput
                                name="isActive"
                                label="Is Active"
                                value={values["isActive"]}
                                onChange={handleChange}
                                {...rest}
                              />
                              <CheckboxInput
                                name="isVerfied"
                                label="Is Verified"
                                value={values["isVerfied"]}
                                onChange={handleChange}
                                {...rest}
                              />
                            </Col>
                            <Col lg={6} className="mb-2">
                              <InputCom
                                labelName="Alternate Home Address"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="textarea"
                                name="alternateAddress"
                                onBlur={handleBlur}
                                value={values.alternateAddress}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={
                                  touched["alternateAddress"] && errors["alternateAddress"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>

                            <Col lg={6} className="mb-2">
                              {/* <InputCom
                                labelName="Company Address"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="textarea"
                                name="companyAddress"
                                onBlur={handleBlur}
                                value={values.companyAddress}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={
                                  touched["companyAddress"] && errors["companyAddress"]
                                    ? true
                                    : false
                                }
                              /> */}


                              {/* <AddressMapFields address={companyAddress} setAddress={setCompanyAddress} lat={latitude} lng={longitude} title="Company Address" ></AddressMapFields> */}
                              {/* {
                                apiKey &&
                                <GoogleMap editPage={id ? true : false} getByIdAddress={modifiedFranchise.companyAddress ? modifiedFranchise.companyAddress : ""} title="Company Address" setMapSelectedPlace={setCompanyAddress} jobsheet={false} apiKey={apiKey} />
                              } */}

                                <InputCom
                                  labelName={"Home Address"}
                                  inputclassName="form-control"
                                  labelClassName="form-Label"
                                  type="text"
                                  name="companyAddress"
                                  onBlur={handleBlur}
                                  onKeyDown={(e)=>{handleAddress(e)}}
                                  value={values.companyAddress}
                                  onChange={handleChange}
                                  isRequired={false}
                                  invalid={
                                    touched["companyAddress"] && errors["companyAddress"]
                                      ? true
                                      : false
                                  }
                                />
                                 <p style={{ fontSize: "12px",marginBottom:"3px"}}>Please press the Enter key after entering the Address.</p>
                              {companyLatLng && 
                              <iframe src={`https://maps.google.com/maps?q=${companyLatLng.lat},${companyLatLng.lng}&t=&z=15&ie=UTF8&iwloc=&output=embed`} width="100%" height="350" style={{border:0}}  loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                              }
                            </Col>
                            <Col lg={6} className="mb-2">
                              <InputCom
                                labelName="Company Address"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="address"
                                // onBlur={(e:any)=>{handleBlur(e) ; if(values.address) {dispatch(franchiseHomeAddressLatLagLoading(values.address))}}}
                                onBlur={handleBlur}
                                onKeyDown={(e)=>{handleHomeAddress(e)}}
                                value={values.address}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["address"] && errors["address"]
                                    ? true
                                    : false
                                }
                              />
                              <p style={{ fontSize: "12px",marginBottom:"3px"}}>Please press the Enter key after entering the Address.</p>
                              {homeLatLng && 
                              <iframe src={`https://maps.google.com/maps?q=${homeLatLng.lat},${homeLatLng.lng}&t=&z=15&ie=UTF8&iwloc=&output=embed`} width="100%" height="350" style={{border:0}}  loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                              }
                              {/* <AddressMapFields address={address} setAddress={setAddress} lat={latitude} lng={longitude} title="Home Address" ></AddressMapFields> */}
                              {/* {
                                apiKey &&
                                <GoogleMap editPage={id ? true : false} getByIdAddress={modifiedFranchise.address ? modifiedFranchise.address : ""} title="Home Address" setMapSelectedPlace={setMapSelectedPlace} jobsheet={false} apiKey={apiKey} />
                              } */}
                            </Col>



                            {id && <React.Fragment>
                              <div className="mt-1">
                                <h5>Image</h5>
                                <hr />
                                <Row>
                                  <Col sm={12} lg={12} md={12} xs={12}>
                                    <div>
                                      {(selectedFiles.length == 0 && !franchiseImage) && <Dropzone
                                        onDrop={acceptedFiles => {
                                          handleAcceptedFiles(acceptedFiles);
                                        }}
                                        onError={(err: Error) => { console.log("file error", err); }}
                                        onDropRejected={(fileRejections, event) => onDropRejectedFileHandler(fileRejections, event, showToast)}
                                        maxFiles={1}
                                      >
                                        {({ getRootProps, getInputProps }) => (
                                          <div className="dropzone">
                                            <div
                                              className="dz-message needsclick"
                                              {...getRootProps()}
                                            >
                                              <input {...getInputProps()} accept="image/*" />
                                              <div className="">
                                                <i className="display-4 text-muted bx bx-cloud-upload" />
                                              </div>
                                              <h4>Drop files here or click to upload.</h4>
                                            </div>
                                          </div>
                                        )}
                                      </Dropzone>}

                                      {(selectedFiles[0]?.preview || franchiseImage) && <ImagePreviewer selectedFile={selectedFiles[0]?.preview ? selectedFiles[0]?.preview : franchiseImage} removeIndex={deleteConfirmationHandler} />}
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </React.Fragment>}
                            <div className="d-flex justify-content-end mt-3 ">
                              {/* <TooltipMEL title={id ? "Cancel" : "Reset The Form"} position='top'> */}
                              {(franchiseeDetails?.user?.phoneNumberConfirmed != true && id) &&
                                <Button
                                  className="next-status-btn me-2 mt-2"
                                  label={"Verify Mobile No"}
                                  type="button"
                                  onClick={() => handleResendOtp(values.phoneNumber)}
                                />
                              }
                              <Button
                                className="secondary-btn me-2"
                                label={id ? "Cancel" : "Reset"}
                                type="button"
                                onClick={() => handleReset(resetForm)}
                              />

                              <Button
                                className="btn-primary me-2 btn-primary-shadow"
                                label={id ? "Update Franchise" : "Create Franchise"}
                                type="submit"
                              />
                              {/* <Button
                                className="btn-primary me-2"
                                label={id && "Add Franchise SubUser" }
                                onClick={FranchiseSubUser}
                                type="submit"
                              /> */}

                            </div>

                          </Row>
                        </Form>
                      );
                    }}
                  </Formik>

                  {/* Franchisee SubUser List */}
                  {
                    id && <Row className="mt-3">
                      <Col lg={12} className="dealer-newtitle-header">
                        <div>Franchise SubUser Details</div>
                        <Button
                          className="btn-primary me-2 btn-primary-shadow"
                          label={id && "Add Franchise Subuser"}
                          onClick={FranchiseSubUser}
                          type="submit"
                        />
                      </Col>
                      <SubUserList id={id} formValue={franchiseeDetails} />
                    </Row>
                  }

                </React.Fragment>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddEditFranchisee;