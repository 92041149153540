import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { FieldArray } from "formik";
import CheckboxInput from "src/components/UI/CheckboxInput";
import TooltipMEL from "src/components/UI/Tooltip";
import FloatInput from 'src/components/Common/FloatInput';
import { useDispatch, useSelector } from 'react-redux';
import { deletePendingCommand, getPendingReasonAllLoading, getPendingReasonLoading } from 'src/store/actions';
import { RootReducerState } from 'src/store/reducers';
import { ConvertIsoStringWithTime, formatDateTimeFormServerView } from 'src/helpers/common_helpers';
import TextEditor from 'src/components/atoms/TextEditor';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import FeatherIcon from "feather-icons-react";

interface TabProps {
  activeTab: string | number;
  status: string;
  initialValues: any;
  touch: any;
  error: any;
  handleChange?: any;
  onBlur?: () => void;
  changeFlow: any;
  setFieldError: any;
  setFieldTouched: any;
  setFieldValue: any;
  jobId?:any;
}

const PendingComments = (props: TabProps) => {
  const dispatch = useDispatch();
  const { pendingReason,jobsheet,pendingReasonAll } = useSelector((state: RootReducerState) => state.jobsheetReducer);


  const pendingreasonDropdown = pendingReason?.length > 0 ? (pendingReason.map((reason: any) => {
    let data: any = {};
    data['value'] = reason.id;
    data['label'] = reason.name;
    return data;
  })).sort((a: any, b: any) => {
    const labelA = a.label.toUpperCase().trim();
    const labelB = b.label.toUpperCase().trim();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  }) : []

  const handleAddPendingComments = (values: any, push: any) => {
    push({
      pendingReasonId: "",
      // commentedAt: formatDate(new Date()),
      commentedAt: ConvertIsoStringWithTime(new Date()),
      pendingInformation: "",
    });
    values['pendingCommentsDto'] = [{
      pendingReasonId: "",
      commentedAt: ConvertIsoStringWithTime(new Date()),
      pendingInformation: "",
    }]
    props.changeFlow(props.initialValues, props.initialValues.status, props.setFieldError, props.setFieldTouched, props.setFieldValue)
  };


  const handleAddPendingCommentsNew = (push : any,status : any) => {
    debugger
    if(status == "add" && jobsheet == null){
      push({
        pendingReasonId: '',
        commentedAt: ConvertIsoStringWithTime(new Date()) , // or format as needed
        pendingInformation: ''
      });
    }else if(status == "add" && jobsheet?.pendingCommentsDto?.length == 0){
      push({
        pendingReasonId: '',
        commentedAt: ConvertIsoStringWithTime(new Date()), // or format as needed
        pendingInformation: ''
      });
    }
    else if(jobsheet == null && status == "remove"){
      props.setFieldValue("pendingCommentsDto",[])
    }else if(status == "remove" && jobsheet?.pendingCommentsDto?.length == 0){
      props.setFieldValue("pendingCommentsDto",[])
    }
  };

  const formatDate = (date: any) => {
    return date.toISOString();
  };

  useEffect(() => {
    dispatch(getPendingReasonLoading())
    dispatch(getPendingReasonAllLoading())
  }, [])

  const htmlHandler = (htmlElement: string) => {
    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: htmlElement }} className='pending-information-large'/>
      </div>
    );
  };

  const handleRemovePendingComment = (index:any)=>{

  }
  const rowDelete = (index: any) => {
    
    return (
      <div>
        <span className="trash_icon">
                            <FeatherIcon
                              onClick={() => handleRemovePendingComment(index.rowIndex)}
                              icon="trash-2"
                              style={{color:"red",width:"20px",height:"20px"}}
                              className={`icon-sm right-icon pointer`}
                            />
                          </span>
      </div>
    );
  };

  const findPendingLabel=(id:any)=>{
    const val = pendingReasonAll?.find((item:any)=>item.id == id)?.name
    
    return val
  }
  
const [formVal,setFormVal]=useState<any>({pendingReasonId:"",commentedAt:new Date(),pendingInformation:""})

const handleValueChange =(e:any,nameVal:any)=> {
  setFormVal({
    ...formVal,
    [nameVal]:e.target.value
  })
}


const handleAddPendingComment =(initialPendingComments:any) => {
let obj = {
  id: 0,
  jobId: props.jobId,
  pendingReasonId: formVal.pendingReasonId,
  pendingInformation: formVal.pendingInformation,
  commentedAt: formVal.commentedAt,
  isDeleted: false
}
if(formVal.pendingReasonId != "" && formVal.pendingInformation != "" && formVal.commentedAt != ""){
  initialPendingComments.push(obj)
  setFormVal({pendingReasonId:"",commentedAt:new Date(),pendingInformation:""})
}
}

  return (
    <div className={`job-sheet-tab-content  ${props.activeTab === "Present Status" ? "" : "d-none"}`} >
      <Card className='pe-0 ps-0 mb-0 border-leftrigh-none'>
        <div className='dealer-title-header font-size-15 border-bottom'>Pending Information</div>

        <CardBody className='p-0'>
          <div className='px-3 pb-3'>
            <Row >
              <Col lg={3} className="d-flex align-items-center">
                {/* <CheckboxInput
                  name="inPending"
                  label="Mark Job as Pending"
                  value={props.initialValues.inPending}
                  onChange={
                    (e:any)=>{
                      props.handleChange(e);
                      props.initialValues.inPending =  e.target.checked;
                      props.changeFlow(props.initialValues, props.initialValues?.status,  props.setFieldError, props.setFieldTouched, props.setFieldValue)
                    }
                    }
                /> */}
                <FieldArray name="pendingCommentsDto">
                  {({ push }) => (
                    <CheckboxInput
                      name="inPending"
                      label="Mark Job as Pending"
                      value={props.initialValues.inPending}
                      onChange={(e) => {
                        debugger
                        props.handleChange(e);
                        props.initialValues.inPending = e.target.checked;
                        if(!props.initialValues.isOldPending){
                          if (e.target.checked) {
                              props.initialValues.recentInPending=true;
                            }else{
                              props.initialValues.recentInPending=false;
                            }
                        }
                        // if (e.target.checked) {
                        //   handleAddPendingCommentsNew(push,"add");
                        // }else{
                        //   handleAddPendingCommentsNew(push,"remove");
                        // }
                        props.changeFlow(props.initialValues, props.initialValues?.status, props.setFieldError, props.setFieldTouched, props.setFieldValue);
                      }}
                    />
                  )}
                </FieldArray>
              </Col>
            </Row>

            <Row>
            <div >
            <Row className='mt-2'>

              <FieldArray name="pendingCommentsDtoNew">
                {({ insert, remove, push }) => (
                  <div >
                    <Row className='mt-2'>
                      <div className='dealer-title-header font-size-15 border-bottom'>
                        <span className='pull-left'>Job Sheet Present Status</span>
                        {/* <div className="add-btn-container mt-0 pull-right">
                          <TooltipMEL title="Add Pending Comments" position="left">
                            <i className="fas fa-plus-circle" onClick={() => {
                              handleAddPendingComments(props.initialValues, push)
                            }}></i>
                          </TooltipMEL>
                        </div> */}
                      </div>
                    </Row>
                    {props.initialValues.pendingCommentsDtoNew?.length > 0 &&
                      props.initialValues.pendingCommentsDtoNew.map(
                        (value: any, index: any) => {
                          return <div key={index}>
                            <Row>
                              <Col lg={4} md={6} className="mt-mb">
                                <FloatInput
                                  type="dropdown"
                                  name={`pendingCommentsDtoNew[${index}].pendingReasonId`}
                                  label="Pending reason"
                                  disabled={false}
                                  onChange={props.handleChange}
                                  onBlur={props.onBlur}
                                  required={true}
                                  value={value.pendingReasonId}
                                  options={pendingreasonDropdown}
                                  invalid={props.touch.pendingCommentsDtoNew?.[index]?.pendingReasonId && props.error.pendingCommentsDtoNew?.[index]?.pendingReasonId ? true : false}
                                />
                              </Col>
                              {/* <Col lg={4} md={6} className="mt-mb">
                                <FloatInput
                                  label="Commented at"
                                  type="text"
                                  name={`pendingCommentsDtoNew[${index}].commentedAt`}
                                  onChange={props.handleChange}
                                  onBlur={props.onBlur}
                                  disabled={true}
                                  required={false}
                                  value={formatDateTimeFormServerView(value.commentedAt)}
                                  // value={formatDate(currentDate)} 
                                  invalid={props.touch.pendingCommentsDtoNew?.[index]?.commentedAt && props.error.pendingCommentsDtoNew?.[index]?.commentedAt ? true : false}
                                />
                              </Col> */}
                              <Col lg={4} className="mt-mb-textarea">
                                <div className="d-flex align-items-start">
                                  <div className="d-block w-100">
                                  
                                    <TextEditor
                                      onChange={props.handleChange}
                                      labelName='Pending information'
                                      name={`pendingCommentsDtoNew[${index}].pendingInformation`}
                                      required={true}
                                      value={value.pendingInformation}
                                      invalid={props.touch.pendingCommentsDtoNew?.[index]?.pendingInformation && props.error.pendingCommentsDtoNew?.[index]?.pendingInformation ? true : false}
                                      setFieldTouched={props.setFieldTouched}
                                      setFieldValue={props.setFieldValue}
                                      onBlur={props.onBlur}
                                      readOnly={false}
                                    />
                                  </div>
                                  {/* <div className="justify-content-center" style={{ marginLeft: "10px" }}>
                                    <TooltipMEL title="Remove pending comment" position="left">
                                      <i className="fas fa-times-circle color-red" onClick={() => {
                                        // if (value.id) {
                                        //   dispatch(deletePendingCommand(value.id))
                                        // } else {
                                          remove(index);
                                        // }
                                      }}></i>
                                    </TooltipMEL>
                                  </div> */}
                                </div>
                              </Col>
                            </Row>
                          </div>;
                        }
                      )}
                  </div>

                )}
              </FieldArray>
              {props.initialValues.pendingCommentsDto.length>0 ?
                <div className="card ps-0 pe-0 mt-2" id="table-border">
                  <DataTable size='large' value={props.initialValues.pendingCommentsDto} showGridlines tableStyle={{ minWidth: '50rem', padding: "30px" }}>
                    <Column field="pendingReasonId" header="Pending reason" className='' body={ (rowData) => findPendingLabel(rowData.pendingReasonId)}></Column>
                    <Column field="pendingInformation" header="Pending Information" className=''  body={ (rowData) => htmlHandler(rowData.pendingInformation)}></Column>
                    <Column field="commentedAt" header="Commented At" className=''  body={ (rowData) => formatDateTimeFormServerView(rowData.commentedAt)}></Column>
                  </DataTable>
                </div>
                : null }
            </Row>
          </div>
          </Row>
          </div>
        </CardBody>
      </Card>
    </div >
  )
}

export default PendingComments