import React from 'react';
import { Dialog } from "primereact/dialog";
import "./ConfirmationDialog.scss";
import { Form, Formik } from 'formik';
import { Col, Row } from 'reactstrap';
import InputCom from '../Input';
import Button from "src/components/UI/Button";
import { INTENT_STATUS } from 'src/constants/commonConstants';
import * as Yup from "yup";

interface ConfirmationDialogProps {
    message: string;
    visible: boolean;
    onSubmit: (value:any) => void; 
    onHide: () => void;
    status: string;
    isSubimt?:boolean;
}


const ConfirmationDialog = ({ message, visible, onHide, onSubmit,status,isSubimt=false }: ConfirmationDialogProps) => {

    const handleSubmit = async (values: any) => {
        if (true) {
          const submittedData: any = {
            franchiseId: values.note,
          };
          onSubmit(submittedData)
        }
      };

      
  const initialvalue = {
    note:  "",
    }

    const validateSchema = Yup.object().shape({
       note: Yup.string().required("Notes is required"),
    })


    return (
        <React.Fragment >
            <div>
                <Dialog header={status == "Reply" ? "Reply" : "Confirmation"} visible={visible} maximizable className='vw30' onHide={onHide}>
                    <div className="m-0 .validation-text" id="confirmation-dialog">
                      <h4 className={`${status == INTENT_STATUS.APPROVED ? "green-color"  : ""} ${status == INTENT_STATUS.REJECTED ? "red-color"  : ""}`}>{message}</h4>
                      <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialvalue}
                    validationSchema={validateSchema}
                    enableReinitialize
                  >
                    {({
                      values,
                      handleChange,
                      touched,
                      errors,
                      handleBlur,
                      resetForm,
                      reset,
                      ...rest
                    }: any) => {
                      return (
                        <Form onChange={handleChange}>
                          <Row>
                             <Col xl={12} lg={12} className="mb-2">
                             <InputCom
                                labelName=""
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="textarea"
                                name="note"
                                placeholder={status == "Reply" ? "Reply note" : 'Confirmation Note'}
                                onBlur={handleBlur}
                                value={values.note}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                touched["note"] && errors["note"]
                                ? true
                                : false
                                }
                            />
                            </Col>

                            </Row>
                            <div className="d-flex justify-content-end mt-2">
                            <Button
                            className="secondary-btn me-2"
                            label="Cancel" 
                            type="button"
                            onClick={onHide}
                            />
                            <Button
                            className="btn-primary me-2"
                            label="Confirm"
                            type="submit"
                            disabled={isSubimt}
                            />
                         </div>
                        </Form>
                        );
                    }}
                  </Formik>
                    </div>
                </Dialog>
            </div>

        </React.Fragment>
    )
}


export default ConfirmationDialog;






