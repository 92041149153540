import React, { useEffect, useMemo, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // for selectable events
import { Dialog } from "primereact/dialog";
import { formatDateTimeMEL } from "src/helpers/common_helpers";
import { useNavigate } from "react-router";
import { ROUTER } from "src/constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { employeeDropdownForJobLoading, getcustomerdropdownListLoading, getFranchiseAppointmentDetailLoading, getTechnicianDropdownLoading, jobDealerdropdownListLoading } from "src/store/actions";
import { RootReducerState } from "src/store/reducers";
import {  Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { Dropdown } from "primereact/dropdown";
import { Form, Formik } from "formik";
import DropdownInput from "src/components/UI/DropdownInput";
import Button from "src/components/UI/Button";
import FloatInput from "src/components/Common/FloatInput";
import InputCom from "src/components/Common/Input";
import NonScheduledCard from "./NonScheduledCard";
import JobsheetStatusPieChart from "./JobsheetStatus";

const EventCalendar = ({convertedDate,date}:any) => {
    const { franchiseAppointmentDetail,eventLoading,franchiseNotScheduledDetail } = useSelector((state: RootReducerState) => state.dashboard);
    const { technicianList,employeeList,customerList,dealerList } = useSelector((state: RootReducerState) => state.jobsheetReducer);
    
    const [startDate,setStartDate] = useState<any>()
    const [endDate,setEndDate] = useState<any>()
    const [value,setValue] = useState<any>()

    const getRandomColorClass = (id: string) => {
      // const colorClasses = [
      //   "color-1", "color-2", "color-3", "color-4", "color-5",
      //   "color-6", "color-7", "color-8", "color-9", "color-10"
      // ];
      const colorClasses:any = {
        OpenCalls:"color-1",
        ClosedCalls:"color-2",
        Scheduled:"color-3",
        unScheduled:"color-4",
        WithoutAppoinment:"color-5",
        
      };
      return `${colorClasses[id]} height-60`;
    };

  const events =useMemo(()=>{
    let data = franchiseAppointmentDetail?.filter((item:any) => item.appoinmentTime)
    .map((item:any,index:any) => ({
      id: item.id.toString(),
      classNames: [getRandomColorClass(item.className)],
      title: `${item.jobNumber} - ${item.customerName || item.dealerName || ""} ${item.className == "ClosedCalls" ? "👍" :""}`,
      start: item.appoinmentTime,
      extendedProps: {
        franchiseName: item.franchiseName,
        jobNumber: item.jobNumber,
        dealerName: item.dealerName,
        customerName: item.customerName,
        jobPrefix: item.jobPrefix,
        status: item.status,
        id: item.id,
        technicianId: item.technicianId,
        technicianGUId: item.technicianGUId,
        appoinmentTime: item.appoinmentTime,
      },
    }));
    return data
  },[franchiseAppointmentDetail]) 
  

  const tehhnicianDropdownOptions = useMemo(()=>{
    let data = [...technicianList,...employeeList]
    return data?.length >0 ? data.map((option:any)=>({
      label:option.name,
      value:option?.userId
    })) : []
  },[technicianList,employeeList])
  
  const customerDropdownOptions = useMemo(()=>{
    return customerList?.length >0 ? customerList.map((option:any)=>({
      label:option.name,
      value:option?.id
    })) : []
  },[customerList])
  
  const dealerDropdownOptions = useMemo(()=>{
    return dealerList?.length >0 ? dealerList.map((option:any)=>({
      label:option.name,
      value:option?.id
    })) : []
  },[dealerList])
  
    useEffect(() => {
      const preloader: any = document.getElementById("preloader");
      if (eventLoading) {
        preloader.style.display = "block";
      } else {
        preloader.style.display = "none";
      }
    }, [eventLoading]);

    const [visible, setVisible] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState<any>(null);
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(()=>{
      dispatch(getTechnicianDropdownLoading())
      dispatch(employeeDropdownForJobLoading())      
      dispatch(jobDealerdropdownListLoading())  
      dispatch(getcustomerdropdownListLoading())  
    },[])

    const viewJob = (id:any)=>{
        navigate(`${ROUTER.JOB_SHEETS_VIEW}/${id}`)
    }

    const getDataOnChange = (startDateVal:any,endDateVal:any)=>{
      setStartDate(startDateVal)
      setEndDate(endDateVal)
      let modifiedData={
        filters:{
          AppoinmentStartDate:{value:startDateVal},
          AppoinmentEndDate:{value:endDateVal},
          TechnicianGUId:{value:value?.technicianId ? value?.technicianId : ""},
          CustomerId:{value:value?.customerId ? value?.customerId : ""},
          DealerId:{value:value?.dealerId ? value?.dealerId : ""},
        },
        rows:1000,
      }
      let modifiedNewData={
        filters:{
          TechnicianGUId:{value:value?.technicianId ? value?.technicianId : ""},
          CustomerId:{value:value?.customerId ? value?.customerId : ""},
          DealerId:{value:value?.dealerId ? value?.dealerId : ""},
        },
        rows:1000,
      }
      dispatch(getFranchiseAppointmentDetailLoading(modifiedData,modifiedNewData))
    }

    const handleSubmit = (values:any) =>{
      setValue(values)
      let modifiedData={
        filters:{
          AppoinmentStartDate:{value:startDate},
          AppoinmentEndDate:{value:endDate},
          TechnicianGUId:{value:values.technicianId ? values.technicianId : ""},
          CustomerId:{value:values.customerId ? values.customerId : ""},
          DealerId:{value:values.dealerId ? values.dealerId : ""},
          Region:{value:values.Region ? values.Region : ""},
        },
        rows:1000,
      }
      let modifiedNewData={
        filters:{
          TechnicianGUId:{value:values.technicianId ? values.technicianId : ""},
          CustomerId:{value:values.customerId ? values.customerId : ""},
          DealerId:{value:values.dealerId ? values.dealerId : ""},
          Region:{value:values.Region ? values.Region : ""},
        },
        rows:1000,
      }
      dispatch(getFranchiseAppointmentDetailLoading(modifiedData,modifiedNewData))
    }
    const handleReset = (resetForm:any) =>{
      setValue({})
      resetForm()
      let modifiedData={
        filters:{
          AppoinmentStartDate:{value:startDate},
          AppoinmentEndDate:{value:endDate},
        },
        rows:1000,
      }
      let modifiedNewData={
        filters:{
        },
        rows:1000,
      }
      dispatch(getFranchiseAppointmentDetailLoading(modifiedData,modifiedNewData))
    }

    const initialvalue = {
      technicianId:"",
      customerId:"",
      dealerId:"",
      Region:""
    }
    const calendarRef = useRef<any>(null);

  return (
    <React.Fragment>
      <Col xl={12}>
        <Card className="card-h-100 event-calendar">

          <CardBody>
      <div className="mb-2">
        <b>Appointment Details</b>
      </div>
      <Col lg={12} className="mt-4 mb-4">
              <Formik
                onSubmit={handleSubmit}
                initialValues={initialvalue}
                enableReinitialize
              >
                {({
                  values,
                  handleChange,
                  touched,
                  errors,
                  handleBlur,
                  resetForm,
                  reset,
                  isSubmitting,
                  ...rest
                }: any) => {
                  return (
                    <Form onChange={handleChange}>
                      <Row>
                        <Col lg={2} md={4} sm={12}>
                          <DropdownInput
                            options={tehhnicianDropdownOptions}
                            name="technicianId"
                            label="Technician"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required={false}
                            value={values.technicianId}
                            invalid={
                              touched["technicianId"] && errors["technicianId"]
                                ? true
                                : false
                            }
                          />
                        </Col>
                        <Col lg={2} md={4} sm={12}>
                          <DropdownInput
                            options={customerDropdownOptions}
                            name="customerId"
                            label="Customer"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required={false}
                            value={values.customerId}
                            invalid={
                              touched["customerId"] && errors["customerId"]
                                ? true
                                : false
                            }
                          />
                        </Col>
                        <Col lg={2} md={4} sm={12}>
                          <DropdownInput
                            options={dealerDropdownOptions}
                            name="dealerId"
                            label="Dealer"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required={false}
                            value={values.dealerId}
                            invalid={
                              touched["dealerId"] && errors["dealerId"]
                                ? true
                                : false
                            }
                          />
                        </Col>
                        <Col lg={2} md={4} sm={12}>
                          <InputCom
                            name="Region"
                            labelName="Region"
                            inputclassName="form-control"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required={false}
                            value={values.Region}
                            disabled={false}
                            invalid={
                              touched["Region"] && errors["Region"]
                                ? true
                                : false
                            }
                          />
                        </Col>
                        <Col lg={2} md={4} sm={12} style={{display:"flex", alignItems:"end"}}>
                        <div className="d-flex  me-0">
                            <Button
                              className="secondary-btn me-2"
                              label={"Reset"}
                              type="button"
                              onClick={() => handleReset(resetForm)}
                            />
                            <Button
                              className="btn-primary btn-primary-shadow"
                              label={"Search"}
                              type="submit"
                            />
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  );}}
        </Formik>
        </Col>
    <FullCalendar
      ref={calendarRef}
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
      initialView="dayGridMonth"
      events={events}
      timeZone="UTC"
      eventDisplay="list-item"
      headerToolbar={{
        left: "prev,next today",
        center: "title",
        right: "timeGridDay,dayGridWeek,dayGridMonth",
      }}
      eventTimeFormat={{
        hour: "numeric",
        minute: "2-digit",
        meridiem: "short",
      }}
      datesSet={(info) => {
        // Fetch events when the view changes
        const startDate = info.startStr;
        const endDate = info.endStr;
        getDataOnChange(startDate,endDate)
      }}
      height="500px"
  contentHeight="auto"
      eventClick={(info:any) => {
        setSelectedEvent(info?.event?.extendedProps); // Store clicked event details
        setVisible(true);
      }}
      // eventMouseEnter={(info:any) => {
      //   setSelectedEvent(info?.event?.extendedProps)
      //   setVisible(true);
      // }}
      dateClick={(info:any) => {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.changeView("timeGridDay", info.dateStr);
      }}
    />
    <div className="d-flex gap-3">
    <div className="legend d-flex gap-2 justify-content-center align-items-center"><div className="color-3" style={{width:"15px",height:"15px"}}></div>Scheduled</div>
    <div className="legend d-flex gap-2 justify-content-center align-items-center"><div className="color-2" style={{width:"15px",height:"15px"}}></div>Closed</div>
    <div className="legend d-flex gap-2 justify-content-center align-items-center"><div className="color-1" style={{width:"15px",height:"15px",animation:"none",backgroundColor:"#ff2f00"}}></div>Open</div>
    <div className="legend d-flex gap-2 justify-content-center align-items-center"><div className="color-5" style={{width:"15px",height:"15px",animation:"none",backgroundColor:"#861ce2"}}></div>Without Appointment</div>
    </div>
    <Row>
    <Col lg={4} md={4} sm={12}>
    <Card>
      <CardHeader>
        <b>Non Scheduled Technician</b>
      </CardHeader>
      <CardBody style={{maxHeight:"400px",overflowY:"auto"}}>
        <Row>
          <Col lg={12}>
          <div className='d-flex gap-4'>
          <div className="data-non-scheduled-header-index">
            <div className="label">S.No</div>
          </div>
          <div className="data-non-scheduled-header">
            <div className="label">Technician</div>
          </div>
          <div className="data-non-scheduled-header">
            <div className="label">Job Number</div>
          </div>
          <div className="data-non-scheduled-header">
            <div className="label">Assigned Time</div>
          </div>
          </div>
          </Col>
        {
          franchiseNotScheduledDetail?.map((data:any,index:any)=>{
            return(
              <NonScheduledCard data={data} index={index} key={index}/>
            )
          })
        }
        </Row>
      </CardBody>
    </Card>
    </Col>
    <Col lg={8} md={8} sm={12}>
    <JobsheetStatusPieChart date={convertedDate} realDate={date} />
    </Col>
    </Row>
     <Dialog
        visible={visible}
        onHide={() => setVisible(false)}
        header="Appointment Details"
        className="calendar-dialog"
      >
        {selectedEvent && (
          <div className="appointment-calendar">
            <div className="header">
                <div className="job-status">{selectedEvent.status}</div>
                <div className="job-view" onClick={()=>viewJob(selectedEvent.id)}>View Job</div>
            </div>
            <div className="model-content">
                {selectedEvent.jobNumber && <div className="data-content">
                    <div className="label">Job Number</div>
                    <div className="data-value" style={{cursor:"pointer",color:"blue"}} onClick={()=>viewJob(selectedEvent.id)}>{selectedEvent.jobNumber}</div>
                    </div>}
                {selectedEvent.franchiseName && <div className="data-content">
                    <div className="label">Technician</div>
                    <div className="data-value">{selectedEvent.franchiseName}</div>
                    </div>}
                {selectedEvent.dealerName && <div className="data-content">
                    <div className="label">Dealer</div>
                    <div className="data-value">{selectedEvent.dealerName}</div>
                    </div>}
                {selectedEvent.customerName && <div className="data-content">
                    <div className="label">Customer</div>
                    <div className="data-value">{selectedEvent.customerName}</div>
                    </div>}
                {selectedEvent.jobPrefix && <div className="data-content">
                    <div className="label">Job Prefix</div>
                    <div className="data-value">{selectedEvent.jobPrefix}</div>
                    </div>}
                {selectedEvent.appoinmentTime && <div className="data-content">
                    <div className="label">Appointment Time</div>
                    <div className="data-value">{formatDateTimeMEL(selectedEvent.appoinmentTime)}</div>
                    </div>}
            </div>
          </div>
        )}
      </Dialog>
    </CardBody>
    </Card>
    </Col>
    </React.Fragment>
  );
};

export default EventCalendar;
