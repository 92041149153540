import React, { useEffect } from 'react';
import FeatherIcon from "feather-icons-react";
import { Card, CardBody, Col, Row } from 'reactstrap';
import { AnimatePresence, motion } from 'framer-motion';
import FloatInput from 'src/components/Common/FloatInput';
import { CHECK_VALUE_IN_ARRAY} from 'src/helpers/jobsheet_validation';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from 'src/store/reducers';
import { getCustmerDetailsInJobsheetLoading, getUserCurretnAddressLatLngLoading } from 'src/store/actions';
import { customerItem } from 'src/models/pages/jobsheetModel';
import { hasCustomerRoleHandler } from 'src/helpers/common_helpers';


interface CustomerTabProps {
  activeTab: string | number;
  values: any;
  status: string;
  touch: any;
  error: any;
  audioModel: any;
  isdealer: boolean;
  handleChange: (e: any) => void;
  onBlur?: any;
  validationConfiguration?: any;
  setFieldValue: any;
  setFieldTouched: any;
  setFieldError: any;
  accordion?: any;
  changeAccorditionHandler?: any;
  pastHistoryData?: any;
  iniialvalue?: any;
  customerProfile?: any;
  setAddress : any;
  changeFlow: any;
}


const CustomerDetails = (props: CustomerTabProps) => {

  const dispatch = useDispatch();
  let isCustomer = hasCustomerRoleHandler();
  const { customerGetDetails } = useSelector((state: RootReducerState) => state.jobsheetReducer);

  const resetPhoneState = (setFieldValue: any) => {
    props.setFieldError("customerAddress", "");
    props.setFieldError("customerName", "");
    props.setFieldError("customerEmail", "");
    props.setFieldError("customerGSTNumber", "");

    props.setFieldTouched("customerAddress", false);
    props.setFieldTouched("customerName", false);
    props.setFieldTouched("customerEmail", false);
    props.setFieldTouched("customerGSTNumber", false);

    setFieldValue("customerAddress", "");
    setFieldValue("customerName", "");
    setFieldValue("customerEmail", "");
    setFieldValue("customerGSTNumber", "");
    setFieldValue("customerId",null)
  };

  useEffect(() => {
    let detail = (customerGetDetails as customerItem);
    if (customerGetDetails != null && !hasCustomerRoleHandler()) {
      if (customerGetDetails?.phoneNumber != null && customerGetDetails?.email != null && customerGetDetails?.name != null) {
        props.setFieldValue("customerName", customerGetDetails?.name);
        props.setFieldValue("customerAddress", customerGetDetails?.address ? customerGetDetails?.address : "");
        props.setFieldValue("customerEmail", customerGetDetails?.email);
        props.setFieldValue("customerPhoneNumber", customerGetDetails?.phoneNumber);
        props.setFieldValue("customerGSTNumber", customerGetDetails?.gstNo);
        props.setFieldValue("customerId", customerGetDetails?.id);
        props.values["customerName"] = customerGetDetails?.name
        if(!props.values.dealerId){
          props.setFieldValue("serviceLocation",customerGetDetails?.address ? customerGetDetails?.address : "")
          dispatch(getUserCurretnAddressLatLngLoading(customerGetDetails?.address));
        }
        props.changeFlow(props.values, props.values?.status, props.setFieldError, props.setFieldTouched, props.setFieldValue)
      }
    }
  }, [customerGetDetails]);
  
  useEffect(() => {
    let detail = (customerGetDetails as customerItem);
    if (isCustomer) {
      if (props.customerProfile != "") {
        if (props.customerProfile?.extraProperties?.CustomerId != "") {
          props.setFieldValue("customerId", props.customerProfile?.extraProperties?.CustomerId);
        }
      }
    }
  }, [props.customerProfile]);

  const handleApiPhone = (value: any, event: any,type:string) => {
    if (!event?.target?.value) return;

    let toScan = event.target.value;

    if (event.key === 'Enter' || event.key === "Tab") {
        props.setFieldValue("customerId","")
      if(type=="phone"){
        resetPhoneState(props.setFieldValue)
      }
      if(type=="email"){
        // resetEmailState(props.setFieldValue)
      }
      dispatch(getCustmerDetailsInJobsheetLoading(value, "phone"));
    }
  };



  return (
    <div className={`job-sheet-tab-content ${props.activeTab === "Job Sheet Information" ? "" : "d-none"}`} >
      <Card className='pe-0 ps-0 mb-0 border-leftrigh-none'>
        <div className='dealer-title-header font-size-15 border-bottom'> <span><FeatherIcon className="pointer" icon={props.accordion['customerInformation'] ? "chevron-down" : "chevron-right"} onClick={() => props.changeAccorditionHandler('customerInformation')} /></span> Customer Information</div>
        <AnimatePresence>
          {props.accordion['customerInformation'] && (<motion.div
            className="accordion-content"
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
          ><CardBody className='p-0'>
              <div className='px-3 pb-3'>
                <Row>
                  <Col lg={3} md={6} className="mt-mb">
                    <FloatInput
                      type="number"
                      name="customerPhoneNumber"
                      label="Mobile Number"
                      value={props.values.customerPhoneNumber}
                      invalid={props.touch["customerPhoneNumber"] && props.error["customerPhoneNumber"] ? true : false}
                      onChange={(e) => { props.handleChange(e);
                        if(e.target.value==""){
                          props.setFieldValue("customerId","")
                        }
                       }}
                      onBlur={(e) => {
                        props.onBlur(e);

                      }}
                      onKeyDown={(e: any) => handleApiPhone({ phoneNumber: e.target.value }, e,"phone")}
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "customerPhoneNumber")}
                      disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "customerPhoneNumber")}
                    />
                     <p style={{ fontSize: "12px" }}>Please press the Enter key after entering the mobile number.</p>
                  </Col>

                  <Col lg={3} md={6} className="mt-mb">
                    <FloatInput
                      type="text"
                      name="customerEmail"
                      label="Email"
                      value={props.values.customerEmail}
                      invalid={props.touch["customerEmail"] && props.error["customerEmail"] ? true : false}
                      onChange={(e) => { props.handleChange(e);
                        if(e.target.value==""){
                          props.setFieldValue("customerId","")
                        }
                       }}
                      // onBlur={props.onBlur}
                      onBlur={(e) => {
                        props.onBlur(e);
                        // customerEmailHandler(e.target.value);
                      }}
                      onKeyDown={(e: any) => handleApiPhone({ email: e.target.value }, e,"email")}
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "customerEmail")}
                      disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "customerEmail")}
                    />
                    <p style={{ fontSize: "12px" }}>Please press the Enter key after entering the email.</p>
                  </Col>

                  <Col lg={3} md={6} className="mt-mb">
                    <FloatInput
                      type="text"
                      name="customerName"
                      label="Customer Name"
                      value={props.values.customerName}
                      invalid={props.touch["customerName"] && props.error["customerName"] ? true : false}
                      onChange={(e) => { props.handleChange(e); }}
                      onBlur={props.onBlur}
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "customerName")}
                      disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "customerName")}
                    />
                  </Col>
                  <Col lg={3} md={6} className="mt-mb">
                    <FloatInput
                      type="text"
                      name="customerAddress"
                      label="Customer Address"
                      value={props.values.customerAddress}
                      invalid={props.touch["customerAddress"] && props.error["customerAddress"] ? true : false}
                      onChange={(e) => { props.handleChange(e); }}
                      onBlur={props.onBlur}
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "customerAddress")}
                      disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "customerAddress")}
                    />
                  </Col>
                  <Col lg={3} md={6} className="mt-mb">
                    <FloatInput
                      type="text"
                      name="customerGSTNumber"
                      label="Customer GST Number"
                      value={props.values.customerGSTNumber}
                      invalid={props.touch["customerGSTNumber"] && props.error["customerGSTNumber"] ? true : false}
                      onChange={(e) => { props.handleChange(e); }}
                      onBlur={props.onBlur}
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "customerGSTNumber")}
                      disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "customerGSTNumber")}
                    />
                  </Col>
                </Row>

              </div>
            </CardBody></motion.div>)}
        </AnimatePresence>
      </Card>
    </div>
  );
};

export default CustomerDetails;
