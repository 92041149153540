import React, { useEffect, useState } from "react";

import { Card, CardBody, Col, Container, Row } from "reactstrap";

// import Widgets from "./Widgets";
import CountUp from "react-countup";
import ReactApexChart from "react-apexcharts";

import JobsheetStatusPieChart from "./JobsheetStatus";
import TopDealerJobsheetStatusTreeChart from "./TopDealerJobStatus";
import HighMovingAudioPartChart from "./HighMovingAudioPart";
import StockAdjustmentStatusChart from "./StockAdjustmentStatus";
import PurchaseOrderStatusChart from "./PurchaseOrderStatus";
import DEADLER_IMG from '../../assets/images/employee.png';
import SUPPLIER_IMG from '../../assets/images/distribution.png';
import FRANCHISE_IMG from '../../assets/images/franchise.png';
import CUSTOMER_IMG from '../../assets/images/client.png';
import { useDispatch, useSelector } from "react-redux";
import { getCustomerCount, getDealerCount, getFranchiseeCount, getInvoiceCountLoading, getJobsheetGraphDetailLoading, getPendingPaymentLoading, getReceivedPaymentLoading, getSupplierCount } from "src/store/actions";
import { RootReducerState } from "src/store/reducers";
import DashboardBreadcrumb from "./DashBoardBreadCrumb";
import { convertToMonthYear } from "src/helpers/common_helpers";
import JobsheetTrend from "./JobSheetTrend";
import Calender from "../Calendar";
import EventCalendar from "./EventCalendar";


const options: Object = {
  chart: {
    height: 50,
    type: "line",
    toolbar: { show: false },
    sparkline: {
      enabled: true
    },
  },
  colors: ["#5156be"],
  stroke: {
    curve: "smooth",
    width: 2,
  },
  xaxis: {
    labels: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
  tooltip: {
    fixed: {
      enabled: false,
    },
    x: {
      show: false,
    },
    y: {
      title: {
        formatter: function (seriesName: any) {
          return "";
        },
      },
    },
    marker: {
      show: false,
    },
  },
};




const Dashboard = () => {
  const { dealerCount, supplierCount, franchiseeCount, customerCount, jobsheetGraphDetail,invoiceCount,receivedPaymentCount,paymentPendingCount } = useSelector((state: RootReducerState) => state.dashboard);
  const dispatch = useDispatch();
  const [customerCountFormat,setCustomerCountFormat] =useState<any>(0) 
  

  useEffect(() => {
    dispatch(getDealerCount());
    dispatch(getSupplierCount());
    dispatch(getFranchiseeCount());
    dispatch(getCustomerCount());


  }, [])

  useEffect(()=>{
   let modifiedCount = `${customerCount.customers}`
   setCustomerCountFormat(modifiedCount)
  },[customerCount])


  const WidgetsDatas = [
    {
      id: 1,
      title: "Jobsheet",
      price: jobsheetGraphDetail?.reduce((acc, item) => acc + (item.noOfItems || 0), 0),
      rank: "+$20.9k",
      isDoller: false,
      postFix: "",
      statusColor: "success",
      series: jobsheetGraphDetail?.map((item: any) => item.noOfItems),
    },
    {
      id: 3,
      title: "Invoice",
      price: invoiceCount?.reduce((acc, item) => acc + (item.numberOfCount || 0), 0),
      rank: "+$2.8k",
      isDoller: false,
      postFix: "",
      statusColor: "success",
      series: invoiceCount?.map((item) => item.numberOfCount),
    },
    {
      id: 5,
      title: "Received payment",
      price: receivedPaymentCount?.reduce((acc, item) => acc + (item.amount || 0), 0),
      rank: "+2.95%",
      isDoller: true,
      postFix: "",
      statusColor: "success",
      series: receivedPaymentCount?.map((item) => item.amount)
    },
    {
      id: 6,
      title: "Pending payment",
      price:paymentPendingCount?.reduce((acc, item) => acc + (item.amount || 0), 0) ,
      rank: "+2.95%",
      isDoller: true,
      postFix: "",
      statusColor: "success",
      series: paymentPendingCount?.map((item) => item.amount)
    }
  ];
  
  const PeopleManagementDetails: any[] = [

    {
      title: "Dealer",
      total: dealerCount,
      icon: DEADLER_IMG,
      className: "delaer-color"

    },
    {
      title: "Supplier",
      total: supplierCount,
      icon: SUPPLIER_IMG,
      className: "supplier-color"
    },
    {
      title: "Franchise",
      total: franchiseeCount,
      icon: FRANCHISE_IMG,
      className: "franchise-color"
    },
    {
      title: "Customer",
      total:customerCountFormat,
      currentUser :String(customerCount.currentMonthCustomers),
      icon: CUSTOMER_IMG,
      className: "customer-color"
    },
  ]
  
  // document.title = "Dashboard";
  const [date, setDate] = useState<any>("")
  useEffect(() => {
    setConvertedDate(convertToMonthYear(date))
  }, [date])

  const [convertedDate, setConvertedDate] = useState<any>("")
  const [month, year]:any = convertedDate?.split(' ').map((value: any, index: any) => index === 0 ? new Date(`${value} 1, 2000`).getMonth() + 1 : parseInt(value))
  useEffect(()=>{
    if(month && year){

    dispatch(getJobsheetGraphDetailLoading({month:month,year:year}));
    dispatch(getInvoiceCountLoading({month:month,year:year}))
    dispatch(getReceivedPaymentLoading({month:month,year:year}))
    dispatch(getPendingPaymentLoading({month:month,year:year}))
  }
  },[month,year])
 
  return (
    <React.Fragment >
      <div className="page-content" id="dashboard">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <DashboardBreadcrumb title="Dashboard" breadcrumbItem="Dashboard" setDate={setDate} date={date} />

          <Row>
            {/* <Widgets options={options} /> */}
            {(WidgetsDatas || []).map((widget, key) => (
              <Col xl={3} md={6} key={key}>
                <Card className="card-h-100">
                  <CardBody>
                    <Row className="align-items-center">
                      <Col xs={6}>
                        <span className="text-muted mb-3 lh-1 d-block">
                          {widget.title}
                        </span>
                        <h4 className="mb-3">
                          {widget.isDoller === true ? <span>&#8377;</span> : ""}
                          <span className="counter-value">
                            <CountUp
                              start={0}
                              end={widget.price}
                              duration={2}
                              useIndianSeparators={widget.isDoller === true ? true : false}
                              // decimals={2}
                              separator=","
                            />
                            {widget.postFix}
                          </span>
                        </h4>
                      </Col>
                      <Col xs={6}>
                        <ReactApexChart
                          options={options}
                          series={[{ data: [...widget["series"]] }]}
                          type="line"
                          className="apex-charts mb-2"
                          dir="ltr"
                          height={50}
                        />
                      </Col>
                    </Row>
                    {/* <div className="text-nowrap">
                      <span
                        className={
                          "badge bg-" +
                          widget.statusColor +
                          "-subtle text-" +
                          widget.statusColor
                        }
                      >
                        {widget.rank}
                      </span>
                      <span className="ms-1 text-muted font-size-13"> Since last week
                      </span>
                    </div> */}
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
          <Row>
            {(PeopleManagementDetails || []).map((widget, key) => (
              <Col xl={3} md={6} key={key}>
                <Card className="card-h-100">
                  <CardBody className={widget.className}>
                    <Row className="align-items-center">
                      <Col xs={6}>
                        <span className="text-muted mb-3 lh-1 d-block text-truncate">
                          {widget.title}
                        </span>
                        <h4 className="mb-3">
                          <span className="counter-value">
                            <CountUp
                              start={0}
                              // end={widget.total}
                              end={(widget.total)}
                              duration={3}
                              separator=""
                            />
                          </span>{widget.currentUser && "/"+ widget.currentUser}
                          
                        </h4>
                        
                      </Col>
                      <Col xs={6} style={{ textAlign: 'end' }}>
                        <img src={widget.icon} alt={widget.title} className="img-container" />
                      </Col>
                    </Row>

                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
          <Row>
          <EventCalendar convertedDate={convertedDate} date={date}/>
  
            <JobsheetTrend date={convertedDate} realDate={date} />
            <TopDealerJobsheetStatusTreeChart date={convertedDate} realDate={date} />
            <HighMovingAudioPartChart date={convertedDate} realDate={date} />
            <PurchaseOrderStatusChart date={convertedDate} realDate={date} />
            <StockAdjustmentStatusChart date={convertedDate} realDate={date} />
            {/* <MarketOverview />
          <WalletBalance /> */}



            {/* <NewSlider /> */}


          </Row>
          <Row>
            {/* <InvestedOverview />
            <Locations /> */}
          </Row>
          <Row>
            {/* <Trading />
            <Transactions />
            <RecentActivity /> */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;