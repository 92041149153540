import React from 'react'
import { Col } from 'reactstrap';
import { formatDateTimeMEL } from 'src/helpers/common_helpers';
interface PropTypes{
    data:any;
    index:any;
}
const NonScheduledCard = ({data,index}:PropTypes) => {
    
  return (
    <Col lg={12} md={12} sm={12} xs={12}>
    <div className='d-flex gap-4'>
        <div className="data-non-scheduled-index">
            <div className="label">{index+1} )</div>
        </div>
        <div className="data-non-scheduled">
            <div className="label">{data.franchiseName}</div>
        </div>
        <div className="data-non-scheduled">
            <div className="label">{data.jobNumber}</div>
        </div>
        <div className="data-non-scheduled">
            <div className="label">{formatDateTimeMEL(data.technicianAssignedTime)}</div>
        </div>
    </div>
    </Col>
  )
}

export default NonScheduledCard