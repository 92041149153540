import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Button from 'src/components/UI/Button';
import Breadcrumb from "src/components/Common/Breadcrumb";

import CheckboxInput from "src/components/UI/CheckboxInput";
import FeatherIcon from "feather-icons-react";
import { useDispatch, useSelector } from "react-redux";
import { RootReducerState } from "src/store/reducers";
import { useToast } from "src/components/Common/ReactToaster";
import * as Yup from "yup";
import { ROUTER } from "src/constants/routes";
import { FieldModal } from "src/models/components/inputWrapperModel";
import { hasFranchiseRoleHandler, numberFormatter } from "src/helpers/common_helpers";
import { Dialog } from "primereact/dialog";

import { parts } from "src/models/pages/partsModel";
import DropdownInput from "src/components/UI/DropdownInput";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import {
  clearValues,
  createStockInspection,
  getAudioBrandDropdown,
  getAudioBrandPartSkuDetails,
  getInitiatedByDropdown,
  getPartSkuWithLocation,
  getSkuNumberWithStatus,
  getStockInspectionBYID,
  getStockLocationDropdown,
  getSupplierDropdown,
  resetStockInspectionMessage,
} from "src/store/stockInspection/action";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Info from "src/components/Common/Icons/Info";
import Scanner from "src/components/Common/Scanner/Scanner";
import { ConfirmDialog } from 'primereact/confirmdialog';
import "./stockInspection.scss";

const AddOrEditStockInspection = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [customerChoicePartNumber, setCustomerChoicePartNumber] = useState(false);
  const dispatch = useDispatch();

  const [selectedCity, setSelectedCity] = useState(null);
  const [duplicateParts, setDuplicateParts] = useState([]);



  const { error, message, loading, supplierList, initiatedByList, supplierPartSkuDetail, audioBrandPartSkuDetail, audioBrandList, scanResponse, skuNumberStatus, stockLocationList, Unused, Used, Rejected, Missing, Scrapped, Floating } = useSelector(
    (state: RootReducerState) => state.StockInspectionReducer
  );
  const formValue: any = useSelector(
    (state: RootReducerState) => state.StockInspectionReducer.stockInspectionDetail
  );

  const franchiseLogin = hasFranchiseRoleHandler();


  const [customError, setCustomError] = useState<string>("");

  const skuStatus = ["Unused", "Used", "Rejected", "Scrapped", "Missing", "Floating"];

  // This array is important for form submission in post and put method
  const formSubmitionKeys: any = {
    Unused: [
      { name: "totalUnusedSkuStart", field: "start" },
      { name: "totalUnusedSkuEnd", field: "end" },
      { name: "totalUnusedSkuValue", field: "totalPrice" },
      { name: "unusedPartSkus", field: "skuNumbers" },
    ],
    Used: [
      { name: "totalUsedSkuStart", field: "start" },
      { name: "totalUsedSkuEnd", field: "end" },
      { name: "totalUsedSkuValue", field: "totalPrice" },
      { name: "usedPartSkus", field: "skuNumbers" },
    ],
    Rejected: [
      { name: "totalReceivedSkuStart", field: "start" },
      { name: "totalReceivedSkuEnd", field: "end" },
      { name: "totalReceivedSkuValue", field: "totalPrice" },
      { name: "rejectedPartSkus", field: "skuNumbers" },
    ],
    Scrapped: [
      { name: "totalScrappedSkuStart", field: "start" },
      { name: "totalScrappedSkuEnd", field: "end" },
      { name: "totalScrappedSkuValue", field: "totalPrice" },
      { name: "scrappedPartSkus", field: "skuNumbers" },
    ],
    Missing: [
      { name: "totalMissingSkuStart", field: "start" },
      { name: "totalMissingSkuEnd", field: "end" },
      { name: "totalMissingSkuValue", field: "totalPrice" },
      { name: "missingPartSkus", field: "skuNumbers" },
    ],
    Floating: [
      { name: "totalFloatingSkuStart", field: "start" },
      { name: "totalFloatingSkuEnd", field: "end" },
      { name: "totalFloatingSkuValue", field: "totalPrice" },
      { name: "floatingPartSkus", field: "skuNumbers" },
    ],
  };

  const FranchiseLogin = hasFranchiseRoleHandler();


  ///initial values set 0 
  useEffect(() => {
    setUnusedSkuDetails(Unused);
    let data = skuStatus.reduce((acc: any, each: string) => {
      let filteredArray: any[] = [];
      let formValueAssign: any[] = [];
      switch (each) {
        case 'Unused':
          filteredArray = Unused;
          formValueAssign = formValue?.totalUnusedSkuStart;
          break;
        case 'Used':
          filteredArray = Used;
          formValueAssign = formValue?.totalUsedSkuStart;
          break;
        case "Rejected":
          filteredArray = Rejected;
          formValueAssign = formValue?.totalReceivedSkuStart;
          break;
        case "Scrapped":
          filteredArray = Scrapped;
          formValueAssign = formValue?.totalScrappedSkuStart;
          break;
        case "Missing":
          filteredArray = Missing;
          formValueAssign = formValue?.totalMissingSkuStart;
          break;
        case "Floating":
          filteredArray = Floating;
          formValueAssign = formValue?.totalFloatingSkuStart;
          break;
        default:
          filteredArray = [];
      }
      //create start and end same value
      //put method formvalue intial
      let data = {
        name: each,
        start: id ? formValueAssign : filteredArray?.length,
        end: id ? filteredArray?.length ?? 0 : 0,
        // start: filteredArray?.length ?? 0,
        // end: filteredArray?.length ?? 0,
        // start: !id ? filteredArray?.length ?? 0 : formValueAssign,
        // end: 0,
        [each]: filteredArray,
        skuNumbers: filteredArray?.map((eachSku: any) => {
          return { "barCode": eachSku?.number };
        }),
        totalPrice: filteredArray.reduce((acc: number, each: any) => {
          if (each?.price)
            acc += each?.price;
          return acc;
        }, 0)
      };
      acc.push(data);
      return acc;
    }, []);
    setSkuStatusDetails(data);

    let newpayload = skuStatus.reduce((acc: any, each: string) => {
      let filteredArray: any[] = [];
      let formValueAssign: any[] = [];
      switch (each) {
        case 'Unused':
          filteredArray = Unused;
          formValueAssign = formValue?.totalUnusedSkuStart;
          break;
        case 'Used':
          filteredArray = Used;
          formValueAssign = formValue?.totalUsedSkuStart;
          break;
        case "Rejected":
          filteredArray = Rejected;
          formValueAssign = formValue?.totalReceivedSkuStart;
          break;
        case "Scrapped":
          filteredArray = Scrapped;
          formValueAssign = formValue?.totalScrappedSkuStart;
          break;
        case "Missing":
          filteredArray = Missing;
          formValueAssign = formValue?.totalMissingSkuStart;
          break;
        case "Floating":
          filteredArray = Floating;
          formValueAssign = formValue?.totalFloatingSkuStart;
          break;
        default:
          filteredArray = [];
      }
      let newData = {
        name: each,
        start: id ? formValueAssign : filteredArray?.length,
        end: id ? filteredArray?.length ?? 0 : filteredArray?.length,
        [each]: filteredArray,
        skuNumbers: filteredArray?.map((eachSku: any) => {
          return { "barCode": eachSku?.number };
        }),
        totalPrice: filteredArray.reduce((acc: number, each: any) => {
          if (each?.price)
            acc += each?.price;
          return acc;
        }, 0)
      };
      acc.push(newData);
      return acc;
    }, []);

    setNewSkuStatusDetails(newpayload);

    const scannedParts = formValue?.scannedPartSkus;

    //new line added

    const combinedArray = skuStatus.reduce((acc, status) => {
      const foundItem = data.find((item: any) => item.name === status);
      if (foundItem && foundItem[status]) {
        acc = acc.concat(foundItem[status]);
      }
      return acc;
    }, []);

    const scannedPartNumbers = scannedParts?.map((part: any) => part.barCode);
    const updatedUsedPart = combinedArray?.map((part: any) => ({
      ...part,
      scanned: scannedPartNumbers?.includes(part.number) || part.status != "Unused"
    }));
    setUnusedSkuDetails(updatedUsedPart);

  }, [Unused, Used, Rejected, Scrapped, Missing, Floating]);

  useEffect(() => {
    if (id) {
      dispatch(getStockInspectionBYID(id));
    }
  }, []);

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const [localFormFields, setLocalFormFields] = useState<FieldModal[]>([]);
  const [contactDetailFalse, setContactDetailFalse] = useState(false);

  const [visible, setVisible] = useState(false);
  const [lockedStateVisible, setLockedStateVisible] = useState(false);
  const [showSkuPopup, setShowSkuPopup] = useState(false);
  const [skuStatusInfo, setSkuInfo] = useState<any>(null);
  const [scannedResponse, setScannedResponse] = useState<any>(null);
  const [skuStatusDetails, setSkuStatusDetails] = useState<any>([]);

  const [skuNewStatusDetails, setNewSkuStatusDetails] = useState<any>([]);
  const [skuDetails, setSkuDetails] = useState<any[]>([]);
  const [unUsedSkuDetails, setUnusedSkuDetails] = useState<any[]>([]);
  const [unUsedSkuNumbers, setUnUsedSkuNumbers] = useState<string[]>([]);
  const [currentUnUsed, setCurrentUnUsed] = useState<string[]>([]);
  const [scannedNumbers, setScannedNumbers] = useState<string[]>([]);
  const [openScanner, setOpenScanner] = useState<boolean>(false);
  const [preventCreate, setPreventCreate] = useState<boolean>(false);
  const [supplierError, setSupplierError] = useState<string>("");
  const [audioBrandError, setAudioBrandError] = useState<string>("");

  const [formValues, setFormValues] = useState<any>(null);


  const supplierDropdown = supplierList?.map((item: any) => ({
    label: item.name,
    value: item.id
  })).sort((a: any, b: any) => {
    const labelA = a.label.toUpperCase().trim();
    const labelB = b.label.toUpperCase().trim();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });

  const initiatedByDropdown = initiatedByList?.map((item: any) => ({
    label: item.name,
    value: item.id
  })).sort((a: any, b: any) => {
    const labelA = a.label.toUpperCase().trim();
    const labelB = b.label.toUpperCase().trim();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });;
  const audioBrandDropdown = audioBrandList?.map((item: any) => ({
    label: item.brandName,
    value: item.id
  })).sort((a: any, b: any) => {
    const labelA = a.label.toUpperCase().trim();
    const labelB = b.label.toUpperCase().trim();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });;
  const StockLocationDropdown = stockLocationList?.map((item: any) => ({
    label: item.locationName,
    value: item.id
  })).sort((a: any, b: any) => {
    const labelA = a.label.toUpperCase().trim();
    const labelB = b.label.toUpperCase().trim();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });;

  const openScannerHandler = (values: any) => {
    setFormValues(values);
    setOpenScanner(true);
  };

  useEffect(() => {
    if (scanResponse?.data) {
      let skuIndex;
      const matchedIds = unUsedSkuDetails.filter((item: any, index: number) => scanResponse?.data?.some((response: any) => {
        if (response.id === item.id) {
          skuIndex = index;
        }
        return response.id === item.id;
      }));
      if (matchedIds?.length > 1) {
        if (openScanner) {
          setOpenScanner(false);
        }
        setCustomerChoicePartNumber(true);
        const scannedIds = scanResponse?.data?.map((item: any) => item.id);
        const unscannedMatchedItems = unUsedSkuDetails.filter((item: any) => scannedIds?.includes(item.id) && !item.scanned);
        const unscanndIds = unscannedMatchedItems?.map((item: any) => item.id);
        const apiResponse = scanResponse?.data?.filter((item: any) => unscanndIds?.includes(item.id));
        const modifiedData: any = apiResponse?.length > 0 ? apiResponse?.map((data: any) => {
          let object = {
            name: `Number : ${data.partNumber} Hsn :${data.partHsnNumber} Price : ${data.totalPrice}`,
            number: data.partNumber,
            hsnNumber: data.partHsnNumber,
            price: data.totalPrice,
            value: data.id,
            partId: data?.partId
          };
          return object;
        }) : [];
        setDuplicateParts(modifiedData);
        setScannedResponse(modifiedData);
      } else {
        if (typeof (skuIndex) === 'number') {
          unUsedSkuDetails[skuIndex].scanned = true;
          unUsedSkuDetails[skuIndex].newScanned = true;
          unUsedSkuDetails[skuIndex].partId = scanResponse?.data[0]?.partId;
        }
        setCustomerChoicePartNumber(false);
        setScannedResponse(scanResponse?.data);
      }
    }
  }, [scanResponse]);


  useEffect(() => {
    if (error) {
      setIsSubmitting(false);
      setVisible(true);
      const errors = ["The selected Supplier has pending inspections", "The selected Audio Brand has pending inspections"];
      if (typeof (error) === 'object' && errors?.includes(error.message)) {
        setPreventCreate(true);
        if (error.message === "The selected Supplier has pending inspections") {
          setSupplierError(error.message);
        } else if (error?.message === "The selected Audio Brand has pending inspections") {
          setAudioBrandError(error?.message);
        }
      }
    }
  }, [error]);

  const hidePopup = () => {
    setVisible(false);
    setCustomError("");
    dispatch(resetStockInspectionMessage());
    // dispatch(clearSupplierMessage());
  };

  const hideSkuInfo = () => {
    setShowSkuPopup(false);
    setSkuInfo(null);
  };

  useEffect(() => {
    dispatch(getSupplierDropdown());
    dispatch(getInitiatedByDropdown());
    dispatch(getAudioBrandDropdown());
    dispatch(getStockLocationDropdown());
    return () => {
      dispatch(clearValues());
      setSkuInfo(null);
      setSkuInfo([]);
      setScannedResponse(null);
      setSkuStatusDetails([]);
      setSkuDetails([]);
      setUnusedSkuDetails([]);
      setUnUsedSkuNumbers([]);
      setCurrentUnUsed([]);
      setScannedNumbers([]);
    };
  }, []);


  // =====SKU NUMBER SET IN STATE ======= //

  // useEffect(() => {



  //   let newResult = [...Unused, ...Used, ...Rejected, ...Scrapped, ...Missing, ...Floating]



  // }, [skuNumberStatus]);

  useEffect(() => {
    if (id) {
      editSupplierOrAudioSkuFetchHandler(initialvalue);
    }
  }, [formValue?.['supplierId'], formValue?.['audioBrandId']]);

  const getSkuWithSupplierStatus = (supplierId: string, values: any) => {

    values = { ...values, supplierId };

    for (let status of skuStatus) {
      values = { ...values, status };

      let modifiedData = {};
      if (FranchiseLogin) {
        let { stockLocationId, ...allData } = values;
        modifiedData = { ...allData, stockLocationId: 0 };
      }
      dispatch(getSkuNumberWithStatus(FranchiseLogin ? modifiedData : values, true, id ? true : false));
    }
  };

  const getSkuWithAudioIdStatus = (audioBrandId: string, values: any) => {

    values = { ...values, audioBrandId };
    for (let status of skuStatus) {
      values = { ...values, status };
      let modifiedData = {};
      if (FranchiseLogin) {
        let { stockLocationId, ...allData } = values;
        modifiedData = { ...allData, stockLocationId: 0 };
      }
      dispatch(getSkuNumberWithStatus(FranchiseLogin ? modifiedData : values, false, id ? true : false));
    }
  };

  const skuInfoDialog = () => {
    return (<Dialog header={skuStatusInfo?.name + " SKU numbers"} visible={showSkuPopup} maximizable className='vw30' onHide={() => hideSkuInfo()}>
      {skuStatusInfo?.[skuStatusInfo?.name]?.length ? <div className="m-0 .validation-text">
        {/* <ul> */}
        <div className="Sku_eye_View">
          {skuStatusInfo?.[skuStatusInfo?.name]?.map((eachSku: any, index: number) => (
            // <Col md={4} lg={4} xl={3} xxl={3} xs={2} key={eachSku?.id}>
            //   <li className="p-1">{eachSku?.number}</li>
            // </Col>
            <div className="Sku_view_align" key={eachSku?.id}>
              <div className="empty-number-jobeheet"><span></span></div> {eachSku?.number}
            </div>
          ))}
        </div>
        {/* </ul> */}
      </div> : 'No SKU numbers found'}
    </Dialog>);
  };


  const selectedParts = () => {

  };
  const hideOtpContainer = () => {
    setCustomerChoicePartNumber(false);
  };

  // const openCustomerChoicePartDetails=(scanResponse : any)=>{
  //   return (
  //     <PartNumberDetails scanDetails={scanResponse} onHide={hideOtpContainer} onSubmit={selectedParts} />
  //   )
  // }

  const scannerContainer = () => {
    return <Scanner visible={openScanner} onHide={() => setOpenScanner(false)} unUsedSkus={currentUnUsed} values={formValues} />;
  };

  const dialogContainer = (error: any) => {
    console.log("render")
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };

  const accept = (setFieldValue: any) => {
    setFieldValue("locked", true);
    setLockedStateVisible(false);
  };

  const reject = (setFieldValue: any) => {
    setFieldValue("status", "");
    setFieldValue("locked", false);
    setLockedStateVisible(false);
  };


  const showWarningMessage = () => {
    setLockedStateVisible(true);
  };

  const newDialogContainer = (setFieldValue?: any, resetForm?: any, values?: any) => {
    return (
      <ConfirmDialog
        className="validationPopp"
        visible={true}
        onHide={() => { setVisible(false); setLockedStateVisible(false); }}
        message="Cancelled status will automatically lock the stock. Are you sure you want to proceed?"
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={() => accept(setFieldValue)}
        style={{ width: '30vw', display: "flex" }}
        // reject={() => reject(values, resetForm)}
        reject={() => reject(setFieldValue)}
      />
    );
  };

  const post = (formValue: parts) => {
    dispatch(createStockInspection(formValue));
  };

  useEffect(() => {
    if (message) {
      showToast(message, { type: "success" });
      setTimeout(() => {
        setIsSubmitting(false);
        navigate(ROUTER.STOCK_INSPECTION_GRID);
      }, 500);
      dispatch(resetStockInspectionMessage());
    }
  }, [message]);

  useEffect(() => {
    if (formValue) {
      let keys = Object.keys(formSubmitionKeys);
      let skuNumbers: { barCode: string; }[] = [];
      let unUsedSkuNumbers: string[] = [];
      const result = keys.reduce((acc: any, each: string) => {
        let statusDetails = formSubmitionKeys[each];
        let data: any = {};
        data['name'] = each;
        // [each] = filteredArray,
        statusDetails?.forEach((eachDetail: any) => {
          if (eachDetail.field === 'skuNumbers') {
            skuNumbers.push(formValue[eachDetail.name] ?? []);
            if (eachDetail.name === 'unusedPartSkus') {
              unUsedSkuNumbers = formValue[eachDetail.name]?.map((a: { barCode: string; }) => a.barCode);
            }
          }
          data[eachDetail.field] = formValue[eachDetail.name];
        });
        acc.push(data);
        return acc;
      }, []);

      let currentUnused = formValue?.['currentUnusedPartSkus']?.map((a: { barCode: string; }) => a.barCode);
      let scannedNumbers = formValue?.['scannedPartSkus']?.map((a: { barCode: string; }) => a.barCode);
      setCurrentUnUsed(currentUnused);
      setScannedNumbers(scannedNumbers);
      setUnUsedSkuNumbers(unUsedSkuNumbers);
      setSkuStatusDetails(result);
    }
  }, [formValue]);

  const update = (formValue: any) => {
    formValue['id'] = id;
    dispatch(createStockInspection(formValue));
  };

  const initialvalue = {
    initiatedById: formValue?.initiatedById ?? "",
    supplierId: formValue?.supplierId ?? 0,
    stockLocationId: formValue?.stockLocationId ?? "",
    audioBrandId: formValue?.audioBrandId ?? 0,
    status: formValue?.status ?? "",
    locked: formValue?.locked ?? false,
    duplicateParts: ""
  };

  const Franchiseinitialvalue = {
    initiatedById: formValue?.initiatedById ?? "",
    supplierId: formValue?.supplierId ?? 0,
    audioBrandId: formValue?.audioBrandId ?? 0,
    status: formValue?.status ?? "",
    locked: formValue?.locked ?? false
  };

  const handleSubmit = async (formValue: any) => {
    setIsSubmitting(true);
    const { scanSku, ...values } = formValue;
    if (!values['supplierId'] && !values['audioBrandId']) {
      setCustomError("Please select either Supplier or Audio Brand");
      setVisible(true);
      return null;
    }
    if (preventCreate) {
      if (supplierError && values['supplierId']) {
        setCustomError(supplierError);
      } else if (audioBrandError && values['audioBrandId']) {
        setCustomError(audioBrandError);
      }
      return setVisible(true);
    }
    let status = Object.keys(formSubmitionKeys);


    status.forEach((status: any) => {
      let filteredStatus = skuNewStatusDetails?.filter((statusDetail: any) => statusDetail.name === status)?.[0];
      formSubmitionKeys[status]?.forEach((each: { name: string, field: string; }) => {
        values[each.name] = filteredStatus?.[each.field];
      });
    });
    const unusedPart: any = unUsedSkuDetails.length > 0 ? unUsedSkuDetails?.filter((eachSku: any) => !eachSku?.scanned)?.map((each: any) => {
      return { barCode: each?.number,partId :each?.partId };
    }) : null;
    values["currentUnusedPartSkus"] = unusedPart?.length > 0 ? unusedPart : null;

    values["unusedPartSkus"] = Unused.length ? Unused.map(each => ({ barCode: each?.number,partId :each?.partId  })) : null;

    values["usedPartSkus"] = Used.length ? Used.map((each: any) => { return { barCode: each?.number,partId :each?.partId }; }) : null;

    values["rejectedPartSkus"] = Rejected.length ? Rejected.map((each: any) => { return { barCode: each?.number,partId :each?.partId }; }) : null;

    values["missingPartSkus"] = Missing.length ? Missing.map((each: any) => { return { barCode: each?.number,partId :each?.partId }; }) : null;

    values["scrappedPartSkus"] = Scrapped.length ? Scrapped.map((each: any) => { return { barCode: each?.number,partId :each?.partId }; }) : null;

    values["floatingPartSkus"] = Floating.length ? Floating.map((each: any) => { return { barCode: each?.number,partId :each?.partId }; }) : null;

    const scannedparts: any = unUsedSkuDetails.length > 0 ? unUsedSkuDetails?.filter((eachSku: any) => eachSku?.scanned && eachSku?.status === 'Unused')?.map((each: any) => {
      return { barCode: each?.number, partId: each?.partId };
    }) : null;

    values['scannedPartSkus'] = scannedparts?.length > 0 ? scannedparts : null;

    if (values['supplierId']) {
      values['supplierName'] = supplierDropdown.find((each: any) => each.value === values['supplierId'])?.label;
      if ('audioBrandId' in values) {
        delete values['audioBrandId'];
      }
    }
    if (values['audioBrandId']) {
      values['audioBrandName'] = audioBrandDropdown.find((each: any) => each.value === values['audioBrandId'])?.label;
      if ('supplierId' in values) {
        delete values['supplierId'];
      }
    }


    let modifiedData = {};
    if (FranchiseLogin) {
      modifiedData = {
        ...values,
        stockLocationId: 0
      };
    }


    let submittedData = FranchiseLogin ? modifiedData : values;
    id ? update(submittedData) : post(submittedData);
    setIsSubmitting(false);
  };

  const validationSchema = Yup.object().shape({
    initiatedById: Yup.string().required(`Initiated by is required`),
    stockLocationId: Yup.string().required(`Stock Location is required`),
    status: Yup.string().required(`Status is required`),
  });

  const validationSchemaForEdit = Yup.object().shape({
    initiatedById: Yup.string().required(`Initiated by is required`),
    stockLocationId: Yup.string().required(`Stock Location is required`),
    status: Yup.string().required(`Status is required`),
  });

  const validationSchemaForFranchise = Yup.object().shape({
    initiatedById: Yup.string().required(`Initiated by is required`),
    status: Yup.string().required(`Status is required`),
  });

  const removeIndex = (i: number) => {
    setselectedFiles((prevFiles: any) => {
      const newFiles = [...prevFiles];
      newFiles.splice(i, 1);
      return newFiles;
    });
  };

  function handleAcceptedFiles(files: any) {
    files?.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }

  const openSkuInfo = (rowData: any) => {
    setSkuInfo(rowData);
    setShowSkuPopup(true);
  };

  const skuInfo = (rowData: any) => {
    return <Info onClick={() => openSkuInfo(rowData)} />;
  };

  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const handleReset = (resetForm: any) => {
    resetForm(getEmptyInitialValues());
    if (id) {
      navigate(ROUTER.STOCK_INSPECTION_GRID);
    }
  };

  const getEmptyInitialValues = () => {
    const initialValue = localFormFields?.reduce((acc: any, field: any) => {
      if (field.type !== 'checkbox') {
        acc[field.name] = '';
      } else {
        acc[field.name] = false;
      }
      return acc;
    }, {});
    return initialValue;
  };

  const status = [
    { label: "Cancelled", value: "Cancelled" },
    { label: "Completed", value: "Completed" },
    { label: "In Progress", value: "InProgress" },
  ];

  useEffect(() => {
    if (supplierPartSkuDetail) {
      setSkuDetails(supplierPartSkuDetail);
    }
  }, [supplierPartSkuDetail]);

  useEffect(() => {
    if (audioBrandPartSkuDetail) {
      setSkuDetails(audioBrandPartSkuDetail);
    }
  }, [audioBrandPartSkuDetail]);



  const display = (
    <Row>
      <Col lg={12}>
        <div className="mt-2">
          <div className="card p-1">
            <Row>
              <Col lg={6} xxl={6} md={12}>
                <DataTable size='large' value={skuStatusDetails ? skuStatusDetails.slice(0, 3) : []} showGridlines tableStyle={{ minWidth: '100%', padding: "30px" }}>
                  <Column field="name" align="center" header="SKU status" className=''></Column>
                  <Column field="start" header="Start" align="right" className=' '></Column>
                  <Column field="end" header="End" align="right" className='' ></Column>
                  <Column field="totalPrice" header="Value" align="right" className='' body={(rowData) => numberFormatter(rowData?.totalPrice ?? 0)}></Column>
                  <Column field="info" align="right" header={<Info />} body={(rowData) => skuInfo(rowData)} className='' ></Column>

                </DataTable>

              </Col>


              <Col lg={6} xxl={6} md={12}>

                <DataTable size='large' value={skuStatusDetails ? skuStatusDetails.slice(3, 6) : []} showGridlines tableStyle={{ minWidth: '100%', padding: "30px" }}>
                  <Column field="name" align="center" header="SKU status" className=''></Column>
                  <Column field="start" align="right" header="Start" className=' '></Column>
                  <Column field="end" align="right" header="End" className=' ' ></Column>
                  <Column field="totalPrice" align="right" header="Value" className='' body={(rowData) => numberFormatter(rowData?.totalPrice ?? 0)}></Column>
                  <Column field="info" align="center" header={<Info />} body={(rowData) => skuInfo(rowData)} className='' ></Column>
                </DataTable>

              </Col>




            </Row>
          </div>


        </div>
      </Col>

    </Row>
  );



  const [scanResult, setScanResult] = useState("");
  const handleKeyPress = (e: any, values: any, setFieldValue: any) => {
    // if (event.key !== 'Enter' && event.key !== 'Tab') return;
    // if (event.which == 13 || event.which == 32) {
      e?.preventDefault();
    // }]

    debugger
    // let toScan = inputVal;
    // @ts-ignore
    // let toScan = document.getElementById('skunum')?.value;
    let toScan = numRef?.current ? numRef?.current?.value : "";
    if (!toScan) return;

    // if (event.key === 'Enter' || event.key === "Tab") {
    debugger
      let alreadyScanned = unUsedSkuDetails?.some(a => a.number === toScan && a?.scanned);
      let val = unUsedSkuDetails?.find(a => a.number === toScan);
      if (alreadyScanned) {
        // setFieldValue('scanSku', '');
        // setInputVal("")
        numRef.current.value = ""; 
        // numRef.current.focus(); 
        // numRef.current.blur();
        // numRef.current.focus();         // Re-focus to reset the cursor position
      // numRef.current.value = numRef.current.value.replace(/\n/g, "");

        return showToast('SKU number already scanned', { type: "error" });
      }
      setScanResult(toScan);

      values = { ...values, scanSku: toScan };
      let modifiedData = {};
      if (franchiseLogin) {
        modifiedData = { ...values, stockLocationId: 0 };
      }
      dispatch(getPartSkuWithLocation(franchiseLogin ? modifiedData : values));
      // setInputVal("")
      // values.scanSku = "";
      // setFieldValue('scanSku', '');
      numRef.current.value = ""; 
      // numRef.current.focus();         
      // numRef.current.blur();
      // numRef.current.focus();         // Re-focus to reset the cursor position
      // numRef.current.value = numRef.current.value.replace(/\n/g, "");
    // }
  };

  const clearAllDataWhenSupplierOrAudioEmpty = () => {
    setPreventCreate(false);
    setSkuInfo(null);
    setScannedResponse(null);
    setSkuStatusDetails([]);
    setSkuDetails([]);
    setUnusedSkuDetails([]);
    setUnUsedSkuNumbers([]);
    setCurrentUnUsed([]);
    setScannedNumbers([]);
  };

  const audioBrandApiCall = (e: any, locationId: any) => {

    if (e) {
      dispatch(getAudioBrandPartSkuDetails(e, locationId));
    }
    setPreventCreate(false);
    setSkuInfo(null);
    setSkuInfo([]);
    setScannedResponse(null);
    setSkuStatusDetails([]);
    setSkuDetails([]);
    setUnusedSkuDetails([]);
    setUnUsedSkuNumbers([]);
    setCurrentUnUsed([]);
    setScannedNumbers([]);
  };

  const locationHandler = (event: any, values: any) => {
    // API call used before 29-02-2024
    // if (supplierId) {
    //   supplierApiCall(supplierId, event?.value);
    // } else if (audioBrandId) {
    //   audioBrandApiCall(audioBrandId, event?.value);
    // }
    // return event;

    // API call after performance optimization on 29-02-2024
    // supplierId: number, audioBrandId: number
    values = { ...values, stockLocationId: event?.value };
    if (values?.supplierId) {
      getSkuWithSupplierStatus(values?.supplierId, values);
    } else if (values?.audioBrandId) {
      getSkuWithAudioIdStatus(values?.audioBrandId, values);
    }
  };

  const editSupplierOrAudioSkuFetchHandler = (values: any) => {
    if (values?.supplierId) {
      getSkuWithSupplierStatus(values?.supplierId, values);
    } else if (values?.audioBrandId) {
      getSkuWithAudioIdStatus(values?.audioBrandId, values);
    }
  };

  const validateStatus = (event: any, setFieldValue: any) => {
    setTimeout(() => {
      if (event.value == "Completed") {
        setFieldValue("locked", false);
        setLockedStateVisible(false);
      } else if (event.value == "Cancelled") {
        showWarningMessage();
      } else if (event.value == "InProgress") {
        setFieldValue("locked", false);
        setLockedStateVisible(false);
      }
    }, 0);
  };

  const onHide = () => {
    setCustomerChoicePartNumber(false);
  };
  const renderButton = (rowData: any) => {
    return (
      <FeatherIcon icon="check-circle" style={{ color: "green" }} onClick={
        (e: any) => {
          let skuIndex;
          unUsedSkuDetails.forEach((val: any, index: number) => {
            if (rowData.value === val.id) {
              skuIndex = index;
            }
          });
          if (typeof (skuIndex) === 'number') {
            unUsedSkuDetails[skuIndex].scanned = true;
            unUsedSkuDetails[skuIndex].partId = rowData.partId;
            const showValues = scanResponse?.data?.filter((data: any) => data.id == rowData.value);
            setScannedResponse(showValues);
          }
          setCustomerChoicePartNumber(false);
        }
      } />
    );
  };
  const selectParts = () => {
    return (

      <Dialog
        header="Choose Part"
        visible={customerChoicePartNumber}
        maximizable
        style={{ width: "auto" }}
        onHide={onHide}
      >
        {/* {
    customerChoicePartNumber && <div className="mt-2">
        {
          duplicateParts?.map((item:any ,index)=>{
          return(
            <div className="options-parent" key={index}>
            <div className="options" onClick={
              (e:any) => {
                debugger
                let skuIndex;
                unUsedSkuDetails.forEach((val: any, index: number) => {
                  if (item.value === val.id) {
                    skuIndex = index;
                  }
                });
                if (typeof (skuIndex) === 'number') {
                  unUsedSkuDetails[skuIndex].scanned = true;
                  const showValues = scanResponse?.data?.filter((data: any) => data.id == item.value)
                  setScannedResponse(showValues)
                }
                setCustomerChoicePartNumber(false)
              }
            }>{item.name}</div>
            </div>
          )
          })
        }
    </div>
  } */}
        <>
          <DataTable value={duplicateParts} tableStyle={{ width: "auto" }}>
            <Column field="number" header="Number"></Column>
            <Column field="hsnNumber" header="HSN"></Column>
            <Column field="price" header="Price"></Column>
            <Column header="Select" body={renderButton}></Column>
          </DataTable>
        </>

      </Dialog>
    );
  };
  console.count("render")
const [ inputVal,setInputVal]=useState<any>("")
const numRef = useRef<any>(null)
  return (
    <div id="view-dealer">

      {visible && (error && dialogContainer(error) || customError && dialogContainer(customError))}
      {openScanner && scannerContainer()}
      {showSkuPopup && skuInfoDialog()}
      {customerChoicePartNumber && selectParts()}
      {/* {customerChoicePartNumber && openCustomerChoicePartDetails(scanResponse) } */}
      {/* {customerChoicePartNumber && <div className='balckscreen'></div> } */}
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title="Stock Inspection"
            titlePath={ROUTER.STOCK_INSPECTION_GRID}
            breadcrumbItem={id ? "Update Stock Inspection" : "Create Stock Inspection"}
          />
          <Row>
            <Card>
              <CardBody>
                <React.Fragment>
                  <Formik
                    onSubmit={handleSubmit}
                    initialValues={FranchiseLogin ? Franchiseinitialvalue : initialvalue}
                    validationSchema={(id && !FranchiseLogin) ? validationSchemaForEdit : ((id && FranchiseLogin) || (!id && FranchiseLogin)) ? validationSchemaForFranchise : validationSchema}
                    enableReinitialize   validateOnChange={false}
                  >
                    {({
                      values,
                      handleChange,
                      touched,
                      errors,
                      handleBlur,
                      resetForm,
                      setFieldValue,
                      reset,
                      ...rest
                    }: any) => {
                      return (
                        <Form onChange={handleChange}>
                          <Row>
                            <Col lg={3} className="mb-2">
                              <DropdownInput
                                options={initiatedByDropdown}
                                name="initiatedById"
                                label="Initiated By"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required={true}
                                value={values.initiatedById}
                                invalid={
                                  touched["initiatedById"] && errors["initiatedById"]
                                    ? true
                                    : false
                                } />
                            </Col>
                            {
                              !FranchiseLogin &&
                              <Col lg={3} className="mb-2">
                                <DropdownInput
                                  options={StockLocationDropdown}
                                  name="stockLocationId"
                                  label="Stock Location"
                                  onChange={(event) => { handleChange(event); locationHandler(event, values); }}
                                  onBlur={handleBlur}
                                  required={true}
                                  value={values.stockLocationId}
                                  invalid={
                                    touched["stockLocationId"] && errors["stockLocationId"]
                                      ? true
                                      : false
                                  } />
                              </Col>
                            }



                            <Col lg={3} className="mb-2">
                              <DropdownInput
                                options={supplierDropdown}
                                name="supplierId"
                                label="Supplier"
                                onChange={(e) => {
                                  handleChange(e);
                                  // supplierApiCall(e.value, values.stockLocationId);
                                  clearAllDataWhenSupplierOrAudioEmpty();
                                  if (e.value) {
                                    getSkuWithSupplierStatus(e.value, values);
                                  }
                                }}
                                disabled={(FranchiseLogin == false && (id || values.audioBrandId || !values.stockLocationId)) ? true : (FranchiseLogin == true && (values.audioBrandId != 0 && values.audioBrandId != null)) ? true : (FranchiseLogin == true && values.audioBrandId == null) ? false : false}
                                // onBlur={(e: any) => { handleBlur; supplierApiCall(values.supplierId); }}
                                required={false}
                                value={values.supplierId}
                                showClear={true}
                                invalid={
                                  touched["supplierId"] && errors["supplierId"]
                                    ? true
                                    : false
                                } />
                            </Col>

                            <Col lg={3} className="mb-2">
                              <DropdownInput
                                options={audioBrandDropdown}
                                name="audioBrandId"
                                label="Audio Brand"
                                onChange={(e) => {
                                  handleChange(e);
                                  clearAllDataWhenSupplierOrAudioEmpty();
                                  if (e.value) {
                                    getSkuWithAudioIdStatus(e.value, values);
                                  }
                                }}
                                disabled={(FranchiseLogin == false && (id || values.supplierId || !values.stockLocationId)) ? true : (FranchiseLogin == true && (values.supplierId != 0 && values.supplierId != null)) ? true : (FranchiseLogin == true && values.supplierId == null) ? false : false}
                                required={false}
                                showClear={true}
                                value={values.audioBrandId}
                                invalid={
                                  touched["audioBrandId"] && errors["audioBrandId"]
                                    ? true
                                    : false
                                } />
                            </Col>

                            <Col lg={3} className="mb-2">
                              <DropdownInput
                                options={status}
                                name="status"
                                label="Status"
                                // onChange={handleChange}
                                onChange={(e) => {
                                  handleChange(e);
                                  validateStatus(e, setFieldValue);
                                }}
                                onBlur={handleBlur}
                                required={true}
                                value={values.status}
                                invalid={
                                  touched["status"] && errors["status"]
                                    ? true
                                    : false
                                } />
                            </Col>
                            {lockedStateVisible && newDialogContainer(setFieldValue, resetForm, values)}

                          </Row>
                          {(!!values.audioBrandId || !!values.supplierId) && <><Row className="p-3-custom" >
                            <React.Fragment>
                              <div className="custom-card-input">
                                <Col lg={12} md={12} sm={12}>
                                <div className="d-flex custom-input">

                                
                                  <div className="inp-icon">
                                    <FeatherIcon icon="search" className="icon-lg left-icon" />
                                    <textarea
                                      className="icon-input"
                                      // type="text"
                                      placeholder="Scan SKU here"
                                      // onBlur={(e)=>{setInputVal(e.target.value);handleBlur(e)}}
                                      // id="skunum"
                                      ref={numRef}
                                      // name="scanSku"
                                      // value={inputVal}
                                      required={false}
                                      // onChange={(e)=>setInputVal(e.target.value)}
                                      
                                      onKeyDown={(e) =>{ 
                                        if (e.key === 'Enter' || e.key === "Tab") {
                                          handleKeyPress(e, values, setFieldValue)
                                        }
                                      }}
                                    />
                                    <FeatherIcon icon="camera" className="icon-lg right-icon pointer" onClick={() => { openScannerHandler(values); }} />
                                  </div>


                                {/* <div className="d-flex get-detail"  onClick={() => { handleKeyPress({}, values, setFieldValue)  }} >Get Details</div> */}
                                </div>
                                </Col>
                                    
                                <Col lg={12} md={12} sm={12}>
                                  <div className="scanned">
                                    <b>Details</b>
                                    {/* {JSON.stringify(scannedResponse)} */}
                                    {/* {scannedResponse && <div className="scanned-sku">
                                      <span className="custom-badge">Number: {scannedResponse?.number}</span>
                                      <span className="custom-badge">Value: {numberFormatter(scannedResponse?.totalPrice)}</span>
                                      <span className="custom-badge">Description: {scannedResponse?.partDescription}</span>
                                    </div>
                                    } */}

                                    {scannedResponse?.length > 0 &&
                                      <div className="scanned-sku">
                                        <span className="custom-badge">Number: {scannedResponse[0]?.number}</span>
                                        <span className="custom-badge">Value: {numberFormatter(scannedResponse[0]?.totalPrice)}</span>
                                        <span className="custom-badge">Description: {scannedResponse[0]?.partDescription}</span>
                                      </div>
                                    }
                                  </div>
                                </Col>
                              </div>
                              <div className="card custom-card-height" style={{width:"49%"}}>
                                <Row>
                                  {
                                    unUsedSkuDetails?.filter((data:any)=>(data.status == "Unused" && data.scanned != true))?.map((val: any, index: number) => {
                                      return (

                                        <Col lg={2} md={3} sm={4} xs={6} key={index}>
                                          <div className="display-value">
                                            <li className={(val?.scanned && val?.newScanned) ? 'new-tick' : (val?.scanned && !val?.newScanned) ?`tick-list` : `normal-list`}>
                                              <FeatherIcon icon={val?.scanned ? `check-circle` : `circle`} className="icon-lg" ></FeatherIcon>
                                              <span>{val?.number}</span>
                                            </li>
                                          </div>
                                        </Col>
                                      );
                                    })
                                  }
                                </Row>
                              </div>
                              <div className="card custom-card-height" style={{width:"49%"}}>
                                <Row>
                                  {
                                    unUsedSkuDetails?.filter((data:any)=> data.scanned == true)?.map((val: any, index: number) => {
                                      return (

                                        <Col lg={2} md={3} sm={4} xs={6} key={index}>
                                          <div className="display-value">
                                            <li className={(val?.scanned && val?.newScanned) ? 'new-tick' : (val?.scanned && !val?.newScanned) ?`tick-list` : `normal-list`}>
                                              <FeatherIcon icon={val?.scanned ? `check-circle` : `circle`} className="icon-lg" ></FeatherIcon>
                                              <span>{val?.number}</span>
                                            </li>
                                          </div>
                                        </Col>
                                      );
                                    })
                                  }
                                </Row>
                              </div>
                            </React.Fragment>
                          </Row>
                            {display}
                          </>}

                          <Col lg={3} className="mb-2 d-flex align-items-center">
                            {/* {JSON.stringify(values.status)} */}
                            <CheckboxInput
                              name="locked"
                              label="Locked"
                              value={values.locked}
                              disabled={values.status !== 'Completed'}
                              onChange={handleChange}
                              {...rest}
                            />
                          </Col>

                          <div className="d-flex justify-content-end mt-2">
                            <Button
                              className="secondary-btn me-2"
                              label={id ? "Cancel" : "Reset"}
                              type="button"
                              onClick={() => handleReset(resetForm)}
                            />
                            <Button
                              className="btn-primary me-2"
                              label={id ? "Update Stock Inspection" : "Create Stock Inspection"}
                              type="submit"
                            />
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </React.Fragment>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default AddOrEditStockInspection;