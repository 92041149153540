import { vehicleBrandsDetails } from "src/models/pages/vehicleBrandsModel";
import { CarBrandsTypes } from "./actionTypes";



// For Grid

export const getCarBrandsList = (lazyState: any) => {
  return {
    type: CarBrandsTypes.GET_CAR_BRANDS_LIST,
    lazyState,
  };
};



export const getCarBrandsListSuccess = (vehicleBrands: any) => {
  return {
    type: CarBrandsTypes.GET_CAR_BRANDS_LIST_SUCCESS,
    payload: vehicleBrands,
  };
};

export const getCarBrandsListError = (error: any) => {
  return {
    type: CarBrandsTypes.GET_CAR_BRANDS_LIST_FAIL,
    payload: error,
  };
};

export const clearCarBrandsMessage = ()=>{
  return {
    type: CarBrandsTypes.RESET_CAR_BRANDS,
  }
}

export const resetCarBrand = ()=>{
  return {
    type: CarBrandsTypes.CLEAR_CAR_BRANDS,
  }
}

export const deleteCarBrandsFromList = (payload:any) => {
  return {
    type: CarBrandsTypes.DELETE_CAR_BRANDS,
    payload: payload,
  }
}

export const deleteCarBrandsFromListSuccess = (response:any,undo : boolean) => {
  return {
    type: CarBrandsTypes.DELETE_CAR_BRANDS_SUCCESS,
    payload: response,
    undo
  }
}

export const deleteCarBrandsFromListError = (error:any) => {
  return {
    type: CarBrandsTypes.DELETE_CAR_BRANDS_FAIL,
    payload: error,
  }
}



//  update 

// update Car

export const updateCarBrands = (carModel: any) => {
  return { type: CarBrandsTypes.UPDATE_CAR_BRANDS
    
    , payload: carModel };
};

export const updateCarBrandsSuccess = (vehicle: vehicleBrandsDetails) => {
  return { type: CarBrandsTypes.UPDATE_CAR_BRANDS_SUCCESS, payload: vehicle };
};

export const updateCarBrandsError = (error: any) => {
  return { type: CarBrandsTypes.UPDATE_CAR_BRANDS_FAIL, payload: error };
};


// post Car
export const createCarBrands = (vehicle: any) => {
  return { type: CarBrandsTypes.POST_CAR_BRANDS, payload: vehicle };
};

export const createCarBrandsSuccess = (vehicle: any) => {
  return { type: CarBrandsTypes.POST_CAR_BRANDS_SUCCESS, payload: vehicle };
};

export const createCarBrandsError = (error: any) => {
  return { type: CarBrandsTypes.POST_CAR_BRANDS_FAIL, payload: error };
};


// get vehicle
export const getCarBrandsBYID = (id: string) => {
  return { type: CarBrandsTypes.GET_CAR_BRANDS_BY_ID, payload: id };
};

export const getCarBrandsBYIDSuccess = (vehicle: any) => {
  return { type: CarBrandsTypes.GET_CAR_BRANDS_BY_ID_SUCCESS, payload: vehicle };
};

export const getCarBrandsBYIDError = (error: any) => {
  return { type: CarBrandsTypes.GET_CAR_BRANDS_BY_ID_FAIL, payload: error };
};

export const getVehicleBrandDropdown = () => {
  return {
    type: CarBrandsTypes.GET_VEHICLE_BRAND_DROPDOWN_LOADING,
  };
};



export const getVehicleBrandDropdownSuccess = (vehicleBrands: any) => {
  return {
    type: CarBrandsTypes.GET_VEHICLE_BRAND_DROPDOWN_SUCCESS,
    payload: vehicleBrands,
  };
};

export const getVehicleBrandDropdownError = (error: any) => {
  return {
    type: CarBrandsTypes.GET_VEHICLE_BRAND_DROPDOWN_FAIL,
    payload: error,
  };
};