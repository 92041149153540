import { IntentInitialState } from './../models/pages/intentModel';
import { invoiceItemWiseRegistersInitialState } from 'src/models/pages/invoiceItemWiseRegistersModel';
import { administratorsInitialState } from './../models/pages/administratorModel';
import { customers, customersInitialState } from './../models/pages/customerModel';
import { combineReducers } from "redux"
// Layout
import Layout from "./layout/reducer";

// Calendar
import Calendar from "./calendar/reducer";

//Chat
import Chat from "./chat/reducer";

//Invoices
// import Invoices  from "./invoices/reducer";
import invoiceReducer from "./invoices/reducer";

//Contact
import contacts from "./contacts/reducer";

//login
import login, { LoginState } from "./auth/login/reducer";

//register
import register, { RegisterState } from "./auth/register/reducer";

// User Profile 
import profile, { ProfileState } from "./auth/profile/reducer";

// Forget Password
import forgetPassword, { ForgetPaswordState } from "./auth/forgetpwd/reducer";

//meltable
import MeltableReducer from './melTable/reducer'

/* jobsheet */
import jobsheetReducer from './jobsheets/reducer';

import userReducer from './users/reducer'
import customerReducer from './customers/reducer'
import admnistratorReducer from './administrator/reducer'
import audioBrandsReducer from './audioBrands/reducer'
import audioModelsReducer from './audioModels/reducer'
import partsReducer from './parts/reducer'
import taxesReducer from './taxes/reducer'
import vehicleBrandsReducer from './vehicleBrands/reducer'
import partSkuReducer from './partSku/reducer';
import franchiseeReducer from './franchisee/reducer';
import subUserReducer from './subuser/reducer';
import purchaseOrderReducer from './purchaseOrders/reducer';
import PartsInboundReducer from './partsInbound/reducer';
import stockLocationReducer from './stockLocation/reducer';
import attendanceReducer from './attendance/reducer';
//Dashboard
import dashboard, { DashboardState } from "./dashboard/reducer"
import { LayoytState } from "./layout/actionTypes";
import { CalendarState } from "./calendar/actionTypes";
import { UserTypes } from "./users/actionTypes"
import { ChatState } from "./chat/actionTypes";
import { ContactState } from "./contacts/actionTypes";
import profilesReducer from "./profile/reducer";
import { usersInitialState } from 'src/models/pages/userModel';
import DealerReducer from './dealer/reducer';
import { dealerInitialState } from 'src/models/pages/dealerModel';
import { audioBrandsInitialState } from 'src/models/pages/audioBrandsModel';
import { audioModelsInitialState } from 'src/models/pages/audioModelsModel';
import { partsInitialState } from 'src/models/pages/partsModel';
import { taxesInitialState } from 'src/models/pages/taxes';
import { vehicleBrandsInitialState } from 'src/models/pages/vehicleBrandsModel';
import { partSkuInitialState } from 'src/models/pages/partSkusModel';
import { subUserInitialState } from 'src/models/pages/subUserModel';
import { FranchiseeInitialState } from 'src/models/pages/franchiseeModel';
import StockAdjustmentReducer from './stockAdjustment/reducer';
import stockRegisterReducer from './stockRegister/reducer';
import { supplierInitialState } from 'src/models/pages/supplierModel';
import supplierReducer from './supplier/reducer';
import dealerSubUserReducer from './dealersubuser/reducer'
import stockValueBySupplierReducer from "./stockValueBySupplier/reducer";
import stockQuantityBySupplierReducer from "./StockQuantityBySupplier/reducer";
import stockValueByMusicBrandReducer from "./stockValueByMusicSystemBrand/reducer";
import stockQuantityByMusicSystemBrandReducer from "./stockQuantityByMusicSystemBrand/reducer"
import { purchaseOrderInitialState } from 'src/models/pages/purchaseOrders';
import { DealersubuserInitialStore } from 'src/models/pages/dealersubuserModel';
import { StockValueBySupplierInitialStore } from 'src/models/pages/stockValueBySupplier';
import { StockQuantityBySupplierInitialStore } from 'src/models/pages/stockQuantityBySupplier';
import { StockValueByMusicSystemInitialStore } from 'src/models/pages/stockValueMusicSystemBrandModel';
import { StockQuantityByMusicSystemBrandInitialStore } from 'src/models/pages/stockQuantityByMusicSystemModel';
import { stockValuebyPartInitialState } from 'src/models/pages/stockValuebyPart';
import stockValuebyPartReducer from './stockValuebyPart/reducer';
import stockQtybyPartReducer from './stockQtybyPart/reducer';
import { stockQtybyPartInitialState } from 'src/models/pages/stockQtybyPart';
import { PartInboudReducerInitialState } from 'src/models/pages/partsInboundModel';
import { InvoiceInitialState } from 'src/models/pages/invoiceModel';
import invoiceItemWiseRgisterReducer from './invoiceItemWiseRegisters/reducer';
import EnquiryReducer from './enquiries/reducer';
import outGoingDispatchReducer from "./outGoingDispatch/reducer"
import { outGoingDispatchInitialState } from 'src/models/pages/outGoingDispatchModel';
import IncomingDispatchReducer from './incomingDispatch/reducer';
import { stockLocationInitialState } from 'src/models/pages/stockLocationModel';
import { attendanceInitialState } from 'src/models/pages/attendanceModel';
import { stockRegisterInitialState } from 'src/models/pages/stockRegisterModel';
import { stockAdjustmentInitialState } from 'src/models/pages/stockAdjustment';
import { incomingDispatchTypes } from './incomingDispatch/actionTypes';
import { inComingDispatchInitialState } from 'src/models/pages/incomingDispatch';
import { jobsheetInitialState } from 'src/models/pages/jobsheetModel';
import intentReducer from './intent/reducer';
import StockInspectionReducer from './stockInspection/reducer';
import { StockInspection } from 'src/models/pages/stockinspectionmodel';
import { courierReducerState } from 'src/models/pages/couriersModel';
import courierReducer from './couriers/reducer'
import { jobsheetReducerGlobalState } from 'src/models/pages/jobSheetReportsModel';
import jobSheetReportReducer from "./jobSheetReports/reducer"
import pendingPartsConsolodatedReducer from './pendingpartsconsolidated/reducer';
import { pendingPartsConsolidatedList } from 'src/models/pages/pendingPartsConsolidate';
import { jobsheetTatReportsReducerState } from 'src/models/pages/jobsheetTatReports';
import jobSheetTatReportsReducer from "./jobsheetTatReports/reducer"
import { monthlyPartsSummaryReducerState } from 'src/models/pages/monthlyPartSummaryModel';
import monthlyPartsSummaryReducer from "./monthlyPartSummary/reducer";
import { pendingPartsReducerState } from 'src/models/pages/pendingPartsModel';
import pendingPartsReducer from "./pendingParts/reducer";
import { receivedPaymentReducerState } from 'src/models/pages/receivedPaymentModel';
import receivedPaymentReducer from "./receivedPayment/reducer";
import { LoginAuthState } from 'src/models/auth/loginAuthState';
import loginAuthReducer from './authLogin/reducer';

import { profileTableState } from 'src/models/pages/profileModel';
import { FeedbackInitialState } from 'src/models/pages/feedbackModel';

import feedbackReducer from "./feedback/reducer"
import franchiseextraDetailsReducer from "./franchiseAdditionalDetails/reducer"
import { FranchiseAdditionalDetailsModel } from 'src/models/pages/franchiseAdditionalDetailsModel';
import employeeProfileReducer from './EmployeeProfile/reducer';
import { employeeProfileModel } from 'src/models/pages/employeProfileModel';
import AttendanceReportReducer from './AttendanceReport/reducer';
import { attendanceReportInitialState } from 'src/models/pages/attendanceReportModel';
import { ticketSystemInitialState } from 'src/models/pages/ticketSystemModel';
import ticketSystemReducer from "./ticketSystem/reducer" ;
import BulkEmailReducer from './BulkEmail/reducer';
import { BulkEmailInitialState } from 'src/models/pages/BulkEmailModel';
import BulkSmsReducer from './BulkSms/reducer';
import { BulkSmsInitialState } from 'src/models/pages/BulkSmsModel';
import { IncentiveState } from 'src/models/pages/incentiveModels';
import incentiveReducer from './incentive/reducer'
import rewardValueReducer from './RewardValue/reducer';
import { RewardValueState } from 'src/models/pages/rewardValueModel';
import totalWorkingDaysReducer from './TotalWorkingDays/reducer';
import { TotalWorkingDayState } from 'src/models/pages/totalWorkingDaysModel';
import { enquiryModel } from 'src/models/pages/enquiryModel';
import incentiveRewardReducer from './IncentiveReward/reducer';
import { IncentiveRewardState } from 'src/models/pages/incentiverewardModel';
import { AdditionalExpenseReportsReducerState } from 'src/models/pages/additionalExpenseReportsModel';
import additionaExpenseReportsReducer from './additionalExpenseReports/reducer';
import permissionReducer from './permission/reducer';
import WhatsappReducer from './WhatsApp/reducer';
import { WhatsAppInitialState } from 'src/models/pages/massWhatsAppModel';
import ManufacturerReducer from './manufacturer/reducer';
import { ManufacturerState } from 'src/models/pages/manufacturerModel';
import pendingJobsheetTatReportReducer from './PendingJobsheetTatReports/reducer';
import { PendingJobsheetTatReportsReducerState } from 'src/models/pages/PendingJobsheetTatReportsModel';
import VoucherReducer from './voucher/reducer';
import { VoucherStateModel } from 'src/models/pages/voucherModel';
import StockTransferReducer from './stockTransfer/reducer';
import { StockTransferInitialState } from 'src/models/pages/stockTransferModel';
import defectivePartReducer from './defectivePart/reducer';
import { DefectivePartInitialStore } from 'src/models/pages/defectivePartModal';
import partTransactionReducer from './partTransaction/reducer';
import { partTransactionInitialState } from 'src/models/pages/partTransactionModal';
import carBrandReducer from './carbrand/reducer';
import { carBrandsInitialState } from 'src/models/pages/carBrandInitialState';

export interface RootReducerState {
  Layout: LayoytState
  Calendar: CalendarState
  Chat: ChatState
  // Invoices: InvoiceState
  contacts: ContactState


  login: LoginState
  register: RegisterState
  profile: ProfileState
  forgetPassword: ForgetPaswordState
  dashboard: DashboardState
  dealer: dealerInitialState
  userReducer: usersInitialState
  customerReducer: customersInitialState
  admnistratorReducer: administratorsInitialState
  audioBrandsReducer: audioBrandsInitialState
  audioModelsReducer: audioModelsInitialState
  partsReducer: partsInitialState
  taxesReducer: taxesInitialState
  vehicleBrandsReducer: vehicleBrandsInitialState
  partSkuReducer: partSkuInitialState;
  franchiseeReducer: FranchiseeInitialState;
  subUserReducer: subUserInitialState;
  supplierReducer: supplierInitialState;
  purchaseOrderReducer: purchaseOrderInitialState;
  dealerSubUserReducer: DealersubuserInitialStore;
  stockValuebyPartReducer: stockValuebyPartInitialState;
  stockValueBySupplierReducer: StockValueBySupplierInitialStore;
  stockQuantityBySupplierReducer: StockQuantityBySupplierInitialStore;
  stockValueByMusicBrandReducer: StockValueByMusicSystemInitialStore;
  stockQuantityByMusicSystemBrandReducer: StockQuantityByMusicSystemBrandInitialStore;
  stockQtybyPartReducer: stockQtybyPartInitialState;
  PartsInboundReducer: PartInboudReducerInitialState;
  invoiceReducer: InvoiceInitialState;
  invoiceItemWiseRgisterReducer: invoiceItemWiseRegistersInitialState;
  outGoingDispatchReducer: outGoingDispatchInitialState
  IncomingDispatchReducer: inComingDispatchInitialState;
  stockLocationReducer: stockLocationInitialState;
  StockAdjustmentReducer:stockAdjustmentInitialState;
  attendanceReducer: attendanceInitialState;
  stockRegisterReducer : stockRegisterInitialState;
  jobsheetReducer: jobsheetInitialState;
  intentReducer: IntentInitialState;
  StockInspectionReducer:StockInspection ;
  courierReducer : courierReducerState;
  jobSheetReportReducer : jobsheetReducerGlobalState;
  pendingPartsConsolodatedReducer:pendingPartsConsolidatedList ;
  jobSheetTatReportsReducer : jobsheetTatReportsReducerState ;
  monthlyPartsSummaryReducer : monthlyPartsSummaryReducerState ;
  pendingPartsReducer : pendingPartsReducerState ;
  receivedPaymentReducer : receivedPaymentReducerState;
  loginAuthReducer: LoginAuthState;
  profilesReducer:profileTableState,
  feedbackReducer : FeedbackInitialState;
  franchiseextraDetailsReducer : FranchiseAdditionalDetailsModel;
  employeeProfileReducer:employeeProfileModel
  AttendanceReportReducer:attendanceReportInitialState ;
  ticketSystemReducer : ticketSystemInitialState;
  BulkEmailReducer:BulkEmailInitialState;
  BulkSmsReducer:BulkSmsInitialState;
  incentiveReducer : IncentiveState;
  rewardValueReducer:RewardValueState;
  totalWorkingDaysReducer:TotalWorkingDayState;
  EnquiryReducer:enquiryModel
  incentiveRewardReducer:IncentiveRewardState;
  additionaExpenseReportsReducer : AdditionalExpenseReportsReducerState;
  permissionReducer:any;
  WhatsappReducer:WhatsAppInitialState;
  ManufacturerReducer:ManufacturerState;
  pendingJobsheetTatReportReducer:PendingJobsheetTatReportsReducerState;
  VoucherReducer:VoucherStateModel;
  StockTransferReducer:StockTransferInitialState;
  defectivePartReducer:DefectivePartInitialStore;
  partTransactionReducer:partTransactionInitialState;
  carBrandReducer:carBrandsInitialState;
}

const rootReducer = combineReducers({
  // public
  Layout,
  Calendar,
  Chat,
  // Invoices,
  contacts,
  login,
  register,
  profile,
  forgetPassword,
  dashboard,
  MeltableReducer,
  profilesReducer,
  userReducer,
  dealer: DealerReducer,
  customerReducer,
  admnistratorReducer,
  audioBrandsReducer,
  audioModelsReducer,
  partsReducer,
  taxesReducer,
  vehicleBrandsReducer,
  partSkuReducer,
  franchiseeReducer,
  subUserReducer,
  purchaseOrderReducer,
  PartsInboundReducer,
  StockAdjustmentReducer,
  stockRegisterReducer,
  supplierReducer,
  dealerSubUserReducer,
  stockValuebyPartReducer,
  stockQtybyPartReducer,
  stockValueBySupplierReducer,
  stockQuantityBySupplierReducer,
  stockValueByMusicBrandReducer,
  stockQuantityByMusicSystemBrandReducer,
  invoiceReducer,
  invoiceItemWiseRgisterReducer,
  EnquiryReducer,
  outGoingDispatchReducer,
  IncomingDispatchReducer,
  stockLocationReducer,
  attendanceReducer,
  jobsheetReducer,
  intentReducer,
  StockInspectionReducer,
  courierReducer,
  jobSheetReportReducer,
  pendingPartsConsolodatedReducer,
  jobSheetTatReportsReducer,
  monthlyPartsSummaryReducer,
  pendingPartsReducer,
  receivedPaymentReducer,
  loginAuthReducer,
  feedbackReducer,
  franchiseextraDetailsReducer,
  employeeProfileReducer,
  AttendanceReportReducer,
  ticketSystemReducer,
  BulkEmailReducer,
  BulkSmsReducer,
  incentiveReducer,
  rewardValueReducer,
  totalWorkingDaysReducer,
  incentiveRewardReducer,
  additionaExpenseReportsReducer,
  permissionReducer,
  WhatsappReducer,
  ManufacturerReducer,
  pendingJobsheetTatReportReducer,
  VoucherReducer,
  StockTransferReducer,
  defectivePartReducer,
  partTransactionReducer,
  carBrandReducer,
})

export default rootReducer
