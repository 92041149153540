import { Form, Formik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import InputCom from 'src/components/Common/Input';
import { useToast } from 'src/components/Common/ReactToaster';
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';
import Button from 'src/components/UI/Button';
import CheckboxInput from 'src/components/UI/CheckboxInput';
import { ROUTER } from 'src/constants/routes';
// import { clearFranchiseSubuserMessage, createSubuserLoading, deleteFranchiseSubuserProfileLoading, franchiseSubuserLatLagLoading, getFranchiseSubuserProfileLoading, getSubuserByIdLoading, resendOtpFranchiseSubuser, resetOtpFranchiseSubuserMessage, resetSubuser, sendFranchiseSubuserOtpLoading, setFranchiseSubuserEmailIdLoading, setFranchiseSubuserMobileNumberLoading, updateSubuserLoading, uploadFranchiseSubuserProfileLoading } from 'src/store/actions';
import { RootReducerState } from 'src/store/reducers';
import * as Yup from "yup";
import { hasAdminOrEmployeeRoleHandler, onDropRejectedFileHandler } from 'src/helpers/common_helpers';
import Dropzone from 'react-dropzone';
import ImagePreviewer from 'src/components/Common/ImagePreviewer/ImagePreviewer';
import { FILE_UPLOAD_SIZE } from 'src/constants/commonConstants';
import DeleteModal from '../Calendar/DeleteModal';
import FeatherIcon from "feather-icons-react";
import OtpScreen from '../Customers/OtpScreen';
import { Dialog } from 'primereact/dialog';
import MultiSelector from 'src/components/Common/MultiSelector';
import { clearManufacturerMessage, createManufacturerLoading, deleteMANUFACTURERProfileLoading, getManufacturerByIdLoading, getManufacturerProfileLoading, ManufacturerLatLagLoading, manufacturerVehicleBrandList, resendOtpManufacturer, resetManufacturer, resetOtpManufacturerMessage, sendManufacturerOtpLoading, setManufacturerEmailIdLoading, setManufacturerMobileNumberLoading, updateManufacturerLoading, uploadManufacturerProfileLoading } from 'src/store/manufacturer/action';

const AddEditManufacturer = () => {
  const { error, loading, message, createMessage, createError, manufacturerImage, mailMessage, phoneMessage, editedMobileNumber, createdData, otpmessage, updatedmessage,jobPrefixList } = useSelector((state: RootReducerState) => state.ManufacturerReducer);
  const { subUserDetails } = useSelector((state: RootReducerState) => state.ManufacturerReducer.manufacturerList);
  const [visible, setVisible] = useState(false);
  const [createvisible, setCreateVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [customError, setcustomError] = useState(false);

  const dispatch = useDispatch();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const { id, sub_id }: any = useParams();

  const hasAdminOrEmployeeRole = hasAdminOrEmployeeRoleHandler();

  const convertNumber = (phone: any) => {
    let data = parseInt(phone);
    return data;
  };


  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);


  const handleReset = (resetForm: any) => {
    if (id) {
      navigate(ROUTER.MANUFACTURER_GRID);
    }
    else {
      resetForm();
    }
  };


  //new work
  const initialvalue: any =useMemo(()=> {
    return{
    name: subUserDetails?.user?.name ? subUserDetails?.user?.name : "",
    userName: subUserDetails?.user?.userName ? subUserDetails?.user?.userName : "",
    email: subUserDetails?.user?.email ? subUserDetails?.user?.email : "",
    phoneNumber: convertNumber(subUserDetails?.user?.phoneNumber) ? subUserDetails?.user?.phoneNumber : "",
    alternateNumber: convertNumber(subUserDetails?.alternateNumber) ? subUserDetails?.alternateNumber : "",

    isApprovedEnabled: subUserDetails?.isApprovedEnabled ? subUserDetails?.isApprovedEnabled : false,
    isActive: subUserDetails?.user?.isActive ? subUserDetails?.user?.isActive : false,
    sendEmail: subUserDetails?.user?.extraProperties?.SendEmail ? subUserDetails?.user?.extraProperties?.SendEmail : false,
    sendWhatsapp: subUserDetails?.user?.extraProperties?.SendWhatsapp ? subUserDetails?.user?.extraProperties?.SendWhatsapp : false,
    isVerfied: subUserDetails?.user?.extraProperties?.IsVerfied ? subUserDetails?.user?.extraProperties?.IsVerfied : false,
    lockoutEnabled: subUserDetails?.user?.lockoutEnabled ? subUserDetails?.user?.lockoutEnabled : false,
    jobPrefix: subUserDetails?.['manufacturerJobPrefixMaps']?.length > 0 ? subUserDetails?.['manufacturerJobPrefixMaps'].filter((eachValue: any) => !eachValue.isDeleted).map((eachValue: any) => {
      return eachValue.jobPrefix;
    }) : [],}
  },[subUserDetails]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(`Name is required`),
    userName: Yup.string().required(`User Name is required`),
    email: Yup.string()
    .required('Email is required')
    .email('Enter a valid email')
    .test('valid-email', 'Enter a valid email', function (value) {
      if (!value) return true;
      return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(value);
    }),
    jobPrefix: Yup.array()
      .of(Yup.string())
      .min(1, 'At least one Job Prefix must be selected') // Ensure at least one value is selected
      .required('Job Prefix is required'),
    phoneNumber: Yup.string().required(`Mobile number is required`).matches(/^\d{10}$/, 'Mobile number must be 10 digits'),
  });
  const editValidataionSchema = Yup.object().shape({
    name: Yup.string().required(`Name is required`),
    userName: Yup.string().required(`User Name is required`),
    email: Yup.string()
      .required('Email is required')
      .email('Enter a valid email')
      .test('valid-email', 'Enter a valid email', function (value) {
        if (!value) return true; 
        return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(value);
      }),
      jobPrefix: Yup.array()
      .of(Yup.string())
      .min(1, 'At least one Job Prefix must be selected') // Ensure at least one value is selected
      .required('Job Prefix is required'),
    // phoneNumber: Yup.string().required(`Mobile number is required`).matches(/^\d{10}$/, 'Mobile number must be 10 digits'),
  });


  const submitFunction = (values: any) => {

    if (isSubmitting) return;
    const submittedData: any = {
      "alternateNumber": String(values.alternateNumber),
      "isApprovedEnabled": values.isApprovedEnabled,
      "user": {
        "id": subUserDetails?.user?.id ? subUserDetails?.user?.id : 0,
        "userName": values.userName,
        "name": values.name,
        "email": values.email,
        "phoneNumber": String(values.phoneNumber),
        "isActive": values.isActive,
        "lockoutEnabled": values.lockoutEnabled,
        "isVerfied": values.isVerfied,
        "sendEmail": values.sendEmail,
        "sendWhatsapp": values.sendWhatsapp,
      },
      "manufacturerJobPrefixMapList": values.jobPrefix?.map((eachvalue: any) => {
        let vehicleId = subUserDetails?.['manufacturerJobPrefixMaps'].filter((each: any) => each.jobPrefix == eachvalue)?.[0]?.id;
        let vehicleLength = subUserDetails?.['manufacturerJobPrefixMaps'].filter((each: any) => each.jobPrefix == eachvalue).length;
        let data = {
          "id": vehicleId ?? 0,
          "manufacturerId": (vehicleLength && id) ? id : 0,
          "jobPrefix": eachvalue
        };
        return data;
      })
    };
    if (id) {
      // const { phoneNumber, ...rest } = formateddata.user;
      dispatch(updateManufacturerLoading(submittedData, id));
      if (selectedFiles.length > 0) {
        dispatch(uploadManufacturerProfileLoading(selectedFiles[0], id));
      }

    } else {
      let data={...submittedData}
      delete data.user.id
      dispatch(createManufacturerLoading(data));

    }
    setIsSubmitting(true);
  };

  const handleSubmit = async (values: any) => {
    await submitFunction(values);
  };

  const hidePopup = () => {
    dispatch(clearManufacturerMessage());
    setVisible(false);
  };


  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };

  const hidePopupNew = () => {
    dispatch(clearManufacturerMessage());
    setCreateVisible(false);
  };
  const dialogContainerNew = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={createvisible} onHide={hidePopupNew} />
      </>
    );
  };

  useEffect(() => {
    if (message != "" && message != undefined) {
      setIsSubmitting(false);
      showToast(message, { type: "success" });
      if(id){

        dispatch(getManufacturerProfileLoading(id))
      }
    }
    if (createMessage) {
      setIsSubmitting(false);
      showToast(createMessage, { type: "success" });
    }

    dispatch(clearManufacturerMessage());
  }, [message, createMessage]);

  useEffect(() => {
    if (updatedmessage != "" && updatedmessage != undefined) {
      setIsSubmitting(false);
      showToast(updatedmessage, { type: "success" });
      navigate(ROUTER.MANUFACTURER_GRID)
    }

    dispatch(clearManufacturerMessage());
  }, [updatedmessage]);

  useEffect(() => {
    if (otpmessage != "" && otpmessage != undefined) {
      setOpenOtp(false);
      setIsSubmitting(false);
      if(id){
        dispatch(getManufacturerByIdLoading(id));
      }else{
        navigate(ROUTER.MANUFACTURER_GRID)
      }
      showToast(otpmessage, { type: "success" });
    }
  }, [otpmessage]);

  useEffect(() => {
    if (mailMessage != "" && mailMessage != undefined) {
      showToast(mailMessage, { type: "success" });
      dispatch(getManufacturerByIdLoading(id));
      setEmailEdit(false);
    }
  }, [mailMessage]);

  useEffect(() => {
    if (phoneMessage != "" && phoneMessage != undefined) {
      showToast(phoneMessage, { type: "success" });
      setPhoneEdit(false);
      setOpenOtp(true);
    }
  }, [phoneMessage]);

  useEffect(() => {
    if (error) {
      setIsSubmitting(false);
      setVisible(true);
    }
  }, [error]);

  useEffect(() => {
    if (createError) {
      setIsSubmitting(false);
      setCreateVisible(true);
    }
  }, [createError]);

  useEffect(() => {
    if (id) {
      dispatch(getManufacturerByIdLoading(id));
      dispatch(getManufacturerProfileLoading(id));
    }
    return () => {
      dispatch(resetManufacturer());
    };
  }, [id]);

  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  function handleAcceptedFiles(files: any) {
    console.log("accepted --> ", files);
    let filesModified = files.map((file: any) => {
      console.log("Files --> ", file);
      return Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
        isImage: file.type.startsWith('image/')
      });
    }
    );

    if (filesModified[0]?.isImage) {
      if (filesModified[0]?.size <= FILE_UPLOAD_SIZE) {
        setselectedFiles(files);
      } else {
        showToast("File size exceeds 5MB", { type: "error" });
        setselectedFiles([]);
      }
    } else {
      showToast("Please upload the image file", { type: "error" });
      setselectedFiles([]);
    }

  }


  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  const deleteConfirmationHandler = () => {
    setDeleteModal(true);
  };
  const removeCustomerImage = () => {
    setDeleteModal(false);
    if (manufacturerImage) {
      dispatch(deleteMANUFACTURERProfileLoading(id));
    } else if (selectedFiles.length > 0) {
      setselectedFiles([]);
    }
  };
  const hidePopupForCustomValidation = () => {
    setcustomError(false);
    setIsSubmitting(false);
  };
  const dialogContainerCustomError = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={customError} onHide={hidePopupForCustomValidation} />
      </>
    );
  };
  const [emailEdit, setEmailEdit] = useState<boolean>(false);
  const [phoneEdit, setPhoneEdit] = useState<boolean>(false);
  const preventSpecialChar = (event: any) => {
    if ((event.which >= 106 && event.which <= 111) || (event.which >= 187 && event.which <= 191) || event.which === 69) {
      event.preventDefault();
    }
  };
  const [otpValue, setOtpValue] = useState<any>(null);
  const [openOtp, setOpenOtp] = useState<any>(false);
  const handleOtpSubmit = () => {
    if (id) {

      dispatch(sendManufacturerOtpLoading(otpValue, subUserDetails?.user?.id, editedMobileNumber));
    } else {
      dispatch(sendManufacturerOtpLoading(otpValue, createdData?.user?.id, createdData?.user?.phoneNumber));
    }
    // setOpenOtp(false)
  };
  const hideOtpPopup = () => {
    setOpenOtp(false);
    dispatch(resetOtpManufacturerMessage());
    if (id) {
      dispatch(getManufacturerByIdLoading(id));
    }
    setOtpValue("");
  };
  const openOtpScreen = () => {
    return (
      <>
        <OtpScreen visible={openOtp} onHide={hideOtpPopup} onSubmit={handleOtpSubmit} otpValue={otpValue} setOtpValue={setOtpValue} />
      </>
    );
  };

  useEffect(() => {
    debugger;
    if (createdData) {
      dispatch(setManufacturerMobileNumberLoading(createdData?.user?.phoneNumber, createdData?.user?.id));

    }
  }, [createdData]);

  const handleResendOtp = (number: any) => {
    dispatch(resendOtpManufacturer(subUserDetails?.user?.id, number));
  };

  useEffect(() => {
    dispatch(manufacturerVehicleBrandList())
    return () => {
      dispatch(resetOtpManufacturerMessage());
    };
  }, []);

  const [Number, setNumber] = useState<any>("");
  const onHide = () => {
    setPhoneEdit(false);
  };
  const handleNumberSubmit = (values: any) => {
    setPhoneEdit(false);
    dispatch(setManufacturerMobileNumberLoading(values.phoneNumber, subUserDetails?.user?.id));
  };

  const EditValidationNumber = Yup.object().shape({

    phoneNumber: Yup.string()
      .required(`Mobile number is required`)
      .matches(/^\d{10}$/, "Phone number must be 10 digits"),

  });
  const PhoneNumber = () => {
    return (
      <>
        <Dialog
          header="Edit Mobile Number"
          visible={phoneEdit}
          maximizable
          style={{ width: '400px' }}
          onHide={onHide}
        >
          <div >
            <Formik
              onSubmit={handleNumberSubmit}
              validationSchema={EditValidationNumber}
              initialValues={{
                phoneNumber: Number
              }}
              enableReinitialize
            >
              {({
                values,
                handleChange,
                touched,
                errors,
                handleBlur,
                resetForm,
                reset,
                ...rest
              }: any) => {
                return (
                  <div style={{ maxWidth: "400px" }}>
                    <Form onChange={handleChange}>
                      <InputCom
                        labelName="Mobile"
                        inputclassName="form-control"
                        labelClassName="form-Label"
                        type="number"
                        name="phoneNumber"
                        onBlur={handleBlur}
                        value={values.phoneNumber}
                        onChange={handleChange}
                        onKeyDown={preventSpecialChar}
                        disabled={id && !phoneEdit ? true : false}
                        isRequired={true}
                        invalid={
                          touched["phoneNumber"] &&
                            errors["phoneNumber"]
                            ? true
                            : false
                        }
                      />
                      <div className="d-flex float-end mt-2 me-0">
                        <Button
                          className="secondary-btn me-2 mt-2"
                          label={"Cancel"}
                          type="button"
                          onClick={() => setPhoneEdit(false)}
                        />
                        <Button
                          className="btn-primary mt-2 btn-primary-shadow"
                          label={"Update"}
                          type="submit"
                        />
                      </div>
                    </Form>
                  </div>
                );
              }
              }
            </Formik>
          </div>
        </Dialog>
      </>
    );
  };

  const onHideEmail = () => {
    setEmailEdit(false);
  };
  const [Email, setEmail] = useState<any>("");
  const handleNumberEmailSubmit = (values: any) => {
    setEmailEdit(false);
    dispatch(setManufacturerEmailIdLoading(values.email, subUserDetails?.user?.id));
  };
  const EditValidationEmail = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Enter a valid email")
      .test("valid-email", "Enter a valid email", function (value) {
        if (!value) return true; // Let Yup handle the "required" validation
        return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(value);
      }),
  });
  const EmailEdit = () => {
    return (
      <>
        <Dialog
          header="Edit Email"
          visible={emailEdit}
          maximizable
          style={{ width: '400px' }}
          onHide={onHideEmail}
        >
          <div >
            <Formik
              onSubmit={handleNumberEmailSubmit}
              validationSchema={EditValidationEmail}
              initialValues={{
                email: Email
              }}
              enableReinitialize
            >
              {({
                values,
                handleChange,
                touched,
                errors,
                handleBlur,
                resetForm,
                reset,
                ...rest
              }: any) => {
                return (
                  <div style={{ maxWidth: "400px" }}>
                    <Form onChange={handleChange}>
                      <InputCom
                        labelName="Email"
                        inputclassName="form-control"
                        labelClassName="form-Label"
                        type="text"
                        name="email"
                        onBlur={handleBlur}
                        value={values.email}
                        onChange={(e) => {
                          let event = { ...e };
                          event.target.value =
                            e.target.value.toLocaleLowerCase();
                          handleChange(event);
                        }}
                        isRequired={true}
                        disabled={id && !emailEdit ? true : false}
                        invalid={
                          touched["email"] && errors["email"]
                            ? true
                            : false
                        }
                      />
                      <div className="d-flex float-end mt-2 me-0">
                        <Button
                          className="secondary-btn me-2 mt-2"
                          label={"Cancel"}
                          type="button"
                          onClick={() => setEmailEdit(false)}
                        />
                        <Button
                          className="btn-primary mt-2 btn-primary-shadow"
                          label={"Update"}
                          type="submit"
                        />
                      </div>
                    </Form>
                  </div>
                );
              }
              }
            </Formik>
          </div>
        </Dialog>
      </>
    );
  };

  const handleAddress = (event:any) => {
    if (event.key === 'Enter' || event.key === "Tab") {
      event.preventDefault()
      if(event.target.value){ 
        dispatch(ManufacturerLatLagLoading(event.target.value))
      }
    }
  }

  const[jobPrefixOptions,setJobPrefixOptions]=useState<[]>([])
  useEffect(()=>{

    if (jobPrefixList) {
      const jobPrefixOpt = jobPrefixList?.map((val:any)=>({
        name:val?.jobPrefix,
        id:val?.jobPrefix,
      }))

      setJobPrefixOptions(jobPrefixOpt)
    }
  },[jobPrefixList])

  
  return (
    <React.Fragment>
      {createvisible && dialogContainerNew(createError ? createError : error)}
      {visible && dialogContainer(error)}
      {openOtp && openOtpScreen()}
      {openOtp && <div className='balckscreen'></div>}
      {customError && dialogContainerCustomError("Address field required")}
      {phoneEdit && PhoneNumber()}
      {emailEdit && EmailEdit()}
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => {
          removeCustomerImage();
        }}
        onCloseClick={() => {
          setDeleteModal(false);
        }}
      />
      <div className='page-content'>
        <Container fluid>
          <Breadcrumb title={"Manufacturer"} titlePath={ROUTER.MANUFACTURER_GRID} breadcrumbItem={id ? "Edit Manufacturer" : "Create Manufacturer"} />
          <Row>
            <Card>
              <CardBody>
                <React.Fragment>
                  <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialvalue}
                    validationSchema={id ? editValidataionSchema : validationSchema}
                    enableReinitialize
                  >
                    {({
                      values,
                      handleChange,
                      touched,
                      errors,
                      handleBlur,
                      resetForm,
                      reset,
                      ...rest
                    }: any) => {
                      return (
                        <Form onChange={handleChange}>
                          <Row>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Name"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="name"
                                onBlur={handleBlur}
                                value={values.name}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["name"] && errors["name"]
                                    ? true
                                    : false
                                }
                              />

                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="User Name"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="userName"
                                onBlur={handleBlur}
                                value={values.userName}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["userName"] && errors["userName"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <div className="" style={{ position: "relative" }}>
                                <InputCom
                                  labelName="Email"
                                  inputclassName="form-control"
                                  labelClassName="form-Label"
                                  type="text"
                                  name="email"
                                  onBlur={handleBlur}
                                  value={values.email}
                                  onChange={(e) => {
                                    let event = { ...e };
                                    event.target.value =
                                      e.target.value.toLocaleLowerCase();
                                    handleChange(event);
                                  }}
                                  isRequired={true}
                                  disabled={id && !emailEdit ? true : false}
                                  invalid={
                                    touched["email"] && errors["email"]
                                      ? true
                                      : false
                                  }
                                />
                                {!emailEdit && id && (
                                  <FeatherIcon icon="edit" className={`icon-lg right-icon`} style={{ position: "absolute", right: "5px", top: "38px", }}
                                    onClick={() => { setEmail(values.email); setEmailEdit(true); }}
                                  />
                                )}

                              </div>
                            </Col>
                         
                            <Col lg={3} className="mb-2">
                              <div className="" style={{ position: "relative" }}>
                                <InputCom
                                  labelName="Mobile"
                                  inputclassName="form-control"
                                  labelClassName="form-Label"
                                  type="number"
                                  name="phoneNumber"
                                  onBlur={handleBlur}
                                  value={values.phoneNumber}
                                  onChange={handleChange}
                                  onKeyDown={preventSpecialChar}
                                  disabled={id && !phoneEdit ? true : false}
                                  isRequired={true}
                                  invalid={
                                    touched["phoneNumber"] &&
                                      errors["phoneNumber"]
                                      ? true
                                      : false
                                  }
                                />
                                {!phoneEdit && id && (<FeatherIcon icon="edit" className={`icon-lg right-icon`} style={{ position: "absolute", right: "5px", top: "38px", }}
                                  onClick={() => { setNumber(values.phoneNumber); setPhoneEdit(true); }}
                                />
                                )}

                              </div>
                            </Col>

                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Alternate Number"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="alternateNumber"
                                onBlur={handleBlur}
                                value={values.alternateNumber}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={
                                  touched["alternateNumber"] && errors["alternateNumber"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col xl={3} lg={4} className="mb-2">
                              <MultiSelector
                                name="jobPrefix"
                                id="jobPrefix"
                                label="Job Prefix"
                                options={jobPrefixOptions?.sort((a: any, b: any) => {
                                  const labelA = a?.name?.toUpperCase().trim();
                                  const labelB = b?.name?.toUpperCase().trim();
                                  if (labelA < labelB) {
                                    return -1;
                                  }
                                  if (labelA > labelB) {
                                    return 1;
                                  }
                                  return 0;
                                })}
                                optionLabel="name"
                                optionValue="id"
                                labelClassName="form-Label"
                                onBlur={handleBlur}
                                value={values.jobPrefix}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={touched["jobPrefix"] && errors["jobPrefix"] ? true : false}

                              />
                            </Col>
                            <Col lg={12} className='d-flex align-items-center'>
                            <Row>
                              <Col lg={3} md={6} sm={6} xs={6}>
                                <CheckboxInput
                                  name="lockoutEnabled"
                                  label="LockOut Enable"
                                  value={values["lockoutEnabled"]}
                                  onChange={handleChange}
                                  {...rest}
                                />
                              </Col>
                              <Col lg={3} md={6} sm={6} xs={6} >
                                <CheckboxInput
                                  name="isActive"
                                  label="Is Active"
                                  value={values["isActive"]}
                                  onChange={handleChange}
                                  {...rest}
                                />
                              </Col>                              
                              <Col lg={3} md={6} sm={6} xs={6} >
                                <CheckboxInput
                                  name="isVerfied"
                                  label="Is Verified"
                                  value={values["isVerfied"]}
                                  onChange={handleChange}
                                  {...rest}
                                />
                              </Col>
                              <Col lg={3} md={6} sm={6} xs={6} >
                                <CheckboxInput
                                  name="sendEmail"
                                  label="Send Email"
                                  value={values["sendEmail"]}
                                  onChange={handleChange}
                                  {...rest}
                                />
                              </Col>
                              <Col lg={3} md={6} sm={6} xs={6} >
                                <CheckboxInput
                                  name="sendWhatsapp"
                                  label="Send Whatsapp"
                                  value={values["sendWhatsapp"]}
                                  onChange={handleChange}
                                  {...rest}
                                />
                              </Col>
                              <Col lg={3} md={6} sm={6} xs={6} >
                                <CheckboxInput
                                  name="isApprovedEnabled"
                                  label="Is Approver"
                                  value={values["isApprovedEnabled"]}
                                  onChange={handleChange}
                                  {...rest}
                                />
                              </Col>
                              </Row>
                              </Col>
                            {id && <React.Fragment>
                              <div className="mt-1">
                                <h5>Manufacturer Profile</h5>
                                <hr />
                                <Row>
                                  <Col sm={12} lg={12} md={12} xs={12}>
                                    <div>
                                      {(selectedFiles.length == 0 && !manufacturerImage) && <Dropzone
                                        onDrop={acceptedFiles => {
                                          handleAcceptedFiles(acceptedFiles);
                                        }}
                                        onError={(err: Error) => { console.log("file error", err); }}
                                        onDropRejected={(fileRejections, event) => onDropRejectedFileHandler(fileRejections, event, showToast)}
                                        maxFiles={1}
                                      >
                                        {({ getRootProps, getInputProps }) => (
                                          <div className="dropzone">
                                            <div
                                              className="dz-message needsclick"
                                              {...getRootProps()}
                                            >
                                              <input {...getInputProps()} accept="image/*" />
                                              <div className="">
                                                <i className="display-4 text-muted bx bx-cloud-upload" />
                                              </div>
                                              <h4>Drop files here or click to upload.</h4>
                                            </div>
                                          </div>
                                        )}
                                      </Dropzone>}

                                      {(selectedFiles[0]?.preview || manufacturerImage) && <ImagePreviewer selectedFile={selectedFiles[0]?.preview ? selectedFiles[0]?.preview : manufacturerImage} removeIndex={deleteConfirmationHandler} />}
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </React.Fragment>}

                          </Row>

                          <div className="d-flex float-end mt-2 me-0">
                            {(subUserDetails?.user?.phoneNumberConfirmed != true && id) &&
                              <Button
                                className="next-status-btn me-2 mt-2"
                                label={"Verify Mobile No"}
                                type="button"
                                onClick={() => handleResendOtp(values.phoneNumber)}
                              />
                            }
                            <Button
                              className="secondary-btn me-2 mt-2"
                              label={id ? "Cancel" : "Reset"}
                              type="button"
                              onClick={() => handleReset(resetForm)}
                            />
                            <Button
                              className="btn-primary mt-2 btn-primary-shadow"
                              label={id ? "Update Manufacturer" : "Create Manufacturer"}
                              type="submit"
                            />
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </React.Fragment>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddEditManufacturer;