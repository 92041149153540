import { hasCustomerRoleHandler, hasDealerSubuserRoleHandler, hasFranchiseSubuserRoleHandler, hasIncomingCourierValues, hasManufacturerRoleHandler } from 'src/helpers/common_helpers';
import { hasFranchiseRoleHandler } from 'src/helpers/common_helpers';
import { hasAdminOrEmployeeRoleHandler, hasDealerRoleHandler } from 'src/helpers/common_helpers';
import { JOB_SHEET_CREATED } from './../constants/JobSheetStatus';
import { createJobsheetLoading, franchiseCompletedJobLoading, updateAssignToEstimateJob, updateAssignToTechnicianJob, updateCancelJob, updateCreateJob, updateEstimateApproveOrRejectJob, updateEstimateCompleteJob, updateJobClosed, updateJobInvoiced, updateReadyToInvoiceJob, updateReplacementSystemDispatch, updateZeroEstimateAssignToTechnicianJob } from "src/store/jobsheets/action";
import * as Yup from "yup";
import { ConvertIsoStringWithTime } from "./common_helpers";
import { AnyARecord } from 'dns';
import { indexOf } from 'lodash';

interface ValidationAndFieldProbs {
   validation: any,
   requiredField: string[],
   disableField: string[],
   showField?: string[],
   showTabs: string[],
   url: any,
   nextStatus: string,
   hideField?: string[],

}

export const JOB_SHEET_MESSAGE = {

};

export enum JOB_SHEET_STATUS {
   "New Job" = 0,
   "Service Request Generated" = 1,
   "Replacement System Dispatched" = 2,
   "Job Sheet Created" = 3,
   "Assigned To Estimator" = 4,
   "Job Estimated Awaiting Approval" = 5,
   "Estimation Rejected" = 13,
   "Job Assignable To Technician" = 6,
   "Assigned To Technician" = 7,
   "Part outbound concern" = 8,
   "Franchise Job Completed" = 9,
   "Ready To Invoice" = 10,
   "Invoiced Job" = 11,
   "Job Closed" = 12,
   "Job Cancelled" = 14

   // "Ready To Invoice" = 8,
   // "Invoiced Job" = 9,
   // "Job Closed" = 10,
   // "Franchise arrived" = 11,
   // "Franchise closed job" = 12,

}
export enum NEXT_JOB_SHEET_STATUS {
   GENERATE_JOB_REQUEST = 0,
   REPLACEMENT_SYSTEM_DISPATCHED = 1,
   CREATE_JOB = 2,
   MAKE_ZERO_ESTIMATE_FOR_SYSTEM_IN_WARRANTY = 3,
   ASSIGN_ESTIMATOR = 4,
   ESTIMATE_COMPLETE = 5,
   MARK_AS_APPROVED_REJECTED = 6,
   CANCEL_JOB = 13,
   ASSIGNED_TO_TECHNICIAN = 7,
   FRANCHISE_ARRIVED = 8,
   FRANCHISE_COMPLETED_JOB = 9,
   PARTS_OUTBOUND_CONCERN_FIXED = 10,
   IS_INVOICE_COMPLETE = 11,
   CLOSE_JOB = 12,
   // PARTS_OUTBOUND_CONCERN_FIXED = 8,
   // IS_INVOICE_COMPLETE = 9,
   // CLOSE_JOB = 10,
}

const JOB_SHEET_TABS: any = {
   JOB_SHEET_INFORMATION: "JOB_SHEET_INFORMATION",
   JOB_SHEET_DETAILS: "JOB_SHEET_DETAILS",
   PENDING_COMMENTS: "PENDING_COMMENTS",
   OUTGOING_COURIER: "OUTGOING_COURIER",
   INCOMING_COURIER: "INCOMING_COURIER",
   ESTIMATE_DETAILS: "ESTIMATE_DETAILS",
   INSPECTION_INFORMATION: "INSPECTION_INFORMATION",
   ADDITIONAL_EXPENSES: "ADDITIONAL_EXPENSES",
   DEFECTIVE_PART: "DEFECTIVE_PART",
};

export const APPROVE = "APPROVE";
export const REJECT = "REJECT";

export const EXCHANGE_INVOICE = "General / Other Service Invoice";
export const SERVICE_INVOICE = "Service Invoice";
export const OTHER = "Other";


export const getDistanceFromLatLonInKm = (lat1: number, lon1: number, lat2: number, lon2: number) => {

   var R = 6371; // Radius of the earth in km
   var dLat = deg2rad(lat2 - lat1);  // deg2rad below
   var dLon = deg2rad(lon2 - lon1);
   var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ;
   var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
   var d = R * c; // Distance in km
   return d;
};

const deg2rad = (deg: number) => {
   return deg * (Math.PI / 180);
};

export const isCreateFeedback = (status: any) => {
   if (hasDealerRoleHandler() || hasCustomerRoleHandler()) {
      if (status == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Closed"]]) {
         return true;
      } else {
         return false;
      }
   }
};
export const isShowAdditionalExpense = (status: any, userType: any) => {
   if (hasAdminOrEmployeeRoleHandler() || hasFranchiseRoleHandler()) {
      if ((status == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Franchise Job Completed"]] || status == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Closed"]]) && userType == "Franchise") {
         return true;
      } else {
         return false;
      }
   }
};

export const isShowEdit = (status: any, isFeedbackPending: any, invoice?: any) => {

   if (hasAdminOrEmployeeRoleHandler()) {
      if (status == "Job Closed" || status == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Cancelled"]]) {
         return false;
      } else {
         return true;
      }
   }
   if (hasDealerRoleHandler() || hasCustomerRoleHandler()) {
      // if(status == JOB_SHEET_STATUS[JOB_SHEET_STATUS["New Job"]] || status == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Closed"]]){
      if (status == JOB_SHEET_STATUS[JOB_SHEET_STATUS["New Job"]] || status == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Estimated Awaiting Approval"]] || (status == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Closed"]] && isFeedbackPending != false)) {
         return true;
      } else {
         return false;
      }
   }
   if (hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler()) {
      let invoiceDetails: any = invoice?.filter((eachInvoice: any) => {
         return eachInvoice.invoiceTypeName == SERVICE_INVOICE;
      })?.[0];
      if (status == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Assigned To Technician"]]) {
         return true;
      } else {
         return false;
      }
   }

   if (hasCustomerRoleHandler()) {
      if (status == JOB_SHEET_STATUS[JOB_SHEET_STATUS["New Job"]] || status == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Estimated Awaiting Approval"]]) {
         return true;
      } else {
         return false;
      }
   }

   return false;
};

export const isShowDelete = () => {
   if (hasAdminOrEmployeeRoleHandler()) {
      return true;
   } else {
      return false;
   }
};


export const isShowPrintJobSheet = () => {
   if (hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler() || hasDealerRoleHandler() || hasCustomerRoleHandler() || hasDealerSubuserRoleHandler() || hasManufacturerRoleHandler()) {
      return false;
   }
   return true;
};

export const isShowPrintServiceRequest = (currentStatus: string) => {
   if (hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler() || hasDealerRoleHandler() || hasCustomerRoleHandler() || hasDealerSubuserRoleHandler() || hasManufacturerRoleHandler()) {
      return false;
   }

   if (currentStatus != JOB_SHEET_STATUS[JOB_SHEET_STATUS["New Job"]]) {
      return true;
   } else {
      return false;
   }

};

export const isShowPrintEstimate = (currentStatus: string, warranty: string) => {
   if (hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler() || hasDealerRoleHandler() || hasCustomerRoleHandler() || hasDealerSubuserRoleHandler() || hasManufacturerRoleHandler()) {
      return false;
   }
   if (currentStatus) {
      if (warranty == "In Warranty") {
         return false;
      }
      if ((currentStatus != JOB_SHEET_STATUS[JOB_SHEET_STATUS["New Job"]]) && (currentStatus != JOB_SHEET_STATUS[JOB_SHEET_STATUS["Service Request Generated"]]) && (currentStatus != JOB_SHEET_STATUS[JOB_SHEET_STATUS["Replacement System Dispatched"]]) && (currentStatus != JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Sheet Created"]]) && (currentStatus != JOB_SHEET_STATUS[JOB_SHEET_STATUS["Assigned To Estimator"]])) {
         return true;
      } else {
         return false;
      }
   } else {
      return false;
   }
};


export const isShowEmailEstimate = (currentStatus: string, warranty: string, isExchange: boolean) => {
   if (hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler() || hasDealerRoleHandler() || hasCustomerRoleHandler() || hasDealerSubuserRoleHandler() || hasManufacturerRoleHandler()) {
      return false;
   }
   if (currentStatus) {
      if (warranty == "In Warranty") {
         return false;
      }
      if (isExchange) {
         return false;
      }

      if ((currentStatus != JOB_SHEET_STATUS[JOB_SHEET_STATUS["New Job"]]) && (currentStatus != JOB_SHEET_STATUS[JOB_SHEET_STATUS["Service Request Generated"]]) && (currentStatus != JOB_SHEET_STATUS[JOB_SHEET_STATUS["Replacement System Dispatched"]]) && (currentStatus != JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Sheet Created"]]) && (currentStatus != JOB_SHEET_STATUS[JOB_SHEET_STATUS["Assigned To Estimator"]]) && (currentStatus != JOB_SHEET_STATUS[JOB_SHEET_STATUS["Estimation Rejected"]])) {
         return true;
      } else {
         return false;
      }
   } else {
      return false;
   }
};

export const isShowCreatePartsOutbound = (currentStatus: string, invoice: any[], jobDetails?: any, employeeList?: any, technicianList?: any) => {
   if (hasDealerRoleHandler() || hasCustomerRoleHandler() || hasDealerSubuserRoleHandler() || hasManufacturerRoleHandler()) {
      return false;
   }

   let invoiceDetailsLength = invoice.filter((eachInvoice: any) => {
      return eachInvoice.invoiceTypeName == SERVICE_INVOICE;
   })?.length;

   if (hasAdminOrEmployeeRoleHandler() && currentStatus && invoiceDetailsLength == 0 && (currentStatus == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Assigned To Technician"]] || currentStatus == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Estimation Rejected"]])) {
      let isFranchise = (employeeList?.filter((eachEmpolyee: any) => eachEmpolyee.value == jobDetails?.technicianGUId).length >= 1) ? false : ((technicianList?.filter((eachTechnician: any) => eachTechnician.value == jobDetails?.technicianGUId).length >= 1) ? true : false);
      if (isFranchise) {
         return false;
      } else {
         return true;
      }
   }

   if (currentStatus && invoiceDetailsLength == 0 && (!hasFranchiseRoleHandler() && !hasFranchiseSubuserRoleHandler())) {
      if (currentStatus == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Assigned To Technician"]]) {
         return true;
      } else {
         return false;
      }
   }
   else if (currentStatus && invoiceDetailsLength == 0) {
      if (((hasFranchiseRoleHandler()) && jobDetails?.franchiseArrivedTime != null) && currentStatus == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Assigned To Technician"]]) {
         return true;
      } else if (((hasFranchiseSubuserRoleHandler()) && jobDetails?.franchiseArrivedTime != null) && currentStatus == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Assigned To Technician"]]) {
         return true;
      }
      else {
         return false;
      }
   } else {
      return false;
   }
};

export const isShowCreateEnquiry = () => {
   if (hasAdminOrEmployeeRoleHandler() || hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler()) {
      return true;
   } else {
      return false;
   }
};

export const isShowEditPartsOutbound = (currentStatus: string, invoice: any[]) => {
   if (hasDealerRoleHandler() || hasCustomerRoleHandler() || hasDealerSubuserRoleHandler() || hasManufacturerRoleHandler()) {
      return false;
   }
   let invoiceDetails: any = invoice.filter((eachInvoice: any) => {
      return eachInvoice.invoiceTypeName == SERVICE_INVOICE;
   })?.[0];
   let invoiceDetailsLength: number = invoice.filter((eachInvoice: any) => {
      return eachInvoice.invoiceTypeName == SERVICE_INVOICE;
   })?.length;

   if (currentStatus && invoiceDetailsLength == 1 && hasAdminOrEmployeeRoleHandler()) {
      if ((currentStatus == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Assigned To Technician"]] || currentStatus == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Ready To Invoice"]] || currentStatus == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Estimation Rejected"]]) && !invoiceDetails?.locked) {
         return true;
      } else{
         return false
      }
   }else if(currentStatus && invoiceDetailsLength == 1 && (hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler()) && (currentStatus == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Assigned To Technician"]] || currentStatus == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Part outbound concern"]]) && !invoiceDetails?.locked) {
      return true;
   } else {
      return false;
   }
};

export const isShowPrintInvoice = (currentStatus: string, invoice: any[]) => {
   if (hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler() || hasDealerRoleHandler() || hasCustomerRoleHandler() || hasDealerSubuserRoleHandler() || hasManufacturerRoleHandler()) {
      return false;
   }

   if (currentStatus) {
      if ((currentStatus == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Invoiced Job"]] || currentStatus == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Ready To Invoice"]] || currentStatus == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Closed"]]) && (invoice?.filter((eachValue: any) => eachValue['invoiceTypeName'] == SERVICE_INVOICE && eachValue['locked']).length > 0)) {
         return true;
      } else {
         return false;
      }
   } else {
      return false;
   }
};
export const isShowEmailInvoice = (currentStatus: string, isExchange: boolean, invoice: any[]) => {

   if (hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler() || hasDealerRoleHandler() || hasCustomerRoleHandler() || hasDealerSubuserRoleHandler() || hasManufacturerRoleHandler()) {
      return false;
   }

   // if (isExchange) {
   //    return false;
   // }

   if (currentStatus) {
      if ((currentStatus == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Invoiced Job"]] || currentStatus == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Ready To Invoice"]] || currentStatus == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Closed"]]) && (invoice?.filter((eachValue: any) => eachValue['invoiceTypeName'] == SERVICE_INVOICE && eachValue['locked']).length > 0)) {
         return true;
      } else {
         return false;
      }
   } else {
      return false;
   }
};

export const isShowCreateExchangePartOutbound = (currentStatus: string, isExchange: boolean, invoice: any[], jobsheet?: any) => {
   if (hasDealerRoleHandler() || hasCustomerRoleHandler() || hasDealerSubuserRoleHandler() || hasManufacturerRoleHandler()) {
      return false;
   }

   let invoiceDetailsLength: number = invoice.filter((eachInvoice: any) => {
      return eachInvoice.invoiceTypeName == EXCHANGE_INVOICE;
   })?.length;

   if (currentStatus) {
      if (isExchange && invoiceDetailsLength == 0 && (!hasFranchiseRoleHandler() && !hasFranchiseSubuserRoleHandler())) {
         if (currentStatus == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Replacement System Dispatched"]] || currentStatus == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Sheet Created"]] || currentStatus == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Assigned To Technician"]] || currentStatus == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Ready To Invoice"]]) {
            return true;
         }
      } else if (isExchange && invoiceDetailsLength == 0 && (hasFranchiseRoleHandler() && hasFranchiseSubuserRoleHandler())) {
         if ((currentStatus == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Replacement System Dispatched"]] || currentStatus == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Sheet Created"]] || currentStatus == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Assigned To Technician"]] || currentStatus == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Ready To Invoice"]]) && jobsheet?.franchiseArrivedTime != null) {
            return true;
         }
      }
      else {
         return false;
      }
   } else {
      return false;
   }
};

export const isShowCreateExchangePartOutboundMultiple = (currentStatus: string, isExchange: boolean, invoice: any[], jobsheet?: any) => {
   if (hasDealerRoleHandler() || hasCustomerRoleHandler() || hasDealerSubuserRoleHandler() || hasManufacturerRoleHandler()) {
      return false;
   }

   let invoiceDetailsLength: number = invoice.filter((eachInvoice: any) => {
      return eachInvoice.invoiceTypeName == EXCHANGE_INVOICE;
   })?.length;

   if (currentStatus) {
      if (isExchange && (!hasFranchiseRoleHandler() && !hasFranchiseSubuserRoleHandler())) {
        
            if (currentStatus == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Replacement System Dispatched"]] || currentStatus == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Sheet Created"]] || currentStatus == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Assigned To Technician"]] || currentStatus == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Ready To Invoice"]]) {
               return true;
            }
         
        
      } else if (isExchange && (hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler())) {
         if ((currentStatus == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Replacement System Dispatched"]] || currentStatus == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Sheet Created"]] || currentStatus == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Assigned To Technician"]] || currentStatus == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Ready To Invoice"]]) && jobsheet?.franchiseArrivedTime != null) {
            return true;
         }
      }
      else {
         return false;
      }
   } else {
      return false;
   }
};

export const isShowPrintExchnageInvoice = (currentStatus: string, isExchange: boolean, invoice: any[]) => {

   if (hasDealerRoleHandler() || hasCustomerRoleHandler() || hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler() || hasDealerSubuserRoleHandler() || hasManufacturerRoleHandler()) {
      return false;
   }
   let invoiceDetailsLength: number = invoice.filter((eachInvoice: any) => {
      return eachInvoice.invoiceTypeName == EXCHANGE_INVOICE;
   })?.length;

   let invoiceDetails: any = invoice.filter((eachInvoice: any) => {
      return eachInvoice.invoiceTypeName == EXCHANGE_INVOICE;
   });

   if (currentStatus) {
      if (isExchange && invoiceDetailsLength == 1 && invoiceDetails[0]?.locked == true) {
         return true;
      } else {
         return false;
      }
   } else {
      return false;
   }
};

export const isShowEditExchangePartOutbound = (currentStatus: string, isExchange: boolean, invoice: any[]) => {
   if (hasDealerRoleHandler() || hasCustomerRoleHandler() || hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler() || hasDealerSubuserRoleHandler() || hasManufacturerRoleHandler()) {
      return false;
   }

   let invoiceDetailsLength: number = invoice.filter((eachInvoice: any) => {
      return eachInvoice.invoiceTypeName == EXCHANGE_INVOICE;
   })?.length;
   let invoiceDetails: any = invoice.filter((eachInvoice: any) => {
      return eachInvoice.invoiceTypeName == EXCHANGE_INVOICE;
   });



   if (currentStatus) {
      if (isExchange && invoiceDetailsLength == 1) {
         if ((currentStatus == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Replacement System Dispatched"]] || currentStatus == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Sheet Created"]] || currentStatus == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Assigned To Technician"]] || currentStatus == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Ready To Invoice"]]) && !invoiceDetails?.[0]?.locked) {
            return true;
         }
      } else {
         return false;
      }
   } else {
      return false;
   }
};








export const CHECK_VALUE_IN_ARRAY = <T>(arr: T[], value: T): boolean => {
   return arr.includes(value);
};



export const GET_NEXT_STATUS = (VALUES: any, CURRENT_STATUS: string) => {

   let status: string = "";
   switch (CURRENT_STATUS) {
      // 1
      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["New Job"]]): {
         status = NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.GENERATE_JOB_REQUEST];
         break;
      }
      // 2
      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["Service Request Generated"]]): {
         if (VALUES.isExchange) {
            status = NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.REPLACEMENT_SYSTEM_DISPATCHED];
         } else {
            status = NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.CREATE_JOB];
         }
         //  for test remove once tested
         //   status = NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.REPLACEMENT_SYSTEM_DISPATCHED]; 
         break;
      }
      // 3
      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["Replacement System Dispatched"]]): {
         status = NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.CREATE_JOB];
         break;
      }
      // 4
      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Sheet Created"]]): {

         if (VALUES.warrantyStatus == "In Warranty") {
            status = NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.MAKE_ZERO_ESTIMATE_FOR_SYSTEM_IN_WARRANTY];
         } else {
            status = NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.ASSIGN_ESTIMATOR];
         }
         break;
      }
      // 5
      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["Assigned To Estimator"]]): {
         status = NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.ESTIMATE_COMPLETE];
         break;
      }
      // 6
      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Estimated Awaiting Approval"]]): {
         status = NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.MARK_AS_APPROVED_REJECTED];
         break;
      }
      // 13
      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["Estimation Rejected"]]): {
         status = NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.CANCEL_JOB];
         break;
      }
      // 7
      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Assignable To Technician"]]): {
         status = NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.ASSIGNED_TO_TECHNICIAN];
         break;
      }
      // 8
      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["Assigned To Technician"]]): {
         if (!VALUES.isFranchise) {
            status = NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.PARTS_OUTBOUND_CONCERN_FIXED];
         } else {
            if (hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler()) {
               status = NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.FRANCHISE_COMPLETED_JOB];
            } else {
               status = NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.PARTS_OUTBOUND_CONCERN_FIXED];
            }
         }
         break;
      }

      //9
      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["Franchise Job Completed"]]): {
         status = NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.PARTS_OUTBOUND_CONCERN_FIXED];
         break;
      }


      // 10
      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["Ready To Invoice"]]): {
         if (!VALUES.isFranchise) {
            status = NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.IS_INVOICE_COMPLETE];
         } else {
            if (hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler()) {
               status = NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.FRANCHISE_COMPLETED_JOB];
            } else {
               status = NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.IS_INVOICE_COMPLETE];
            }
         }
         break;
      }

      //12
      // case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["Franchise Job Closed"]]): {
      //    status = NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.IS_INVOICE_COMPLETE];
      //    break;
      // }

      // 11
      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["Invoiced Job"]]): {
         status = NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.CLOSE_JOB];
         break;
      }
      // 12
      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Closed"]]): {
         status = NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.CLOSE_JOB];
         break;
      }

   }
   return status;
};

export const GET_VALIDATION_AND_FIELD_SHOW = (VALUES: any, CURRENT_STATUS: string, NEXT_STATUS: string, setFieldError: any = () => { }, setFieldTouched: any = () => { }, setFieldValue: any = () => { }) => {
   let validationAndFields: ValidationAndFieldProbs = {
      validation: "",
      requiredField: [],
      disableField: [],
      showTabs: [],
      url: "",
      nextStatus: "",
      hideField: []
   };
   let STATUS = CURRENT_STATUS + NEXT_STATUS;

   let validation: any = {};

   if (VALUES.isExchange) {
      validationAndFields.requiredField.push('exchangeAudioSerialNumber');
      validationAndFields.requiredField.push('warrantyStatus');
      validation['exchangeAudioSerialNumber'] = Yup.string().required(`New audio serial number is required`);
      validation['warrantyStatus'] = Yup.string().required(`Warranty status is required`);
   } else {
      setFieldError("exchangeAudioSerialNumber", "");
      setFieldTouched("exchangeAudioSerialNumber", false);
      setFieldError("warrantyStatus", "");
      setFieldTouched("warrantyStatus", false);
   }
   if (VALUES.warrantyStatus == "In Warranty") { // In 
      validationAndFields.requiredField.push('dateOfSale');
      //   validation['dateOfSale'] = Yup.string().required(`Date of sale is required`)
      validation['dateOfSale'] = Yup.string()
         .required('Vehicle Date of Sale is required')
         .test('futureDate', 'Vehicle Date of Sale must not be in the future', function (value) {
            const selectedDate = new Date(value);
            const currentDate = new Date();
            return selectedDate <= currentDate;
         });
   } else {
      setFieldError("dateOfSale", "");
      setFieldTouched("dateOfSale", false);
   }
   if (!VALUES.isOldPending && VALUES.recentInPending) {
      validation['pendingCommentsDtoNew'] = Yup.array().of(
         Yup.object().shape({
            pendingReasonId: Yup.string().required('Pending reason is required'),
            pendingInformation: Yup.string().required('Pending information is required'),
         })
      );
   } 
   else if (VALUES.pendingCommentsDtoNew.length>0) {
      validation['pendingCommentsDtoNew'] = Yup.array().of(
         Yup.object().shape({
           pendingReasonId: Yup.string().nullable(),
           pendingInformation: Yup.string().test('conditional-required', 'Pending information is required', function (value) {
            const { pendingReasonId } = this.parent;
            if (!pendingReasonId) return true; // If pendingReasonId is not present, pendingInformation is not required
            return !!value && value.trim() !== ''; // Ensure value is not empty
          })
         })
       )
   }

      validation['isExchange'] = Yup.boolean().required()
      .test(
         'cannot-both-true',
         'Both can not be enabled. Job can\'t be both remote and exchange at the same time',
         function(value) {
           const { isRemoteService } = this.parent;
           console.log("isRemoteService --->",isRemoteService,value,!(isRemoteService && value))
           return !(isRemoteService && value);
         }
       )




   if (VALUES?.inPending && VALUES?.IsAfcsGenerated) {
      validation['IsAfcsGenerated'] = Yup.boolean().required().oneOf([false], 'Job Sheet in pending state. No next state allowed until it is out of pending');
   } else if (hasAdminOrEmployeeRoleHandler() && (VALUES?.status == "Assigned To Technician" && VALUES.isFranchise)) {
      validation['IsAfcsGenerated'] = Yup.boolean().required().oneOf([false], 'Job Sheet in pending state. No next state allowed until it Franchise Job Closed');
   }

   // if((VALUES?.perfectionFilesUploadStatus == false || VALUES?.uploadedFiles == false) && VALUES?.isFranchise == true && (hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler())){
   //    validationAndFields.disableField.push("IsAfcsGenerated")
   // }


   if ((VALUES?.isFranchise == true && VALUES?.franchiseArrivedOrNot == false) && (hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler())) {
      validationAndFields.disableField.push("IsAfcsGenerated");
   } else {
      validationAndFields.disableField.push("franchiseArrived");
      validationAndFields.disableField = validationAndFields.disableField.filter((eachInput: any) => eachInput != eachInput.IsAfcsGenerated);
   }

   //  Faulty System flow always disabled if exchange it is checked otherwise not checked.
   // validationAndFields.disableField.push('isFaultySystemAvailable');
   if(VALUES.isExchange){
      // setFieldValue('isFaultySystemAvailable',true);
   }else{
      setFieldValue('isFaultySystemAvailable',false);
      setFieldValue('dispatched',false);
   }

   // validation['proofDocuments'] = Yup.array().of(
   //    Yup.object().shape({
   //       proofName: Yup.string()
   //       .nullable() 
   //          .test(
   //             'proofName-required-if-files-present',
   //             'Proof Name is required if Document is uploaded',
   //             function (value) {
   //                const { files } = this.parent;
   //                return files && files.length > 0 ? !!value : true;
   //             }
   //          ),
   //       files: Yup.mixed()
   //       .nullable() 
   //          .test(
   //             'files-required-if-proofName-present',
   //             'Document is required if Proof Name is provided',
   //             function (value:any) {
   //                console.log("valuevalue",value)
   //                const { proofName } = this.parent;
   //                return proofName ? value && value !== null && (Array.isArray(value) ? value.length > 0 : true) : true;

   //             }
   //          ),
   //    })
   // );
   validation['proofDocuments'] = Yup.array().of(
      Yup.object().shape({
        proofName: Yup.string().required("Proof Name is required"),
        files: Yup.mixed()
          .test(
            'files-required-if-proofName-present',
            'Document is required',
            function (value) {
              const { proofName } = this.parent;
              return value && value !== null && (Array.isArray(value) ? value.length > 0 : true);
            }
          )
          .required('Document is required'), // Always require files
      })
    );
   // else if ((hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler()) && (VALUES?.status == "Assigned To Technician"  && VALUES?.uploadedFiles == false)) {
   //    validation['IsAfcsGenerated'] = Yup.boolean().required().oneOf([false], `In this stage you can simply update the fault images.you can't move the next stage`);
   // }
   // else if ((hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler()) && (VALUES?.status == "Assigned To Technician" && VALUES.uploadedFiles && VALUES?.perfectionFilesUploadStatus == false)) {
   //    validation['IsAfcsGenerated'] = Yup.boolean().required().oneOf([false], `In this stage you can simply update the perfection images.you can't move the next stage`);
   // }

   switch (STATUS) {

      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["New Job"]] + NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.GENERATE_JOB_REQUEST]): {
         validationAndFields.requiredField.push('vehicleBrandId', 'audioModel', 'serviceCharge', 'audioSerialNumber', 'audioPartNumber', 'customerConcern', 'vinNumber', 'vehicleRegistrationNumber', 'carModelId', 'kmsCovered', 'manufacturedDate');
         validationAndFields.disableField.push('number', 'dealerAddress', 'estimatorGUId', 'technicianGUId', 'afcsReceivedDate', 'serviceCharge', 'serviceChargeTaxPercentage', 'serviceChargeTaxAmount', 'serviceChargeTotalWithTax', 'historicalJobsForAudioSystem', 'preparedByName', 'melObservations', 'completeServiceInformation', 'dealerObservation', 'requestDate', 'failedDate', 'inspectionStatus', 'audioPartNumber', 'franchiseNotes', 'appointmentTime', 'franchiseArrived');

         validationAndFields.disableField.push("accessoriesList", "missingAccessories", "additionalRemarks","dispatched");

         if(!hasAdminOrEmployeeRoleHandler() ){
            validationAndFields.disableField.push("isExchange","isRemoteService","isFaultySystemAvailable");
         }
         if(!hasAdminOrEmployeeRoleHandler() && !hasDealerRoleHandler()){
            validationAndFields.disableField.push("supplierReferenceNumber","priority");
         }
         if(!VALUES.isExchange){
            validationAndFields.disableField.push('isFaultySystemAvailable');
         }

         if (VALUES?.id) {
            // // if (VALUES?.isExchange) {
            // //    validationAndFields.disableField.push('warrantyStatus');
            // // }
            // // validationAndFields.disableField.push('isExchange');
            validationAndFields.disableField.push('dealerId');
            validationAndFields.disableField.push('customerId');
            validationAndFields.disableField.push('customerName');
            validationAndFields.disableField.push('customerPhoneNumber');
            validationAndFields.disableField.push('customerEmail');
            validationAndFields.disableField.push('customerAddress');
            validationAndFields.disableField.push('customerGSTNumber');
         }
         if (hasDealerRoleHandler()) {
            validationAndFields.disableField.push('dealerId');
            validationAndFields.requiredField.push("kmsCovered", "manufacturedDate", 'vinNumber', 'vehicleRegistrationNumber', 'carModelId');
            validation['manufacturedDate'] = Yup.string().required(`Failed Audio Manufactured Date is required`);
            validation['vehicleBrandId'] = Yup.string().required(`Vehicle brand is required`);
            validation['vinNumber'] = Yup.string().required(`Vin Number is required`).max(30, 'Vin Number cannot be more than 30 characters');
            validation['vehicleRegistrationNumber'] = Yup.string().required(`Vehicle registration number is required`);
            validation['carModelId'] = Yup.string().required(`Vehicle Model is required`);
         }
         if (hasCustomerRoleHandler()) {
            validationAndFields.disableField.push('dealerId');
            validationAndFields.disableField.push('customerId');
            validationAndFields.disableField.push('customerName');
            validationAndFields.disableField.push('customerPhoneNumber');
            validationAndFields.disableField.push('customerEmail');
            validationAndFields.disableField.push('customerAddress');
            validationAndFields.disableField.push('customerGSTNumber');
            validationAndFields.disableField.push('IsAfcsGenerated');
         }
         
         validation['vinNumber'] = Yup.string().required(`Vin Number is required`).max(30, 'Vin Number cannot be more than 30 characters');
         validation['vehicleRegistrationNumber'] = Yup.string().required(`Vehicle registration number is required`);
         validation['carModelId'] = Yup.string().required(`Vehicle Model is required`);
         validation["manufacturedDate"] = Yup.string().required('Failed Audio Manufactured Date is required');
         validation['audioModel'] = Yup.string().required(`Audio model is required`);
         validation['serviceCharge'] = Yup.string().required(`Service charge is required`);
         validation['vehicleBrandId'] = Yup.string().required(`Vehicle brand is required`);
         validation['audioSerialNumber'] = Yup.string().required(`Failed Audio serial Number is required`).max(150, 'Failed Audio serial Number must be at most 150 characters');
         validation['audioPartNumber'] = Yup.string().required(`Audio Part Number is required`);
         validation['kmsCovered'] = Yup.number().required("Vehicle KM covered must be required").min(10, 'Vehicle KM covered must be at most 10');
         if (hasCustomerRoleHandler()) {
            validationAndFields.showTabs = [JOB_SHEET_TABS.JOB_SHEET_INFORMATION, JOB_SHEET_TABS.JOB_SHEET_DETAILS];
         } else {
            validationAndFields.showTabs = [JOB_SHEET_TABS.JOB_SHEET_INFORMATION, JOB_SHEET_TABS.JOB_SHEET_DETAILS, JOB_SHEET_TABS.PENDING_COMMENTS,JOB_SHEET_TABS.ADDITIONAL_EXPENSES];
         }
         if (!VALUES.dealerId && !VALUES.customerName) {
            validation['dealerId'] = Yup.string().required(`Dealer is required`);
            validation['customerName'] = Yup.string().required(`Customer Name is required`);
            validation['customerGSTNumber'] = Yup.string().notRequired().nullable().matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/, 'Invalid GST number');
            validation['customerPhoneNumber'] = Yup.string().required(`Customer Mobile Number is required`).matches(/^\d{10}$/, "Mobile number must be 10 digits");
            validation['customerEmail'] = Yup.string().required(`Customer Email is required`).email("Enter a valid email")
               .test("valid-email", "Enter a valid email", function (value) {
                  if (!value) return true;
                  return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(value);
               });
            validation['customerAddress'] = Yup.string().required(`Customer Address is required`);
            validationAndFields.requiredField.push('dealerId');
            validationAndFields.requiredField.push('customerName');
            validationAndFields.requiredField.push('customerPhoneNumber');
            validationAndFields.requiredField.push('customerEmail');
            validationAndFields.requiredField.push('customerAddress');
            // validationAndFields.requiredField.push('customerGSTNumber');
         }
         if (VALUES.dealerId) {
            validation['dealerId'] = Yup.string().required(`Dealer is required`);
            validation['vinNumber'] = Yup.string().required(`Vin number is required`).max(30, 'Vin Number cannot be more than 30 characters');
            validation['requestDate'] = Yup.string().when(['failedDate'], (failedDate, schema) => {
               return schema.test({
                  test: function (requestDate) {
                     const failedDateValue: any = this.resolve(failedDate);
                     if (!failedDateValue || !requestDate) {
                        return true;
                     }
                     const failedTimestamp = Date.parse(failedDateValue);
                     const requestTimestamp = Date.parse(requestDate);

                     return requestTimestamp >= failedTimestamp;
                  },
                  message: 'Request date must be greater than or equal to failed date',
               });
            }).required('Request date is required');
            validation['kmsCovered'] = Yup.number().required("Vehicle KM covered must be required").min(10, 'Vehicle KM covered must be at most 10');
            validation['failedDate'] = Yup.string().required(`Failed date is required`);

            validationAndFields.requiredField.push('dealerId');
            validationAndFields.requiredField.push('vinNumber');
            validationAndFields.requiredField.push('requestDate');
            validationAndFields.requiredField.push('kmsCovered');
            validationAndFields.requiredField.push('failedDate');

            validationAndFields.requiredField = validationAndFields.requiredField.filter((field) => field != "customerName" && field != "customerPhoneNumber" && field != "customerEmail" && field != "customerAddress");

            // new line (24-06-24)
            validationAndFields.requiredField.push('failedDate');
            validation['customerPhoneNumber'] = Yup.string().matches(/^\d{10}$/, "Mobile number must be 10 digits");
            validation['customerEmail'] = Yup.string().email("Enter a valid email")
               .test("valid-email", "Enter a valid email", function (value) {
                  if (!value) return true; // Let Yup handle the "required" validation
                  return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(value);
               });
            validationAndFields.requiredField.push('dealerObservation');
            validationAndFields.disableField = validationAndFields.disableField.filter((a: any) => a != "dealerObservation");

            if (!VALUES?.id) {
               validationAndFields.disableField = validationAndFields.disableField.filter((a: any) => a != 'failedDate' && a != 'requestDate');
            }

         } else {
            validation['customerName'] = Yup.string().required(`Customer Name is required`);
            validation['customerGSTNumber'] = Yup.string().notRequired().nullable().matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/, 'Invalid GST number');
            validation['customerPhoneNumber'] = Yup.string().required(`Customer Mobile Number is required`).matches(/^\d{10}$/, "Mobile number must be 10 digits");
            validation['customerEmail'] = Yup.string().required(`Customer Email is required`).email("Enter a valid email")
               .test("valid-email", "Enter a valid email", function (value) {
                  if (!value) return true;
                  return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(value);
               });
            validation['customerAddress'] = Yup.string().required(`Customer Address is required`);
            validationAndFields.requiredField.push('customerId');
            validationAndFields.requiredField.push('customerName');
            validationAndFields.requiredField.push('customerPhoneNumber');
            validationAndFields.requiredField.push('customerEmail');
            // validationAndFields.requiredField.push('customerGSTNumber');
            validationAndFields.requiredField.push('customerAddress');
         }

         if (VALUES.dealerId && !VALUES.customerName) {
            setFieldError("customerId", "");
            setFieldTouched("customerId", false);
            setFieldError("customerAddress", "");
            setFieldTouched("customerAddress", false);
            setFieldError("customerName", "");
            setFieldTouched("customerName", false);
            setFieldError("customerPhoneNumber", "");
            setFieldTouched("customerPhoneNumber", false);
            setFieldError("customerEmail", "");
            setFieldTouched("customerEmail", false);
            setFieldError("customerGSTNumber", "");
            setFieldTouched("customerGSTNumber", false);
         }

         if (!VALUES.dealerId && VALUES.customerName) {
            setFieldError("dealerId", "");
            setFieldTouched("dealerId", false);
            setFieldError("dealerAddress", "");
            setFieldTouched("dealerAddress", false);
            setFieldError("vinNumber", "");
            setFieldTouched("vinNumber", false);
            setFieldError("requestDate", "");
            setFieldTouched("requestDate", false);
            setFieldError("kmsCovered", "");
            setFieldTouched("kmsCovered", false);
            setFieldError("failedDate", "");
            setFieldTouched("failedDate", false);
         }

         if (VALUES.IsAfcsGenerated) {
            validation['customerConcern'] = Yup.string().required(`Customer concern is required`);
            validation['customerConcern'] = Yup.string().required(`Customer concern is required`);
            if (VALUES.dealerId) {
               validationAndFields.requiredField.push('dealerObservation');
               validation['dealerObservation'] = Yup.string().required(`Dealer observation is required`);
            } else {
               setFieldError("dealerObservation", "");
               setFieldTouched("dealerObservation", false);
            }
            validationAndFields.url = createJobsheetLoading;
         } else {
            setFieldError("customerConcern", "");
            setFieldTouched("customerConcern", false);
            if (VALUES.dealerId) {
               setFieldError("dealerObservation", "");
               setFieldTouched("dealerObservation", false);
            }
            validationAndFields.url = createJobsheetLoading;
         }

         validationAndFields.nextStatus = "Generate Service Request";
         //statements; 
         break;
      }
      // 2
      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["Service Request Generated"]] + NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.REPLACEMENT_SYSTEM_DISPATCHED]): {
         let modifiedValues = {
            ...VALUES
         };
         modifiedValues['IsAfcsGenerated'] = true;
         validationAndFields = GET_VALIDATION_AND_FIELD_SHOW(modifiedValues, JOB_SHEET_STATUS[JOB_SHEET_STATUS["New Job"]], NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.GENERATE_JOB_REQUEST], setFieldError, setFieldTouched);
         validation = validationAndFields.validation;
         // validationAndFields.requiredField.push("packedBy", "packedDate", "deliveryDate", "deliveryType", "deliveredTo", "deliveredById", "approximateValue", "notes");
         let arr:any = []
       
         for(let i=0;i<VALUES.outgoingDispatchListNew.length;i++){
            let names=[
               `outgoingDispatchListNew[${i}].packedBy`,
               `outgoingDispatchListNew[${i}].packedDate`,
               `outgoingDispatchListNew[${i}].deliveryDate`,
               `outgoingDispatchListNew[${i}].deliveryType`,
               `outgoingDispatchListNew[${i}].deliveredTo`,
               `outgoingDispatchListNew[${i}].deliveredById`,
               `outgoingDispatchListNew[${i}].approximateValue`,
               `outgoingDispatchListNew[${i}].notes`,
            ]
            arr.push(...names)
         }
         validationAndFields.requiredField.push(...arr);
         if(VALUES.isExchange){
            // validationAndFields.requiredField.push("deliveredUserType");
            let arr2:any = []
            for(let i=0;i<VALUES.outgoingDispatchListNew.length;i++){
               let names2=[
                  `outgoingDispatchListNew[${i}].deliveredUserType`,
               ]
               arr2.push(...names2)
            }

            validationAndFields.requiredField.push(...arr2);
         }
         validationAndFields.showTabs.push(JOB_SHEET_TABS.OUTGOING_COURIER,JOB_SHEET_TABS.ADDITIONAL_EXPENSES);
         // if (VALUES.deliveryType == 'Hand Carry') {
         //    setFieldValue('courierId', "");
         //    validationAndFields.disableField.push('courierId');
         //    setFieldValue('courierId', "");
         // }

         // if (VALUES.deliveryType == 'Courier') {
         //    validationAndFields.requiredField.push('courierId');
         //    validation['courierId'] = Yup.string().required('Courier is required');
         // }
         for (let i = 0; i < VALUES.outgoingDispatchListNew.length; i++) {
            const courierIdField = `outgoingDispatchListNew[${i}].courierId`;
            const deliveryType = VALUES.outgoingDispatchListNew[i].deliveryType;
         
            if (deliveryType === 'Hand Carry') {
               setFieldValue(courierIdField, "");
               validationAndFields.disableField.push(courierIdField);
            }
         
            if (deliveryType === 'Courier') {
               validationAndFields.requiredField.push(courierIdField);
               // validation[courierIdField] = Yup.string().required('Courier is required');
            }
         }
         if(VALUES.isExchange){
            // validationAndFields.requiredField.push("packedBy", "packedDate", "deliveryDate", "deliveryType");
            let arr:any = []
            for(let i=0;i<VALUES.outgoingDispatchListNew.length;i++){
               let names=[
                  `outgoingDispatchListNew[${i}].packedBy`,
                  `outgoingDispatchListNew[${i}].packedDate`,
                  `outgoingDispatchListNew[${i}].deliveryDate`,
                  `outgoingDispatchListNew[${i}].deliveryType`,
                  `outgoingDispatchListNew[${i}].deliveredTo`,
                  `outgoingDispatchListNew[${i}].deliveredById`,
                  `outgoingDispatchListNew[${i}].approximateValue`,
                  `outgoingDispatchListNew[${i}].notes`,
                  `outgoingDispatchListNew[${i}].deliveredUserType`,
               ]
               arr.push(...names)
            }
            validationAndFields.requiredField.push(...arr);
            let hasValues = (VALUES.outgoingDispatchListNew[0].packedBy != "" || VALUES.outgoingDispatchListNew[0].packedDate != "" || VALUES.outgoingDispatchListNew[0].deliveryDate != "" || VALUES.outgoingDispatchListNew[0].deliveryType != "" || VALUES.outgoingDispatchListNew[0].deliveredTo != "" || VALUES.outgoingDispatchListNew[0].deliveredById != "" || VALUES.outgoingDispatchListNew[0].deliveredUserType != "" || VALUES.outgoingDispatchListNew[0].approximateValue != "" || VALUES.outgoingDispatchListNew[0].notes != "" || VALUES.outgoingDispatchListNew[0].courierId != ""  )

            if((VALUES.IsAfcsGenerated && VALUES.outgoingDispatchList?.length<1) || hasValues){
            
            validation['outgoingDispatchListNew'] = Yup.array().of(
               Yup.object().shape({
            
            packedBy:Yup.string().required('Packed by is required'),

            packedDate:Yup.string().required('Packed date is required'),
            deliveryDate:Yup.string().required('Delivery date is required'),
             deliveryType:Yup.string().required('delivery type is required'),
            deliveredTo:Yup.string().required('Delivered to is required'),
            deliveredById:Yup.string().required('Delivered by is required'),
            deliveredUserType:Yup.string().required('Delivered by User Type is required'),
            approximateValue:Yup.string().required('Approximate value is required').max(6, 'Approximate value must be at most 6 characters'),
            notes:Yup.string().required('Notes is required'),
            courierId: Yup.string().test(
               'courier-required-if-not-hand-carried',
               'Courier info required.',
               function (value) {
                  
                 const { deliveryType } = this.parent; // Access the current object
                 // Apply validation only when deliveryType is NOT "Hand Carried"
                 return deliveryType === 'Hand Carry' || !!value;
               }
             ),
               })
            )
         }
         }

         if (VALUES.IsAfcsGenerated) {
            
            // validationAndFields.requiredField.push("packedBy", "packedDate", "deliveryDate", "deliveryType"); 
            let arr:any = []
            for(let i=0;i<VALUES.outgoingDispatchListNew.length;i++){
               let names=[
                  `outgoingDispatchListNew[${i}].packedBy`,
                  `outgoingDispatchListNew[${i}].packedDate`,
                  `outgoingDispatchListNew[${i}].deliveryDate`,
                  `outgoingDispatchListNew[${i}].deliveryType`,
                  `outgoingDispatchListNew[${i}].deliveredTo`,
                  `outgoingDispatchListNew[${i}].deliveredById`,
                  `outgoingDispatchListNew[${i}].approximateValue`,
                  `outgoingDispatchListNew[${i}].notes`,
               ]
               arr.push(...names)
            }

            validationAndFields.requiredField.push(...arr);

            // validation['outgoingDispatchListNew'] = Yup.array().of(
            //    Yup.object().shape({
            //       packedBy:Yup.string().required('Packed by is required'),

            //       packedDate:Yup.string().required('Packed date is required'),
            //       deliveryDate:Yup.string().required('Delivery date is required'),
            //       deliveryType:Yup.string().required('delivery type is required'),
            //    }))
            // validation["packedDate"] = Yup.string()
            //    .required('packedDate is required')
            //    .test('is-date', 'Packed date is not a valid date', value => !isNaN(Date.parse(value))),
            //    validation['deliveryDate'] = Yup.string()
            //       .required('Delivery Date is required')
            //       .test('is-date', 'Delivery Date is not a valid date', value => !isNaN(Date.parse(value)))
            //       .test('is-after-manufacturedDate', 'Delivery date must be less than or equal to the packed date ', function (value) {
            //          const { packedDate } = this.parent;
            //          if (!packedDate || !value) return true;
            //          return new Date(value).getTime() >= new Date(packedDate).getTime();
            //       });


            // validation['deliveryType'] = Yup.string().required('delivery type is required');
    

            // validation['outgoingDispatchListNew'] = Yup.array().of(
            //    Yup.object().shape({
            //       deliveredTo:Yup.string().required('Delivered to is required'),
            //       packedBy:Yup.string().required('Packed by is required'),

            //       packedDate:Yup.string().required('Packed date is required'),
            //       deliveryDate:Yup.string().required('Delivery date is required'),
            //       deliveryType:Yup.string().required('delivery type is required'),
            //       deliveredById:Yup.string().required('Delivered by is required'),
            //       approximateValue:Yup.string().required('Approximate value is required').max(6, 'Approximate value must be at most 6 characters'),
            //       notes:Yup.string().required('Notes is required'),
            //    }))

            //    if(VALUES.isExchange){
            //       validationAndFields.requiredField.push("deliveredUserType");
            //       for(let i=0;i<VALUES.outgoingDispatchListNew.length;i++){
            //          let names=[
            //             `outgoingDispatchListNew[${i}].deliveredUserType`,
            //          ]
            //          validationAndFields.requiredField.push(...names)
            //       }
            //       validation['outgoingDispatchListNew'] = Yup.array().of(
            //          Yup.object().shape({
            //             deliveredUserType:Yup.string().required('Delivered by User Type is required'),
            //          }))
            //    }
            let baseValidationSchema = Yup.object().shape({
               deliveredTo: Yup.string().required('Delivered to is required'),
               packedBy: Yup.string().required('Packed by is required'),
               packedDate: Yup.string().required('Packed date is required'),
               deliveryDate: Yup.string().required('Delivery date is required'),
               deliveryType: Yup.string().required('Delivery type is required'),
               deliveredById: Yup.string().required('Delivered by is required'),
               approximateValue: Yup.string()
                  .required('Approximate value is required')
                  .max(6, 'Approximate value must be at most 6 characters'),
               notes: Yup.string().required('Notes is required'),
               courierId: Yup.string().test(
                  'courier-required-if-not-hand-carried',
                  'Courier info required',
                  function (value) {
                     
                    const { deliveryType } = this.parent;
                    return deliveryType === 'Hand Carry' || !!value;
                  }
                ),
            });
            
            // Conditionally add extra fields if `isExchange` is true
            if (VALUES.isExchange) {
               const exchangeValidationSchema = Yup.object().shape({
                  deliveredUserType: Yup.string().required('Delivered User Type is required'),
               });
               baseValidationSchema = baseValidationSchema.concat(exchangeValidationSchema); // Merging the base schema with the extra field
            }
               let hasValues = (VALUES.outgoingDispatchListNew[0].packedBy != "" || VALUES.outgoingDispatchListNew[0].packedDate != "" || VALUES.outgoingDispatchListNew[0].deliveryDate != "" || VALUES.outgoingDispatchListNew[0].deliveryType != "" || VALUES.outgoingDispatchListNew[0].deliveredTo != "" || VALUES.outgoingDispatchListNew[0].deliveredById != "" || VALUES.outgoingDispatchListNew[0].deliveredUserType != "" || VALUES.outgoingDispatchListNew[0].approximateValue != "" || VALUES.outgoingDispatchListNew[0].notes != "" || VALUES.outgoingDispatchListNew[0].courierId != ""  )

            if(VALUES.outgoingDispatchList?.length<1 || hasValues){
            validation['outgoingDispatchListNew'] = Yup.array().of(baseValidationSchema);
            }
            // validation['deliveredTo'] = Yup.string().required('Delivered to is required');
            // validation['deliveredById'] = Yup.string().required('Delivered by is required');
            // validation['approximateValue'] = Yup.string().required('Approximate value is required').max(6, 'Approximate value must be at most 6 characters');
            // validation['notes'] = Yup.string().required('Notes is required');
         } else {
            // setFieldError("packedBy", "");
            // setFieldTouched("packedBy", false);

            // setFieldError("packedDate", "");
            // setFieldTouched("packedDate", false);

            // setFieldError("deliveryDate", "");
            // setFieldTouched("deliveryDate", false);

            // setFieldError("deliveryType", "");
            // setFieldTouched("deliveryType", false);
            const fieldsToClear = [
               'packedBy',
               'packedDate',
               'deliveryDate',
               'deliveryType',
               'deliveredTo',
               'deliveredById',
               'approximateValue',
               'notes',
               'courierId'
            ];
            
            for (let i = 0; i < VALUES.outgoingDispatchListNew.length; i++) {
               fieldsToClear.forEach((field) => {
                  const fieldName = `outgoingDispatchListNew[${i}].${field}`;
                  setFieldError(fieldName, "");
                  setFieldTouched(fieldName, false);
               });
            }
            // setFieldValue("packedBy", "")
            // setFieldValue("packedDate", "")
            // setFieldValue("deliveryDate", "")
            // setFieldValue("deliveryType", "")
            // setFieldValue("deliveredTo", "")
            // setFieldValue("deliveredById", "")
            // setFieldValue("approximateValue", "")
            // setFieldValue("courierId", "")
            // setFieldValue("notes", "")

            // change it emergency

            // setFieldError("deliveredTo", "");
            // setFieldTouched("deliveredTo", false);
            // // setFieldValue("deliveredTo", "")

            // setFieldError("deliveredById", "");
            // setFieldTouched("deliveredById", false);
            // // setFieldValue("deliveredById", "")

            // setFieldError("deliveredUserType", "");
            // setFieldTouched("deliveredUserType", false);
            // // setFieldValue("deliveredUserType", "")

            // setFieldError("approximateValue", "");
            // setFieldTouched("approximateValue", false);
            // // setFieldValue("approximateValue", "")

            // setFieldError("notes", "");
            // setFieldTouched("notes", false);
            // // setFieldValue("notes", "")


            // validationAndFields.disableField.push("packedBy", "packedDate", "deliveryDate", "deliveryType", "deliveredTo", "deliveredById",
            //    "approximateValue", "courierId", "notes")

         }


         validationAndFields.nextStatus = "Replacement system dispatched";
         validationAndFields.url = updateReplacementSystemDispatch;


         break;
      }
      // 3
      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["Replacement System Dispatched"]] + NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.CREATE_JOB]): {
         //statements; 
         let modifiedValues = {
            ...VALUES
         };
         modifiedValues['IsAfcsGenerated'] = true;
         validationAndFields = GET_VALIDATION_AND_FIELD_SHOW(modifiedValues, JOB_SHEET_STATUS[JOB_SHEET_STATUS["Service Request Generated"]],
            NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.REPLACEMENT_SYSTEM_DISPATCHED], setFieldError, setFieldTouched);
         validation = validationAndFields.validation;
         // validationAndFields.disableField.push('isExchange');
         // if (VALUES?.isExchange) {
         //    validationAndFields.disableField.push('warrantyStatus');
         // }

         // Flow Changed Below due to client requirement on 02-09-2024
         // validationAndFields.showTabs.push(JOB_SHEET_TABS.INCOMING_COURIER);


         validationAndFields.nextStatus = "Create Job";
         validationAndFields.url = updateCreateJob;
         validationAndFields.disableField = validationAndFields.disableField.filter((eachValue: any) => {
            return eachValue != "accessoriesList" && eachValue != "missingAccessories" && eachValue != "additionalRemarks";
         });


         // changing below 02-09-2024

         // validation['courierAmount'] = Yup.string().matches(/^[1-9][0-9]*$/, 'Courier amount must be a positive integer');
         // validation['courierChequeNumber'] = Yup.string().matches(/^[a-zA-Z0-9].*$/, "Courier cheque number must be valid");
         // if (VALUES.jobReceivedMode == 'Hand Carry') {
         //    validationAndFields.hideField?.push("courierReceivedAt", "courierPackingCondition", "courierAmount",
         //       "courierChequeNumber", "courierBankName", "incomingDispatchDto_courierId");
         //    setFieldValue("courierReceivedAt", "");
         //    setFieldValue("courierPackingCondition", "");
         //    setFieldValue("courierAmount", "");
         //    setFieldValue("courierChequeNumber", "");
         //    setFieldValue("courierBankName", "");
         //    setFieldValue("incomingDispatchDto_courierId", "");
         // }


         
         // if (VALUES.jobReceivedMode == 'Paid' || VALUES.jobReceivedMode == 'To Pay') {
         //    if (VALUES.jobReceivedMode != "" && VALUES.IsAfcsGenerated) {
         //       validationAndFields.requiredField.push("incomingDispatchDto_courierId");
         //       validation['incomingDispatchDto_courierId'] = Yup.string().required(`Courier is required`);
         //    }
         //    validationAndFields.hideField?.push("handCarriedPersonName", "handCarriedPersonDesignation");
         //    setFieldValue("handCarriedPersonName", "");
         //    setFieldValue("handCarriedPersonDesignation", "");

         // }
         validationAndFields.requiredField.push("accessoriesList", "missingAccessories", "additionalRemarks");
        if(VALUES.isExchange){
         
         let hasValues = (VALUES.outgoingDispatchListNew[0].packedBy != "" || VALUES.outgoingDispatchListNew[0].packedDate != "" || VALUES.outgoingDispatchListNew[0].deliveryDate != "" || VALUES.outgoingDispatchListNew[0].deliveryType != "" || VALUES.outgoingDispatchListNew[0].deliveredTo != "" || VALUES.outgoingDispatchListNew[0].deliveredById != "" || VALUES.outgoingDispatchListNew[0].deliveredUserType != "" || VALUES.outgoingDispatchListNew[0].approximateValue != "" || VALUES.outgoingDispatchListNew[0].notes != "" || VALUES.outgoingDispatchListNew[0].courierId != ""  )
         if((hasValues) || (VALUES.outgoingDispatchList?.length <1)){
         
            validation['outgoingDispatchListNew'] = Yup.array().of(
               Yup.object().shape({
            
            packedBy:Yup.string().required('Packed by is required'),

            packedDate:Yup.string().required('Packed date is required'),
            deliveryDate:Yup.string().required('Delivery date is required'),
             deliveryType:Yup.string().required('delivery type is required'),
            deliveredTo:Yup.string().required('Delivered to is required'),
            deliveredById:Yup.string().required('Delivered by is required'),
            deliveredUserType:Yup.string().required('Delivered by User Type is required'),
            approximateValue:Yup.string().required('Approximate value is required').max(6, 'Approximate value must be at most 6 characters'),
            notes:Yup.string().required('Notes is required'),
            courierId: Yup.string().test(
               'courier-required-if-not-hand-carried',
               'Courier info required.',
               function (value) {
                  
                 const { deliveryType } = this.parent; // Access the current object
                 // Apply validation only when deliveryType is NOT "Hand Carried"
                 return deliveryType === 'Hand Carry' || !!value;
               }
             ),
               })
            )
         }else{
            validation['outgoingDispatchListNew'] = Yup.array().of(
               Yup.object().shape({})
            )
         }
      }
         if (VALUES.IsAfcsGenerated) {
            if (VALUES.isExchange) {
                // changing below 02-09-2024
               validationAndFields.requiredField.push("accessoriesList", "missingAccessories", "additionalRemarks");
               validationAndFields.disableField = validationAndFields.disableField.filter((eachValue: any) => {
                  return eachValue != "incomingDispatchDto_receivedDate" && eachValue != "incomingDispatchDto_receivedId" && eachValue != "incomingDispatchDto_notes" && eachValue != "incomingDispatchDto_courierId" && eachValue != "accessoriesList" && eachValue != "missingAccessories" && eachValue != "additionalRemarks";
               });
               validation['accessoriesList'] = Yup.string().required(`Accessories list is required`);
               validation['missingAccessories'] = Yup.string().required(`Missing accessories list is required`);
               validation['additionalRemarks'] = Yup.string().required(`Additional remarks list is required`);
               
               // changing below 02-09-2024
               // validation['incomingDispatchDto_receivedDate'] = Yup.string().required(`Received date is required`);
               // validation['incomingDispatchDto_receivedId'] = Yup.string().required(`Received by is required`);
               // validation['jobReceivedMode'] = Yup.string().required(`Job received mode is required`);
               // validation['isFaultySystemAvailable'] = Yup.boolean().required().oneOf([true], 'Faulty system must be available');
            } else {
                // changing below 02-09-2024
               validationAndFields.requiredField.push("accessoriesList", "missingAccessories", "additionalRemarks");

               validationAndFields.disableField = validationAndFields.disableField.filter((eachValue: any) => {
                  return eachValue != "incomingDispatchDto_receivedDate" && eachValue != "incomingDispatchDto_receivedId" && eachValue != "incomingDispatchDto_notes" && eachValue != "incomingDispatchDto_courierId" && eachValue != "accessoriesList" && eachValue != "missingAccessories" && eachValue != "additionalRemarks";
               });
               validation['accessoriesList'] = Yup.string().required(`Accessories list is required`);
               validation['missingAccessories'] = Yup.string().required(`Missing accessories list is required`);
               validation['additionalRemarks'] = Yup.string().required(`Additional remarks list is required`);

                // changing below 02-09-2024

               // validation['incomingDispatchDto_receivedDate'] = Yup.string().required(`Received date is required`);
               // validation['incomingDispatchDto_receivedId'] = Yup.string().required(`Received by is required`);
               // validation['jobReceivedMode'] = Yup.string().required(`Job received mode is required`);
            }




         } else {
            // setFieldValue("accessoriesList", "")
            setFieldError("accessoriesList", "");
            setFieldTouched("accessoriesList", false);

            setFieldError("isFaultySystemAvailable", "");
            setFieldTouched("isFaultySystemAvailable", false);

            // setFieldValue("missingAccessories", "")
            setFieldError("missingAccessories", "");
            setFieldTouched("missingAccessories", false);

            // setFieldValue("additionalRemarks", "")
            setFieldError("additionalRemarks", "");
            setFieldTouched("additionalRemarks", false);


            // changing below 02-09-2024

            // setFieldError("incomingDispatchDto_receivedDate", "");
            // setFieldTouched("incomingDispatchDto_receivedDate", false);

            // setFieldError("incomingDispatchDto_receivedId", "");
            // setFieldTouched("incomingDispatchDto_receivedId", false);



         }


         break;
      }
      // 4
      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["Service Request Generated"]] + NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.CREATE_JOB]): {

         //statements; 
         let modifiedValues = {
            ...VALUES
         };
         modifiedValues['IsAfcsGenerated'] = true;

         validationAndFields = GET_VALIDATION_AND_FIELD_SHOW(modifiedValues, JOB_SHEET_STATUS[JOB_SHEET_STATUS["New Job"]], NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.GENERATE_JOB_REQUEST], setFieldError, setFieldTouched);

         validation = validationAndFields.validation;

         
         // Flow Changed Below due to client requirement on 02-09-2024
         // validationAndFields.showTabs.push(JOB_SHEET_TABS.INCOMING_COURIER);
         if(!VALUES.isExchange){
            if(VALUES.incomingDispatchList?.length>0){
               const fieldsToClear = [
                  'receivedDate',
                  'receivedId',
                  'jobReceivedMode',
                  'courierReceivedAt',
                  'courierPackingCondition',
                  'courierAmount',
                  'courierChequeNumber',
                  'courierBankName',
                  'courierId',
                  'handCarriedPersonName',
                  'handCarriedPersonDesignation',
                  'notes'
               ];
               fieldsToClear.forEach((field) => {
                  const fieldName = `incomingDispatchListNew[0].${field}`;
                  validationAndFields.disableField?.push(fieldName)
               });               
               
            }
         }

         // changing below 02-09-2024
         validationAndFields.requiredField.push("accessoriesList", "missingAccessories", "additionalRemarks");

         validationAndFields.disableField = validationAndFields.disableField.filter((eachValue: any) => {
            return eachValue != "incomingDispatchDto_receivedDate" && eachValue != "incomingDispatchDto_receivedId" && eachValue != "incomingDispatchDto_notes" && eachValue != "incomingDispatchDto_courierId" && eachValue != "accessoriesList" && eachValue != "missingAccessories" && eachValue != "additionalRemarks";
         });
         validationAndFields.url = updateCreateJob;
         validationAndFields.showTabs.push(JOB_SHEET_TABS.ADDITIONAL_EXPENSES)
         // changing below 02-09-2024
         // validation['courierAmount'] = Yup.string().matches(/^[1-9][0-9]*$/, 'Courier amount must be a positive integer');
         // validation['courierChequeNumber'] = Yup.string().matches(/^[a-zA-Z0-9].*$/, "Courier cheque number must be valid");



         // if (VALUES.jobReceivedMode == 'Hand Carry') {

         //    validationAndFields.hideField?.push("courierReceivedAt", "courierPackingCondition", "courierAmount",
         //       "courierChequeNumber", "courierBankName", "incomingDispatchDto_courierId");
         //    setFieldValue("courierReceivedAt", "");
         //    setFieldValue("courierPackingCondition", "");
         //    setFieldValue("courierAmount", "");
         //    setFieldValue("courierChequeNumber", "");
         //    setFieldValue("courierBankName", "");
         //    setFieldValue("incomingDispatchDto_courierId", "");
         // }
         // if (VALUES.jobReceivedMode == 'Paid' || VALUES.jobReceivedMode == 'To Pay') {
         //    validationAndFields.requiredField.push("incomingDispatchDto_courierId");
         //    if (VALUES.jobReceivedMode != "" && VALUES.IsAfcsGenerated) {
         //       validationAndFields.requiredField.push("incomingDispatchDto_courierId");
         //       validation['incomingDispatchDto_courierId'] = Yup.string().required(`Courier is required`);
         //    }
         //    validationAndFields.hideField?.push("handCarriedPersonName", "handCarriedPersonDesignation");
         //    setFieldValue("handCarriedPersonName", "");
         //    setFieldValue("handCarriedPersonDesignation", "");

         // }

         //  change to un disable the field as per new requirements

         // validationAndFields.disableField.push('incomingDispatchDto_receivedDate', "incomingDispatchDto_receivedId", "incomingDispatchDto_notes",
         //    "incomingDispatchDto_courierId")

         if (VALUES.IsAfcsGenerated) {

            // // new line (24-06-24)
            // validationAndFields.requiredField.push("accessoriesList", "missingAccessories", "additionalRemarks", "incomingDispatchDto_receivedDate",
            //    "incomingDispatchDto_receivedId", "jobReceivedMode")  

            // validationAndFields.disableField = validationAndFields.disableField.filter((eachValue: any) => {
            //    return eachValue != "incomingDispatchDto_receivedDate" && eachValue != "incomingDispatchDto_receivedId" && eachValue != "incomingDispatchDto_notes" && eachValue != "incomingDispatchDto_courierId" && eachValue != "accessoriesList" && eachValue != "missingAccessories" && eachValue != "additionalRemarks"
            // })

            validation['accessoriesList'] = Yup.string().required(`Accessories list is required`);
            validation['missingAccessories'] = Yup.string().required(`Missing accessories list is required`);
            validation['additionalRemarks'] = Yup.string().required(`Additional remarks list is required`);


            // changing below 02-09-2024
            // validation['incomingDispatchDto_receivedDate'] = Yup.string().required(`Received date is required`);
            // validation['incomingDispatchDto_receivedId'] = Yup.string().required(`Received by is required`);
            // validation['jobReceivedMode'] = Yup.string().required(`Job received mode is required`);


         } else {

            // setFieldValue("accessoriesList", "")
            setFieldError("accessoriesList", "");
            setFieldTouched("accessoriesList", false);

            // setFieldValue("missingAccessories", "")
            setFieldError("missingAccessories", "");
            setFieldTouched("missingAccessories", false);

            // setFieldValue("additionalRemarks", "")
            setFieldError("additionalRemarks", "");
            setFieldTouched("additionalRemarks", false);

            // changing below 02-09-2024
            // setFieldError("incomingDispatchDto_receivedDate", "");
            // setFieldTouched("incomingDispatchDto_receivedDate", false);

            // setFieldError("incomingDispatchDto_receivedId", "");
            // setFieldTouched("incomingDispatchDto_receivedId", false);
         }

         validationAndFields.nextStatus = "Create Job";


         break;
      }
      // 5
      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Sheet Created"]] + NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.MAKE_ZERO_ESTIMATE_FOR_SYSTEM_IN_WARRANTY]): {
         
         let modifiedValues = {
            ...VALUES
         };
         modifiedValues['IsAfcsGenerated'] = true;
         validationAndFields = GET_VALIDATION_AND_FIELD_SHOW(modifiedValues, JOB_SHEET_STATUS[JOB_SHEET_STATUS["Service Request Generated"]], NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.CREATE_JOB], setFieldError, setFieldTouched);
         validation = validationAndFields.validation;

         // if(!VALUES.isRemoteService){
            validationAndFields.showTabs.push(JOB_SHEET_TABS.INCOMING_COURIER);
         // }

         validationAndFields.disableField.push("customerConcern"); //"isRemoteService"

         if(VALUES.isExchange && !VALUES.isExchangeChangedNow){
            // validationAndFields.disableField.push("isExchange","isRemoteService","isFaultySystemAvailable");
            validationAndFields.disableField.push("isExchange","isRemoteService");
         };
         if(VALUES.isExchange){
            validationAndFields.disableField = validationAndFields.disableField.filter((eachValue: any) => {
               return eachValue != "dispatched";
            });
            validationAndFields.requiredField.push("dispatched");
            // validation['dispatched'] = Yup.boolean().required().oneOf([true], 'Dispatched is required');
            if(VALUES.IsAfcsGenerated){

               validation['dispatched'] = Yup.boolean()
                  .required('Dispatched is required') // ensures the field is present
                  .test( 'is-true', 'Dispatched is required', value => value === true);
            }
         }else{
            setFieldValue("dispatched",false)
         }
         if(VALUES.dispatched && !VALUES.dispatchedNow){
            validationAndFields.disableField.push("dispatched");
         }
         // console.log("Exchange --->",!validationAndFields.disableField.includes('isExchange'))
         // if (!validationAndFields.disableField.includes('isExchange')){
         //    validationAndFields.disableField.push("isExchange");
         // }

         validationAndFields.nextStatus = "Make Zero estimate for System in warranty";
         validationAndFields.url = updateZeroEstimateAssignToTechnicianJob;

         validationAndFields.showTabs.push(JOB_SHEET_TABS.ADDITIONAL_EXPENSES)
         // Outgoing Flow start based on 02-09-2024
         if(VALUES.isExchange){
            validationAndFields.showTabs.push(JOB_SHEET_TABS.OUTGOING_COURIER);
            
            // validationAndFields.requiredField.push("packedBy", "packedDate", "deliveryDate", "deliveryType", "deliveredTo", "deliveredById","deliveredUserType", "approximateValue", "notes");

            //    validationAndFields.requiredField.push("packedBy", "packedDate", "deliveryDate", "deliveryType");
            //    validationAndFields.requiredField.push("deliveredTo", "deliveredById", "approximateValue", "notes");

               let arr:any = []
               for(let i=0;i<VALUES.outgoingDispatchListNew.length;i++){
                  let names=[
                     `outgoingDispatchListNew[${i}].packedBy`,
                     `outgoingDispatchListNew[${i}].packedDate`,
                     `outgoingDispatchListNew[${i}].deliveryDate`,
                     `outgoingDispatchListNew[${i}].deliveryType`,
                     `outgoingDispatchListNew[${i}].deliveredTo`,
                     `outgoingDispatchListNew[${i}].deliveredById`,
                     `outgoingDispatchListNew[${i}].deliveredUserType`,
                     `outgoingDispatchListNew[${i}].courierId`,
                     `outgoingDispatchListNew[${i}].notes`,
                  ]
                  arr.push(...names)
               }
               
               validationAndFields.requiredField.push(...arr);

               // validation['packedBy'] = Yup.string().required('Packed by is required');
   
               // validation['packedDate'] = Yup.string().required('Packed date is required');
               // validation['deliveryDate'] = Yup.string().required('Delivery date is required');
               //  validation['deliveryType'] = Yup.string().required('delivery type is required');
               // validation['deliveredTo'] = Yup.string().required('Delivered to is required');
               // validation['deliveredById'] = Yup.string().required('Delivered by User Type is required');
               // validation['deliveredUserType'] = Yup.string().required('Delivered by is required');
               // validation['approximateValue'] = Yup.string().required('Approximate value is required').max(6, 'Approximate value must be at most 6 characters');
               // validation['notes'] = Yup.string().required('Notes is required');
               // validation['outgoingDispatchListNew'] = Yup.array().of(
               //    Yup.object().shape({
               //       packedBy : Yup.string().required('Packed by is required'),
               //       packedDate : Yup.string().required('Packed date is required'),
               //       deliveryDate : Yup.string().required('Delivery date is required'),
               //       deliveryType : Yup.string().required('delivery type is required'),
               //       deliveredTo : Yup.string().required('Delivered to is required'),
               //       deliveredById : Yup.string().required('Delivered by is required'),
               //       deliveredUserType : Yup.string().required('Delivered by User Type is required'),
               //       approximateValue : Yup.string().required('Approximate value is required').max(6, 'Approximate value must be at most 6 characters'),
               //       notes : Yup.string().required('Notes is required'),
               //    })
               // );

               let baseValidationSchema = Yup.object().shape({
                  deliveredTo: Yup.string().required('Delivered to is required'),
                  packedBy: Yup.string().required('Packed by is required'),
                  packedDate: Yup.string().required('Packed date is required'),
                  deliveryDate: Yup.string().required('Delivery date is required'),
                  deliveryType: Yup.string().required('Delivery type is required'),
                  deliveredById: Yup.string().required('Delivered by is required'),
                  approximateValue: Yup.string()
                     .required('Approximate value is required')
                     .max(6, 'Approximate value must be at most 6 characters'),
                  notes: Yup.string().required('Notes is required'),
                  courierId: Yup.string().test(
                     'courier-required-if-not-hand-carried',
                     'Courier info required',
                     function (value) {
                        
                       const { deliveryType } = this.parent;
                       return deliveryType === 'Hand Carry' || !!value;
                     }
                   ),
               });
               
               // Conditionally add extra fields if `isExchange` is true
               if (VALUES.isExchange) {
                  const exchangeValidationSchema = Yup.object().shape({
                     deliveredUserType: Yup.string().required('Delivered User Type is required'),
                  });
                  baseValidationSchema = baseValidationSchema.concat(exchangeValidationSchema); // Merging the base schema with the extra field
               }
               let hasValues = (VALUES.outgoingDispatchListNew[0].packedBy != "" || VALUES.outgoingDispatchListNew[0].packedDate != "" || VALUES.outgoingDispatchListNew[0].deliveryDate != "" || VALUES.outgoingDispatchListNew[0].deliveryType != "" || VALUES.outgoingDispatchListNew[0].deliveredTo != "" || VALUES.outgoingDispatchListNew[0].deliveredById != "" || VALUES.outgoingDispatchListNew[0].deliveredUserType != "" || VALUES.outgoingDispatchListNew[0].approximateValue != "" || VALUES.outgoingDispatchListNew[0].notes != "" || VALUES.outgoingDispatchListNew[0].courierId != ""  )

               if(VALUES.outgoingDispatchList?.length<1 || hasValues){
               validation['outgoingDispatchListNew'] = Yup.array().of(baseValidationSchema);
               }
               // if (VALUES.deliveryType == 'Hand Carry') {
               //    setFieldValue('courierId', "");
               //    validationAndFields.disableField.push('courierId');
               //    setFieldValue('courierId', "");
               // }

               // if (VALUES.deliveryType == 'Courier') {
               //    validationAndFields.requiredField.push('courierId');
               //    validation['courierId'] = Yup.string().required('Courier is required');
               // }
               for (let i = 0; i < VALUES.outgoingDispatchListNew.length; i++) {
                  const deliveryTypeField = `outgoingDispatchListNew[${i}].deliveryType`;
                  const courierIdField = `outgoingDispatchListNew[${i}].courierId`;
            
                     if (VALUES.outgoingDispatchListNew[i].deliveryType === 'Hand Carry') {
                        setFieldValue(courierIdField, "");
                        validationAndFields.disableField.push(courierIdField);
                        validationAndFields.requiredField = validationAndFields.requiredField.filter(field => field !== courierIdField);
                     }
            
                     if (VALUES.outgoingDispatchListNew[i].deliveryType === 'Courier') {
                        validationAndFields.requiredField.push(courierIdField);
                        // validation[courierIdField] = Yup.string().required('Courier is required');
                     }
                  
               }
         }
         // Outgoing Flow end


         // changing below 02-09-2024
         // Incoming courier start
         if(!VALUES.isRemoteService){
         //    validation['courierAmount'] = Yup.string().matches(/^[1-9][0-9]*$/, 'Courier amount must be a positive integer');
         // validation['courierChequeNumber'] = Yup.string().matches(/^[a-zA-Z0-9].*$/, "Courier cheque number must be valid");]
         debugger
         if (VALUES?.incomingDispatchListNew?.[0]?.jobReceivedMode == 'Hand Carry') {
            // validationAndFields.hideField?.push("courierReceivedAt", "courierPackingCondition", "courierAmount",
            //    "courierChequeNumber", "courierBankName", "incomingDispatchDto_courierId");
            // setFieldValue("courierReceivedAt", "");
            // setFieldValue("courierPackingCondition", "");
            // setFieldValue("courierAmount", "");
            // setFieldValue("courierChequeNumber", "");
            // setFieldValue("courierBankName", "");
            // setFieldValue("incomingDispatchDto_courierId", "");

            const fieldsToHide = [
               'courierReceivedAt',
               'courierPackingCondition',
               'courierAmount',
               'courierChequeNumber',
               'courierBankName',
               'courierId',
            ];
            
            for (let i = 0; i < VALUES.incomingDispatchListNew.length; i++) {
               fieldsToHide.forEach((field) => {
                  const fieldName = `incomingDispatchListNew[${i}].${field}`;
                  setFieldValue(fieldName, "");
                  setFieldTouched(fieldName, false);
                  validationAndFields.hideField?.push(fieldName)
               });
            }
         }
         let incomingValidation = Yup.object().shape({
            courierAmount: Yup.string().matches(/^[1-9][0-9]*$/, 'Courier amount must be a positive integer'),
            courierChequeNumber: Yup.string().matches(/^[a-zA-Z0-9].*$/, "Courier cheque number must be valid"),
            receivedDate:  Yup.string().required(`Received date is required`),
            receivedId: Yup.string().required(`Received by is required`),
            jobReceivedMode:  Yup.string().required(`Job received mode is required`),
           
         });
         
         if((VALUES.incomingDispatchList?.length<1 && VALUES.IsAfcsGenerated) || hasIncomingCourierValues(VALUES.incomingDispatchListNew[0])){
            validation['incomingDispatchListNew'] = Yup.array().of(incomingValidation);
         }else{
            validation['incomingDispatchListNew'] = Yup.array().of(Yup.object().shape({}));
         }

        if (VALUES?.incomingDispatchListNew?.[0]?.jobReceivedMode == 'Paid' || VALUES?.incomingDispatchListNew?.[0]?.jobReceivedMode == 'To Pay') {
         // alert(123)
         
            // if (VALUES?.incomingDispatchListNew?.[0]?.jobReceivedMode != "" && VALUES.IsAfcsGenerated) {
            if (VALUES?.incomingDispatchListNew?.[0]?.jobReceivedMode != "" ) {
               // validationAndFields.requiredField.push("incomingDispatchDto_courierId");
               // validation['incomingDispatchDto_courierId'] = Yup.string().required(`Courier is required`);

               const fieldsToHide = [
                  'courierId',
               ];
               
               for (let i = 0; i < VALUES.incomingDispatchListNew.length; i++) {
                  fieldsToHide.forEach((field) => {
                     const fieldName = `incomingDispatchListNew[${i}].${field}`;
                     validationAndFields.requiredField?.push(fieldName)
                  });
               }
               let incomingValidation = Yup.object().shape({
                  courierId: Yup.string().required(`Courier is required`),
                  courierAmount: Yup.string().matches(/^[1-9][0-9]*$/, 'Courier amount must be a positive integer'),
                  courierChequeNumber: Yup.string().matches(/^[a-zA-Z0-9].*$/, "Courier cheque number must be valid"),
                  receivedDate:  Yup.string().required(`Received date is required`),
                  receivedId: Yup.string().required(`Received by is required`),
                  jobReceivedMode:  Yup.string().required(`Job received mode is required`),
               });
               
               if((VALUES.incomingDispatchList?.length<1 && VALUES.IsAfcsGenerated) || hasIncomingCourierValues(VALUES.incomingDispatchListNew[0])){
                  validation['incomingDispatchListNew'] = Yup.array().of(incomingValidation);
               }else{
                  validation['incomingDispatchListNew'] = Yup.array().of(Yup.object().shape({}));
               }
            }
            const fieldsToHide = [
               'handCarriedPersonName',
               'handCarriedPersonDesignation'
            ];
            
            for (let i = 0; i < VALUES.incomingDispatchListNew.length; i++) {
               fieldsToHide.forEach((field) => {
                  const fieldName = `incomingDispatchListNew[${i}].${field}`;
                  validationAndFields.hideField?.push(fieldName)
                  setFieldValue(fieldName,"")
               });
            }
            // validationAndFields.hideField?.push("handCarriedPersonName", "handCarriedPersonDesignation");
            // setFieldValue("handCarriedPersonName", "");
            // setFieldValue("handCarriedPersonDesignation", "");

         }
         }
         // validationAndFields.requiredField.push("incomingDispatchDto_receivedDate",
         //             "incomingDispatchDto_receivedId", "jobReceivedMode");
                     const requiredField = [
                        'receivedDate',
                        'receivedId',
                        "jobReceivedMode"
                     ];
                     
                     for (let i = 0; i < VALUES.incomingDispatchListNew.length; i++) {
                        requiredField.forEach((field) => {
                           const fieldName = `incomingDispatchListNew[${i}].${field}`;
                           validationAndFields.requiredField?.push(fieldName)
                        });
                     }

         // Incoming courier end
         ;
         //statements; 
         if (VALUES.IsAfcsGenerated) {
            
            validationAndFields.requiredField.push("warrantyStatus");
            validation['warrantyStatus'] = Yup.string().required(`Warranty status is required`);
            ;
             // changing below 02-09-2024
            // Incoming courier start
            if(!VALUES.isRemoteService){
               if (VALUES.isExchange) {
                  
                  // validationAndFields.requiredField.push("incomingDispatchDto_receivedDate",
                  //    "incomingDispatchDto_receivedId", "jobReceivedMode", "isFaultySystemAvailable");
                  // validationAndFields.requiredField.push("incomingDispatchDto_receivedDate",
                  //    "incomingDispatchDto_receivedId", "jobReceivedMode");
                     const requiredField = [
                        'receivedDate',
                        'receivedId',
                        "jobReceivedMode"
                     ];
                     
                     for (let i = 0; i < VALUES.incomingDispatchListNew.length; i++) {
                        requiredField.forEach((field) => {
                           const fieldName = `incomingDispatchListNew[${i}].${field}`;
                           validationAndFields.requiredField?.push(fieldName)
                        });
                     }
                  // validationAndFields.disableField = validationAndFields.disableField.filter((eachValue: any) => {
                  //    return eachValue != `incomingDispatchListNew[0].receivedDate` && eachValue != `incomingDispatchListNew[0].receivedId` && eachValue != `incomingDispatchListNew[0].notes` && eachValue != `incomingDispatchListNew[0].courierId`;
                  // });
                  
                  if(!(VALUES.incomingDispatchList?.length>0) || hasIncomingCourierValues(VALUES.incomingDispatchListNew[0])){
                     let incomingValidation = Yup.object().shape({
                        receivedDate:  Yup.string().required(`Received date is required`),
                        receivedId: Yup.string().required(`Received by is required`),
                        jobReceivedMode:  Yup.string().required(`Job received mode is required`),
                     });
                     if(VALUES.incomingDispatchListNew[0]?.jobReceivedMode == "Paid" || VALUES.incomingDispatchListNew[0]?.jobReceivedMode == "To Pay"){
                        let newValidation = Yup.object().shape({
                           courierId: Yup.string().required(`Courier is required`),
                           courierAmount: Yup.string().matches(/^[1-9][0-9]*$/, 'Courier amount must be a positive integer'),
                           courierChequeNumber: Yup.string().matches(/^[a-zA-Z0-9].*$/, "Courier cheque number must be valid"),
                        });
                        incomingValidation = incomingValidation.concat(newValidation);
                     }
                     validation['incomingDispatchListNew'] = Yup.array().of(incomingValidation);
                  }
                  // validation['incomingDispatchDto_receivedDate'] = Yup.string().required(`Received date is required`);
                  // validation['incomingDispatchDto_receivedId'] = Yup.string().required(`Received by is required`);
                  // validation['jobReceivedMode'] = Yup.string().required(`Job received mode is required`);
                  // validation['isFaultySystemAvailable'] = Yup.boolean().required().oneOf([true], 'Faulty system must be available');
               } else {
                  // validationAndFields.requiredField.push("incomingDispatchDto_receivedDate", "incomingDispatchDto_receivedId", "jobReceivedMode");
                  const requiredField = [
                     'receivedDate',
                     'receivedId',
                     "jobReceivedMode"
                  ];
                  
                  for (let i = 0; i < VALUES.incomingDispatchListNew.length; i++) {
                     requiredField.forEach((field) => {
                        const fieldName = `incomingDispatchListNew[${i}].${field}`;
                        validationAndFields.requiredField?.push(fieldName)
                     });
                  }
                  // validationAndFields.disableField = validationAndFields.disableField.filter((eachValue: any) => {
                  //    return eachValue != `incomingDispatchListNew[0].receivedDate` && eachValue != `incomingDispatchListNew[0].receivedId` && eachValue != `incomingDispatchListNew[0].notes` && eachValue != `incomingDispatchListNew[0].courierId`;
                  // });
                  // validationAndFields.disableField = validationAndFields.disableField.filter((eachValue: any) => {
                  //    return eachValue != "incomingDispatchDto_receivedDate" && eachValue != "incomingDispatchDto_receivedId" && eachValue != "incomingDispatchDto_notes" && eachValue != "incomingDispatchDto_courierId"
                  // });
                  // validation['incomingDispatchDto_receivedDate'] = Yup.string().required(`Received date is required`);
                  // validation['incomingDispatchDto_receivedId'] = Yup.string().required(`Received by is required`);
                  // validation['jobReceivedMode'] = Yup.string().required(`Job received mode is required`);
                  
                  if(!(VALUES.incomingDispatchList?.length>0) || hasIncomingCourierValues(VALUES.incomingDispatchListNew[0])){
                     let incomingValidation = Yup.object().shape({
                        receivedDate:  Yup.string().required(`Received date is required`),
                        receivedId: Yup.string().required(`Received by is required`),
                        jobReceivedMode:  Yup.string().required(`Job received mode is required`),
                     });
                     if(VALUES.incomingDispatchListNew[0]?.jobReceivedMode == "Paid" || VALUES.incomingDispatchListNew[0]?.jobReceivedMode == "To Pay"){
                        let newValidation = Yup.object().shape({
                           courierId: Yup.string().required(`Courier is required`),
                           courierAmount: Yup.string().matches(/^[1-9][0-9]*$/, 'Courier amount must be a positive integer'),
                           courierChequeNumber: Yup.string().matches(/^[a-zA-Z0-9].*$/, "Courier cheque number must be valid"),
                        });
                        incomingValidation = incomingValidation.concat(newValidation);
                     }
                     validation['incomingDispatchListNew'] = Yup.array().of(incomingValidation);
                  }
               }
            }
            
            // Incoming courier end


            // Outgoing Courier Start
            if(VALUES.isExchange){
               validationAndFields.requiredField.push("packedBy", "packedDate", "deliveryDate", "deliveryType");
               let arr:any = []
               for(let i=0;i<VALUES.outgoingDispatchListNew.length;i++){
                  let names=[
                     `outgoingDispatchListNew[${i}].packedBy`,
                     `outgoingDispatchListNew[${i}].packedDate`,
                     `outgoingDispatchListNew[${i}].deliveryDate`,
                     `outgoingDispatchListNew[${i}].deliveryType`,
                  ]
                  arr.push(...names)
               }
               validationAndFields.requiredField.push(...arr);
               // validation['packedBy'] = Yup.string().required('Packed by is required');
   
               // validation['packedDate'] = Yup.string().required('Packed date is required');
               // validation['deliveryDate'] = Yup.string().required('Delivery date is required');
               //  validation['deliveryType'] = Yup.string().required('delivery type is required');
               // validationAndFields.requiredField.push("deliveredTo", "deliveredById","deliveredUserType", "approximateValue", "notes");
               // validation['deliveredTo'] = Yup.string().required('Delivered to is required');
               // validation['deliveredById'] = Yup.string().required('Delivered by is required');
               // validation['deliveredUserType'] = Yup.string().required('Delivered by User Type is required');
               // validation['approximateValue'] = Yup.string().required('Approximate value is required').max(6, 'Approximate value must be at most 6 characters');
               // validation['notes'] = Yup.string().required('Notes is required');
               let hasValues = (VALUES.outgoingDispatchListNew[0].packedBy != "" || VALUES.outgoingDispatchListNew[0].packedDate != "" || VALUES.outgoingDispatchListNew[0].deliveryDate != "" || VALUES.outgoingDispatchListNew[0].deliveryType != "" || VALUES.outgoingDispatchListNew[0].deliveredTo != "" || VALUES.outgoingDispatchListNew[0].deliveredById != "" || VALUES.outgoingDispatchListNew[0].deliveredUserType != "" || VALUES.outgoingDispatchListNew[0].approximateValue != "" || VALUES.outgoingDispatchListNew[0].notes != "" || VALUES.outgoingDispatchListNew[0].courierId != ""  )

               if(VALUES.outgoingDispatchList?.length<1 || hasValues){
               validation['outgoingDispatchListNew'] = Yup.array().of(
                  Yup.object().shape({
                     packedBy : Yup.string().required('Packed by is required'),
                     packedDate : Yup.string().required('Packed date is required'),
                     deliveryDate : Yup.string().required('Delivery date is required'),
                     deliveryType : Yup.string().required('delivery type is required'),
                     deliveredTo : Yup.string().required('Delivered to is required'),
                     deliveredById : Yup.string().required('Delivered by is required'),
                     deliveredUserType : Yup.string().required('Delivered by User Type is required'),
                     approximateValue : Yup.string().required('Approximate value is required').max(6, 'Approximate value must be at most 6 characters'),
                     notes : Yup.string().required('Notes is required'),
                  })
               );
            }
            }
            // Outgoing Courier End

         } else {
            setFieldError("warrantyStatus", "");
            setFieldTouched("warrantyStatus", false);

            // changing below 02-09-2024
            // Incoming courier start

            if(!VALUES.isRemoteService){
               setFieldError("isFaultySystemAvailable", "");
               setFieldTouched("isFaultySystemAvailable", false);
   
               setFieldError("incomingDispatchDto_receivedDate", "");
               setFieldTouched("incomingDispatchDto_receivedDate", false);
   
               setFieldError("incomingDispatchDto_receivedId", "");
               setFieldTouched("incomingDispatchDto_receivedId", false);
            }
            
           

            // Incoming courier end

            // Outgoing Courier Start
            if(VALUES.isExchange){
            //    setFieldError("packedBy", "");
            // setFieldTouched("packedBy", false);

            // setFieldError("packedDate", "");
            // setFieldTouched("packedDate", false);

            // setFieldError("deliveryDate", "");
            // setFieldTouched("deliveryDate", false);

            // setFieldError("deliveryType", "");
            // setFieldTouched("deliveryType", false);
			
			
            // setFieldError("deliveredTo", "");
            // setFieldTouched("deliveredTo", false);

            // setFieldError("deliveredById", "");
            // setFieldTouched("deliveredById", false);

            // setFieldError("approximateValue", "");
            // setFieldTouched("approximateValue", false);

            // setFieldError("notes", "");
            // setFieldTouched("notes", false);
            const fieldsToClear = [
               'packedBy',
               'packedDate',
               'deliveryDate',
               'deliveryType',
               'deliveredTo',
               'deliveredById',
               'approximateValue',
               'notes',
               'courierId'
            ];
            
            for (let i = 0; i < VALUES.outgoingDispatchListNew.length; i++) {
               fieldsToClear.forEach((field) => {
                  const fieldName = `outgoingDispatchListNew[${i}].${field}`;
                  setFieldError(fieldName, "");
                  setFieldTouched(fieldName, false);
               });
            }
            }
            // Outgoing Courier End

         }
         break;
      }
      // 6
      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Sheet Created"]] + NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.ASSIGN_ESTIMATOR]): {
         //statements; 

         let modifiedValues = {
            ...VALUES
         };
         modifiedValues['IsAfcsGenerated'] = true;

         if (VALUES.warrantyStatus == "In Warranty") {
            //  change here
            validationAndFields = GET_VALIDATION_AND_FIELD_SHOW(modifiedValues, JOB_SHEET_STATUS[JOB_SHEET_STATUS["Service Request Generated"]], NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.CREATE_JOB], setFieldError, setFieldTouched);
            validationAndFields.nextStatus = "Make Zero estimate for System in warranty";

         } else {
            // call step 4
            validationAndFields = GET_VALIDATION_AND_FIELD_SHOW(modifiedValues, JOB_SHEET_STATUS[JOB_SHEET_STATUS["Service Request Generated"]], NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.CREATE_JOB], setFieldError, setFieldTouched);
            validationAndFields.nextStatus = "Assign Estimator";
         }
         validationAndFields.showTabs.push(JOB_SHEET_TABS.ADDITIONAL_EXPENSES)
         // (24-06-2024)
         validation = validationAndFields.validation;

         //  flow change
         // if(!VALUES.isRemoteService){
            validationAndFields.showTabs.push(JOB_SHEET_TABS.INCOMING_COURIER);
         // }
        

         validationAndFields.requiredField.push("warrantyStatus");
         validationAndFields.disableField.push("customerConcern"); //"isRemoteService"

         if(VALUES.isExchange && !VALUES.isExchangeChangedNow){
            // validationAndFields.disableField.push("isExchange","isRemoteService", "isFaultySystemAvailable");
            validationAndFields.disableField.push("isExchange","isRemoteService");
         }
      
         if (VALUES.warrantyStatus != "In Warranty") {
            if (VALUES.warrantyStatus) {
               validationAndFields.disableField = validationAndFields.disableField.filter((eachField: any) => eachField != "estimatorGUId");
               validationAndFields.requiredField.push("estimatorGUId");
               // validation['estimatorGUId'] = Yup.string().required(`Estimator is required`)
            }
         } else {
            setFieldValue("estimatorGUId", "");
            setFieldError("estimatorGUId", "");
            setFieldTouched("estimatorGUId", false);
         }



         // changing below 02-09-2024

         // Outgoing courier start
         if(VALUES.isExchange){
            validationAndFields.showTabs.push(JOB_SHEET_TABS.OUTGOING_COURIER);
            // validationAndFields.requiredField.push("packedBy", "packedDate", "deliveryDate", "deliveryType", "deliveredTo", "deliveredById","deliveredUserType", "approximateValue", "notes");
            let arr:any = []
            for(let i=0;i<VALUES.outgoingDispatchListNew.length;i++){
               let names=[
                  `outgoingDispatchListNew[${i}].packedBy`,
                  `outgoingDispatchListNew[${i}].packedDate`,
                  `outgoingDispatchListNew[${i}].deliveryDate`,
                  `outgoingDispatchListNew[${i}].deliveryType`,
                  `outgoingDispatchListNew[${i}].deliveredTo`,
                  `outgoingDispatchListNew[${i}].deliveredById`,
                  `outgoingDispatchListNew[${i}].deliveredUserType`,
                  `outgoingDispatchListNew[${i}].approximateValue`,
                  `outgoingDispatchListNew[${i}].notes`,
               ]
               arr.push(...names)
            }
            validationAndFields.requiredField.push(...arr);
               // if (VALUES.deliveryType == 'Hand Carry') {
               //    setFieldValue('courierId', "");
               //    validationAndFields.disableField.push('courierId');
               //    setFieldValue('courierId', "");
               // }
   
               // if (VALUES.deliveryType == 'Courier') {
               //    validationAndFields.requiredField.push('courierId');
               //    validation['courierId'] = Yup.string().required('Courier is required');
               // }
               for (let i = 0; i < VALUES.outgoingDispatchListNew.length; i++) {
                  const deliveryTypeField = `outgoingDispatchListNew[${i}].deliveryType`;
                  const courierIdField = `outgoingDispatchListNew[${i}].courierId`;
            
                     if (VALUES.outgoingDispatchListNew[i].deliveryType === 'Hand Carry') {
                        setFieldValue(courierIdField, "");
                        validationAndFields.disableField.push(courierIdField);
                        validationAndFields.requiredField = validationAndFields.requiredField.filter(field => field !== courierIdField);
                     }
            
                     if (VALUES.outgoingDispatchListNew[i].deliveryType === 'Courier') {
                        validationAndFields.requiredField.push(courierIdField);
                        // validation[courierIdField] = Yup.string().required('Courier is required');
                     }
                  
               }
         }
         // Outgoing courier end

         // Incoming courier start
         if(!VALUES.isRemoteService){
            
            // validation['courierAmount'] = Yup.string().matches(/^[1-9][0-9]*$/, 'Courier amount must be a positive integer');
            // validation['courierChequeNumber'] = Yup.string().matches(/^[a-zA-Z0-9].*$/, "Courier cheque number must be valid");
            if (VALUES.incomingDispatchListNew[0]?.jobReceivedMode == 'Hand Carry') {
               // validationAndFields.hideField?.push("courierReceivedAt", "courierPackingCondition", "courierAmount",
               //    "courierChequeNumber", "courierBankName", "incomingDispatchDto_courierId");
               // setFieldValue("courierReceivedAt", "");
               // setFieldValue("courierPackingCondition", "");
               // setFieldValue("courierAmount", "");
               // setFieldValue("courierChequeNumber", "");
               // setFieldValue("courierBankName", "");
               // setFieldValue("incomingDispatchDto_courierId", "");
               const fieldsToClear = [
                  'courierReceivedAt',
                  'courierPackingCondition',
                  'courierAmount',
                  'courierChequeNumber',
                  'courierBankName',
                  'courierId',
               ];
               
               for (let i = 0; i < VALUES.incomingDispatchListNew.length; i++) {
                  fieldsToClear.forEach((field) => {
                     const fieldName = `incomingDispatchListNew[${i}].${field}`;
                     setFieldValue(fieldName, "");
                     validationAndFields?.hideField?.push(fieldName)
                  });
               }
            }
           if (VALUES.incomingDispatchListNew[0]?.jobReceivedMode == 'Paid' || VALUES.incomingDispatchListNew[0]?.jobReceivedMode == 'To Pay') {
               if (VALUES?.incomingDispatchListNew?.[0].jobReceivedMode != "" && VALUES.IsAfcsGenerated) {
                  validationAndFields.requiredField.push(`incomingDispatchListNew[0].courierId`);
                  // validation['incomingDispatchDto_courierId'] = Yup.string().required(`Courier is required`);
                  if(!(VALUES.incomingDispatchList?.length>0) || hasIncomingCourierValues(VALUES.incomingDispatchListNew[0])){
                     let incomingValidation = Yup.object().shape({
                        receivedDate:  Yup.string().required(`Received date is required`),
                        receivedId: Yup.string().required(`Received by is required`),
                        jobReceivedMode:  Yup.string().required(`Job received mode is required`),
                     });
                     if(VALUES.incomingDispatchListNew[0]?.jobReceivedMode == "Paid" || VALUES.incomingDispatchListNew[0]?.jobReceivedMode == "To Pay"){
                        let newValidation = Yup.object().shape({
                           courierId: Yup.string().required(`Courier is required`),
                           courierAmount: Yup.string().matches(/^[1-9][0-9]*$/, 'Courier amount must be a positive integer'),
                           courierChequeNumber: Yup.string().matches(/^[a-zA-Z0-9].*$/, "Courier cheque number must be valid"),
                        });
                        incomingValidation = incomingValidation.concat(newValidation);
                     }
                     validation['incomingDispatchListNew'] = Yup.array().of(incomingValidation);
                  }

               }

               const fieldsToClear = [
                  'handCarriedPersonName',
                  'handCarriedPersonDesignation',
               ];
               
               for (let i = 0; i < VALUES.incomingDispatchListNew.length; i++) {
                  fieldsToClear.forEach((field) => {
                     const fieldName = `incomingDispatchListNew[${i}].${field}`;
                     setFieldValue(fieldName, "");
                     validationAndFields?.hideField?.push(fieldName)
                  });
               }
               // validationAndFields.hideField?.push("handCarriedPersonName", "handCarriedPersonDesignation");
               // setFieldValue("handCarriedPersonName", "");
               // setFieldValue("handCarriedPersonDesignation", "");
   
            }
            if((!(VALUES.incomingDispatchList?.length>0) && VALUES.IsAfcsGenerated) || hasIncomingCourierValues(VALUES.incomingDispatchListNew[0])){
               let incomingValidation = Yup.object().shape({
                  receivedDate:  Yup.string().required(`Received date is required`),
                  receivedId: Yup.string().required(`Received by is required`),
                  jobReceivedMode:  Yup.string().required(`Job received mode is required`),
               });
               if(VALUES.incomingDispatchListNew[0]?.jobReceivedMode == "Paid" || VALUES.incomingDispatchListNew[0]?.jobReceivedMode == "To Pay"){
                  let newValidation = Yup.object().shape({
                     courierId: Yup.string().required(`Courier is required`),
                     courierAmount: Yup.string().matches(/^[1-9][0-9]*$/, 'Courier amount must be a positive integer'),
                     courierChequeNumber: Yup.string().matches(/^[a-zA-Z0-9].*$/, "Courier cheque number must be valid"),
                  });
                  incomingValidation = incomingValidation.concat(newValidation);
               }
               validation['incomingDispatchListNew'] = Yup.array().of(incomingValidation);
            }
         }
        
         // validationAndFields.requiredField.push("incomingDispatchDto_receivedDate",
         //             "incomingDispatchDto_receivedId", "jobReceivedMode");
         // Incoming courier end

         const fieldsToClear = [
            'receivedDate',
            'receivedId',
            'jobReceivedMode',
         ];
         
         for (let i = 0; i < VALUES.incomingDispatchListNew.length; i++) {
            fieldsToClear.forEach((field) => {
               const fieldName = `incomingDispatchListNew[${i}].${field}`;
               validationAndFields.requiredField.push(fieldName);
            });
         }
         if (VALUES.IsAfcsGenerated) {

            // validationAndFields.requiredField.push("warrantyStatus"); (24-06-2024)
            validation['warrantyStatus'] = Yup.string().required(`Warranty status is required`);

            if (VALUES.warrantyStatus != "In Warranty") {
               if (VALUES.warrantyStatus) {
                  validationAndFields.disableField = validationAndFields.disableField.filter((eachField: any) => eachField != "estimatorGUId");
                  validationAndFields.requiredField.push("estimatorGUId");
                  validation['estimatorGUId'] = Yup.string().required(`Estimator is required`);
               }
            } else {
               setFieldValue("estimatorGUId", "");
               setFieldError("estimatorGUId", "");
               setFieldTouched("estimatorGUId", false);
            }
            validationAndFields.url = updateAssignToEstimateJob;

             // changing below 02-09-2024
            // Incoming courier start
            if(!VALUES.isRemoteService){
               if (VALUES.isExchange) {
                  // validationAndFields.requiredField.push("incomingDispatchDto_receivedDate",
                  //    "incomingDispatchDto_receivedId", "jobReceivedMode", "isFaultySystemAvailable");
                  // validationAndFields.requiredField.push("incomingDispatchDto_receivedDate",
                  //    "incomingDispatchDto_receivedId", "jobReceivedMode");
                     const fieldsToClear = [
                        'receivedDate',
                        'receivedId',
                        'jobReceivedMode',
                     ];
                     
                     for (let i = 0; i < VALUES.incomingDispatchListNew.length; i++) {
                        fieldsToClear.forEach((field) => {
                           const fieldName = `incomingDispatchListNew[${i}].${field}`;
                           validationAndFields.requiredField.push(fieldName);
                        });
                     }
                  validationAndFields.disableField = validationAndFields.disableField.filter((eachValue: any) => {
                     return eachValue != "incomingDispatchDto_receivedDate" && eachValue != "incomingDispatchDto_receivedId" && eachValue != "incomingDispatchDto_notes" && eachValue != "incomingDispatchDto_courierId";
                  });
                  validation['incomingDispatchDto_receivedDate'] = Yup.string().required(`Received date is required`);
                  validation['incomingDispatchDto_receivedId'] = Yup.string().required(`Received by is required`);
                  validation['jobReceivedMode'] = Yup.string().required(`Job received mode is required`);
                  // validation['isFaultySystemAvailable'] = Yup.boolean().required().oneOf([true], 'Faulty system must be available');
               } else {
                  // validationAndFields.requiredField.push("incomingDispatchDto_receivedDate", "incomingDispatchDto_receivedId", "jobReceivedMode");
                  const fieldsToClear = [
                     'receivedDate',
                     'receivedId',
                     'jobReceivedMode',
                  ];
                  
                  for (let i = 0; i < VALUES.incomingDispatchListNew.length; i++) {
                     fieldsToClear.forEach((field) => {
                        const fieldName = `incomingDispatchListNew[${i}].${field}`;
                        validationAndFields.requiredField.push(fieldName);
                     });
                  }
                  // validationAndFields.disableField = validationAndFields.disableField.filter((eachValue: any) => {
                  //    return eachValue != "incomingDispatchDto_receivedDate" && eachValue != "incomingDispatchDto_receivedId" && eachValue != "incomingDispatchDto_notes" && eachValue != "incomingDispatchDto_courierId";
                  // });
                  // validationAndFields.disableField = validationAndFields.disableField.filter((eachValue: any) => {
                  //    return eachValue != `incomingDispatchListNew[0].receivedDate` && eachValue != `incomingDispatchListNew[0].receivedId` && eachValue != `incomingDispatchListNew[0].notes` && eachValue != `incomingDispatchListNew[0].courierId`;
                  // });

                   if(!(VALUES.incomingDispatchList?.length>0) || hasIncomingCourierValues(VALUES.incomingDispatchListNew[0])){
                     let incomingValidation = Yup.object().shape({
                        receivedDate:  Yup.string().required(`Received date is required`),
                        receivedId: Yup.string().required(`Received by is required`),
                        jobReceivedMode:  Yup.string().required(`Job received mode is required`),
                     });
                     if(VALUES.incomingDispatchListNew[0]?.jobReceivedMode == "Paid" || VALUES.incomingDispatchListNew[0]?.jobReceivedMode == "To Pay"){
                        let newValidation = Yup.object().shape({
                           courierId: Yup.string().required(`Courier is required`),
                           courierAmount: Yup.string().matches(/^[1-9][0-9]*$/, 'Courier amount must be a positive integer'),
                           courierChequeNumber: Yup.string().matches(/^[a-zA-Z0-9].*$/, "Courier cheque number must be valid"),
                        });
                        incomingValidation = incomingValidation.concat(newValidation);
                     }
                     validation['incomingDispatchListNew'] = Yup.array().of(incomingValidation);
                  }
               }
            }
            
            // Incoming courier end

            // Outgoing courier start
            if(VALUES.isExchange){
               validationAndFields.requiredField.push("packedBy", "packedDate", "deliveryDate", "deliveryType");
               validationAndFields.requiredField.push("deliveredTo", "deliveredById","deliveredUserType", "approximateValue", "notes");
               let arr:any = []
               for(let i=0;i<VALUES.outgoingDispatchListNew.length;i++){
                  let names=[
                     `outgoingDispatchListNew[${i}].packedBy`,
                     `outgoingDispatchListNew[${i}].packedDate`,
                     `outgoingDispatchListNew[${i}].deliveryDate`,
                     `outgoingDispatchListNew[${i}].deliveryType`,
                     `outgoingDispatchListNew[${i}].deliveredTo`,
                     `outgoingDispatchListNew[${i}].deliveredById`,
                     `outgoingDispatchListNew[${i}].deliveredUserType`,
                     `outgoingDispatchListNew[${i}].approximateValue`,
                     `outgoingDispatchListNew[${i}].notes`,
                  ]
                  arr.push(...names)
               }
               // validationAndFields.requiredField.push("packedBy", "packedDate", "deliveryDate", "deliveryType", "courierId", "notes", "deliveredTo", "deliveredById");
               validationAndFields.requiredField.push(...arr);

               // validation['packedBy'] = Yup.string().required('Packed by is required');
               // validation['packedDate'] = Yup.string().required('Packed date is required');
               // validation['deliveryDate'] = Yup.string().required('Delivery date is required');
               //  validation['deliveryType'] = Yup.string().required('delivery type is required');
               // validation['deliveredTo'] = Yup.string().required('Delivered to is required');
               // validation['deliveredById'] = Yup.string().required('Delivered by is required');
               // validation['deliveredUserType'] = Yup.string().required('Delivered by User Type is required');
               // validation['approximateValue'] = Yup.string().required('Approximate value is required').max(6, 'Approximate value must be at most 6 characters');
               // validation['notes'] = Yup.string().required('Notes is required');
               let hasValues = (VALUES.outgoingDispatchListNew[0].packedBy != "" || VALUES.outgoingDispatchListNew[0].packedDate != "" || VALUES.outgoingDispatchListNew[0].deliveryDate != "" || VALUES.outgoingDispatchListNew[0].deliveryType != "" || VALUES.outgoingDispatchListNew[0].deliveredTo != "" || VALUES.outgoingDispatchListNew[0].deliveredById != "" || VALUES.outgoingDispatchListNew[0].deliveredUserType != "" || VALUES.outgoingDispatchListNew[0].approximateValue != "" || VALUES.outgoingDispatchListNew[0].notes != "" || VALUES.outgoingDispatchListNew[0].courierId != ""  )
               
               if(VALUES.outgoingDispatchList?.length<1 || hasValues){
               validation['outgoingDispatchListNew'] = Yup.array().of(
                  Yup.object().shape({
                     packedBy : Yup.string().required('Packed by is required'),
                     packedDate : Yup.string().required('Packed date is required'),
                     deliveryDate : Yup.string().required('Delivery date is required'),
                     deliveryType : Yup.string().required('delivery type is required'),
                     deliveredTo : Yup.string().required('Delivered to is required'),
                     deliveredById : Yup.string().required('Delivered by is required'),
                     deliveredUserType : Yup.string().required('Delivered by User Type is required'),
                     approximateValue : Yup.string().required('Approximate value is required').max(6, 'Approximate value must be at most 6 characters'),
                     notes : Yup.string().required('Notes is required'),
                     courierId: Yup.string().test(
                        'courier-required-if-not-hand-carried',
                        'Courier info required',
                        function (value) {
                           
                          const { deliveryType } = this.parent;
                          return deliveryType === 'Hand Carry' || !!value;
                        }
                      ),
                  })
               );
            }
            }
            // Outgoing courier end

         } else {
            validationAndFields.url = updateAssignToEstimateJob;
            // setFieldValue("estimatorGUId", "")
            setFieldError("estimatorGUId", "");
            setFieldTouched("estimatorGUId", false);
            setFieldError("warrantyStatus", "");
            setFieldTouched("warrantyStatus", false);

            
            // changing below 02-09-2024
            // Incoming courier start
            if(!VALUES.isRemoteService){
               setFieldError("isFaultySystemAvailable", "");
               setFieldTouched("isFaultySystemAvailable", false);

               setFieldError("incomingDispatchDto_receivedDate", "");
               setFieldTouched("incomingDispatchDto_receivedDate", false);

               setFieldError("incomingDispatchDto_receivedId", "");
               setFieldTouched("incomingDispatchDto_receivedId", false);
            }  
            

            // Incoming courier end

            // Outgoing courier start
            // setFieldError("packedBy", "");
            // setFieldTouched("packedBy", false);

            // setFieldError("packedDate", "");
            // setFieldTouched("packedDate", false);

            // setFieldError("deliveryDate", "");
            // setFieldTouched("deliveryDate", false);

            // setFieldError("deliveryType", "");
            // setFieldTouched("deliveryType", false);
			
			
            // setFieldError("deliveredTo", "");
            // setFieldTouched("deliveredTo", false);

            // setFieldError("deliveredById", "");
            // setFieldTouched("deliveredById", false);

            // setFieldError("approximateValue", "");
            // setFieldTouched("approximateValue", false);

            // setFieldError("notes", "");
            // setFieldTouched("notes", false);

            const fieldsToClear = [
               'packedBy',
               'packedDate',
               'deliveryDate',
               'deliveryType',
               'deliveredTo',
               'deliveredById',
               'approximateValue',
               'notes',
               'courierId'
            ];
            
            for (let i = 0; i < VALUES.outgoingDispatchListNew.length; i++) {
               fieldsToClear.forEach((field) => {
                  const fieldName = `outgoingDispatchListNew[${i}].${field}`;
                  setFieldError(fieldName, "");
                  setFieldTouched(fieldName, false);
               });
            }
            // Outgoing courier end

         }
         break;
      }
      // 7
      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["Assigned To Estimator"]] + NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.ESTIMATE_COMPLETE]): {

         let modifiedValues = {
            ...VALUES
         };
         modifiedValues['IsAfcsGenerated'] = true;
         validationAndFields = GET_VALIDATION_AND_FIELD_SHOW(modifiedValues, JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Sheet Created"]], NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.ASSIGN_ESTIMATOR], setFieldError, setFieldTouched);
         validation = validationAndFields.validation;
         validationAndFields.disableField?.push("approveStatusShowFiled", "inspectionStatus", "warrantyStatus");

         //  this code present whenever warrantyStatus is disabled
         if(VALUES.warrantyStatus != "In Warranty"){
            validationAndFields.disableField?.push("isExchange", "isRemoteService", "isFaultySystemAvailable");
         }


         // validationAndFields.disableField?.push("approveStatusShowFiled", "inspectionStatus", "warrantyStatus", "isExchange");
         validationAndFields.showTabs?.push(JOB_SHEET_TABS.ESTIMATE_DETAILS);
         validationAndFields.disableField?.push("updatedByName", "estimateTotal");

         validationAndFields.requiredField?.push("estimatorComments"); //(24-06-2024)
         validationAndFields.showTabs.push(JOB_SHEET_TABS.ADDITIONAL_EXPENSES)
         if (VALUES?.partsDetails?.length > 0) {
            validation['partsDetails'] = Yup.array().of(
               Yup.object().shape({
                  partId: Yup.string().required('Part is required').test("is-unique", 'Part name must be unique', function (value) {
                     let test: any = this.options?.context?.partsDetails?.filter((detail: any) => detail?.partId == value).length;
                     return test == 1;
                  }),
                  quantityRequired: Yup.string()
                     .required('Quantity is required')
                     .matches(/^[1-9][0-9]*$/, 'Quantity required must be a positive integer'),
                  unitPrice: Yup.string().required('Unit price is required').matches(/^\d*\.?\d+$/, 'Unit Price must be a positive integer'),
               })
            );
         }

         if (VALUES?.taxDetails?.length > 0) {
            validation['taxDetails'] = Yup.array().of(
               Yup.object().shape({
                  name: Yup.string().required('Name is required').test("is-unique", 'Tax name must be unique', function (value) {
                     let test: any = this.options?.context?.taxDetails?.filter((detail: any) => detail?.name === value).length;
                     return test == 1;
                  }),
                  sacNumber: Yup.string().required('Sac number is required'),
                  amount: Yup.number()
                     .required('Amount is required')
                     .min(0, 'Amount must be valid'),
                  // .positive('Amount must be greater than 0'),
                  taxPercentage: Yup.string().required('Tax percentage is required'),
               })
            );
         }

         if (VALUES.IsAfcsGenerated) {
            validationAndFields.requiredField?.push("estimatorComments", "taxTypeId");
            validation['estimatorComments'] = Yup.string().required('Estimator comments is required');
            validationAndFields.requiredField?.push("taxTypeId");
            validation["taxTypeId"] = Yup.string().required("Tax type field is required");
            validationAndFields.url = updateEstimateCompleteJob;
         } else {
            setFieldError("estimatorComments", "");
            setFieldTouched("estimatorComments", false);
            setFieldError("taxTypeId", "");
            setFieldTouched("taxTypeId", false);
            validationAndFields.url = updateEstimateCompleteJob;
         }
         validationAndFields.nextStatus = "Estimate Complete";
         break;
      }
      // 8
      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Estimated Awaiting Approval"]] + NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.MARK_AS_APPROVED_REJECTED]): {
         //statements; 

         let modifiedValues = {
            ...VALUES
         };
         modifiedValues['IsAfcsGenerated'] = true;
         validationAndFields = GET_VALIDATION_AND_FIELD_SHOW(modifiedValues, JOB_SHEET_STATUS[JOB_SHEET_STATUS["Assigned To Estimator"]], NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.ESTIMATE_COMPLETE], setFieldError, setFieldTouched);
         validation = validationAndFields.validation;
         validationAndFields.url = updateEstimateApproveOrRejectJob;
         validationAndFields.nextStatus = "Mark as Approved / Rejected";

         if (hasCustomerRoleHandler() || hasDealerRoleHandler() || hasDealerSubuserRoleHandler()) {
            validationAndFields.showTabs = validationAndFields.showTabs.filter((eachTabs) => eachTabs != JOB_SHEET_TABS.INCOMING_COURIER && eachTabs != JOB_SHEET_TABS.PENDING_COMMENTS);
            validationAndFields.disableField?.push("estimatorComments", "newParts", "newTaxes", "approveStatusEnableField");
            validationAndFields.disableField = validationAndFields.disableField?.filter((eachValue: any) => eachValue != "manufacturedDate");
         } else {
            validationAndFields.disableField?.push("estimatorComments", "approveStatusEnableField");
         }
         if (hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler()) {
            validationAndFields.disableField?.push("approveStatus_document");
         }
         if(hasCustomerRoleHandler()){

            validationAndFields.showTabs = validationAndFields.showTabs.filter((data:any)=> data != JOB_SHEET_TABS.ADDITIONAL_EXPENSES)
         }else{
            validationAndFields.showTabs.push(JOB_SHEET_TABS.ADDITIONAL_EXPENSES)
         }

         validationAndFields.disableField = validationAndFields.disableField?.filter((eachValue: any) => eachValue != "approveStatusShowFiled");
         if (VALUES.IsAfcsGenerated || VALUES.approveStatus == APPROVE || VALUES.approveStatus == REJECT) {
            if (hasCustomerRoleHandler() || hasDealerRoleHandler()) {
               validationAndFields.disableField.push("approvalMode", "newParts", "newTaxes");
               validationAndFields.disableField = validationAndFields.disableField?.filter((eachValue: any) => eachValue != "approveStatus");
               validationAndFields.disableField = validationAndFields.disableField?.filter((eachValue: any) => eachValue != "approveStatusEnableField");
            } else {
               validationAndFields.disableField = validationAndFields.disableField?.filter((eachValue: any) => eachValue != "approveStatus" && eachValue != "newParts" && eachValue != "newTaxes");
               validationAndFields.disableField = validationAndFields.disableField?.filter((eachValue: any) => eachValue != "approveStatusEnableField");
            }
            validationAndFields.requiredField.push("approvalMode");
            validationAndFields.requiredField.push("approvalComments");
            validation['approvalMode'] = Yup.string().required(`Approval mode is required`);
            validation['approvalComments'] = Yup.string().required(`Approval comments is required`);
            validation['approveStatus'] = Yup.string().test('is-selected', 'Estimate is awaiting Approval/Rejection', (value) => {
               return !!value;
            });
            setFieldValue("IsAfcsGenerated", true);
         } else {
            // setFieldError("approvalMode", "")
            // setFieldTouched("approvalMode", false)


            // setFieldValue("approveStatus", "")
            // setFieldValue("approvalMode", "")
            // setFieldValue("approvalComments", "")

            // setFieldError("approvalComments", "")
            // setFieldTouched("approvalComments", false)
            // setFieldError("approveStatus", "")
            // setFieldTouched("approveStatus", false)
         }

         if (hasCustomerRoleHandler() || hasDealerRoleHandler()) {
            validationAndFields.disableField.push("dealerId", "vehicleBrandId", "permitFormReceivedDate", "estimatorGUId", "warrantyStatus",
               "vinNumber", "vehicleRegistrationNumber", "carModelId", "audioModel", "exchangeAudioSerialNumber", "audioSerialNumber",
               "audioPartNumber", "dateOfSale", "lastRepairDate", "kmsCovered", "customerConcern", "accessoriesList", "missingAccessories", "additionalRemarks", "jobReceivedMode", "handCarriedPersonName", "handCarriedPersonDesignation", "courierReceivedAt", "courierPackingCondition", "courierAmount", "courierChequeNumber", "courierBankName", "incomingDispatchDto_receivedDate", "incomingDispatchDto_receivedId", "incomingDispatchDto_courierId", "incomingDispatchDto_notes");

               //  this code present whenever warrantyStatus is disabled
            if(VALUES.warrantyStatus != "In Warranty"){
               validationAndFields.disableField?.push("isExchange", "isRemoteService", "isFaultySystemAvailable");
            }
         }
         break;
      }

      // 13
      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["Estimation Rejected"]] + NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.CANCEL_JOB]): {
         //statements; 

         let modifiedValues = {
            ...VALUES
         };
         modifiedValues['IsAfcsGenerated'] = true;
         validationAndFields = GET_VALIDATION_AND_FIELD_SHOW(modifiedValues, JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Estimated Awaiting Approval"]], NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.MARK_AS_APPROVED_REJECTED], setFieldError, setFieldTouched);
         validationAndFields.disableField.push('approveStatusShowFiled', 'approveStatusEnableField', 'approveStatus', 'newParts', 'newTaxes');
         validationAndFields.requiredField = validationAndFields.requiredField.filter((eachFiled: string) => {
            return eachFiled != "approveStatus" && eachFiled != "approvalComments" && eachFiled != "approvalMode";
         });
         validationAndFields.showTabs.push(JOB_SHEET_TABS.ADDITIONAL_EXPENSES)
         validation = validationAndFields.validation;
         validation['approveStatus'] = "";
         validation['approvalComments'] = "";
         validation['approvalMode'] = "";
         validationAndFields.url = updateCancelJob;
         validationAndFields.nextStatus = "Cancel Jobsheet";

         break;
      }

      // 9
      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Assignable To Technician"]] + NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.ASSIGNED_TO_TECHNICIAN]): {
         let modifiedValues = {
            ...VALUES
         };
         modifiedValues['IsAfcsGenerated'] = false;  // change now to true before it is false
         if (modifiedValues.warrantyStatus == "In Warranty") {

            validationAndFields = GET_VALIDATION_AND_FIELD_SHOW(modifiedValues, JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Sheet Created"]], NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.MAKE_ZERO_ESTIMATE_FOR_SYSTEM_IN_WARRANTY], setFieldError, setFieldTouched);
         } else {
            validationAndFields = GET_VALIDATION_AND_FIELD_SHOW(modifiedValues, JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Estimated Awaiting Approval"]], NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.MARK_AS_APPROVED_REJECTED], setFieldError, setFieldTouched);
         }

         validation = validationAndFields.validation;
         validationAndFields.showTabs = validationAndFields.showTabs.filter((eachTab: any) => {
            return eachTab != JOB_SHEET_TABS.ESTIMATE_DETAILS;
         });
         validationAndFields.showTabs.push(JOB_SHEET_TABS.ADDITIONAL_EXPENSES)
         validationAndFields.disableField.push("warrantyStatus");

         //  this code present whenever warrantyStatus is disabled
         if(VALUES.warrantyStatus != "In Warranty"){
            validationAndFields.disableField?.push("isExchange", "isRemoteService", "isFaultySystemAvailable");
         }

         validationAndFields.requiredField.push('technicianGUId');
         if (VALUES?.isFranchise == true) {
            // validationAndFields.requiredField.push('franchiseNotes', 'appointmentTime', "serviceLocation");
            validationAndFields.requiredField.push( "serviceLocation");
            validationAndFields.disableField = validationAndFields.disableField.filter((eachValue) => eachValue != 'franchiseNotes' && eachValue != 'appointmentTime');
            validation['serviceLocation'] = Yup.string().required(`Service Location is required`);
         } else {
            validationAndFields.requiredField = validationAndFields.requiredField.filter((eachField) => eachField != "franchiseNotes" && eachField != "appointmentTime");  //&& eachField != "customerId" && eachField != "customerAddress"
            setFieldError("appointmentTime", "");
            setFieldTouched("appointmentTime", false);
            setFieldError("franchiseNotes", "");
            setFieldTouched("franchiseNotes", false);
            setFieldError("serviceLocation", "");
            setFieldTouched("serviceLocation", false);
            validationAndFields.disableField.push("franchiseNotes", "appointmentTime");  //"customerId", "customerAddress"
         }
         validationAndFields.disableField = validationAndFields.disableField.filter((eachValue) => eachValue != 'technicianGUId');
         validationAndFields.url = updateAssignToTechnicianJob;
         validationAndFields.nextStatus = "Assigned To Technician";

         if (VALUES.IsAfcsGenerated) {
            if (VALUES.isFranchise) {
               if (VALUES?.franchiseNotes == "" && VALUES?.appointmentTime == "") {
                  // validationAndFields.requiredField.push('franchiseNotes', 'appointmentTime', 'serviceLocation');
                  validationAndFields.requiredField.push('serviceLocation');
                  validationAndFields.disableField = validationAndFields.disableField.filter((eachValue) => eachValue != 'technicianGUId' && eachValue != 'franchiseNotes' && eachValue != 'appointmentTime' && eachValue != 'serviceLocation');
                  // validation['appointmentTime'] = Yup.string().required(`Appointment Time is required`);
                  // validation['franchiseNotes'] = Yup.string().required(`Franchise Notes is required`);
                  validation['serviceLocation'] = Yup.string().required(`Service Location is required`);
               }
            }
            validationAndFields.requiredField.push('technicianGUId');
            validationAndFields.disableField = validationAndFields.disableField.filter((eachValue) => eachValue != 'technicianGUId');
            validation['technicianGUId'] = Yup.string().required(`Technician is required`);


            // write Job Sheet Created  state next state code here start

            validationAndFields.requiredField.push("warrantyStatus");
            validation['warrantyStatus'] = Yup.string().required(`Warranty status is required`);
            ;
            // changing below 02-09-2024
            // Incoming courier start
            if(!VALUES.isRemoteService){
               if (VALUES.isExchange) {
               ;
               // validationAndFields.requiredField.push("incomingDispatchDto_receivedDate",
               //    "incomingDispatchDto_receivedId", "jobReceivedMode", "isFaultySystemAvailable");
               // validationAndFields.requiredField.push("incomingDispatchDto_receivedDate",
               //    "incomingDispatchDto_receivedId", "jobReceivedMode");
                  const requiredFields = [
                     'receivedDate',
                     'receivedId',
                     'jobReceivedMode',
                  ];
                  
                  for (let i = 0; i < VALUES.incomingDispatchListNew.length; i++) {
                     requiredFields.forEach((field) => {
                        const fieldName = `incomingDispatchListNew[${i}].${field}`;
                        validationAndFields.requiredField.push(fieldName);
                     });
                  }
               // validationAndFields.disableField = validationAndFields.disableField.filter((eachValue: any) => {
               //    return eachValue != "incomingDispatchDto_receivedDate" && eachValue != "incomingDispatchDto_receivedId" && eachValue != "incomingDispatchDto_notes" && eachValue != "incomingDispatchDto_courierId";
               // });
               // validationAndFields.disableField = validationAndFields.disableField.filter((eachValue: any) => {
               //    return eachValue != `incomingDispatchListNew[0].receivedDate` && eachValue != `incomingDispatchListNew[0].receivedId` && eachValue != `incomingDispatchListNew[0].notes` && eachValue != `incomingDispatchListNew[0].courierId`;
               // });
               // validation['incomingDispatchDto_receivedDate'] = Yup.string().required(`Received date is required`);
               // validation['incomingDispatchDto_receivedId'] = Yup.string().required(`Received by is required`);
               // validation['jobReceivedMode'] = Yup.string().required(`Job received mode is required`);
               // validation['isFaultySystemAvailable'] = Yup.boolean().required().oneOf([true], 'Faulty system must be available');
               if(!(VALUES.incomingDispatchList?.length>0) || hasIncomingCourierValues(VALUES.incomingDispatchListNew[0])){
                  let incomingValidation = Yup.object().shape({
                     receivedDate:  Yup.string().required(`Received date is required`),
                     receivedId: Yup.string().required(`Received by is required`),
                     jobReceivedMode:  Yup.string().required(`Job received mode is required`),
                  });
                  if(VALUES.incomingDispatchListNew[0]?.jobReceivedMode == "Paid" || VALUES.incomingDispatchListNew[0]?.jobReceivedMode == "To Pay"){
                     let newValidation = Yup.object().shape({
                        courierId: Yup.string().required(`Courier is required`),
                        courierAmount: Yup.string().matches(/^[1-9][0-9]*$/, 'Courier amount must be a positive integer'),
                        courierChequeNumber: Yup.string().matches(/^[a-zA-Z0-9].*$/, "Courier cheque number must be valid"),
                     });
                     incomingValidation = incomingValidation.concat(newValidation);
                  }
                  validation['incomingDispatchListNew'] = Yup.array().of(incomingValidation);
               }
               } else {
               // validationAndFields.requiredField.push("incomingDispatchDto_receivedDate", "incomingDispatchDto_receivedId", "jobReceivedMode");
               // validationAndFields.disableField = validationAndFields.disableField.filter((eachValue: any) => {
               //    return eachValue != "incomingDispatchDto_receivedDate" && eachValue != "incomingDispatchDto_receivedId" && eachValue != "incomingDispatchDto_notes" && eachValue != "incomingDispatchDto_courierId"
               // });
               // validation['incomingDispatchDto_receivedDate'] = Yup.string().required(`Received date is required`);
               // validation['incomingDispatchDto_receivedId'] = Yup.string().required(`Received by is required`);
               // validation['jobReceivedMode'] = Yup.string().required(`Job received mode is required`);
               const requiredFields = [
                  'receivedDate',
                  'receivedId',
                  'jobReceivedMode',
               ];
               
               for (let i = 0; i < VALUES.incomingDispatchListNew.length; i++) {
                  requiredFields.forEach((field) => {
                     const fieldName = `incomingDispatchListNew[${i}].${field}`;
                     validationAndFields.requiredField.push(fieldName);
                  });
               }
               // validationAndFields.disableField = validationAndFields.disableField.filter((eachValue: any) => {
               //    return eachValue != `incomingDispatchListNew[0].receivedDate` && eachValue != `incomingDispatchListNew[0].receivedId` && eachValue != `incomingDispatchListNew[0].notes` && eachValue != `incomingDispatchListNew[0].courierId`;
               // });
               if(!(VALUES.incomingDispatchList?.length>0) || hasIncomingCourierValues(VALUES.incomingDispatchListNew[0])){
                  let incomingValidation = Yup.object().shape({
                     receivedDate:  Yup.string().required(`Received date is required`),
                     receivedId: Yup.string().required(`Received by is required`),
                     jobReceivedMode:  Yup.string().required(`Job received mode is required`),
                  });
                  if(VALUES.incomingDispatchListNew[0]?.jobReceivedMode == "Paid" || VALUES.incomingDispatchListNew[0]?.jobReceivedMode == "To Pay"){
                     let newValidation = Yup.object().shape({
                        courierId: Yup.string().required(`Courier is required`),
                        courierAmount: Yup.string().matches(/^[1-9][0-9]*$/, 'Courier amount must be a positive integer'),
                        courierChequeNumber: Yup.string().matches(/^[a-zA-Z0-9].*$/, "Courier cheque number must be valid"),
                     });
                     incomingValidation = incomingValidation.concat(newValidation);
                  }
                  validation['incomingDispatchListNew'] = Yup.array().of(incomingValidation);
               }
               }
            }

            // Incoming courier end


            // Outgoing Courier Start
            if(VALUES.isExchange){
               // validationAndFields.requiredField.push("packedBy", "packedDate", "deliveryDate", "deliveryType");
               // validationAndFields.requiredField.push("deliveredTo", "deliveredById","deliveredUserType", "approximateValue", "notes");
               let arr:any = []
               for(let i=0;i<VALUES.outgoingDispatchListNew.length;i++){
                  let names=[
                     `outgoingDispatchListNew[${i}].packedBy`,
                     `outgoingDispatchListNew[${i}].packedDate`,
                     `outgoingDispatchListNew[${i}].deliveryDate`,
                     `outgoingDispatchListNew[${i}].deliveryType`,
                     `outgoingDispatchListNew[${i}].deliveredTo`,
                     `outgoingDispatchListNew[${i}].deliveredById`,
                     `outgoingDispatchListNew[${i}].deliveredUserType`,
                     `outgoingDispatchListNew[${i}].approximateValue`,
                     `outgoingDispatchListNew[${i}].notes`,
                  ]
                  arr.push(...names)
               }
               validationAndFields.requiredField.push(...arr);


               // validation['isFaultySystemAvailable'] = Yup.boolean().required().oneOf([true], 'Faulty system must be available');

               // validation['packedBy'] = Yup.string().required('Packed by is required');
               // validation['packedDate'] = Yup.string().required('Packed date is required');
               // validation['deliveryDate'] = Yup.string().required('Delivery date is required');
               // validation['deliveryType'] = Yup.string().required('delivery type is required');
               // validation['deliveredTo'] = Yup.string().required('Delivered to is required');
               // validation['deliveredById'] = Yup.string().required('Delivered by is required');
               // validation['deliveredUserType'] = Yup.string().required('Delivered by User Type is required');
               // validation['approximateValue'] = Yup.string().required('Approximate value is required').max(6, 'Approximate value must be at most 6 characters');
               // validation['notes'] = Yup.string().required('Notes is required');
               let hasValues = (VALUES.outgoingDispatchListNew[0].packedBy != "" || VALUES.outgoingDispatchListNew[0].packedDate != "" || VALUES.outgoingDispatchListNew[0].deliveryDate != "" || VALUES.outgoingDispatchListNew[0].deliveryType != "" || VALUES.outgoingDispatchListNew[0].deliveredTo != "" || VALUES.outgoingDispatchListNew[0].deliveredById != "" || VALUES.outgoingDispatchListNew[0].deliveredUserType != "" || VALUES.outgoingDispatchListNew[0].approximateValue != "" || VALUES.outgoingDispatchListNew[0].notes != "" || VALUES.outgoingDispatchListNew[0].courierId != ""  )
            
               if(VALUES.outgoingDispatchList?.length<1 || hasValues){
               validation['outgoingDispatchListNew'] = Yup.array().of(
                  Yup.object().shape({
                     packedBy : Yup.string().required('Packed by is required'),
                     packedDate : Yup.string().required('Packed date is required'),
                     deliveryDate : Yup.string().required('Delivery date is required'),
                     deliveryType : Yup.string().required('delivery type is required'),
                     deliveredTo : Yup.string().required('Delivered to is required'),
                     deliveredById : Yup.string().required('Delivered by is required'),
                     deliveredUserType : Yup.string().required('Delivered by User Type is required'),
                     approximateValue : Yup.string().required('Approximate value is required').max(6, 'Approximate value must be at most 6 characters'),
                     notes : Yup.string().required('Notes is required'),
                     courierId: Yup.string().test(
                        'courier-required-if-not-hand-carried',
                        'Courier info required',
                        function (value) {
                           
                          const { deliveryType } = this.parent;
                          return deliveryType === 'Hand Carry' || !!value;
                        }
                      ),                     
                  })
               );
            }
            }
            // Outgoing Courier End

            // end

         } else {
            setFieldError("appointmentTime", "");
            setFieldTouched("appointmentTime", false);
            setFieldError("franchiseNotes", "");
            setFieldTouched("franchiseNotes", false);


            // write Job Sheet Created  state next state code here start
            // changing below 02-09-2024
            // Incoming courier start

            if(!VALUES.isRemoteService){
               setFieldError("isFaultySystemAvailable", "");
               setFieldTouched("isFaultySystemAvailable", false);
   
               setFieldError("incomingDispatchDto_receivedDate", "");
               setFieldTouched("incomingDispatchDto_receivedDate", false);
   
               setFieldError("incomingDispatchDto_receivedId", "");
               setFieldTouched("incomingDispatchDto_receivedId", false);
            }
            
           

            // Incoming courier end

            // Outgoing Courier Start
            if(VALUES.isExchange){
               setFieldError("packedBy", "");
            setFieldTouched("packedBy", false);

            setFieldError("packedDate", "");
            setFieldTouched("packedDate", false);

            setFieldError("deliveryDate", "");
            setFieldTouched("deliveryDate", false);

            setFieldError("deliveryType", "");
            setFieldTouched("deliveryType", false);
			
			
            setFieldError("deliveredTo", "");
            setFieldTouched("deliveredTo", false);

            setFieldError("deliveredById", "");
            setFieldTouched("deliveredById", false);

            setFieldError("approximateValue", "");
            setFieldTouched("approximateValue", false);

            setFieldError("notes", "");
            setFieldTouched("notes", false);

            const fieldsToClear = [
               'packedBy',
               'packedDate',
               'deliveryDate',
               'deliveryType',
               'deliveredTo',
               'deliveredById',
               'approximateValue',
               'notes',
               'courierId'
            ];
            
            for (let i = 0; i < VALUES.outgoingDispatchListNew.length; i++) {
               fieldsToClear.forEach((field) => {
                  const fieldName = `outgoingDispatchListNew[${i}].${field}`;
                  setFieldError(fieldName, "");
                  setFieldTouched(fieldName, false);
               });
            }
            }
            // Outgoing Courier End
            // end
         }
         break;
      }
      // 10

      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["Assigned To Technician"]] + NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.PARTS_OUTBOUND_CONCERN_FIXED]): {
         //statements; 
         let modifiedValues = {
            ...VALUES
         };
         console.log("modifiedValues", modifiedValues);
         modifiedValues['IsAfcsGenerated'] = true;
         validationAndFields = GET_VALIDATION_AND_FIELD_SHOW(modifiedValues, JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Assignable To Technician"]], NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.ASSIGNED_TO_TECHNICIAN], setFieldError, setFieldTouched);

         if (modifiedValues.warrantyStatus != "In Warranty") {
            validationAndFields.showTabs?.push(JOB_SHEET_TABS.ESTIMATE_DETAILS);
         }
         
         if (modifiedValues.warrantyStatus == "In Warranty" && VALUES.isExchange) {
            validationAndFields.showTabs.push(JOB_SHEET_TABS.DEFECTIVE_PART);
         }
         validationAndFields.showTabs.push(JOB_SHEET_TABS.ADDITIONAL_EXPENSES)
         validationAndFields.disableField?.push("estimatorComments", "newParts", "newTaxes", "approveStatus", "approveStatusShowFiled", "technicianGUId", "warrantyStatus");

         //  this code present whenever warrantyStatus is disabled
         if(VALUES.warrantyStatus != "In Warranty"){
            validationAndFields.disableField?.push("isExchange", "isRemoteService", "isFaultySystemAvailable");
         }
         
         // if (VALUES?.technicianDropdown == true) {
         //    validationAndFields.disableField = validationAndFields.disableField.filter((eachField)=> eachField != "technicianGUId");
         // }
         validationAndFields.requiredField.push('melObservations', 'completeServiceInformation');
         validation = validationAndFields.validation;
         if(VALUES.isExchange){
            validation['incomingDispatchDto_receivedDate'] = Yup.string().nullable();
            validation['incomingDispatchDto_receivedId'] = Yup.string().nullable();
            validation['jobReceivedMode'] = Yup.string().nullable();
            
         }
         validationAndFields.url = updateReadyToInvoiceJob;
         validationAndFields.nextStatus = "Parts Outbound / Concern fixed";
         validationAndFields.disableField = validationAndFields.disableField.filter((eachValue) => eachValue != 'inspectionStatus' && eachValue != 'melObservations' && eachValue != 'completeServiceInformation');
         if (VALUES.IsAfcsGenerated) {
            validationAndFields.requiredField.push('melObservations', 'completeServiceInformation');
            validationAndFields.disableField = validationAndFields.disableField.filter((eachValue: any) => {
               return eachValue != 'melObservations' && eachValue != 'completeServiceInformation';
            });
            validation['melObservations'] = Yup.string().required('Mel observation is required');
            validation['completeServiceInformation'] = Yup.string().required('Complete service information is required');

            // validationAndFields.disableField = validationAndFields.disableField.filter((eachValue) => eachValue != 'inspectionStatus')
            validationAndFields.requiredField.push('packagedAt', 'cleaningComplete', 'packingComplete');
            validation['packagedAt'] = Yup.string().required('Packaged at is required');
            validation['cleaningComplete'] = Yup.boolean().required().oneOf([true], 'Cleaning not completed yet');
            validation['packingComplete'] = Yup.boolean().required().oneOf([true], 'Packing not completed yet');
         } else {
            setFieldError("melObservations", "");
            setFieldTouched("melObservations", false);

            setFieldError("completeServiceInformation", "");
            setFieldTouched("completeServiceInformation", false);

            setFieldError("franchiseArrived", "");
            setFieldTouched("franchiseArrived", false);
         }
         break;
      }

      //11
      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["Assigned To Technician"]] + NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.FRANCHISE_COMPLETED_JOB]): {
         let modifiedValues = {
            ...VALUES
         };
         modifiedValues['IsAfcsGenerated'] = true;
         if ((VALUES?.appointmentTime != null && VALUES?.appointmentTime != "") && VALUES?.franchiseNotes != "") {
            validationAndFields.disableField.push("appointmentTime", "franchiseNotes");
         }
         validationAndFields = GET_VALIDATION_AND_FIELD_SHOW(modifiedValues, JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Assignable To Technician"]], NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.ASSIGNED_TO_TECHNICIAN], setFieldError, setFieldTouched);
         // validationAndFields.showTabs?.push(JOB_SHEET_TABS.ADDITIONAL_EXPENSES);
         validation = validationAndFields.validation;
         validationAndFields.showTabs = validationAndFields.showTabs.filter((eachTabs: any) => eachTabs != JOB_SHEET_TABS.INCOMING_COURIER && eachTabs != JOB_SHEET_TABS.PENDING_COMMENTS);
         validationAndFields.showTabs?.push(JOB_SHEET_TABS.ADDITIONAL_EXPENSES);
         if(hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler()){
            validationAndFields.showTabs = validationAndFields.showTabs.filter((val:any)=>val != JOB_SHEET_TABS.OUTGOING_COURIER)
         }
         if (modifiedValues.warrantyStatus == "In Warranty" && VALUES.isExchange) {
            validationAndFields.showTabs.push(JOB_SHEET_TABS.DEFECTIVE_PART);
         }
         validationAndFields.url = franchiseCompletedJobLoading;
         validationAndFields.nextStatus = "Franchise Completed Job";
         //new line

         if (VALUES?.additionalExpenses?.length > 0) {
            validation['additionalExpenses'] = Yup.array().of(
               Yup.object().shape({
                  expensesName: Yup.string().required('Expenses name is required'),
                  // taxCode: Yup.string().required('Tax code is required')
               })
            );
         }
         if (VALUES?.franchiseArrivedOrNot == false) {
            validationAndFields.disableField.push("additionalExpenseDynamicAdd", 'franchise_upload_document_section');
            validationAndFields.disableField = validationAndFields.disableField.filter((eachValue) => eachValue != 'franchiseArrived');
         }

         if (VALUES.franchiseArrived == false) {
            validationAndFields.requiredField.push('franchiseArrived');
            validation['franchiseArrived'] = Yup.boolean().required().oneOf([true], 'Franchise Arrived not completed yet');
         } else {
            validationAndFields.disableField = validationAndFields.disableField.filter((eachField) => eachField != "melObservations" && eachField != "completeServiceInformation");
            validationAndFields.requiredField.push('melObservations', 'completeServiceInformation');
         }
         if (hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler()) {
            validationAndFields.disableField.push("vehicleBrandId", "permitFormReceivedDate", "estimatorGUId", "technicianGUId", "warrantyStatus", "vinNumber", "vehicleRegistrationNumber", "carModelId", "audioModel", "exchangeAudioSerialNumber", "audioSerialNumber", "dateOfSale", "manufacturedDate", "lastRepairDate", "kmsCovered", "customerConcern", "accessoriesList", "missingAccessories", "additionalRemarks");

            //  this code present whenever warrantyStatus is disabled
            if(VALUES.warrantyStatus != "In Warranty"){
               validationAndFields.disableField?.push("isExchange", "isRemoteService", "isFaultySystemAvailable");
            }
         }
         if (VALUES.IsAfcsGenerated) {
            if (VALUES.franchiseArrived == false) {
               validationAndFields.requiredField.push('franchiseArrived');
               validation['franchiseArrived'] = Yup.boolean().required().oneOf([true], 'Franchise Arrived not completed yet');
            } else {
               validation['melObservations'] = Yup.string().required('Mel observation is required');
               validation['completeServiceInformation'] = Yup.string().required('Complete service information is required');
            }

         } else {
            if (VALUES.franchiseArrived == false) {
               setFieldValue("franchiseArrived", "");
               setFieldError("franchiseArrived", "");
               setFieldTouched("franchiseArrived", false);
            }
         }
         break;
      }

      //12 
      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["Franchise Job Completed"]] + NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.PARTS_OUTBOUND_CONCERN_FIXED]): {
         //statements; 
         let modifiedValues = {
            ...VALUES
         };
         modifiedValues['IsAfcsGenerated'] = true;
         validationAndFields = GET_VALIDATION_AND_FIELD_SHOW(modifiedValues, JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Assignable To Technician"]], NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.ASSIGNED_TO_TECHNICIAN], setFieldError, setFieldTouched);
         validationAndFields.requiredField.push('melObservations', 'completeServiceInformation');
         validationAndFields.disableField = validationAndFields.disableField.filter((eachValue: any) => {
            return eachValue != 'melObservations' && eachValue != 'completeServiceInformation';
         });
         if (modifiedValues.warrantyStatus != "In Warranty") {
            validationAndFields.showTabs?.push(JOB_SHEET_TABS.ESTIMATE_DETAILS);
         }
         if (modifiedValues.warrantyStatus == "In Warranty" && VALUES.isExchange) {
            validationAndFields.showTabs.push(JOB_SHEET_TABS.DEFECTIVE_PART);
         }
         validationAndFields.showTabs.push(JOB_SHEET_TABS.ADDITIONAL_EXPENSES)
         validationAndFields.disableField?.push("estimatorComments", "newParts", "newTaxes", "approveStatus", "approveStatusShowFiled");
         validation = validationAndFields.validation;
         validationAndFields.url = updateReadyToInvoiceJob;
         validationAndFields.nextStatus = "Parts Outbound / Concern fixed";
         validationAndFields.disableField = validationAndFields.disableField.filter((eachValue) => eachValue != 'inspectionStatus');
         if (VALUES.IsAfcsGenerated) {
            validationAndFields.requiredField.push('melObservations', 'completeServiceInformation');
            validationAndFields.disableField = validationAndFields.disableField.filter((eachValue: any) => {
               return eachValue != 'melObservations' && eachValue != 'completeServiceInformation';
            });
            validation['melObservations'] = Yup.string().required('Mel observation is required');
            validation['completeServiceInformation'] = Yup.string().required('Complete service information is required');

            // validationAndFields.disableField = validationAndFields.disableField.filter((eachValue) => eachValue != 'inspectionStatus')
            validationAndFields.requiredField.push('packagedAt', 'cleaningComplete', 'packingComplete');
            validation['packagedAt'] = Yup.string().required('Packaged at is required');
            validation['cleaningComplete'] = Yup.boolean().required().oneOf([true], 'Cleaning not completed yet');
            validation['packingComplete'] = Yup.boolean().required().oneOf([true], 'Packing not completed yet');
         } else {
            // setFieldValue("melObservations", "")
            setFieldError("melObservations", "");
            setFieldTouched("melObservations", false);

            // setFieldValue("completeServiceInformation", "")
            setFieldError("completeServiceInformation", "");
            setFieldTouched("completeServiceInformation", false);
         }
         break;
      }

      // 13
      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["Ready To Invoice"]] + NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.IS_INVOICE_COMPLETE]): {
         //statements; 
         let modifiedValues = {
            ...VALUES
         };
         modifiedValues['IsAfcsGenerated'] = true;

         if (VALUES.isFranchise == true) {
            validationAndFields = GET_VALIDATION_AND_FIELD_SHOW(modifiedValues, JOB_SHEET_STATUS[JOB_SHEET_STATUS["Franchise Job Completed"]], NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.PARTS_OUTBOUND_CONCERN_FIXED], setFieldError, setFieldTouched);
         } else {
            validationAndFields = GET_VALIDATION_AND_FIELD_SHOW(modifiedValues, JOB_SHEET_STATUS[JOB_SHEET_STATUS["Assigned To Technician"]], NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.PARTS_OUTBOUND_CONCERN_FIXED], setFieldError, setFieldTouched);
         }

          // remote service
         // validationAndFields.disableField.push("isExchange","isRemoteService","isFaultySystemAvailable");
         validationAndFields.disableField.push("isExchange","isRemoteService");
      

         // validationAndFields.showTabs?.push(JOB_SHEET_TABS.OUTGOING_COURIER) // commented for karthik
         if (modifiedValues.warrantyStatus == "In Warranty" && VALUES.isExchange) {
            validationAndFields.showTabs.push(JOB_SHEET_TABS.DEFECTIVE_PART);
         }
         validationAndFields.showTabs.push(JOB_SHEET_TABS.ADDITIONAL_EXPENSES)
         validation = validationAndFields.validation;
         // validation['incomingDispatchDto_receivedDate'] = Yup.string().required(`Received date is required`);
         // validation['incomingDispatchDto_receivedId'] = Yup.string().required(`Received by is required`);
         // validation['jobReceivedMode'] = Yup.string().required(`Job received mode is required`);
         if(!(VALUES.incomingDispatchList?.length>0) || hasIncomingCourierValues(VALUES.incomingDispatchListNew[0])){
            let incomingValidation = Yup.object().shape({
               receivedDate:  Yup.string().required(`Received date is required`),
               receivedId: Yup.string().required(`Received by is required`),
               jobReceivedMode:  Yup.string().required(`Job received mode is required`),
            });
            if(VALUES.incomingDispatchListNew[0]?.jobReceivedMode == "Paid" || VALUES.incomingDispatchListNew[0]?.jobReceivedMode == "To Pay"){
               let newValidation = Yup.object().shape({
                  courierId: Yup.string().required(`Courier is required`),
                  courierAmount: Yup.string().matches(/^[1-9][0-9]*$/, 'Courier amount must be a positive integer'),
                  courierChequeNumber: Yup.string().matches(/^[a-zA-Z0-9].*$/, "Courier cheque number must be valid"),
               });
               incomingValidation = incomingValidation.concat(newValidation);
            }
            validation['incomingDispatchListNew'] = Yup.array().of(incomingValidation);
         }
         if(VALUES.isExchange){
            // validation['isFaultySystemAvailable'] = Yup.boolean().required().oneOf([true], 'Faulty system must be available'); required-data
      }
         validationAndFields.url = updateJobInvoiced;
         validationAndFields.nextStatus = "Invoice complete";
         validationAndFields.disableField.push('technicianGUId');
         break;
      }
      // 14
      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["Invoiced Job"]] + NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.CLOSE_JOB]): {
         //statements; 
         let modifiedValues = {
            ...VALUES
         };
         modifiedValues['IsAfcsGenerated'] = true;
         validationAndFields = GET_VALIDATION_AND_FIELD_SHOW(modifiedValues, JOB_SHEET_STATUS[JOB_SHEET_STATUS["Assigned To Technician"]], NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.PARTS_OUTBOUND_CONCERN_FIXED], setFieldError, setFieldTouched);

         validationAndFields.showTabs?.push(JOB_SHEET_TABS.OUTGOING_COURIER);
         validationAndFields.showTabs.push(JOB_SHEET_TABS.ADDITIONAL_EXPENSES)
         if(!VALUES.isRemoteService){
            let arr:any = []
            for(let i=0;i<VALUES.outgoingDispatchListNew.length;i++){
               let names=[
                  `outgoingDispatchListNew[${i}].packedBy`,
                  `outgoingDispatchListNew[${i}].packedDate`,
                  `outgoingDispatchListNew[${i}].deliveryDate`,
                  `outgoingDispatchListNew[${i}].deliveryType`,
                  `outgoingDispatchListNew[${i}].deliveredTo`,
                  `outgoingDispatchListNew[${i}].deliveredById`,
                  `outgoingDispatchListNew[${i}].courierId`,
                  `outgoingDispatchListNew[${i}].notes`,
               ]
               arr.push(...names)
            }
            // validationAndFields.requiredField.push("packedBy", "packedDate", "deliveryDate", "deliveryType", "courierId", "notes", "deliveredTo", "deliveredById");
            validationAndFields.requiredField.push(...arr);
            if(VALUES.isExchange){
               let arr2:any = []
               for(let i=0;i<VALUES.outgoingDispatchListNew.length;i++){
                  let names2=[
                     `outgoingDispatchListNew[${i}].deliveredUserType`,
                  ]
                  arr2.push(...names2)
               }
               validationAndFields.requiredField.push(...arr2);
            }
         }
       

         // remote service
         // validationAndFields.disableField.push("isExchange","isRemoteService","isFaultySystemAvailable");
         validationAndFields.disableField.push("isExchange","isRemoteService");
   
         //  un disable 24-06-2024

         // validationAndFields.disableField.push("packedBy", "packedDate", "deliveryDate", "deliveryType", "deliveredTo", "deliveredById", "approximateValue", "courierId", "notes")

         if (modifiedValues.warrantyStatus == "In Warranty" && VALUES.isExchange) {
            validationAndFields.showTabs.push(JOB_SHEET_TABS.DEFECTIVE_PART);
         }


         validationAndFields.disableField.push('technicianGUId');

         validation = validationAndFields.validation;
         validationAndFields.url = updateJobClosed;
         validationAndFields.nextStatus = "Close Job";
         if(VALUES.isExchange){
            validationAndFields.requiredField.push("isFaultySystemAvailable");
            // validation['isFaultySystemAvailable'] = Yup.boolean().required().oneOf([true], 'Faulty system must be available'); required-data
         }
         // if(!VALUES.isRemoteService){
         //    if (!VALUES.isExchange) {
         //       if (VALUES.deliveryType == 'Hand Carry') {
         //          setFieldValue('courierId', "");
         //          validationAndFields.disableField.push('courierId');
         //          validationAndFields.requiredField = validationAndFields.requiredField.filter((eachField) => eachField != "courierId");
         //          setFieldValue('courierId', "");
         //       }
   
         //       if (VALUES.deliveryType == 'Courier') {
         //          validationAndFields.requiredField.push('courierId');
         //          validation['courierId'] = Yup.string().required('Courier is required');
         //       }
         //    } else {
         //       validationAndFields.disableField.push("packedBy", "packedDate", "deliveryDate", "deliveryType", "deliveredTo", "deliveredById","deliveredUserType", "approximateValue", "courierId", "notes");
         //    }
         // }
         if (!VALUES.isRemoteService) {
            for (let i = 0; i < VALUES.outgoingDispatchListNew.length; i++) {
               const deliveryTypeField = `outgoingDispatchListNew[${i}].deliveryType`;
               const courierIdField = `outgoingDispatchListNew[${i}].courierId`;
         
               if (!VALUES.isExchange) {
                  if (VALUES.outgoingDispatchListNew[i].deliveryType === 'Hand Carry') {
                     setFieldValue(courierIdField, "");
                     validationAndFields.disableField.push(courierIdField);
                     validationAndFields.requiredField = validationAndFields.requiredField.filter(field => field !== courierIdField);
                  }
         
                  if (VALUES.outgoingDispatchListNew[i].deliveryType === 'Courier') {
                     validationAndFields.requiredField.push(courierIdField);
                     // validation[courierIdField] = Yup.string().required('Courier is required');
                  }
               } else {
                  const disableFields = [
                     'packedBy',
                     'packedDate',
                     'deliveryDate',
                     'deliveryType',
                     'deliveredTo',
                     'deliveredById',
                     'deliveredUserType',
                     'approximateValue',
                     'courierId',
                     'notes',
                  ];
         
                  disableFields.forEach(field => {
                     validationAndFields.disableField.push(`outgoingDispatchListNew[${i}].${field}`);
                  });
               }
            }
         }

         if (VALUES.IsAfcsGenerated) {
            if(!VALUES.isRemoteService){
               if (!VALUES.isExchange) {
                  // validationAndFields.requiredField.push("packedBy", "packedDate", "deliveryDate", "deliveryType", "courierId");
                  // validation['packedBy'] = Yup.string().required('Packed by is required');
                  // validation['packedDate'] = Yup.string().required('Packed date is required');
                  // validation['deliveryDate'] = Yup.string().required('Delivery date is required');
                  // validation['deliveryType'] = Yup.string().required('delivery type is required');

                  // validation['outgoingDispatchListNew'] = Yup.array().of(
                  //    Yup.object().shape({
                  //       packedBy : Yup.string().required('Packed by is required'),
                  //       packedDate : Yup.string().required('Packed date is required'),
                  //       deliveryDate : Yup.string().required('Delivery date is required'),
                  //       deliveryType : Yup.string().required('delivery type is required'),
                  //    })
                  // );
                  let arr:any = []
                  for(let i=0;i<VALUES.outgoingDispatchListNew.length;i++){
                     let names=[
                        `outgoingDispatchListNew[${i}].packedBy`,
                        `outgoingDispatchListNew[${i}].packedDate`,
                        `outgoingDispatchListNew[${i}].deliveryDate`,
                        `outgoingDispatchListNew[${i}].deliveryType`,
                        `outgoingDispatchListNew[${i}].courierId`,
                     ]
                     arr.push(...names)
                  }
                  // validationAndFields.requiredField.push("packedBy", "packedDate", "deliveryDate", "deliveryType", "courierId", "notes", "deliveredTo", "deliveredById");
                  validationAndFields.requiredField.push(...arr);
                  // if (!VALUES.isFranchise) {
                  //    validationAndFields.disableField = validationAndFields.disableField.filter((eachValue: any) => {
                  //       return eachValue != "packedBy" && eachValue != "packedDate" && eachValue != "deliveryDate" && eachValue != "deliveryType" && eachValue != "deliveredTo" && eachValue != "deliveredById" && eachValue != "approximateValue" && eachValue != "notes" && eachValue != "courierId";
                  //    });
                     
                  //    }
                  //    if (VALUES.deliveryType == 'Hand Carry') {
                  //       setFieldValue('courierId', "");
                  //       validationAndFields.disableField.push('courierId');
                  //       validationAndFields.requiredField = validationAndFields.requiredField.filter((eachValue: any) => {
                  //          return eachValue != "courierId";
                  //       });
                  //       setFieldValue('courierId', "");
                  //    }

                  if (!VALUES.isFranchise) {
                     validationAndFields.disableField = validationAndFields.disableField.filter((eachValue: any) => {
                        const fieldsToRemove = [
                           'packedBy',
                           'packedDate',
                           'deliveryDate',
                           'deliveryType',
                           'deliveredTo',
                           'deliveredById',
                           'approximateValue',
                           'notes',
                           'courierId',
                        ];
                  
                        return !VALUES.outgoingDispatchListNew.some((_: any, i: any) =>
                           fieldsToRemove.some(field => eachValue === `outgoingDispatchListNew[${i}].${field}`)
                        );
                     });
                  
                     // Hand Carry check for each item
                     for (let i = 0; i < VALUES.outgoingDispatchListNew.length; i++) {
                        if (VALUES.outgoingDispatchListNew[i].deliveryType === 'Hand Carry') {
                           const courierIdField = `outgoingDispatchListNew[${i}].courierId`;
                  
                           setFieldValue(courierIdField, "");
                           validationAndFields.disableField.push(courierIdField);
                           validationAndFields.requiredField = validationAndFields.requiredField.filter(
                              (eachValue: any) => eachValue !== courierIdField
                           );
                        }
                     }
                  }
                  
                  }
                  //  Emergency fix change it later
                  // validationAndFields.requiredField.push("deliveredTo", "deliveredById", "approximateValue", "notes");
                  for(let i=0;i<VALUES.outgoingDispatchListNew.length;i++){
                     let names=[
                        `outgoingDispatchListNew[${i}].deliveredTo`,
                        `outgoingDispatchListNew[${i}].deliveredById`,
                        `outgoingDispatchListNew[${i}].approximateValue`,
                        `outgoingDispatchListNew[${i}].notes`,
                     ]
                     validationAndFields.requiredField.push(...names)
                  }
                  // validation['deliveredTo'] = Yup.string().required('Delivered to is required');
                  // validation['deliveredById'] = Yup.string().required('Delivered by is required');
                  // validation['approximateValue'] = Yup.string().required('Approximate value is required').max(6, 'Approximate value must be at most 6 characters');
                  // validation['notes'] = Yup.string().required('Notes is required');
                  let hasValues = (VALUES.outgoingDispatchListNew[0].packedBy != "" || VALUES.outgoingDispatchListNew[0].packedDate != "" || VALUES.outgoingDispatchListNew[0].deliveryDate != "" || VALUES.outgoingDispatchListNew[0].deliveryType != "" || VALUES.outgoingDispatchListNew[0].deliveredTo != "" || VALUES.outgoingDispatchListNew[0].deliveredById != "" || VALUES.outgoingDispatchListNew[0].deliveredUserType != "" || VALUES.outgoingDispatchListNew[0].approximateValue != "" || VALUES.outgoingDispatchListNew[0].notes != "" || VALUES.outgoingDispatchListNew[0].courierId != ""  )
            
                  if(VALUES.outgoingDispatchList?.length<1 || hasValues){
                  validation['outgoingDispatchListNew'] = Yup.array().of(
                     Yup.object().shape({
                        packedBy : Yup.string().required('Packed by is required'),
                        packedDate : Yup.string().required('Packed date is required'),
                        deliveryDate : Yup.string().required('Delivery date is required'),
                        deliveryType : Yup.string().required('delivery type is required'),
                        deliveredTo : Yup.string().required('Delivered to is required'),
                        deliveredById : Yup.string().required('Delivered by is required'),
                        approximateValue : Yup.string().required('Approximate value is required').max(6, 'Approximate value must be at most 6 characters'),
                        notes : Yup.string().required('Notes is required'),
                        courierId: Yup.string().test(
                           'courier-required-if-not-hand-carried',
                           'Courier info required',
                           function (value) {
                              
                             const { deliveryType } = this.parent;
                             return deliveryType === 'Hand Carry' || !!value;
                           }
                         ),
                     })
                  );
               }
               
               }else{
                  if(VALUES.isExchange){
                     // validationAndFields.disableField = validationAndFields.disableField.filter((eachValue: any) => {
                     //    return eachValue != "deliveredUserType" ;
                     // });
                     validationAndFields.disableField = validationAndFields.disableField.filter((eachValue: any) => {
                        return !VALUES.outgoingDispatchListNew.some((_: any, i: any) => eachValue === `outgoingDispatchListNew[${i}].deliveredUserType`);
                     });
                     // validation['deliveredUserType'] = Yup.string().required('Delivered by User Type is required');
                     let baseValidationSchema = Yup.object().shape({
                        deliveredTo: Yup.string().required('Delivered to is required'),
                        packedBy: Yup.string().required('Packed by is required'),
                        packedDate: Yup.string().required('Packed date is required'),
                        deliveryDate: Yup.string().required('Delivery date is required'),
                        deliveryType: Yup.string().required('Delivery type is required'),
                        deliveredById: Yup.string().required('Delivered by is required'),
                        approximateValue: Yup.string()
                           .required('Approximate value is required')
                           .max(6, 'Approximate value must be at most 6 characters'),
                        notes: Yup.string().required('Notes is required'),
                        courierId: Yup.string().test(
                           'courier-required-if-not-hand-carried',
                           'Courier info required',
                           function (value) {
                              
                             const { deliveryType } = this.parent;
                             return deliveryType === 'Hand Carry' || !!value;
                           }
                         ),
                     });
                     
                     // Conditionally add extra fields if `isExchange` is true
                     if (VALUES.isExchange) {
                        const exchangeValidationSchema = Yup.object().shape({
                           deliveredUserType: Yup.string().required('Delivered User Type is required'),
                        });
                        baseValidationSchema = baseValidationSchema.concat(exchangeValidationSchema); // Merging the base schema with the extra field
                     }
                     let hasValues = (VALUES.outgoingDispatchListNew[0].packedBy != "" || VALUES.outgoingDispatchListNew[0].packedDate != "" || VALUES.outgoingDispatchListNew[0].deliveryDate != "" || VALUES.outgoingDispatchListNew[0].deliveryType != "" || VALUES.outgoingDispatchListNew[0].deliveredTo != "" || VALUES.outgoingDispatchListNew[0].deliveredById != "" || VALUES.outgoingDispatchListNew[0].deliveredUserType != "" || VALUES.outgoingDispatchListNew[0].approximateValue != "" || VALUES.outgoingDispatchListNew[0].notes != "" || VALUES.outgoingDispatchListNew[0].courierId != ""  )
            
                     if(VALUES.outgoingDispatchList?.length<1 || hasValues){
                     validation['outgoingDispatchListNew'] = Yup.array().of(baseValidationSchema);
                     }
                     // validation['outgoingDispatchListNew'] = Yup.array().of(
                     //    Yup.object().shape({
                     //       deliveredUserType : Yup.string().required('Delivered by User Type is required'),
                     //    })
                     // );
               }
            }
       



         } else {
            if(!VALUES.isRemoteService){
               if (!VALUES.isExchange) {
                  // setFieldError("packedBy", "");
                  // setFieldTouched("packedBy", false);
   
                  // setFieldError("packedDate", "");
                  // setFieldTouched("packedDate", false);
   
                  // setFieldError("deliveryDate", "");
                  // setFieldTouched("deliveryDate", false);
   
                  // setFieldError("deliveryType", "");
                  // setFieldTouched("deliveryType", false);
   
   
   
                  // //  change this it only for emergency
                  // setFieldError("deliveredTo", "");
                  // setFieldTouched("deliveredTo", false);
   
                  // setFieldError("deliveredById", "");
                  // setFieldTouched("deliveredById", false);
   
                  // setFieldError("approximateValue", "");
                  // setFieldTouched("approximateValue", false);
   
                  // setFieldError("notes", "");
                  // setFieldTouched("notes", false);
   
                  const fieldsToClear = [
                     'packedBy',
                     'packedDate',
                     'deliveryDate',
                     'deliveryType',
                     'deliveredTo',
                     'deliveredById',
                     'approximateValue',
                     'notes',
                     'courierId'
                  ];
                  
                  for (let i = 0; i < VALUES.outgoingDispatchListNew.length; i++) {
                     fieldsToClear.forEach((field) => {
                        const fieldName = `outgoingDispatchListNew[${i}].${field}`;
                        setFieldError(fieldName, "");
                        setFieldTouched(fieldName, false);
                     });
                  }
                  // validationAndFields.disableField.push("packedBy", "packedDate", "deliveryDate", "deliveryType", "deliveredTo", "deliveredById",
                  //    "approximateValue", "courierId", "notes")
               }
   
            }
    

         }

         break;
      }

      default: {
         break;
      }
   }

   if (validation != "") {
      validationAndFields['validation'] = { ...validation };
   }
   return validationAndFields;

};

export const FORMAT_VALUE_FOR_UPDATE = (VALUES: any, CURRENT_STATUS: string) => {
   switch (CURRENT_STATUS) {
      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["New Job"]]): {
         
         const addJobId = VALUES?.pendingCommentsDtoNew.filter((val:any)=>val?.pendingReasonId != "" && val?.pendingReasonId != null && val?.pendingReasonId != 0)?.map((obj: any) => {
            obj.jobId = obj?.jobId ? obj?.jobId : 0;
            obj.pendingReasonId = Number(obj.pendingReasonId)
            return obj;
         });
         const formattedData = {
            id: VALUES?.id ? VALUES?.id : 0,
            dealerId: VALUES?.dealerId ? VALUES?.dealerId : null,
            customerId: VALUES?.customerId ? VALUES?.customerId : null,
            vehicleBrandId: VALUES?.vehicleBrandId ? VALUES?.vehicleBrandId : null,
            audioModel: VALUES?.audioModelName ? VALUES?.audioModelName : null, // mismatch
            audioModelId: VALUES?.audioModel ? VALUES?.audioModel : null, // field missing
            estimatorGUId: VALUES?.estimatorGUId ? VALUES?.estimatorGUId : null,
            technicianGUId: VALUES?.technicianGUId ? VALUES?.technicianGUId : null,
            status: VALUES?.status, // field missing
            // serviceCharge: VALUES?.serviceCharge ? VALUES?.serviceCharge : null,
            serviceCharge: VALUES?.serviceCharge ?? null,
            isExchange: VALUES?.isExchange ? VALUES?.isExchange : false,
            isRemoteService: VALUES?.isRemoteService ? VALUES?.isRemoteService : false,
            isFaultySystemAvailable: VALUES?.isFaultySystemAvailable ? VALUES?.isFaultySystemAvailable : false,
            inPending: VALUES?.inPending ? VALUES?.inPending : false, //true field missing
            requestDate: VALUES?.requestDate ? ConvertIsoStringWithTime(VALUES?.requestDate) : null,
            warrantyStatus: VALUES?.warrantyStatus ? VALUES?.warrantyStatus : null,
            dateOfSale: VALUES?.dateOfSale ? ConvertIsoStringWithTime(VALUES?.dateOfSale) : null, // convert missing
            manufacturedDate: VALUES?.manufacturedDate ? ConvertIsoStringWithTime(VALUES?.manufacturedDate) : null,
            failedDate: VALUES?.failedDate ? ConvertIsoStringWithTime(VALUES?.failedDate) : null,
            lastRepairDate: VALUES?.lastRepairDate ? ConvertIsoStringWithTime(VALUES?.lastRepairDate) : null,
            serviceRequestGeneratedDate: null,  // field missing
            kmsCovered: VALUES?.kmsCovered ? VALUES?.kmsCovered : 0,
            vehicleModel: VALUES?.vehicleModel ? VALUES?.vehicleModel : null,
            carModelId: VALUES?.carModelId ? VALUES?.carModelId : null,
            outgoingAudioSerialNumber: VALUES?.exchangeAudioSerialNumber ? VALUES?.exchangeAudioSerialNumber : null,
            audioSerialNumber: VALUES?.audioSerialNumber ? VALUES?.audioSerialNumber : null,
            audioPartNumber: VALUES?.audioPartNumber ? VALUES?.audioPartNumber : null,
            vinNumber: VALUES?.vinNumber ? VALUES?.vinNumber : null,
            vehicleRegistrationNumber: VALUES?.vehicleRegistrationNumber ? VALUES?.vehicleRegistrationNumber : null,
            // permitFormReceivedDate: VALUES?.permitFormReceivedDate ? ConvertIsoStringWithTime(VALUES?.permitFormReceivedDate) : null,
            pendingCommentsDto: addJobId,
            //  Next state
            customerConcern: VALUES?.customerConcern ? VALUES?.customerConcern : null,
            dealerObservation: VALUES?.dealerObservation ? VALUES?.dealerObservation : null,
            IsAfcsGenerated: VALUES?.IsAfcsGenerated ? VALUES?.IsAfcsGenerated : false,
            appointmentTime: VALUES?.appointmentTime ? VALUES?.appointmentTime : null,
            franchiseNotes: VALUES?.franchiseNotes ? VALUES?.franchiseNotes : null,
            files: VALUES.fileData ? VALUES.fileData : null,
            approvedoc: VALUES.approveDocument ? VALUES.approveDocument : null,
            serviceLocation: VALUES?.serviceLocation ? VALUES?.serviceLocation : null,
            additionalExpenses: VALUES?.additionalExpenses ? VALUES?.additionalExpenses : null,
            proofDocuments: VALUES?.proofDocuments ? VALUES?.proofDocuments : null,
            isFranchise: VALUES?.isFranchise ? VALUES?.isFranchise : false,
            additionalExpenseFiles: VALUES?.additionalExpenseFiles?.length > 0 ? VALUES?.additionalExpenseFiles : null,
            franchiseCurrentLag: VALUES?.franchiseCurrentLag ? VALUES?.franchiseCurrentLag : null,
            franchiseCurrentLat: VALUES?.franchiseCurrentLat ? VALUES?.franchiseCurrentLat : null,
            franchiseArrived: VALUES?.franchiseArrived,
            franchiseArrivedOrNot: VALUES?.franchiseArrivedOrNot,
            perfectionFiles: VALUES?.correctedFiles ? VALUES?.correctedFiles : null,
            jobCloseOtp: VALUES?.jobCloseOtp ? VALUES?.jobCloseOtp : null,
            packingComplete: VALUES?.packingComplete ? VALUES?.packingComplete : false,
            cleaningComplete: VALUES?.cleaningComplete ? VALUES?.cleaningComplete : false,
            packagedAt: VALUES?.packagedAt ? ConvertIsoStringWithTime(VALUES?.packagedAt) : null,
            melObservations: VALUES?.melObservations ? VALUES?.melObservations : null,
            completeServiceInformation: VALUES?.completeServiceInformation ? VALUES?.completeServiceInformation : null,
            dealerAddress: VALUES?.dealerAddress ? VALUES?.dealerAddress : null,
            customerAddress: VALUES?.customerAddress ? VALUES?.customerAddress : null,
            customerPhoneNumber: VALUES?.customerPhoneNumber ? VALUES?.customerPhoneNumber.toString() : null,
            customerName: VALUES?.customerName ? VALUES?.customerName : null,
            customerEmail: VALUES?.customerEmail ? VALUES?.customerEmail : null,
            customerGSTNumber: VALUES?.customerGSTNumber ? VALUES?.customerGSTNumber : null,
            supplierReferenceNumber: VALUES?.supplierReferenceNumber ? VALUES?.supplierReferenceNumber : null,
            priority: VALUES?.priority ? VALUES?.priority : false,
            dispatched: VALUES?.dispatched ? VALUES?.dispatched : false,
            defectiveParts: VALUES?.defectiveParts ? VALUES?.defectiveParts : null,
         };
         const proofDocuments = VALUES?.proofDocuments?.map((data: any, index: number) => {
            let object = {
               ...data,
               file: VALUES?.base64Files?.[index],
               fileContentType: VALUES?.proofDocumentFiles?.[index]?.[0]?.type,
               fileName: VALUES?.proofDocumentFiles?.[index]?.[0]?.name,
            };
          
            return object;
         });
         formattedData["proofDocuments"] = proofDocuments
         return formattedData;

      }
      
      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["Service Request Generated"]]): {
         let moditifiedValue = { ...VALUES };

         if (moditifiedValue.isExchange) {
            let formattedData: any = FORMAT_VALUE_FOR_UPDATE(moditifiedValue, JOB_SHEET_STATUS[JOB_SHEET_STATUS["New Job"]]);
            // formattedData['outgoingDispatch'] = {
            //    courierId: VALUES.courierId ? VALUES.courierId : null,
            //    packedBy: VALUES.packedBy ? VALUES.packedBy : null,
            //    packedDate: VALUES.packedDate ? ConvertIsoStringWithTime(VALUES.packedDate) : null,
            //    notes: VALUES.notes ? VALUES.notes : null,
            //    deliveryDate: VALUES.deliveryDate ? ConvertIsoStringWithTime(VALUES.deliveryDate) : null,
            //    deliveryType: VALUES.deliveryType ? VALUES.deliveryType : null,
            //    deliveredTo: VALUES.deliveredTo ? VALUES.deliveredTo : null,
            //    deliveredById: VALUES.deliveredById ? VALUES.deliveredById : null,
            //    deliveredUserType: VALUES.deliveredUserType ? VALUES.deliveredUserType : null,
            //    approximateValue: VALUES.approximateValue ? VALUES.approximateValue : null,
            // };

            let outgoingDispatchNewId = VALUES.outgoingDispatchListNew[0]?.id
            let filteredOutGoingDispatch = VALUES.outgoingDispatchList?.filter((val:any)=>val.id!=outgoingDispatchNewId)
            let outgoinghDispatchListData = [...filteredOutGoingDispatch,...VALUES.outgoingDispatchListNew]?.filter((data:any)=>data.packedBy !="")

            formattedData['outgoingDispatchList'] = outgoinghDispatchListData.length>0 ? outgoinghDispatchListData?.map((val:any)=>({
               jobId:VALUES.id,
               id:val?.id ? val?.id : 0,
               courierId: val.courierId ? val.courierId : null,
               packedBy: val.packedBy ? val.packedBy : null,
               packedDate: val.packedDate ? ConvertIsoStringWithTime(val.packedDate) : null,
               notes: val.notes ? val.notes : null,
               deliveryDate: val.deliveryDate ? ConvertIsoStringWithTime(val.deliveryDate) : null,
               deliveryType: val.deliveryType ? val.deliveryType : null,
               deliveredTo: val.deliveredTo ? val.deliveredTo : null,
               deliveredById: val.deliveredById ? val.deliveredById : null,
               deliveredUserType: val.deliveredUserType ? val.deliveredUserType : null,
               approximateValue: val.approximateValue ? val.approximateValue : null,
            })) : []
          
           
            return formattedData;

         } else {
            let formattedData: any = FORMAT_VALUE_FOR_UPDATE(moditifiedValue, JOB_SHEET_STATUS[JOB_SHEET_STATUS["New Job"]]);

            formattedData['jobReceivedMode'] = VALUES?.jobReceivedMode ? VALUES?.jobReceivedMode : null,
               formattedData['accessoriesList'] = VALUES?.accessoriesList ? VALUES?.accessoriesList : null,
               formattedData['missingAccessories'] = VALUES?.missingAccessories ? VALUES?.missingAccessories : null,
               formattedData['additionalRemarks'] = VALUES?.additionalRemarks ? VALUES?.additionalRemarks : null,
               formattedData['isFaultySystemAvailable'] = VALUES?.isFaultySystemAvailable ? VALUES?.isFaultySystemAvailable : false,
               formattedData['courierReceivedAt'] = VALUES?.courierReceivedAt ? ConvertIsoStringWithTime(VALUES?.courierReceivedAt) : null,
               //   formattedData['courierReceivedAt'] = VALUES?.courierReceivedAt ? VALUES?.courierReceivedAt : null,
               formattedData['courierPackingCondition'] = VALUES?.courierPackingCondition ? VALUES?.courierPackingCondition : null,
               formattedData['courierAmount'] = VALUES?.courierAmount ? VALUES?.courierAmount : null,
               formattedData['courierChequeNumber'] = VALUES?.courierChequeNumber ? VALUES?.courierChequeNumber : null,
               formattedData['courierBankName'] = VALUES?.courierBankName ? VALUES?.courierBankName : null,
               formattedData['handCarriedPersonName'] = VALUES?.handCarriedPersonName ? VALUES?.handCarriedPersonName : null,
               formattedData['handCarriedPersonDesignation'] = VALUES?.handCarriedPersonDesignation ? VALUES?.handCarriedPersonDesignation : null,

               
               formattedData['incomingDispatch'] =((VALUES?.incomingDispatchDto_courierId != "" && VALUES?.incomingDispatchDto_courierId != null) || (VALUES?.incomingDispatchDto_receivedDate != "" && VALUES?.incomingDispatchDto_receivedDate != null) || (VALUES?.incomingDispatchDto_receivedId != "" && VALUES?.incomingDispatchDto_receivedId != null)) ? {
                  id: VALUES?.incomingDispatchDto_id ? VALUES?.incomingDispatchDto_id : 0,
                  // id: 0,
                  receivedDate: VALUES?.incomingDispatchDto_receivedDate ? ConvertIsoStringWithTime(VALUES?.incomingDispatchDto_receivedDate) : null,
                  receivedId: VALUES?.incomingDispatchDto_receivedId ? VALUES?.incomingDispatchDto_receivedId : null,
                  // VALUES?.incomingDispatchDto_receivedId ? VALUES?.incomingDispatchDto_receivedId : null,
                  courierId: VALUES?.incomingDispatchDto_courierId ? VALUES?.incomingDispatchDto_courierId : null,
                  notes: VALUES?.incomingDispatchDto_notes ? VALUES?.incomingDispatchDto_notes : null
               } : null;
               
               let incomingDispatchNewId = VALUES.incomingDispatchListNew[0]?.id
               let filteredincomingDispatch = VALUES.incomingDispatchList?.filter((val:any)=>val.id!=incomingDispatchNewId)
               let incominghDispatchListData = [...filteredincomingDispatch,...VALUES.incomingDispatchListNew]?.filter((data:any)=>data.receivedId !="")
               let inComingDispatchPayload=incominghDispatchListData?.length>0 ? incominghDispatchListData?.map((dispatch:any)=>({
                     id: dispatch?.id ? dispatch?.id : 0,
                     jobReceivedMode: dispatch?.jobReceivedMode ? dispatch?.jobReceivedMode : "",
                     courierReceivedAt: dispatch?.courierReceivedAt ? ConvertIsoStringWithTime(dispatch?.courierReceivedAt) : "",
                     courierPackingCondition: dispatch?.courierPackingCondition ? dispatch?.courierPackingCondition : "",
                     courierAmount: dispatch?.courierAmount ? dispatch?.courierAmount : 0,
                     courierChequeNumber: dispatch?.courierChequeNumber ? dispatch?.courierChequeNumber : "",
                     courierBankName: dispatch?.courierBankName ? dispatch?.courierBankName : "",
                     handCarriedPersonName: dispatch?.handCarriedPersonName ? dispatch?.handCarriedPersonName : "",
                     handCarriedPersonDesignation: dispatch?.handCarriedPersonDesignation ? dispatch?.handCarriedPersonDesignation : "",
                     creatorId: dispatch?.creatorId ? dispatch?.creatorId : "",
                     lastModifierId: dispatch?.lastModifierId ? dispatch?.lastModifierId : "",
                     jobId: dispatch?.jobId ? dispatch?.jobId : 0,
                     receivedDate: dispatch?.receivedDate ? ConvertIsoStringWithTime(dispatch?.receivedDate) : "",
                     receivedId: dispatch?.receivedId ? dispatch?.receivedId : "",
                     courierId: dispatch?.courierId ? dispatch?.courierId : null,
                     notes: dispatch?.notes ? dispatch?.notes : "",
                     receivedByName: dispatch?.receivedByName ? dispatch?.receivedByName : "",
               })) : []
               formattedData["incomingDispatchNew"] = inComingDispatchPayload;
            return formattedData;
         }



      }
      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["Replacement System Dispatched"]]): {
         let moditifiedValue = { ...VALUES };
         let formattedData: any = FORMAT_VALUE_FOR_UPDATE(moditifiedValue, JOB_SHEET_STATUS[JOB_SHEET_STATUS["Service Request Generated"]]);

         formattedData['jobReceivedMode'] = VALUES?.jobReceivedMode ? VALUES?.jobReceivedMode : null,
            formattedData['accessoriesList'] = VALUES?.accessoriesList ? VALUES?.accessoriesList : null,
            formattedData['missingAccessories'] = VALUES?.missingAccessories ? VALUES?.missingAccessories : null,
            formattedData['additionalRemarks'] = VALUES?.additionalRemarks ? VALUES?.additionalRemarks : null,
            formattedData['isFaultySystemAvailable'] = VALUES?.isFaultySystemAvailable ? VALUES?.isFaultySystemAvailable : false,
            formattedData['courierReceivedAt'] = VALUES?.courierReceivedAt ? ConvertIsoStringWithTime(VALUES?.courierReceivedAt) : null,
            //   formattedData['courierReceivedAt'] = VALUES?.courierReceivedAt ? VALUES?.courierReceivedAt : null,
            formattedData['courierPackingCondition'] = VALUES?.courierPackingCondition ? VALUES?.courierPackingCondition : null,
            formattedData['courierAmount'] = VALUES?.courierAmount ? VALUES?.courierAmount : null,
            formattedData['courierChequeNumber'] = VALUES?.courierChequeNumber ? VALUES?.courierChequeNumber : null,
            formattedData['courierBankName'] = VALUES?.courierBankName ? VALUES?.courierBankName : null,
            formattedData['handCarriedPersonName'] = VALUES?.handCarriedPersonName ? VALUES?.handCarriedPersonName : null,
            formattedData['handCarriedPersonDesignation'] = VALUES?.handCarriedPersonDesignation ? VALUES?.handCarriedPersonDesignation : null,
            formattedData['incomingDispatch'] = ((VALUES?.incomingDispatchDto_courierId != "" && VALUES?.incomingDispatchDto_courierId != null) || (VALUES?.incomingDispatchDto_receivedDate != "" && VALUES?.incomingDispatchDto_receivedDate != null) || (VALUES?.incomingDispatchDto_receivedId != "" && VALUES?.incomingDispatchDto_receivedId != null)) ? {
               id: VALUES?.incomingDispatchDto_id ? VALUES?.incomingDispatchDto_id : 0,
               receivedDate: VALUES?.incomingDispatchDto_receivedDate ? ConvertIsoStringWithTime(VALUES?.incomingDispatchDto_receivedDate) : null,
               receivedId: VALUES?.incomingDispatchDto_receivedId ? VALUES?.incomingDispatchDto_receivedId : null,
               // VALUES?.incomingDispatchDto_receivedId ? VALUES?.incomingDispatchDto_receivedId : null,
               courierId: VALUES?.incomingDispatchDto_courierId ? VALUES?.incomingDispatchDto_courierId : null,
               notes: VALUES?.incomingDispatchDto_notes ? VALUES?.incomingDispatchDto_notes : null
            } : null ;

            let incomingDispatchNewId = VALUES.incomingDispatchListNew[0]?.id
            let filteredincomingDispatch = VALUES.incomingDispatchList?.filter((val:any)=>val.id!=incomingDispatchNewId)
            let incominghDispatchListData = [...filteredincomingDispatch,...VALUES.incomingDispatchListNew]?.filter((data:any)=>data.receivedId !="")
            let inComingDispatchPayload=incominghDispatchListData?.length>0 ? incominghDispatchListData?.map((dispatch:any)=>({
                  id: dispatch?.id ? dispatch?.id : 0,
                  jobReceivedMode: dispatch?.jobReceivedMode ? dispatch?.jobReceivedMode : "",
                  courierReceivedAt: dispatch?.courierReceivedAt ? ConvertIsoStringWithTime(dispatch?.courierReceivedAt) : "",
                  courierPackingCondition: dispatch?.courierPackingCondition ? dispatch?.courierPackingCondition : "",
                  courierAmount: dispatch?.courierAmount ? dispatch?.courierAmount : 0,
                  courierChequeNumber: dispatch?.courierChequeNumber ? dispatch?.courierChequeNumber : "",
                  courierBankName: dispatch?.courierBankName ? dispatch?.courierBankName : "",
                  handCarriedPersonName: dispatch?.handCarriedPersonName ? dispatch?.handCarriedPersonName : "",
                  handCarriedPersonDesignation: dispatch?.handCarriedPersonDesignation ? dispatch?.handCarriedPersonDesignation : "",
                  creatorId: dispatch?.creatorId ? dispatch?.creatorId : "",
                  lastModifierId: dispatch?.lastModifierId ? dispatch?.lastModifierId : "",
                  jobId: dispatch?.jobId ? dispatch?.jobId : 0,
                  receivedDate: dispatch?.receivedDate ? ConvertIsoStringWithTime(dispatch?.receivedDate) : "",
                  receivedId: dispatch?.receivedId ? dispatch?.receivedId : "",
                  courierId: dispatch?.courierId ? dispatch?.courierId : null,
                  notes: dispatch?.notes ? dispatch?.notes : "",
                  receivedByName: dispatch?.receivedByName ? dispatch?.receivedByName : "",
            })) : []
            formattedData["incomingDispatchNew"] = inComingDispatchPayload;

         return formattedData;

      }
      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Sheet Created"]]): {
         let formattedData: any = {};
         let moditifiedValue = { ...VALUES };
         if (moditifiedValue.isExchange) {
            formattedData = FORMAT_VALUE_FOR_UPDATE(moditifiedValue, JOB_SHEET_STATUS[JOB_SHEET_STATUS["Replacement System Dispatched"]]);
         } else {
            formattedData = FORMAT_VALUE_FOR_UPDATE(moditifiedValue, JOB_SHEET_STATUS[JOB_SHEET_STATUS["Service Request Generated"]]);
         }
         formattedData['estimatorId'] = VALUES?.estimator ? VALUES?.estimator : null;
         formattedData['estimatorGUId'] = VALUES?.estimatorGUId ? VALUES?.estimatorGUId : null;
         return formattedData;
      }
      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["Assigned To Estimator"]]): {
         let moditifiedValue = { ...VALUES };
         let formattedData: any = FORMAT_VALUE_FOR_UPDATE(moditifiedValue, JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Sheet Created"]]);
         formattedData['estimate'] = {
            jobId: VALUES?.id ? VALUES?.id : 0,
            taxTypeId: VALUES?.taxTypeId ? VALUES?.taxTypeId : 0,
            estimatorComments: VALUES?.estimatorComments ? VALUES?.estimatorComments : null,
            totalEstimation: VALUES?.estimateTotal ? VALUES?.estimateTotal : 0,
            isCompleted: VALUES?.IsAfcsGenerated ? VALUES?.IsAfcsGenerated : false,
            estimatePartDto: VALUES?.partsDetails?.length > 0 ? VALUES?.partsDetails : [],
            estimateTaxDto: VALUES?.taxDetails?.length > 0 ? VALUES?.taxDetails.map((eachValue: any) => {
               let data: any = { ...eachValue };
               data['name'] = eachValue.name;
               return data;
            }) : [],
         };


         return formattedData;



      }
      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Estimated Awaiting Approval"]]): {
         let moditifiedValue = { ...VALUES };
         let formattedData: any = FORMAT_VALUE_FOR_UPDATE(moditifiedValue, JOB_SHEET_STATUS[JOB_SHEET_STATUS["Assigned To Estimator"]]);
         formattedData['estimate']['approvalMode'] = VALUES?.approvalMode ? VALUES?.approvalMode : null;
         formattedData['estimate']['approvalComments'] = VALUES?.approvalComments ? VALUES?.approvalComments : null;
         formattedData['estimate']['approvedStatus'] = VALUES?.approveStatus ? VALUES?.approveStatus == APPROVE ? true : false : null;
         formattedData['estimate']['concernSignature'] = VALUES?.concernSignature ? VALUES?.concernSignature : "";
         formattedData['estimate']['contentType'] = VALUES?.contentType ? VALUES?.contentType : "";
         formattedData['approvedoc'] = VALUES?.approveDocument?.length > 0 ? VALUES?.approveDocument : null;
         return formattedData;

      }
      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["Estimation Rejected"]]): {
         let moditifiedValue = { ...VALUES };
         let formattedData: any = FORMAT_VALUE_FOR_UPDATE(moditifiedValue, JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Estimated Awaiting Approval"]]);
         return formattedData;
      }

      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Assignable To Technician"]]): {

         let moditifiedValue = { ...VALUES };
         let formattedData: any = {};
         if (VALUES.warrantyStatus == "In Warranty") {
            formattedData = FORMAT_VALUE_FOR_UPDATE(moditifiedValue, JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Sheet Created"]]);
         } else {
            formattedData = FORMAT_VALUE_FOR_UPDATE(moditifiedValue, JOB_SHEET_STATUS[JOB_SHEET_STATUS["Assigned To Estimator"]]);
         }
         formattedData['technicianId'] = VALUES?.technicianId ? VALUES?.technicianId : null;
         formattedData['technicianGUId'] = VALUES?.technicianGUId ? VALUES?.technicianGUId : null;
         formattedData['appointmentTime'] = VALUES?.appointmentTime ? ConvertIsoStringWithTime(VALUES?.appointmentTime) : null;
         formattedData['franchiseNotes'] = VALUES?.franchiseNotes ? VALUES?.franchiseNotes : null;
         formattedData['serviceLocation'] = VALUES?.serviceLocation ? VALUES?.serviceLocation : null;
         //added appointment time and franchise notes
         return formattedData;

      }
      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["Assigned To Technician"]]): {
         let moditifiedValue = { ...VALUES };
         let formattedData: any = FORMAT_VALUE_FOR_UPDATE(moditifiedValue, JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Assignable To Technician"]]);
         const formattedAdditional = VALUES?.additionalExpenses?.map((data: any, index: number) => {
            let object = {
               ...data,
               // file: VALUES?.additionalExpenseFiles?.[index] || [],
               file: VALUES?.base64Files?.[index],
               fileContentType: VALUES?.additionalExpenseFiles?.[index]?.[0]?.type,
               fileName: VALUES?.additionalExpenseFiles?.[index]?.[0]?.name,
            };
            return object;
         });
         formattedData["additionalExpensesNewFormat"] = formattedAdditional;
         formattedData['readyToInvoice'] = {
            melObservations: VALUES.melObservations ? VALUES.melObservations : null,
            completeServiceInformation: VALUES.completeServiceInformation ? VALUES.completeServiceInformation : null,
            packagedAt: VALUES.packagedAt ? ConvertIsoStringWithTime(VALUES.packagedAt) : null,
            packingComplete: VALUES.packingComplete ? VALUES.packingComplete : false,
            cleaningComplete: VALUES.cleaningComplete ? VALUES.cleaningComplete : false,
         };
         if (VALUES.franchiseArrived == true) {
            formattedData["franchiseCurrentLat"] = VALUES.franchiseCurrentLat ? VALUES.franchiseCurrentLat : null;
            formattedData["franchiseCurrentLag"] = VALUES.franchiseCurrentLag ? VALUES.franchiseCurrentLag : null;
            formattedData["franchiseArrived"] = VALUES.franchiseArrived;
            formattedData["franchiseArrivedOrNot"] = VALUES.franchiseArrivedOrNot;
         }
         formattedData["perfectionFiles"] = VALUES?.correctedFiles?.length > 0 ? VALUES?.correctedFiles : null;
         return formattedData;
      }
      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["Ready To Invoice"]]): {
         if (!VALUES.isFranchise) {
            let moditifiedValue = { ...VALUES };
            let formattedData: any = FORMAT_VALUE_FOR_UPDATE(moditifiedValue, JOB_SHEET_STATUS[JOB_SHEET_STATUS["Assigned To Technician"]]);
            // formattedData['outgoingDispatch'] = {
            //    courierId: VALUES.courierId ? VALUES.courierId : null,
            //    packedBy: VALUES.packedBy ? VALUES.packedBy : null,
            //    packedDate: VALUES.packedDate ? ConvertIsoStringWithTime(VALUES.packedDate) : null,
            //    notes: VALUES.notes ? VALUES.notes : null,
            //    deliveryDate: VALUES.deliveryDate ? ConvertIsoStringWithTime(VALUES.deliveryDate) : null,
            //    deliveryType: VALUES.deliveryType ? VALUES.deliveryType : null,
            //    deliveredTo: VALUES.deliveredTo ? VALUES.deliveredTo : null,
            //    deliveredById: VALUES.deliveredById ? VALUES.deliveredById : null,
            //    deliveredUserType: VALUES.deliveredUserType ? VALUES.deliveredUserType : null,
            //    approximateValue: VALUES.approximateValue ? VALUES.approximateValue : null,
            // };
            let outgoingDispatchNewId = VALUES.outgoingDispatchListNew[0]?.id
            let filteredOutGoingDispatch = VALUES.outgoingDispatchList?.filter((val:any)=>val.id!=outgoingDispatchNewId)
            let outgoinghDispatchListData = [...filteredOutGoingDispatch,...VALUES.outgoingDispatchListNew]?.filter((data:any)=>data.packedBy !="")

            formattedData['outgoingDispatchList'] = outgoinghDispatchListData.length>0 ? outgoinghDispatchListData?.map((val:any)=>({
               jobId:VALUES.id,
               id:val?.id ? val?.id : 0,
               courierId: val.courierId ? val.courierId : null,
               packedBy: val.packedBy ? val.packedBy : null,
               packedDate: val.packedDate ? ConvertIsoStringWithTime(val.packedDate) : null,
               notes: val.notes ? val.notes : null,
               deliveryDate: val.deliveryDate ? ConvertIsoStringWithTime(val.deliveryDate) : null,
               deliveryType: val.deliveryType ? val.deliveryType : null,
               deliveredTo: val.deliveredTo ? val.deliveredTo : null,
               deliveredById: val.deliveredById ? val.deliveredById : null,
               deliveredUserType: val.deliveredUserType ? val.deliveredUserType : null,
               approximateValue: val.approximateValue ? val.approximateValue : null,
            })) : []
            return formattedData;
         } else {
            let moditifiedValue = { ...VALUES };
            let formattedData: any = FORMAT_VALUE_FOR_UPDATE(moditifiedValue, JOB_SHEET_STATUS[JOB_SHEET_STATUS["Assigned To Technician"]]);
            // formattedData['outgoingDispatch'] = {
            //    courierId: VALUES.courierId ? VALUES.courierId : null,
            //    packedBy: VALUES.packedBy ? VALUES.packedBy : null,
            //    packedDate: VALUES.packedDate ? ConvertIsoStringWithTime(VALUES.packedDate) : null,
            //    notes: VALUES.notes ? VALUES.notes : null,
            //    deliveryDate: VALUES.deliveryDate ? ConvertIsoStringWithTime(VALUES.deliveryDate) : null,
            //    deliveryType: VALUES.deliveryType ? VALUES.deliveryType : null,
            //    deliveredTo: VALUES.deliveredTo ? VALUES.deliveredTo : null,
            //    deliveredById: VALUES.deliveredById ? VALUES.deliveredById : null,
            //    deliveredUserType: VALUES.deliveredUserType ? VALUES.deliveredUserType : null,
            //    approximateValue: VALUES.approximateValue ? VALUES.approximateValue : null,
            //    expenseName: VALUES.expenseName ? VALUES.expenseName : null,
            //    taxCode: VALUES.taxCode ? VALUES.taxCode : null,
            //    taxPercentage: VALUES.taxPercentage ? VALUES.taxPercentage : 0,
            //    amountWithTax: VALUES.amountWithTax ? VALUES.amountWithTax : 0,
            //    files: VALUES.fileData ? VALUES.fileData : null,
            //    additionalExpenseFiles: VALUES?.additionalExpenseFiles
            //       ?.length > 0 ? VALUES?.additionalExpenseFiles
            //       : null

            // };
            let outgoingDispatchNewId = VALUES.outgoingDispatchListNew[0]?.id
            let filteredOutGoingDispatch = VALUES.outgoingDispatchList?.filter((val:any)=>val.id!=outgoingDispatchNewId)
            let outgoinghDispatchListData = [...filteredOutGoingDispatch,...VALUES.outgoingDispatchListNew]?.filter((data:any)=>data.packedBy !="")

            formattedData['outgoingDispatchList'] = outgoinghDispatchListData.length>0 ? outgoinghDispatchListData?.map((val:any)=>({
               jobId:VALUES.id,
               id:val?.id ? val?.id : 0,
               courierId: val.courierId ? val.courierId : null,
               packedBy: val.packedBy ? val.packedBy : null,
               packedDate: val.packedDate ? ConvertIsoStringWithTime(val.packedDate) : null,
               notes: val.notes ? val.notes : null,
               deliveryDate: val.deliveryDate ? ConvertIsoStringWithTime(val.deliveryDate) : null,
               deliveryType: val.deliveryType ? val.deliveryType : null,
               deliveredTo: val.deliveredTo ? val.deliveredTo : null,
               deliveredById: val.deliveredById ? val.deliveredById : null,
               deliveredUserType: val.deliveredUserType ? val.deliveredUserType : null,
               approximateValue: val.approximateValue ? val.approximateValue : null,
            })) : []
            return formattedData;
         }
      }
      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["Franchise Job Completed"]]): {
         if (!VALUES.isFranchise) {
            let moditifiedValue = { ...VALUES };
            let formattedData: any = FORMAT_VALUE_FOR_UPDATE(moditifiedValue, JOB_SHEET_STATUS[JOB_SHEET_STATUS["Assigned To Technician"]]);
            // formattedData['outgoingDispatch'] = {
            //    courierId: VALUES.courierId ? VALUES.courierId : null,
            //    packedBy: VALUES.packedBy ? VALUES.packedBy : null,
            //    packedDate: VALUES.packedDate ? ConvertIsoStringWithTime(VALUES.packedDate) : null,
            //    notes: VALUES.notes ? VALUES.notes : null,
            //    deliveryDate: VALUES.deliveryDate ? ConvertIsoStringWithTime(VALUES.deliveryDate) : null,
            //    deliveryType: VALUES.deliveryType ? VALUES.deliveryType : null,
            //    deliveredTo: VALUES.deliveredTo ? VALUES.deliveredTo : null,
            //    deliveredById: VALUES.deliveredById ? VALUES.deliveredById : null,
            //    deliveredUserType: VALUES.deliveredUserType ? VALUES.deliveredUserType : null,
            //    approximateValue: VALUES.approximateValue ? VALUES.approximateValue : null,
            // };
            let outgoingDispatchNewId = VALUES.outgoingDispatchListNew[0]?.id
            let filteredOutGoingDispatch = VALUES.outgoingDispatchList?.filter((val:any)=>val.id!=outgoingDispatchNewId)
            let outgoinghDispatchListData = [...filteredOutGoingDispatch,...VALUES.outgoingDispatchListNew]?.filter((data:any)=>data.packedBy !="")

            formattedData['outgoingDispatchList'] = outgoinghDispatchListData.length>0 ? outgoinghDispatchListData?.map((val:any)=>({
               jobId:VALUES.id,
               id:val?.id ? val?.id : 0,
               courierId: val.courierId ? val.courierId : null,
               packedBy: val.packedBy ? val.packedBy : null,
               packedDate: val.packedDate ? ConvertIsoStringWithTime(val.packedDate) : null,
               notes: val.notes ? val.notes : null,
               deliveryDate: val.deliveryDate ? ConvertIsoStringWithTime(val.deliveryDate) : null,
               deliveryType: val.deliveryType ? val.deliveryType : null,
               deliveredTo: val.deliveredTo ? val.deliveredTo : null,
               deliveredById: val.deliveredById ? val.deliveredById : null,
               deliveredUserType: val.deliveredUserType ? val.deliveredUserType : null,
               approximateValue: val.approximateValue ? val.approximateValue : null,
            })) : []
            return formattedData;
         } else {
            let moditifiedValue = { ...VALUES };
            let formattedData: any = FORMAT_VALUE_FOR_UPDATE(moditifiedValue, JOB_SHEET_STATUS[JOB_SHEET_STATUS["Assigned To Technician"]]);
            // formattedData['outgoingDispatch'] = {
            //    courierId: VALUES.courierId ? VALUES.courierId : null,
            //    packedBy: VALUES.packedBy ? VALUES.packedBy : null,
            //    packedDate: VALUES.packedDate ? ConvertIsoStringWithTime(VALUES.packedDate) : null,
            //    notes: VALUES.notes ? VALUES.notes : null,
            //    deliveryDate: VALUES.deliveryDate ? ConvertIsoStringWithTime(VALUES.deliveryDate) : null,
            //    deliveryType: VALUES.deliveryType ? VALUES.deliveryType : null,
            //    deliveredTo: VALUES.deliveredTo ? VALUES.deliveredTo : null,
            //    deliveredById: VALUES.deliveredById ? VALUES.deliveredById : null,
            //    deliveredUserType: VALUES.deliveredUserType ? VALUES.deliveredUserType : null,
            //    approximateValue: VALUES.approximateValue ? VALUES.approximateValue : null,

            //    // expenseName: VALUES.expenseName ? VALUES.expenseName : null,
            //    // taxCode: VALUES.taxCode ? VALUES.taxCode : null,
            //    // taxPercentage: VALUES.taxPercentage ? VALUES.taxPercentage : 0,
            //    // amountWithTax: VALUES.amountWithTax ? VALUES.amountWithTax : 0,
            //    additionalExpenses: VALUES.additionalExpenses?.length > 0 ? VALUES.additionalExpenses : null

            // };
            let outgoingDispatchNewId = VALUES.outgoingDispatchListNew[0]?.id
            let filteredOutGoingDispatch = VALUES.outgoingDispatchList?.filter((val:any)=>val.id!=outgoingDispatchNewId)
            let outgoinghDispatchListData = [...filteredOutGoingDispatch,...VALUES.outgoingDispatchListNew]?.filter((data:any)=>data.packedBy !="")

            formattedData['outgoingDispatchList'] = outgoinghDispatchListData.length>0 ? outgoinghDispatchListData?.map((val:any)=>({
               jobId:VALUES.id,
               id:val?.id ? val?.id : 0,
               courierId: val.courierId ? val.courierId : null,
               packedBy: val.packedBy ? val.packedBy : null,
               packedDate: val.packedDate ? ConvertIsoStringWithTime(val.packedDate) : null,
               notes: val.notes ? val.notes : null,
               deliveryDate: val.deliveryDate ? ConvertIsoStringWithTime(val.deliveryDate) : null,
               deliveryType: val.deliveryType ? val.deliveryType : null,
               deliveredTo: val.deliveredTo ? val.deliveredTo : null,
               deliveredById: val.deliveredById ? val.deliveredById : null,
               deliveredUserType: val.deliveredUserType ? val.deliveredUserType : null,
               approximateValue: val.approximateValue ? val.approximateValue : null,
            })) : []
            return formattedData;
         }
      }
      case (JOB_SHEET_STATUS[JOB_SHEET_STATUS["Invoiced Job"]]): {
         let moditifiedValue = { ...VALUES };
         let formattedData: any = FORMAT_VALUE_FOR_UPDATE(moditifiedValue, JOB_SHEET_STATUS[JOB_SHEET_STATUS["Ready To Invoice"]]);
         return formattedData;

      }


   }
}



