import React, { useEffect, useMemo, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import FloatInput from 'src/components/Common/FloatInput';
import { CHECK_VALUE_IN_ARRAY, JOB_SHEET_STATUS } from 'src/helpers/jobsheet_validation';
import { useDispatch, useSelector } from 'react-redux';
import { audioModelDetailsForJobLoading, getJobsheetHistoryByAudioSerial } from 'src/store/actions';
import { RootReducerState } from 'src/store/reducers';
import { AudioModelDetailsForJobSheet } from 'src/models/pages/jobsheetModel';
import FeatherIcon from "feather-icons-react";
import { AnimatePresence, motion } from 'framer-motion';
import CheckboxInput from "src/components/UI/CheckboxInput";
import { ErrorMessage } from 'formik';
import ErrorText from '../ErrorText';
import TextEditor from 'src/components/atoms/TextEditor';

interface TabProps {
  activeTab: string | number;
  values: any;
  status: string;
  touch: any;
  error: any;
  audioModel: any;
  isdealer: boolean;
  handleChange: (e: any) => void;
  onBlur?: any;
  validationConfiguration?: any;
  setFieldValue: any;
  setFieldTouched: any;
  setFieldError: any;
  accordion?: any;
  changeAccorditionHandler?: any;
  pastHistoryData?: any;
  carModelIdDropdownOptions?:any;
}

const JobSheetDetails = (props: TabProps) => {
  const [autoModel, setAutoModel] = useState<any>([]);
  const currentUser: any = localStorage.getItem("authUser");
  const parseUser = JSON.parse(currentUser);
  
  const dispatch = useDispatch();

  useEffect(() => {
    const customerListdrop = props.audioModel?.length > 0 ? (props.audioModel.map((audio: any) => {
      let data: any = {};
      data['value'] = audio.id;
      data['label'] = `${audio.audioModelName}(${audio.audioBrandName})`;
      return data;
    })).sort((a: any, b: any) => {
      const labelA = a.label.toUpperCase().trim();
      const labelB = b.label.toUpperCase().trim();
      if (labelA < labelB) {
        return -1;
      }
      if (labelA > labelB) {
        return 1;
      }
      return 0;
    }) : []
    setAutoModel(customerListdrop)

  }, [props.audioModel])

  const carModelDropdownOptions:any = useMemo(()=>{
    let data = props.carModelIdDropdownOptions?.length>0 ? props.carModelIdDropdownOptions?.map((data:any)=>({
      label:data.carModelName,
      value:data.id,
    })) : []
    return data
      },[props.carModelIdDropdownOptions])


  const resetAudioModelDetails = (setFieldValue: any) => {
    props.setFieldError("serviceCharge", "")
    props.setFieldError("serviceChargeTaxPercentage", "")
    props.setFieldError("serviceChargeTaxAmount", "")
    props.setFieldError("serviceChargeTotalWithTax", "")
    props.setFieldError("audioPartNumber", "")

    props.setFieldTouched("serviceCharge", false)
    props.setFieldTouched("serviceChargeTaxPercentage", false)
    props.setFieldTouched("serviceChargeTaxAmount", false)
    props.setFieldTouched("serviceChargeTotalWithTax", false)
    props.setFieldTouched("audioPartNumber", false)

    setFieldValue("serviceCharge", "");
    setFieldValue("serviceChargeTaxPercentage", "");
    setFieldValue("serviceChargeTaxAmount", "");
    setFieldValue("serviceChargeTotalWithTax", "");
    setFieldValue("audioPartNumber", "");
  }


  const audioModelChangeHandler = (value: any, currentValueState: any) => {
    resetAudioModelDetails(props.setFieldValue);
    if (value) {
      dispatch(audioModelDetailsForJobLoading(value, currentValueState?.isExchange))
    }
  };

  const { audioModelDetails, jobsheet } = useSelector((state: RootReducerState) => state.jobsheetReducer);
  

  useEffect(() => {
    let detail = (audioModelDetails as AudioModelDetailsForJobSheet)
    props.setFieldValue("serviceCharge", (props.values?.warrantyStatus == "In Warranty") ? 0 : detail?.serviceCharge ? detail?.serviceCharge : 0 )
    props.setFieldValue("serviceChargeTaxPercentage", detail?.taxpercentage)
    props.setFieldValue("serviceChargeTaxAmount",(props.values?.warrantyStatus == "In Warranty") ? 0 :  detail?.serviceChargeTaxAmount ? detail?.serviceChargeTaxAmount : 0)
    props.setFieldValue("serviceChargeTotalWithTax",(props.values?.warrantyStatus == "In Warranty") ? 0 :  detail?.serviceChargeTotalWithTax ? detail?.serviceChargeTotalWithTax : 0)
    props.setFieldValue("audioPartNumber", detail?.audioPartNumber)
  }, [audioModelDetails])

  // useEffect(() => {
  //   if(!props.values.id){
  //     props.setFieldValue("preparedByName", parseUser['username'])
  //   }
  // }, [])

  useEffect(() => {
    props.setFieldValue("historicalJobsForAudioSystem", props.pastHistoryData)
  }, [props.pastHistoryData])

  return (
    <div className={` `} >
      <Card className='pe-0 ps-0 mb-0 border-leftrigh-none'>
        <div className='dealer-title-header font-size-15 border-bottom'> <span><FeatherIcon className="pointer" icon={props.accordion['vehicleInformation'] ? "chevron-down" : "chevron-right"} onClick={() => props.changeAccorditionHandler('vehicleInformation')} /></span> Vehicle Information</div>
        <AnimatePresence>
          {props.accordion['vehicleInformation'] && (<motion.div
            className="accordion-content"
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
          ><CardBody className='p-0'>
              <div className='px-3 pb-3'>
                <Row>
                  <Col lg={3} md={6} className="mt-mb">
                    <FloatInput
                      type="text"
                      name="vinNumber"
                      label="Vin number"
                      value={props.values.vinNumber}
                      invalid={props.touch["vinNumber"] && props.error["vinNumber"] ? true : false}
                      onChange={(e) => {
                        let event = { ...e }
                        event.target.value = e.target.value.toLocaleUpperCase()
                        props.handleChange(event)
                      }}
                      onBlur={props.onBlur}
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "vinNumber")}
                      disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "vinNumber")}
                    />
                  </Col>
                  <Col lg={3} md={6} className="mt-mb">
                    <FloatInput
                      type="text"
                      label="Vehicle registration number"
                      name="vehicleRegistrationNumber"
                      onChange={(e) => {
                        let event = { ...e }
                        event.target.value = e.target.value.toLocaleUpperCase()
                        props.handleChange(event)
                      }}
                      onBlur={props.onBlur}
                      invalid={props.touch["vehicleRegistrationNumber"] && props.error["vehicleRegistrationNumber"] ? true : false}
                      value={props.values.vehicleRegistrationNumber}
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "vehicleRegistrationNumber")}
                      disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "vehicleRegistrationNumber")}
                    />
                  </Col>
                  <Col lg={3} md={6} className="mt-mb">
                    {/* <FloatInput
                      label="Vehicle model"
                      type="text"
                      name="vehicleModel"
                      onChange={(e) => {
                        let event = { ...e }
                        event.target.value = e.target.value.toLocaleUpperCase()
                        props.handleChange(event)
                      }}
                      onBlur={props.onBlur}
                      invalid={props.touch["vehicleModel"] && props.error["vehicleModel"] ? true : false}
                      value={props.values.vehicleModel}
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "vehicleModel")}
                      disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "vehicleModel")}
                    /> */}
                    <FloatInput
                      type="dropdown"
                      name="carModelId"
                      label="Vehicle Model"
                      options={carModelDropdownOptions}
                      onChange={(e) => {
                        props.handleChange(e);
                      }
                    }
                    onBlur={props.onBlur}
                    value={props.values.carModelId}
                    invalid={props.touch["carModelId"] && props.error["carModelId"] ? true : false}
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "carModelId")}
                      disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "carModelId") || !props.values.vehicleBrandId}
                    />
                  </Col>
                  <Col lg={3} md={6} className="mt-mb">
                    <FloatInput
                      label="Service request received date"
                      type="date"
                      name="afcsReceivedDate"
                      onChange={props.handleChange}
                      onBlur={props.onBlur}
                      value={props.values.afcsReceivedDate}
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "afcsReceivedDate")}
                      disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "afcsReceivedDate")}
                    />
                  </Col>
                  <Col lg={3} md={6} className="mt-mb">
                    <FloatInput
                      label="Request date"
                      type="date"
                      name="requestDate"
                      onChange={props.handleChange}
                      onBlur={props.onBlur}
                      invalid={props.touch["requestDate"] && props.error["requestDate"] ? true : false}
                      value={props.values.requestDate}
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "requestDate")}
                      disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "requestDate")}
                    />
                  </Col>
                  <Col lg={3} md={6} className="mt-mb">
                    <FloatInput
                      type="dropdown"
                      name="audioModel"
                      label="Audio Model"
                      options={props.values.vehicleBrandId ? autoModel : []}
                      invalid={props.touch["audioModel"] && props.error["audioModel"] ? true : false}
                      onChange={(e) => {
                        props.handleChange(e);
                        props.setFieldValue('audioModelName', autoModel.filter((each: any) => each.value == e.target.value)?.[0]?.label)
                        audioModelChangeHandler(e.target.value, props.values);
                      }
                      }
                      onBlur={props.onBlur}
                      value={props.values.audioModel}
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "audioModel")}
                      disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "audioModel")}
                    />
                  </Col>
                  <Col lg={3} md={6} className="mt-mb">
                    <FloatInput
                      label="Service charge"
                      type="number"
                      name="serviceCharge"
                      onChange={props.handleChange}
                      onBlur={props.onBlur}
                      value={props.values.serviceCharge}
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "serviceCharge")}
                      disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "serviceCharge")}
                    />
                  </Col>
                  <Col lg={3} md={6} className="mt-mb">
                    <FloatInput
                      label="Service charge tax percentage"
                      type="number"
                      name="serviceChargeTaxPercentage"
                      onChange={props.handleChange}
                      onBlur={props.onBlur}
                      value={props.values.serviceChargeTaxPercentage}
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "serviceChargeTaxPercentage")}
                      disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "serviceChargeTaxPercentage")}
                    />
                  </Col>
                  <Col lg={3} md={6} className="mt-mb">
                    <FloatInput
                      label="Service charge tax amount"
                      type="number"
                      name="serviceChargeTaxAmount"
                      onChange={props.handleChange}
                      onBlur={props.onBlur}
                      value={props.values.serviceChargeTaxAmount}
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "serviceChargeTaxAmount")}
                      disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "serviceChargeTaxAmount")}
                    />
                  </Col>
                  <Col lg={3} md={6} className="mt-mb">
                    <FloatInput
                      label="Service charge total with tax"
                      type="number"
                      name="serviceChargeTotalWithTax"
                      onChange={props.handleChange}
                      onBlur={props.onBlur}
                      value={props.values.serviceChargeTotalWithTax}
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "serviceChargeTotalWithTax")}
                      disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "serviceChargeTotalWithTax")}
                    />
                  </Col>
                  <Col lg={3} md={6} className="mt-mb">
                    <FloatInput
                      label="New Audio Serial Number"
                      type="text"
                      name="exchangeAudioSerialNumber"
                      onChange={props.handleChange}
                      onBlur={props.onBlur}
                      invalid={props.touch["exchangeAudioSerialNumber"] && props.error["exchangeAudioSerialNumber"] ? true : false}
                      value={props.values.exchangeAudioSerialNumber}
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "exchangeAudioSerialNumber")}
                      disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "exchangeAudioSerialNumber")}
                    />
                  </Col>
                  <Col lg={3} md={6} className="mt-mb">
                    <FloatInput
                      label="Failed Audio serial number"
                      type="text"
                      name="audioSerialNumber"
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "audioSerialNumber")}
                      disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "audioSerialNumber")}
                      invalid={props.touch["audioSerialNumber"] && props.error["audioSerialNumber"] ? true : false}
                      // onChange={props.handleChange}
                      onChange={(e) => {
                        let event = { ...e }
                        event.target.value = e.target.value.toLocaleUpperCase()
                        props.handleChange(event)
                      }}
                      onBlur={() => {
                        props.onBlur
                        if (props.values.status == JOB_SHEET_STATUS[JOB_SHEET_STATUS["New Job"]] && props.values.audioSerialNumber) {
                          dispatch(getJobsheetHistoryByAudioSerial(props.values.audioSerialNumber))
                        }
                      }
                      }
                      value={props.values.audioSerialNumber}
                    />
                  </Col>
                  <Col lg={3} md={6} className="mt-mb">
                    <FloatInput
                      label="Historical jobs for audio system"
                      type="text"
                      name="historicalJobsForAudioSystem"
                      className={props.values.historicalJobsForAudioSystem ? "color-yello" : ""}
                      onChange={props.handleChange}
                      onBlur={props.onBlur}
                      value={props.values.historicalJobsForAudioSystem}
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "historicalJobsForAudioSystem")}
                      disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "historicalJobsForAudioSystem")}
                    />
                  </Col>
                  <Col lg={3} md={6} className="mt-mb">
                    <FloatInput
                      label="Audio part number"
                      type="text"
                      name="audioPartNumber"
                      invalid={props.touch["audioPartNumber"] && props.error["audioPartNumber"] ? true : false}
                      onChange={props.handleChange}
                      onBlur={props.onBlur}
                      value={props.values.audioPartNumber}
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "audioPartNumber")}
                      disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "audioPartNumber")}
                    />
                  </Col>
                  <Col lg={3} md={6} className="mt-mb">
                    <FloatInput
                      label="Vehicle Date of Sale"
                      type="date"
                      name="dateOfSale"
                      invalid={props.touch["dateOfSale"] && props.error["dateOfSale"] ? true : false}
                      onChange={props.handleChange}
                      onBlur={props.onBlur}
                      value={props.values.dateOfSale}
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "dateOfSale")}
                      disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "dateOfSale")}
                    />
                  </Col>
                  <Col lg={3} md={6} className="mt-mb">
                    <FloatInput
                      label="Failed Audio Manufactured Date"
                      type="date"
                      name="manufacturedDate"
                      onChange={props.handleChange}
                      onBlur={props.onBlur}
                      invalid={props.touch["manufacturedDate"] && props.error["manufacturedDate"] ? true : false}
                      value={props.values.manufacturedDate}
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "manufacturedDate")}
                      disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "manufacturedDate")}
                    />
                  </Col>
                  <Col lg={3} md={6} className="mt-mb">
                    <FloatInput
                      label="Failed date"
                      type="date"
                      name="failedDate"
                      onChange={props.handleChange}
                      onBlur={props.onBlur}
                      invalid={props.touch["failedDate"] && props.error["failedDate"] ? true : false}
                      value={props.values.failedDate}
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "failedDate")}
                      disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "failedDate")}
                    />
                  </Col>
                  <Col lg={3} md={6} className="mt-mb">
                    <FloatInput
                      label="Last repair date"
                      type="date"
                      name="lastRepairDate"
                      onChange={props.handleChange}
                      onBlur={props.onBlur}
                      value={props.values.lastRepairDate}
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "lastRepairDate")}
                      disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "lastRepairDate")}
                    />
                  </Col>
                  <Col lg={3} md={6} className="mt-mb">
                    <FloatInput
                      label="Vehicle KM covered"
                      type="number"
                      name="kmsCovered"
                      invalid={props.touch["kmsCovered"] && props.error["kmsCovered"] ? true : false}
                      onChange={props.handleChange}
                      onBlur={props.onBlur}
                      value={props.values.kmsCovered}
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "kmsCovered")}
                      disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "kmsCovered")}
                    />
                  </Col>
                  <Col lg={3} md={6} className="mt-mb">
                    <FloatInput
                      name="preparedByName"
                      label="Prepared by"
                      type="text"
                      invalid={false}
                      onChange={props.handleChange}
                      onBlur={props.onBlur}
                      value={props.values.preparedByName}
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "preparedByName")}
                      disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "preparedByName")}
                    />
                  </Col>
                </Row>

              </div>
            </CardBody></motion.div>)}
        </AnimatePresence>
      </Card>

      
      <Card className='pe-0 ps-0 mb-0 border-leftrigh-none'>
        <div className='dealer-title-header font-size-15 border-bottom'> <span><FeatherIcon className="pointer" icon={props.accordion['complaintInformation'] ? "chevron-down" : "chevron-right"} onClick={() => props.changeAccorditionHandler('complaintInformation')} /></span> Complaint Information</div>
        <AnimatePresence>
          {props.accordion['complaintInformation'] && (<motion.div
            className="accordion-content"
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
          ><CardBody className='p-0'>
              <div className='px-3 pb-3'>
                <Row >
                  <Col lg={4} md={6} className="mt-mb-textarea">
                    {/* <FloatInput
                      label="Customer concern"
                      type="textarea"
                      name="customerConcern"
                      onChange={props.handleChange}
                      onBlur={props.onBlur}
                      value={props.values.customerConcern}
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "customerConcern")}
                      disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "customerConcern")}
                      invalid={props.touch["customerConcern"] && props.error["customerConcern"] ? true : false}
                    /> */}
                   
                    <TextEditor
                      onChange={props.handleChange}
                      labelName='Customer concern'
                      name='customerConcern'
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "customerConcern") ? true : false}
                      value={props.values.customerConcern}
                      invalid={props.touch["customerConcern"] && props.error["customerConcern"] ? true : false}
                      setFieldTouched={props.setFieldTouched}
                      setFieldValue={props.setFieldValue}
                      onBlur={props.onBlur}
                      readOnly={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "customerConcern") ? true : false}
                    />
                  </Col>
                  <Col lg={4} md={6} className="mt-mb-textarea">
                    {/* <FloatInput
                      label="Dealer observation"
                      type="textarea"
                      name="dealerObservation"
                      onChange={props.handleChange}
                      onBlur={props.onBlur}
                      value={props.values.dealerObservation}
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "dealerObservation")}
                      disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "dealerObservation")}
                      invalid={props.touch["dealerObservation"] && props.error["dealerObservation"] ? true : false}
                    /> */}
                  
                    <TextEditor
                      onChange={props.handleChange}
                      labelName='Dealer observation'
                      name='dealerObservation'
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "dealerObservation") ? true : false}
                      value={props.values.dealerObservation}
                      invalid={props.touch["dealerObservation"] && props.error["dealerObservation"] ? true : false}
                      setFieldTouched={props.setFieldTouched}
                      setFieldValue={props.setFieldValue}
                      onBlur={props.onBlur}
                      readOnly={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "dealerObservation") ? true : false}
                    />
                    
                  </Col>
                  <Col lg={4} md={6} className="mt-mb-textarea">
                    {/* <FloatInput
                      label="Accessories list"
                      type="textarea"
                      name="accessoriesList"
                      onChange={props.handleChange}
                      onBlur={props.onBlur}
                      value={props.values.accessoriesList}
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "accessoriesList")}
                      disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "accessoriesList")}
                      invalid={props.touch["accessoriesList"] && props.error["accessoriesList"] ? true : false}
                    /> */}
              
                    <TextEditor
                      onChange={props.handleChange}
                      labelName='Accessories list'
                      name='accessoriesList'
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "accessoriesList") ? true : false}
                      value={props.values.accessoriesList}
                      invalid={props.touch["accessoriesList"] && props.error["accessoriesList"] ? true : false}
                      setFieldTouched={props.setFieldTouched}
                      setFieldValue={props.setFieldValue}
                      onBlur={props.onBlur}
                      readOnly={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "accessoriesList") ? true : false}
                    />
                    
                  </Col>
                  <Col lg={4} md={6} className="mt-mb-textarea">
                    {/* <FloatInput
                      label="Missing accessories"
                      type="textarea"
                      name="missingAccessories"
                      onChange={props.handleChange}
                      onBlur={props.onBlur}
                      value={props.values.missingAccessories}
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "missingAccessories")}
                      disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "missingAccessories")}
                      invalid={props.touch["missingAccessories"] && props.error["missingAccessories"] ? true : false}
                    /> */}
                    <TextEditor
                      onChange={props.handleChange}
                      labelName='Missing accessories'
                      name='missingAccessories'
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "missingAccessories") ? true : false}
                      value={props.values.missingAccessories}
                      invalid={props.touch["missingAccessories"] && props.error["missingAccessories"] ? true : false}
                      setFieldTouched={props.setFieldTouched}
                      setFieldValue={props.setFieldValue}
                      onBlur={props.onBlur}
                      readOnly={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "missingAccessories") ? true : false}
                    />
                  </Col>
                  <Col lg={4} md={6} className="mt-mb-textarea">
                    {/* <FloatInput
                      label="Additional remarks"
                      type="textarea"
                      name="additionalRemarks"
                      onChange={props.handleChange}
                      onBlur={props.onBlur}
                      value={props.values.additionalRemarks}
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "additionalRemarks")}
                      disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "additionalRemarks")}
                      invalid={props.touch["additionalRemarks"] && props.error["additionalRemarks"] ? true : false}
                    /> */}
                    <TextEditor
                      onChange={props.handleChange}
                      labelName='Additional remarks'
                      name='additionalRemarks'
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "additionalRemarks") ? true : false}
                      value={props.values.additionalRemarks}
                      invalid={props.touch["additionalRemarks"] && props.error["additionalRemarks"] ? true : false}
                      setFieldTouched={props.setFieldTouched}
                      setFieldValue={props.setFieldValue}
                      onBlur={props.onBlur}
                      readOnly={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "additionalRemarks") ? true : false}
                    />

                  </Col>
                  <Col lg={4} md={6} className="mt-mb-textarea">
                    {/* <FloatInput
                      label="Mel observations"
                      type="textarea"
                      name="melObservations"
                      onChange={props.handleChange}
                      onBlur={props.onBlur}
                      value={props.values.melObservations}
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "melObservations")}
                      disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "melObservations")}
                      invalid={props.touch["melObservations"] && props.error["melObservations"] ? true : false}
                    /> */}
                     <TextEditor
                      onChange={props.handleChange}
                      labelName='Mel observations'
                      name='melObservations'
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "melObservations") ? true : false}
                      value={props.values.melObservations}
                      invalid={props.touch["melObservations"] && props.error["melObservations"] ? true : false}
                      setFieldTouched={props.setFieldTouched}
                      setFieldValue={props.setFieldValue}
                      onBlur={props.onBlur}
                      readOnly={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "melObservations") ? true : false}
                    />
                  </Col>
                  <Col lg={4} md={6} className="mt-mb-textarea">
                    {/* <FloatInput
                      label="Complete service information"
                      type="textarea"
                      name="completeServiceInformation"
                      onChange={props.handleChange}
                      onBlur={props.onBlur}
                      value={props.values.completeServiceInformation}
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "completeServiceInformation")}
                      disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "completeServiceInformation")}
                      invalid={props.touch["completeServiceInformation"] && props.error["completeServiceInformation"] ? true : false}
                    /> */}
                    <TextEditor
                      onChange={props.handleChange}
                      labelName='Complete service information'
                      name='completeServiceInformation'
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "completeServiceInformation") ? true : false}
                      value={props.values.completeServiceInformation}
                      invalid={props.touch["completeServiceInformation"] && props.error["completeServiceInformation"] ? true : false}
                      setFieldTouched={props.setFieldTouched}
                      setFieldValue={props.setFieldValue}
                      onBlur={props.onBlur}
                      readOnly={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "completeServiceInformation") ? true : false}
                    />
                  </Col>
                </Row>
              </div>
            </CardBody></motion.div>)}
        </AnimatePresence>



      </Card>
      {!CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "inspectionStatus") && <Card className='pe-0 ps-0 mb-0 border-leftrigh-none'>
        <div className='dealer-title-header font-size-15 border-bottom'> <span><FeatherIcon className="pointer" icon={props.accordion['packagingDetails'] ? "chevron-down" : "chevron-right"} onClick={() => props.changeAccorditionHandler('packagingDetails')} /></span> Package Details</div>
        <AnimatePresence>
          {props.accordion['packagingDetails'] && (<motion.div
            className="accordion-content"
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
          ><CardBody className='p-0'>
              <div className='px-3 pb-3'>
                <Row>

                  <Col lg={3} md={6} className="mb-1  align-items-center">
                    <CheckboxInput
                      name="packingComplete"
                      label="Packing complete"
                      onChange={props.handleChange}
                      value={props.values.packingComplete}
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "packingComplete")}
                      disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "packingComplete")}
                    />
                    <ErrorMessage name={"packingComplete"} component={ErrorText} />
                  </Col>
                  <Col lg={3} md={6} className="mb-1  align-items-center">
                    <CheckboxInput
                      name="cleaningComplete"
                      label="Cleaning complete"
                      onChange={props.handleChange}
                      value={props.values.cleaningComplete}
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "cleaningComplete")}
                      disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "cleaningComplete")}
                    />
                    <Row>
                      <ErrorMessage name={"cleaningComplete"} component={ErrorText} />
                    </Row>
                  </Col>
                  <Col lg={3} md={6} className="mt-mb">
                    <FloatInput
                      label="Packaged at"
                      type="datetime"
                      name="packagedAt"
                      onChange={props.handleChange}
                      onBlur={props.onBlur}
                      value={props.values.packagedAt}
                      invalid={props.touch["packagedAt"] && props.error["packagedAt"] ? true : false}
                      required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "packagedAt")}
                      disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "packagedAt")}
                    />
                  </Col>
                </Row>
              </div>
            </CardBody></motion.div>)}
        </AnimatePresence>



      </Card>}
    </div>
  )
}

export default JobSheetDetails