import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Col, Container, Row } from 'reactstrap';
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter'
import { ROUTER } from 'src/constants/routes'
import { useDispatch, useSelector } from 'react-redux'
import { RootReducerState } from 'src/store/reducers'
import { CurrencyFormatter, formatDateMEL, hasFranchiseRoleHandler } from 'src/helpers/common_helpers';
import StatusHghlighter from 'src/components/Common/StatusHighlighter/StatusHighlighter'
import { resetPurchaseOrder } from 'src/store/actions';
import { clearValues, deleteStockInspectionFromList, downloadStockInspection, downloadUnUsedSkusLoading, getPartSkuListBYID, getStockInspectionBYID, resetStockInspectionMessage, sentMailToStockInspection } from 'src/store/stockInspection/action'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import DeleteModal from "../../Calendar/DeleteModal";
import { useToast } from "src/components/Common/ReactToaster";
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup'
import { Link } from 'react-router-dom'

const ViewStockInspection = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const editUserClick = () => {
    navigate(`${ROUTER.PURCHASE_ORDER_EDIT}/${id}`)
  }
  
  useEffect(() => {
    if (id) {
      dispatch(getStockInspectionBYID(id));
      dispatch(getPartSkuListBYID(id));
    }
    return () => {
      dispatch(clearValues());
      dispatch(resetPurchaseOrder())
    }
  }, []);

  const formValue: any = useSelector(
    (state: RootReducerState) => state.StockInspectionReducer.stockInspectionDetail
  );

  
  const { error, message, loading ,partSkuList} = useSelector(
    (state: RootReducerState) => state.StockInspectionReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);


  const statusBodyTemplate = (value: boolean) => {
    let status = value;
    return (
      <StatusHghlighter status={status} />
    )

  }


  
  const [visible, setVisible] = useState(false);
  const { showToast } = useToast();
  const [deleteModal, setDeleteModal] = useState(false);
  
  useEffect(() => {
    if (error) {
      setVisible(true);
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(resetStockInspectionMessage());
      if(message == "Stock Inspection is deleted successfully"){
        navigate(`${ROUTER.STOCK_INSPECTION_GRID}`)
      }
    }
  }, [error, message]);

  const supplierBodyTemplate = (rowData:any) => {
    
    return (
      <>
      {!hasFranchiseRoleHandler ?  <Link to={`${ROUTER.SUPPLIER_VIEW}/${formValue?.supplierId}`}>{rowData}</Link> : rowData}
     
      </>
    );
  };
  const AudioBrandBodyTemplate = (rowData:any) => {
    
    return (
      <>
      {!hasFranchiseRoleHandler ?
      <Link to={`${ROUTER.AUDIO_BRANDS_VIEW}/${formValue?.audioBrandId}`}>{rowData}</Link>: rowData}
     
      </>
    );
  };

  const userView = {
    generalDetails: [
      {
        name: "Initiated By",
        value: formValue?.initiatedByName
      },
      {
        name: "Stock Location",
        value: formValue?.locationName
      },
      {
        name: "Supplier",
        value: supplierBodyTemplate( formValue?.supplierName)
      },
      {
        name: "Audio Brand",
        value: AudioBrandBodyTemplate(formValue?.audioBrandName)
      },
      {
        name: "Inspection Date",
        value:  formatDateMEL(formValue?.lockedDate)
      },
      
      {
        name: "Status",
        value: formValue?.status
      },
      {
        name: "Locked",
        value: statusBodyTemplate(formValue?.locked)
      }
    ],
    stockDetails:[

          {
            name: "Total Unused Skus Start",
            value: formValue?.totalUnusedSkuStart
          },
          {
            name: "Total Unused Skus End",
            value:  formValue?.totalUnusedSkuEnd
          },
          
          {
            name: "Total Unused Skus Value",
            value: formValue?.totalUnusedSkuValue
          },
          {
            name: "Total Used Skus Start",
            value: formValue?.totalUsedSkuStart
          },
          {
            name: "Total Used Skus End",
            value: formValue?.totalUsedSkuEnd
          },
          {
            name: "Total Used Skus Value",
            value: formValue?.totalUsedSkuValue
          },
          {
            name: "Total Missing Skus Start",
            value:  formValue?.totalMissingSkuStart
          },
          
          {
            name: "Total Missing Skus End",
            value: formValue?.totalMissingSkuEnd
          },//
          {
            name: "Total Missing Skus Value",
            value:  formValue?.totalMissingSkuValue
          },
          
          {
            name: "Total Rejected Skus Start",
            value: formValue?.totalReceivedSkuStart
          },
          {
            name: "Total Rejected Skus End",
            value: formValue?.totalReceivedSkuEnd
          },
          {
            name: "Total Rejected Skus Value",
            value: formValue?.totalReceivedSkuValue
          },
          {
            name: "Total Scrapped Skus Start",
            value: formValue?.totalScrappedSkuStart
          },
          {
            name: "Total Scrapped Skus End",
            value:  formValue?.totalScrappedSkuEnd
          },
          
          {
            name: "Total Scrapped Skus Value",
            value: formValue?.totalScrappedSkuValue
          },
          {
            name: "Total Floating Skus Start",
            value: formValue?.totalFloatingSkuStart
          },
          {
            name: "Total Floating Skus End",
            value:  formValue?.totalFloatingSkuEnd
          },
          
          {
            name: "Total Floating Skus Value",
            value: formValue?.totalFloatingSkuValue
          },
          {
            name: "Current Un Scanned Part Skus",
            value: formValue?.currentUnusedPartSkus ? formValue?.currentUnusedPartSkus?.length : null
          },
          {
            name: "Scanned Part Skus",
            value: formValue?.scannedPartSkus ? formValue?.scannedPartSkus?.length : null
          },
          
    ]
  }
  const percentageBodyTemplate = (rowData: any, field: string) => {
    return (
      <div>{rowData[field] + ' %'}</div>
    );
  };
  
  const currencyBodyTemplate = (amount:any) => {
    
    return (<div className="">{CurrencyFormatter({ value: amount, currencyCode: "INR" })}</div>);
  };

  const handleEditStockInspection = () => {
    navigate(`${ROUTER.STOCK_INSPECTION_EDIT}/${id}`)
  }

  const purchaseOrderList: any = formValue?.purchaseOrderDetailsDtos?.length > 0 ? (formValue?.purchaseOrderDetailsDtos.map((eachPurchase: any) => {
    let data: any = {};
    data['partId'] = eachPurchase?.partId;
    data['quantity'] = eachPurchase?.quantity;
    data['buyingPrice'] = eachPurchase?.buyingPrice;
    return data;
  })) : [
    {
      partId: "",
      quantity: "",
      buyingPrice: "",
    }
  ];




    const createStockInspection = () => {
      navigate(`${ROUTER.STOCK_INSPECTION_CREATE}`)
    }

    const sendEmailStockInspection = () => {
      dispatch(sentMailToStockInspection(id))
      // navigate(`${ROUTER.PURCHASE_ORDER_CREATE}`)
    }

    const printStockInspection = () => {
      dispatch(downloadStockInspection(id))
    }

    const archiveStockInpsection = ()=>{
      setDeleteModal(true)
    }

    const hidePopup = () => {
      setVisible(false);
      dispatch(resetStockInspectionMessage());
    };
    
  const dialogContainer = (error: any) => {
    return (
      <>
      <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
    </>
    );
  };

  return (
    <React.Fragment>
      <div className='userpage'>
      {visible && dialogContainer(error)}
      <DeleteModal
          show={deleteModal}
          undo={false}
          onDeleteClick={() => {
            let payload = {
              id: id,
              undo: false,
            };
            dispatch(deleteStockInspectionFromList(payload));
            setDeleteModal(false);
          }}
          onCloseClick={() => {
            setDeleteModal(false);
          }}
        />
        <Container fluid>
          <BreadcrumbWithFilter
            title="Stock Inspection" titlePath={ROUTER.STOCK_INSPECTION_GRID} breadcrumbItem='Stock Inspection Details'
            isShowCreate={false}
            isShowFilter={false}
            filter={false}

            isShowFirstButton={(formValue?.locked == true && formValue?.status =="Completed") ? false : ((formValue?.locked == false && formValue?.status =="Completed")) ? true : (formValue?.status =="Cancelled") ? false : true}
            firstButtonLabel='Edit Stock Inspection'
            onFirstButtonClick={handleEditStockInspection}

            isShowSecondButton={formValue?.locked ? false : true}
            secondButtonLabel='Archive Stock Inspection'
            onSecondButtonClick={archiveStockInpsection}

            isShowThirdButton={formValue?.locked ? true : false}
            thirdButtonLabel='Email Stock Inspection'
            onThirdButtonClick={sendEmailStockInspection}

            isShowForthButton={formValue?.locked ? true : false}
            forthButtonLabel='Print Stock Inspection'
            forthButtonDisabled={false}
            
            onForthButtonClick={printStockInspection}

            isShowFifthButton={true}
            fifthButtonLabel='Download Unscanned Sku'
            onFifthButtonClick={()=>dispatch(downloadUnUsedSkusLoading(id))}

            isShowSithButton = {true}
            sixthButtonLabel ='New Stock Inspection'
            onSixthButtonClick={createStockInspection}
          />
          <Row>
            <div className="view">
              <div className="general-details">
                <b>General Details</b>
              </div>
              <div className="subview">
              <div className="parentCls">
                  {
                    userView.generalDetails.map((data, index) => {
                      return (
                        <div key={index} className={` pt-1 childCls`}>
                          <div>{data.name}</div>
                          <div>{data.value ? data.value : <div className='view-none-text'>-</div>}</div>
                        </div>
                      )
                    })
                  }
                  {/* <hr /> */}

                </div>
              </div>
            </div>
            <div className="view">
              <div className="general-details">
                <b>Stock Details</b>
              </div>
              <div className="subview">
              <div className="parentCls3">
                  {
                    userView.stockDetails.map((data, index) => {
                      return (
                        <div key={index} className={` pt-1 childCls`}>
                          <div>{data.name}</div>
                          <div>{data.value ? data.value : <div className='text-light'>-</div>}</div>
                        </div>
                      )
                    })
                  }
                  {/* <hr /> */}

                </div>
              </div>
            </div>

            <div className="view">
              <div className="general-details">
                <b>Part SKUs Details</b>
              </div>
              <div className="subview">
              <Row>
            <Col  lg={12}>
              <div className="card ps-0 pe-0 " id="table-border">
                <DataTable size='large' value={ partSkuList?partSkuList:[]} showGridlines tableStyle={{ minWidth: '50rem', padding: "30px" }}>
                  <Column field="number" header="Number" className=''></Column>
                  <Column field="partNumber"  header="Part Number" className=' '></Column>
                  <Column field="partDescription"   header="Part Description" className=' ' ></Column>
                  <Column field="partHsnNumber"  header="Part HSN Number" className='' ></Column>
                  <Column field="partTaxPercentage"  bodyClassName={'align-right'} header="Tax Percentage" body={(rowData:any)=>percentageBodyTemplate(rowData,'partTaxPercentage')}></Column>
                  <Column field="partPrice" header="Price"  bodyClassName={'align-right'} body={ (rowData) => currencyBodyTemplate(rowData['partPrice'])}></Column>
                  <Column field="totalPrice" header="Total"  bodyClassName={'align-right'} body={ (rowData) => currencyBodyTemplate(rowData['totalPrice'])}></Column>
                  <Column field="status" header="Status"></Column>
                </DataTable>
              </div>
              </Col>
            </Row>
              </div>
            </div>

          </Row>
          {/* <Row>
          <div className="view">
              <div className="general-details">
                <b>Other Details</b>
              </div>
              <div className="subview">
                <Row>
                  {
                    userView.otherDetails.map((data, index) => {
                      return (
                        <Col lg={3} key={index} className={`${index >= 4 ? "mt-4" : "mt-1"}`}>
                          <div className='mb-2'>{data.name}</div>
                          <b>{data.value?data.value:<div className='none-text'>-</div>}</b>
                        </Col>
                      )
                    })
                  }
                </Row>
                </div>
                </div>
          </Row> */}
        </Container>
      </div >
    </React.Fragment >
  )
}

export default ViewStockInspection