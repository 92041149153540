import React, { useState, useEffect, useMemo } from 'react';
import { ErrorMessage, FieldArray, Form, Formik } from 'formik';
import { useParams, useNavigate } from 'react-router';
import FloatInput from 'src/components/Common/FloatInput';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import { ROUTER } from 'src/constants/routes';
import FeatherIcon from "feather-icons-react";
import CheckboxInput from "src/components/UI/CheckboxInput";
import Button from 'src/components/UI/Button';
import { JOB_SHEET_TAB_AND_FIELD, TAB_ADD_OPTIONS, TABOPTIONS } from 'src/constants/commonConstants';
import './styles.scss';
import JobSheetDetails from 'src/components/UI/JobSheetTabs/JobSheetDetails';
import PendingComments from 'src/components/UI/JobSheetTabs/PendingComments';
import IncomingCourier from 'src/components/UI/JobSheetTabs/IncomingCourier';
import OutgoingCourier from 'src/components/UI/JobSheetTabs/OutgoingCourier';
import EstimateDetails from 'src/components/UI/JobSheetTabs/EstimateDetails';
import { RootReducerState } from 'src/store/reducers';
import InspectionInformation from 'src/components/UI/JobSheetTabs/InspectionInformation';
import { tabOption } from "src/models/components/inputWrapperModel";
import * as Yup from "yup";
import { READY_TO_INVOICE } from 'src/constants/JobSheetStatus';
import { applicableTab, formatDateMEL, formatDateTimeForHistoryView, formatUtcNewForEditWithTime, hasAdminOrEmployeeRoleHandler, hasCustomerRoleHandler, hasDealerRoleHandler, hasDealerSubuserRoleHandler, hasFranchiseRoleHandler, hasFranchiseSubuserRoleHandler } from 'src/helpers/common_helpers';
import { audioModelDetailsForJobLoading, changeTechnicianLoading, clearJobsheet, customerOrDelaerLatLagLoading, employeeDropdownForJobLoading, franchiseArrivedLoading, getAdditionalExpenseWithoutqueryLoading, getApproveDocumentLoading, getAudioModelJobdropdownListLoading, getCarModelDropdownLoading, getCustmerDetailsInJobsheetLoading, getCustomerProfile, getDefectivePartByJobId, getFranchiseUploadedDocumentLoading, getJobsheetById, getJobsheetsStatus, getProofDocumentLoading, getTechnicianDropdownLoading, getUserCurretnAddressLatLngLoading, getVehiclebrandRelatedtoDealerLoading, getcustomerdropdownListLoading, jobDealerdropdownListLoading, jobSheetClearMessage, partsDropdownForJobLoading, taxDropdownForJobLoading, updateFaultySystemLoading } from 'src/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { WARRANTY_TYPES } from 'src/constants/commonConstants';
import { useToast } from 'src/components/Common/ReactToaster';
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';
import { APPROVE, CHECK_VALUE_IN_ARRAY, FORMAT_VALUE_FOR_UPDATE, GET_NEXT_STATUS, GET_VALIDATION_AND_FIELD_SHOW, getDistanceFromLatLonInKm, JOB_SHEET_STATUS, NEXT_JOB_SHEET_STATUS, REJECT } from 'src/helpers/jobsheet_validation';
import { AnimatePresence, motion } from 'framer-motion';
import JobTooltipMEL from 'src/components/UI/JobTooltip';
import FloatInputGroup from 'src/components/Common/FloatInputGroup';
import JobsheetSignatureModal from 'src/components/UI/JobSheetTabs/JobsheetSignature';
import AdditionalExpense from 'src/components/UI/JobSheetTabs/AdditionalExpense';
import ErrorText from 'src/components/UI/ErrorText';
import OtpScreen from 'src/components/UI/JobSheetTabs/OtpScreen';
import { AudioModelDetailsForJobSheet } from 'src/models/pages/jobsheetModel';
import ApproveRejectConfirm from 'src/components/atoms/ApproveRejectConfirm';
import JobsheetValidationPopup from 'src/components/Common/ValidationMessage/JobsheetValidationPopup';
import CustomerDetails from 'src/components/UI/JobSheetTabs/CustomerDetails';
import { APPENV_URL } from 'src/util/appUrlEnv';
import { Link } from 'react-router-dom';
import DefectivePart from 'src/components/UI/JobSheetTabs/DefectiveParts';

const JobSheetEditForm: React.FC = () => {
  const { id, customer_Id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showToast } = useToast();
  const [apiKey, setApiKey] = useState<string | null>(null);

  const checkTapOption = () => {
    if (hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler()) {
      return TABOPTIONS[6].label;
    }
    return TABOPTIONS[0].label;
  };
  const [activeTab, setActiveTab] = useState<string | number>(checkTapOption());
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [technicianLabel,setTechnicianLabel ] = useState(false);
  const [correctedFiles, setCorrectedFiles] = useState<any[]>([]);
  const [addtionalExpensesFiles, setAddtionalExpensesFiles] = useState<any[]>([]);
  const [proofDocumentFiles, setProofDocumentFiles] = useState<any[]>([]);
  const [approveDocuments, setApproveDocuments] = useState<any[]>([]);
  const [formikSubmitHandler, setFormikSubmitHandler] = useState<any>(() => { });
  const [address, setAddress] = useState<any>("");
  const [otpScreen, setOtpScreen] = useState(false);
  const [jobCloseOtpScreen, setJobCloseOtpScreen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorTabs, setErrorTabs] = useState<any>([]);
  const [latitude, setLatitude] = useState<any>(null);
  const [longitude, setLongitude] = useState<any>(null);
  const [rejectConfirmation, setRejectConfirmation] = useState(false);
  const [serviceLocationConfirm, setServiceLocationConfirm] = useState(false);
  const [scanParts,setScanParts] = useState<any>([])
  const [franchiseArrivedLocalState, setFranchiseArrivedLocalState] = useState(false);
  const [tabOptions, setTabOptions] = useState<tabOption[]>(TAB_ADD_OPTIONS);
  const { dealerList, dealerVehiclebrandList, vehiclebrandList, customerList, audioModelList, loading, message, pendingCommandMessage, error, jobsheet, employeeList, technicianList, historyOfJob, jobsheetId, getLatLng, selectedArea, jobSheetStatus, withoutQueryAdditionaDetails,proofDocumentDetails, franchiseDocumet, franchiseArrivedMessage, audioModelDetails, getcurrentUserLatLng } = useSelector((state: RootReducerState) => state.jobsheetReducer);
  const { customerProfile, defectivePartsData,carModelList } = useSelector((state: RootReducerState) => state.jobsheetReducer);
  const { layoutType } = useSelector((state: RootReducerState) => state.Layout);
  const [visible, setVisible] = useState(false);
  const isAdmin = hasAdminOrEmployeeRoleHandler();
  const isdealer = hasDealerRoleHandler();
  const authuserId: any = localStorage.getItem("authUser");
  const parseUser = JSON.parse(authuserId);
  const [showTabError, setShowTabError] = useState<any>(JOB_SHEET_TAB_AND_FIELD);
  const [base64stringForUploadedFiles, setBase64stringForUploadedFiles] = useState<any>([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [rejectedSubmit, setRejectedSubmit] = useState(false);
  const pastHistoryData: any = useMemo(() => {
    return historyOfJob?.map((eachValue: any) => {
      let jobNo = eachValue?.number ? eachValue?.number : "";
      let date = formatDateTimeForHistoryView(eachValue?.creationTime, false);
      return `${jobNo} (${date})`;
    })?.join(', ');
  }, [historyOfJob]);

useEffect(()=>{
  let filteredData = defectivePartsData?.length > 0 ? defectivePartsData?.filter((data:any)=>data.isDeleted != true) : []
    setScanParts(filteredData)
},[defectivePartsData])

  useEffect(() => {
    const loginToken = localStorage.getItem('token');
    const fetchApiKey = async () => {
      try {
        const response = await fetch(APPENV_URL.AppUrl + "/" + `mel-setting-management/gcp-setting`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json; text/plain",
            "Authorization": `Bearer ${loginToken}`
          },
        });
        if (response.ok) {
          const data = await response.json();
          setApiKey(data.mapKey);
        }
      } catch (error) {
        console.error("Error fetching the API key", error);
      }
    };

    fetchApiKey();
  }, []);


  useEffect(() => {
    if (id) {
      dispatch(getJobsheetById(id));
      dispatch(getDefectivePartByJobId(id));
      dispatch(getFranchiseUploadedDocumentLoading(id));
      if (hasAdminOrEmployeeRoleHandler() || hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler()) {
        dispatch(getAdditionalExpenseWithoutqueryLoading(id));

      } else if (!hasDealerSubuserRoleHandler()) {
        dispatch(getApproveDocumentLoading(id));
      }
      if (hasAdminOrEmployeeRoleHandler() || hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler() || hasDealerRoleHandler()){
        dispatch(getProofDocumentLoading(id));
      }
    }
    dispatch(getJobsheetsStatus(jobSheetStatus));
    return (() => {
      dispatch(clearJobsheet());
    });
  }, [id, dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(getJobsheetById(id));
    }
  }, [franchiseArrivedLocalState]);
  const validationSchema = Yup.object().shape({});
  const [validationError, setValidationError] = useState<any>(validationSchema);
  const vehicleBrand = vehiclebrandList?.length > 0 ? (vehiclebrandList.map((eachVehicle: any) => {
    let data: any = {};
    data['value'] = eachVehicle.id;
    data['label'] = eachVehicle.name;
    return data;
  })).sort((a: any, b: any) => {
    const labelA = a.label.toUpperCase().trim();
    const labelB = b.label.toUpperCase().trim();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  }) : [];

  const dealerVehicleBrand = dealerVehiclebrandList?.length > 0 ? vehicleBrand.filter(brand => {
    return dealerVehiclebrandList.some(dealerBrand => dealerBrand.vehicleBrandId == brand.value);
  }) : [];


  const employeeDropList = employeeList?.length > 0 ? (employeeList.map((eachEmployee: any) => {
    let data: any = {};
    data['value'] = eachEmployee.userId;
    data['label'] = eachEmployee.name;
    data['id'] = eachEmployee.id;
    // data['id'] = eachEmployee.franchiseId;
    return data;
  })).sort((a: any, b: any) => {
    const labelA = a.label.toUpperCase().trim();
    const labelB = b.label.toUpperCase().trim();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  }) : [];

  // const technicianDropList = technicianList?.length > 0 ? (technicianList.map((eachEmployee: any) => {
  //   let data: any = {};
  //   // let distance: number = getDistanceFromLatLonInKm(10.075500, 78.769444, 10.112453, 78.596696)
  //   let distance = null
  //   if(eachEmployee.currentLocation != null){
  //     const [latitude,longitude] = eachEmployee?.currentLocation?.split(','); 
  //     distance  = getDistanceFromLatLonInKm(latitude,longitude,getLatLng?.lat,getLatLng?.lng)
  //   }
  //   data['value'] = eachEmployee.userId;
  //   data['label'] = eachEmployee.name + ` (distance: ${distance ? distance.toFixed(2) : "-"} km)`;
  //   // data['label'] = eachEmployee.name;
  //   data['id'] = eachEmployee.id;

  //   return data;
  // })) : []


  let franchiseuserJobclosed = (technicianList.filter((eachTechnician: any) => eachTechnician.userId == jobsheet?.technicianGUId).length >= 1);


  let franchiseSubUserJobclosed = (technicianList.filter((eachTechnician: any) => eachTechnician.franchiseId == jobsheet?.technicianId).length >= 1);

  let isEmployee = (employeeDropList.filter((eachEmpolyee: any) => eachEmpolyee.value == jobsheet?.technicianGUId).length >= 1) ? true : false;


  // const technicianDropList = useMemo(() => {
  //   if (franchiseuserJobclosed == true || franchiseSubUserJobclosed == true) {
  //     return technicianList?.length > 0 ? (technicianList.map((eachEmployee: any) => {
  //       let data: any = {};
  //       let distance = null
  //       if (eachEmployee.currentLocation != null && getLatLng?.lat && getLatLng?.lng) {
  //         const [latitude, longitude] = eachEmployee?.currentLocation?.split(',');
  //         distance = getDistanceFromLatLonInKm(latitude, longitude, getLatLng?.lat, getLatLng?.lng)
  //       }
  //       data['value'] = eachEmployee.franchiseId
  //       data['label'] = eachEmployee.name + ` (${eachEmployee?.franchiseName}) -${distance ? distance.toFixed(2) : "-"} km`;
  //       data['id'] = eachEmployee.franchiseId;
  //       data['distance'] = distance ? distance.toFixed(2) : "A"
  //       return data;
  //     })).sort((a: any, b: any) => {
  //       const labelA = a?.distance;
  //       const labelB = b?.distance;
  //       if (labelA < labelB) {
  //         return -1;
  //       }
  //       if (labelA > labelB) {
  //         return 1;
  //       }
  //       return 0;
  //     }) : []
  //   } else {
  //     return technicianList?.length > 0 ? (technicianList.map((eachEmployee: any) => {
  //       let data: any = {};
  //       let distance = null
  //       if (eachEmployee.currentLocation != null && getLatLng?.lat && getLatLng?.lng) {
  //         const [latitude, longitude] = eachEmployee?.currentLocation?.split(',');
  //         distance = getDistanceFromLatLonInKm(latitude, longitude, getLatLng?.lat, getLatLng?.lng)
  //       }
  //       data['value'] = eachEmployee.userId
  //       data['label'] = eachEmployee.name + ` (${eachEmployee?.franchiseName}) -${distance ? distance.toFixed(2) : "-"} km`;
  //       data['id'] = eachEmployee.franchiseId;
  //       data['distance'] = distance ? distance.toFixed(2) : "A"
  //       return data;
  //     })).sort((a: any, b: any) => {
  //       const labelA = a?.distance;
  //       const labelB = b?.distance;
  //       if (labelA < labelB) {
  //         return -1;
  //       }
  //       if (labelA > labelB) {
  //         return 1;
  //       }
  //       return 0;
  //     }) : []
  //   }

  // }, [technicianList, getLatLng, franchiseSubUserJobclosed, franchiseuserJobclosed])

  const technicianDropList = useMemo(() => {
    return technicianList?.length > 0 ? (technicianList.map((eachEmployee: any) => {
      let data: any = {};
      let distance = null;
      if (eachEmployee.currentLocation != null && getLatLng?.lat && getLatLng?.lng) {
        const [latitude, longitude] = eachEmployee?.currentLocation?.split(',');
        distance = getDistanceFromLatLonInKm(latitude, longitude, getLatLng?.lat, getLatLng?.lng);
      }
      data['value'] = (jobsheet?.technicianGUId == eachEmployee.userId) ? eachEmployee.userId : eachEmployee.franchiseId;
      data['label'] = eachEmployee.name + ` (${eachEmployee?.franchiseName}) -${distance ? distance.toFixed(2) : "-"} km`;
      data['id'] = eachEmployee.franchiseId;
      data['distance'] = distance ? distance.toFixed(2) : "A";
      return data;
    })).sort((a: any, b: any) => {
      const labelA = a?.distance;
      const labelB = b?.distance;
      if (labelA < labelB) {
        return -1;
      }
      if (labelA > labelB) {
        return 1;
      }
      return 0;
    }) : [];
  }, [technicianList, getLatLng, franchiseSubUserJobclosed, franchiseuserJobclosed]);


  const technicianEmployeeDropList = [...employeeDropList, ...technicianDropList];
  useEffect(() => {
    const handleConfirmation = (event: any) => {
      const confirmationMessage = 'You have unsaved changes. Are you sure you want to leave this page?';
      event.preventDefault();
      event.returnValue = confirmationMessage;
      return confirmationMessage;
    };

    const handleNavigate = (event: any) => {
      event.preventDefault();
      const confirmationMessage = 'You have unsaved changes. Are you sure you want to leave this page?';
      if (!event.currentTarget.confirmationHandled && !window.confirm(confirmationMessage)) {
        event.preventDefault();
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };

    window.addEventListener('beforeunload', handleConfirmation);
    window.addEventListener('popstate', handleNavigate);

    return () => {
      window.removeEventListener('beforeunload', handleConfirmation);
      window.removeEventListener('popstate', handleNavigate);
    };

  }, []);


  useEffect(() => {
    let delaerId = "";
    let customerId = "";
    if (hasDealerRoleHandler() && !id) {
      let dealerUser = JSON.parse(localStorage.getItem('authUser') ? localStorage.getItem('authUser') as string : "");
      let dealerUserId = dealerUser.uid;
      if (dealerListdropdown?.length > 0) {
        delaerId = dealerListdropdown?.filter((eachValue: any) => eachValue.userId == dealerUserId)?.[0]?.id ? dealerListdropdown?.filter((eachValue: any) => eachValue.userId == dealerUserId)?.[0]?.id : "";
        let address = delaerId ? dealerListdropdown?.filter((eachDealer: any) => eachDealer.id == delaerId)?.[0]?.address : "";

        setAddress(address);

      }
    } else if (hasCustomerRoleHandler() && !id) {
      let customerUser = JSON.parse(localStorage.getItem('authUser') ? localStorage.getItem('authUser') as string : "");
      let customerUserId = customerUser.uid;
      // if (customerListdrop?.length > 0) {
      // customerId = customerListdrop?.length > 0 && customerListdrop.filter((eachValue: any) => eachValue.userId == customerUserId)?.[0]?.id ? customerListdrop.filter((eachValue: any) => eachValue.userId == customerUserId)?.[0]?.id : "";
      // let address = jobsheet?.customerAddress ? jobsheet?.customerAddress : "";
      if (customerProfile != "") {
        setAddress(customerProfile?.extraProperties?.Address);
      }

      // }
    } else if (!hasCustomerRoleHandler() && customer_Id) {
      dispatch(getCustmerDetailsInJobsheetLoading({ phoneNumber: customer_Id, email: "", }, "phone"));
    } else {
      setAddress("");
    }
  }, [dealerList, customerList, customer_Id, customerProfile]);


  // useEffect(() => {
  //   const handleConfirmation = (event:any) => {
  //     const confirmationMessage = 'You have unsaved changes. Are you sure you want to leave this page?';
  //     event.preventDefault();
  //     event.returnValue = confirmationMessage;
  //     return confirmationMessage;
  //   };

  //   const handleNavigate = (event:any) => {
  //     const confirmationMessage = 'You have unsaved changes. Are you sure you want to leave this page?';
  //     if (!window.confirm(confirmationMessage)) {
  //       event.preventDefault();
  //     }
  //   };

  //   const handleLinkClick = (event:any) => {
  //     const confirmationMessage = 'You have unsaved changes. Are you sure you want to leave this page?';
  //     if (!window.confirm(confirmationMessage)) {
  //       event.preventDefault();
  //     }
  //   };

  //   window.addEventListener('beforeunload', handleConfirmation);
  //   window.addEventListener('popstate', handleNavigate);

  //   const links = document.querySelectorAll('a');
  //   const li = document.querySelectorAll('li');
  //   links.forEach((link) => {
  //     link.addEventListener('click', handleLinkClick);
  //   });
  //   li.forEach((link) => {
  //     link.addEventListener('click', handleLinkClick);
  //   });

  //   return () => {
  //     window.removeEventListener('beforeunload', handleConfirmation);
  //     window.removeEventListener('popstate', handleNavigate);
  //     links.forEach((link) => {
  //       link.removeEventListener('click', handleLinkClick);
  //     });
  //   };
  // }, []);



  const customerListdrop = customerList?.length > 0 ? (customerList.map((eachCustomer: any) => {
    let data: any = {};
    data['id'] = eachCustomer.id;
    data['value'] = eachCustomer.id;
    data['label'] = eachCustomer.name;
    data['address'] = eachCustomer.address;
    data['userId'] = eachCustomer.userId;
    return data;
  })).sort((a: any, b: any) => {
    const labelA = a.label.toUpperCase().trim();
    const labelB = b.label.toUpperCase().trim();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  }) : [];


  // const [vehiclebranddrop, setVehiclebranddrop] = useState<any>([])


  const dealerListdropdown = dealerList?.length > 0 ? (dealerList?.map((eachDealer: any) => {
    let data: any = {};
    data["id"] = eachDealer.id;
    data["value"] = eachDealer.id;
    data["label"] = `${eachDealer.name} (${eachDealer.phoneNumber})`;
    data["address"] = eachDealer.address;
    data["userId"] = eachDealer.userId;
    return data;
  })).sort((a: any, b: any) => {
    const labelA = a.label.toUpperCase().trim();
    const labelB = b.label.toUpperCase().trim();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  }) : [];

  let status: string = READY_TO_INVOICE;

  const toggleSidebar = () => {
    var bodyElement = document.body;
    var sidebarSize = bodyElement.getAttribute('data-sidebar-size');
    if (sidebarSize === 'lg') {
      bodyElement.setAttribute('data-sidebar-size', 'sm');
      bodyElement.classList.remove('sidebar-enable');
    }
  };

  useEffect(() => {
    dispatch(jobDealerdropdownListLoading());
    dispatch(getcustomerdropdownListLoading());
    if (!hasDealerRoleHandler()) {
      dispatch(getVehiclebrandRelatedtoDealerLoading(0));
    }
    dispatch(partsDropdownForJobLoading());
    dispatch(taxDropdownForJobLoading());
    dispatch(getTechnicianDropdownLoading());

    dispatch(employeeDropdownForJobLoading());
  }, []);

  useEffect(() => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
          },
          (error) => {
            console.error('Error getting the location:', error);
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
    };
    getLocation();
  }, []);
console.log("carModelList",carModelList);

  const resetVehcileBrand = (setFieldValue: any, setFieldError: any, setFieldTouched: any) => {
    setFieldValue("vehicleBrandId", "");
    setFieldError("vehicleBrandId", "");
    setFieldTouched("vehicleBrandId", false);

    resetAudioModelValue(setFieldValue, setFieldError, setFieldTouched);
  };

  const resetAudioModelValue = (setFieldValue: any, setFieldError: any, setFieldTouched: any) => {

    setFieldError('audioModel', "");
    setFieldError("serviceCharge", "");
    setFieldError("serviceChargeTaxPercentage", "");
    setFieldError("serviceChargeTaxAmount", "");
    setFieldError("serviceChargeTotalWithTax", "");

    setFieldError('audioModel', false);
    setFieldTouched("serviceCharge", false);
    setFieldTouched("serviceChargeTaxPercentage", false);
    setFieldTouched("serviceChargeTaxAmount", false);
    setFieldTouched("serviceChargeTotalWithTax", false);

    setFieldValue('audioModel', "");
    setFieldValue('serviceCharge', "");
    setFieldValue('serviceChargeTaxPercentage', "");
    setFieldValue('serviceChargeTaxAmount', "");
    setFieldValue('serviceChargeTotalWithTax', "");
  };



  useEffect(() => {
    if (id) {
      const Displayedtabs = applicableTab(status);
      setTabOptions(Displayedtabs);
    }
    toggleSidebar();

  }, [id, status]);

  const [validationConfiguration, setValidationConfiguration] = useState<any>(null);






  const INITIAL = useMemo(() => {
    let dealerId: any = "";
    let customerId: any = "";
    let customerLoginEmail = "";
    let customerLoginPhoneNumber = "";
    let customerLoginName = "";
    let customerLoginAddress = "";
    let customerLoginGst = "";
    if (id) {
      if (jobsheet?.dealerId) {
        dispatch(getVehiclebrandRelatedtoDealerLoading(jobsheet?.dealerId));
      }
      if (jobsheet?.vehicleBrandId) {
        dispatch(getAudioModelJobdropdownListLoading(jobsheet?.vehicleBrandId));
        dispatch(getCarModelDropdownLoading(jobsheet?.vehicleBrandId));
      }
      if (jobsheet?.audioModelId) {
        dispatch(audioModelDetailsForJobLoading(jobsheet?.audioModelId, jobsheet?.isExchange));
      }
      dealerId = jobsheet?.dealerId ? jobsheet?.dealerId : "";
    } else {
      if (hasDealerRoleHandler()) {
        let dealerUser = JSON.parse(localStorage.getItem('authUser') ? localStorage.getItem('authUser') as string : "");
        let dealerUserId = dealerUser.uid;
        dealerId = dealerListdropdown.filter((eachValue: any) => eachValue.userId == dealerUserId)?.[0]?.id ? dealerListdropdown.filter((eachValue: any) => eachValue.userId == dealerUserId)?.[0]?.id : "";
        if (dealerId) {
          let dealerAddress = dealerListdropdown?.filter((eachDealer: any) => eachDealer.id == dealerId)?.[0]?.address;
          dispatch(getVehiclebrandRelatedtoDealerLoading(dealerId));
          dispatch(getUserCurretnAddressLatLngLoading(dealerAddress));
        }

      } else if (hasCustomerRoleHandler()) {
        if (customerProfile != "") {
          if (customerProfile?.name != "") {
            customerLoginName = customerProfile?.name;
          }
          if (customerProfile?.extraProperties?.Address != "") {
            customerLoginAddress = customerProfile?.extraProperties?.Address;
            dispatch(getUserCurretnAddressLatLngLoading(customerLoginAddress));
          }
          if (customerProfile?.email != "") {
            customerLoginEmail = customerProfile?.email;
          }
          if (customerProfile?.phoneNumber != "") {
            customerLoginPhoneNumber = customerProfile?.phoneNumber;
          }
          if (customerProfile?.extraProperties?.CustomerId != "") {
            customerId = customerProfile?.extraProperties?.CustomerId;
          }
          if (customerProfile?.extraProperties?.GstNumber != "") {
            customerLoginGst = customerProfile?.extraProperties?.GstNumber;
          }
        }

      } else {
        dealerId = jobsheet?.dealerId ? jobsheet?.dealerId : "";
      }
    }

    let isFranchise = (
      employeeDropList.filter((eachEmpolyee: any) => eachEmpolyee.value == jobsheet?.technicianGUId).length >= 1) ? false :
      ((technicianDropList.filter((eachTechnician: any) => eachTechnician.value == jobsheet?.technicianGUId).length >= 1) ? true : (technicianDropList.filter((eachTechnician: any) => eachTechnician.id == jobsheet?.technicianId).length >= 1) ? true : false);

    let franchiseuserJobclosed = (technicianList.filter((eachTechnician: any) => eachTechnician.userId == jobsheet?.technicianGUId).length >= 1);

    let franchiseSubUserJobclosed = (technicianList.filter((eachTechnician: any) => eachTechnician.franchiseId == jobsheet?.technicianId).length >= 1);


    let isEmployee = (employeeDropList.filter((eachEmpolyee: any) => eachEmpolyee.value == jobsheet?.technicianGUId).length >= 1) ? true : franchiseArrivedLocalState;

    // employeeDropList.filter((eachEmpolyee: any) => eachEmpolyee.id == jobsheet?.technicianId).length == 1) ? false : ((technicianDropList.filter((eachTechnician: any) => eachTechnician.id == jobsheet?.technicianId).length == 1) ? true : false)

    let taxtypeId = (
      (dealerId && (!jobsheet?.customerId || !customerId)) ? dealerList.filter((data: any) => data.id === dealerId)?.[0]?.taxTypeId : (!dealerId && (jobsheet?.customerId || customerId)) ? customerList.filter((data: any) => data.id === (jobsheet?.customerId || customerId))?.[0]?.taxTypeId : (dealerId && (jobsheet?.customerId || customerId)) ? dealerList.filter((data: any) => data.id === dealerId)?.[0]?.taxTypeId : ""
    );

    function findTechnicianById(id: any, dropdown: any) {
      return dropdown.some((item: any) => item.userId === id);
    }

    const technicianAvailabilty = findTechnicianById(jobsheet?.technicianGUId, technicianList);
    const uniqueOutgoingDispatchIds = new Set();
    return {
      id: jobsheet?.id ? jobsheet?.id : "",
      number: jobsheet?.number ? jobsheet?.number : `To be generated ${jobsheet?.serviceRequestNumber ? `(${jobsheet?.serviceRequestNumber})` : ""}`,
      dealerId: dealerId,
      vehicleBrandId: jobsheet?.vehicleBrandId ? jobsheet?.vehicleBrandId : "",
      permitFormReceivedDate: jobsheet?.permitFormReceivedDate ? formatUtcNewForEditWithTime(jobsheet?.permitFormReceivedDate) : "",
      customerId: jobsheet?.customerId ? jobsheet?.customerId : (customerId ? customerId : null),
      dealerAddress: dealerId ? dealerListdropdown?.filter((eachDealer: any) => eachDealer.id == dealerId)?.[0]?.address : "",
      customerAddress: customerLoginAddress ? customerLoginAddress : jobsheet?.customerAddress ? jobsheet?.customerAddress : "",
      appointmentTime: jobsheet?.appointmentTime ? formatUtcNewForEditWithTime(jobsheet?.appointmentTime) : "",
      franchiseNotes: jobsheet?.franchiseNotes ? jobsheet?.franchiseNotes : "",
      warrantyStatus: jobsheet?.warrantyStatus ? jobsheet?.warrantyStatus : "",
      estimator: jobsheet?.estimatorId ? jobsheet?.estimatorId : "",
      estimatorGUId: jobsheet?.estimatorGUId ? jobsheet?.estimatorGUId : "",
      technicianId: jobsheet?.technicianId ? jobsheet?.technicianId : "",
      // technicianGUId: (franchiseuserJobclosed == true && franchiseSubUserJobclosed == false) ? jobsheet?.technicianId : (jobsheet?.technicianGUId != null && isEmployee) ? jobsheet?.technicianGUId : (franchiseSubUserJobclosed == true && franchiseuserJobclosed == false) ? jobsheet?.technicianId : (franchiseuserJobclosed == true && franchiseSubUserJobclosed == true) ? jobsheet?.technicianId : "",
      technicianGUId: (isFranchise ? ((technicianAvailabilty == true) ? (jobsheet?.technicianGUId ? jobsheet?.technicianGUId : "") : jobsheet?.technicianId) : jobsheet?.technicianGUId),
      isFranchise: isFranchise,
      isExchange: jobsheet?.isExchange ? jobsheet?.isExchange : false,
      isRemoteService: id ? (jobsheet?.isRemoteService ? jobsheet?.isRemoteService : false) : (hasAdminOrEmployeeRoleHandler() ? false : true),
      isExchangeChangedNow: false,
      // isFaultySystemAvailable: jobsheet?.isFaultySystemAvailable ? jobsheet?.isFaultySystemAvailable : false,
      vinNumber: jobsheet?.vinNumber ? jobsheet?.vinNumber : "",
      vehicleRegistrationNumber: jobsheet?.vehicleRegistrationNumber ? jobsheet?.vehicleRegistrationNumber : "",
      vehicleModel: jobsheet?.vehicleModel ? jobsheet?.vehicleModel : "",
      carModelId: jobsheet?.carModelId ? jobsheet?.carModelId : "",
      afcsReceivedDate: jobsheet?.creationTime ? formatUtcNewForEditWithTime(jobsheet?.creationTime) : "",
      requestDate: jobsheet?.requestDate ? formatUtcNewForEditWithTime(jobsheet?.requestDate) : "",
      audioModel: jobsheet?.audioModelId ? jobsheet?.audioModelId : "",
      audioModelName: jobsheet?.audioModel ? jobsheet?.audioModel : "",
      serviceCharge: jobsheet?.serviceCharge ? jobsheet?.serviceCharge : "",
      serviceChargeTaxPercentage: "",
      serviceChargeTaxAmount: "",
      serviceChargeTotalWithTax: "",
      exchangeAudioSerialNumber: jobsheet?.outgoingAudioSerialNumber ? jobsheet?.outgoingAudioSerialNumber : "",
      audioSerialNumber: jobsheet?.audioSerialNumber ? jobsheet?.audioSerialNumber : "",
      historicalJobsForAudioSystem: "",
      audioPartNumber: jobsheet?.audioPartNumber ? jobsheet?.audioPartNumber : "",
      dateOfSale: jobsheet?.dateOfSale ? formatUtcNewForEditWithTime(jobsheet?.dateOfSale) : "",
      manufacturedDate: jobsheet?.manufacturedDate ? formatUtcNewForEditWithTime(jobsheet?.manufacturedDate) : "",
      failedDate: jobsheet?.failedDate ? formatUtcNewForEditWithTime(jobsheet?.failedDate) : "",
      lastRepairDate: jobsheet?.lastRepairDate ? formatUtcNewForEditWithTime(jobsheet?.lastRepairDate) : "",
      kmsCovered: jobsheet?.kmsCovered ? jobsheet?.kmsCovered : "",
      preparedBy: jobsheet?.preparedById ? jobsheet?.preparedById : "",
      preparedByName: jobsheet?.preparedBy?.name ? jobsheet?.preparedBy?.name : parseUser['username'],

      customerConcern: jobsheet?.customerConcern ? jobsheet?.customerConcern : "",
      dealerObservation: jobsheet?.dealerObservation ? jobsheet?.dealerObservation : "",
      accessoriesList: jobsheet?.accessoriesList ? jobsheet?.accessoriesList : "",
      missingAccessories: jobsheet?.missingAccessories ? jobsheet?.missingAccessories : "",
      additionalRemarks: jobsheet?.additionalRemarks ? jobsheet?.additionalRemarks : "",
      melObservations: jobsheet?.melObservations ? jobsheet?.melObservations : "",
      completeServiceInformation: jobsheet?.completeServiceInformation ? jobsheet?.completeServiceInformation : "",
      taxTypeId: jobsheet?.jobEstimateDto?.length > 0 ? jobsheet?.jobEstimateDto?.filter((eachFilter: any) => !eachFilter.isDeleted)?.[0]?.taxTypeId : taxtypeId != null ? taxtypeId : "",
      IsAfcsGenerated: (hasCustomerRoleHandler()) ? true : false,
      status: jobsheet?.status ? jobsheet?.status : JOB_SHEET_STATUS[JOB_SHEET_STATUS["New Job"]],
      inPending: jobsheet?.inPending ? jobsheet?.inPending : false,
      isOldPending: jobsheet?.inPending ? jobsheet?.inPending : false,
      recentInPending: false,
      pendingCommentsDto: jobsheet?.pendingCommentsDto?.length > 0 ? jobsheet?.pendingCommentsDto?.filter((eachComment: any) => !eachComment.isDeleted)?.map((eachComment: any) => {
        let data = eachComment;
        return data;
      }) : [],
      pendingCommentsDtoNew:[{
        
          id: 0,
          jobId: id,
          pendingReasonId: "",
          pendingInformation: "",
          commentedAt: new Date(),
      }],
      // jobReceivedMode: jobsheet?.jobReceivedMode ? jobsheet?.jobReceivedMode : "",
      // courierReceivedAt: jobsheet?.courierReceivedAt ? formatUtcNewForEditWithTime(jobsheet?.courierReceivedAt) : "",
      // courierPackingCondition: jobsheet?.courierPackingCondition ? jobsheet?.courierPackingCondition : "",
      // courierAmount: jobsheet?.courierAmount ? jobsheet?.courierAmount : "",
      // courierChequeNumber: jobsheet?.courierChequeNumber ? jobsheet?.courierChequeNumber : "",
      // courierBankName: jobsheet?.courierBankName ? jobsheet?.courierBankName : "",
      // handCarriedPersonName: jobsheet?.handCarriedPersonName ? jobsheet?.handCarriedPersonName : "",
      // handCarriedPersonDesignation: jobsheet?.handCarriedPersonDesignation ? jobsheet?.handCarriedPersonDesignation : "",
     
      partsDetails: jobsheet?.jobEstimateDto.filter((eachFilter: any) => !eachFilter.isDeleted)?.[0]?.estimatePartDto?.length > 0 ? jobsheet?.jobEstimateDto.filter((eachFilter: any) => !eachFilter.isDeleted)?.[0]?.estimatePartDto?.map((eachComment: any) => {
        let data = eachComment;
        return data;
      }) : [],
      taxDetails: jobsheet?.jobEstimateDto.filter((eachFilter: any) => !eachFilter.isDeleted)?.[0]?.estimateTaxDto?.length > 0 ? jobsheet?.jobEstimateDto?.filter((eachFilter: any) => !eachFilter.isDeleted)?.[0]?.estimateTaxDto?.map((eachTax: any) => {
        let data = eachTax;
        return data;
      }) : [],
      estimatorComments: jobsheet?.jobEstimateDto?.filter((eachFilter: any) => !eachFilter.isDeleted)?.[0]?.estimatorComments ? jobsheet?.jobEstimateDto?.filter((eachFilter: any) => !eachFilter.isDeleted)?.[0]?.estimatorComments : "",
      updatedById: jobsheet?.updatedById ? jobsheet?.updatedById : "",
      updatedByName: jobsheet?.preparedBy?.name ? jobsheet?.preparedBy?.name : "",
      estimateTotal: jobsheet?.jobEstimateDto?.filter((eachFilter: any) => !eachFilter.isDeleted)?.[0]?.totalEstimation ? jobsheet?.jobEstimateDto?.filter((eachFilter: any) => !eachFilter.isDeleted)?.[0]?.totalEstimation : 0,
      approveStatus: "",
      approvalMode: jobsheet?.approvalMode ? jobsheet?.approvalMode : (hasDealerRoleHandler() || hasCustomerRoleHandler() ? "Portal" : ""),
      approvalComments: jobsheet?.approvalComments ? jobsheet?.approvalComments : "",
      packagedAt: jobsheet?.packagedAt ? formatUtcNewForEditWithTime(jobsheet?.packagedAt) : "",
      cleaningComplete: jobsheet?.cleaningComplete ? jobsheet?.cleaningComplete : false,
      packingComplete: jobsheet?.packingComplete ? jobsheet?.packingComplete : false,
      packedBy: jobsheet?.outgoingDispatch?.packedBy ? jobsheet?.outgoingDispatch?.packedBy : "",
      packedDate: jobsheet?.outgoingDispatch?.packedDate ? formatUtcNewForEditWithTime(jobsheet?.outgoingDispatch?.packedDate) : "",
      deliveryDate: jobsheet?.outgoingDispatch?.deliveryDate ? formatUtcNewForEditWithTime(jobsheet?.outgoingDispatch?.deliveryDate) : "",
      deliveryType: jobsheet?.outgoingDispatch?.deliveryType ? jobsheet?.outgoingDispatch?.deliveryType : "",
      deliveredTo: jobsheet?.outgoingDispatch?.deliveredTo ? jobsheet?.outgoingDispatch?.deliveredTo : "",
      deliveredById: jobsheet?.outgoingDispatch?.deliveredById ? jobsheet?.outgoingDispatch?.deliveredById : "",
      deliveredUserType: jobsheet?.outgoingDispatch?.deliveredUserType ? jobsheet?.outgoingDispatch?.deliveredUserType : "",
      approximateValue: jobsheet?.outgoingDispatch?.approximateValue ? jobsheet?.outgoingDispatch?.approximateValue : 0,
      courierId: jobsheet?.outgoingDispatch?.courierId ? jobsheet?.outgoingDispatch?.courierId : "",
      notes: jobsheet?.outgoingDispatch?.notes ? jobsheet?.outgoingDispatch?.notes : "",
      additionalExpenses: withoutQueryAdditionaDetails?.length > 0 ? withoutQueryAdditionaDetails?.filter((data) => data.isDeleted == false).map((expense: any) => {
        let data = {
          ...expense,
          files: expense.document?.name,
          fileName: expense.document?.name
        };
        return data;
      }) : [],
      proofDocuments: proofDocumentDetails?.length > 0 ? proofDocumentDetails?.filter((data:any) => data.isDeleted == false).map((expense: any) => {
        let data = {
          ...expense,
          files: expense.document?.name,
          fileName: expense.document?.name
        };
        return data;
      }) : [],

      franchiseArrived: jobsheet?.franchiseArrivedLocation !== null ? true : false,
      serviceLocation: jobsheet?.serviceLocation ? jobsheet?.serviceLocation : hasDealerRoleHandler() ? (dealerId ? dealerListdropdown?.filter((eachDealer: any) => eachDealer.id == dealerId)?.[0]?.address : "") : customerLoginAddress ? customerLoginAddress : "",
      zeroAdditional: false,
      files: [],
      uploadedFiles: franchiseDocumet?.length > 0 && franchiseDocumet?.filter((data: any) => data.fileType == "fault").length > 0 ? true : false,
      approvedoc: [],
      additionalExpenseFiles: [],
      proofDocumentFiles: [],
      base64Files: [],
      franchiseCurrentLat: "",
      franchiseCurrentLag: "",
      franchiseArrivedOrNot: jobsheet?.franchiseArrivedTime !== null ? true : false,
      perfectionFiles: [],
      perfectionFilesUploadStatus: franchiseDocumet?.length > 0 && franchiseDocumet?.filter((data: any) => data.fileType == "perfection").length > 0 ? true : false,
      customerEmail: customerLoginEmail ? customerLoginEmail : jobsheet?.customerEmail ? jobsheet?.customerEmail : "",
      customerPhoneNumber: customerLoginPhoneNumber ? customerLoginPhoneNumber : jobsheet?.customerPhoneNumber ? jobsheet?.customerPhoneNumber : "",
      customerName: customerLoginName ? customerLoginName : jobsheet?.customerName ? jobsheet?.customerName : "",
      customerGSTNumber: customerLoginGst ? customerLoginGst : jobsheet?.customerGSTNumber ? jobsheet?.customerGSTNumber : "",
      supplierReferenceNumber: jobsheet?.supplierReferenceNumber ? jobsheet?.supplierReferenceNumber : "",
      priority: jobsheet?.priority ? jobsheet?.priority : "",
      dispatched: jobsheet?.dispatched ? jobsheet?.dispatched : "",
      dispatchedNow:false,
      defectiveParts:[],
      outgoingDispatchList:jobsheet?.outgoingDispatchList?.length>0 ? 
      jobsheet?.outgoingDispatchList?.filter((val:any)=>val.isDeleted != true)?.map((val:any)=>({
        packedBy: val?.packedBy ? val?.packedBy : "",
        packedDate: val?.packedDate ? formatUtcNewForEditWithTime(val?.packedDate) : "",
        deliveryDate: val?.deliveryDate ? formatUtcNewForEditWithTime(val?.deliveryDate) : "",
        deliveryType: val?.deliveryType ? val?.deliveryType : "",
        deliveredTo: val?.deliveredTo ? val?.deliveredTo : "",
        deliveredById: val?.deliveredById ? val?.deliveredById : "",
        deliveredUserType: val?.deliveredUserType ? val?.deliveredUserType : "",
        approximateValue: val?.approximateValue ? val?.approximateValue : 0,
        courierId: val?.courierId ? val?.courierId : "",
        notes: val?.notes ? val?.notes : "",
        id:val?.id ? val?.id : 0
      }))
      : [],
      outgoingDispatchListNew:[
        {
          packedBy: "",
        packedDate: "",
        deliveryDate: "",
        deliveryType:"",
        deliveredTo:  "",
        deliveredById:  "",
        deliveredUserType:"",
        approximateValue: 0,
        courierId: "",
        notes: "",
        id:0
        }
      ],
      incomingDispatchList:jobsheet?.incomingDispatchList?.length>0 ? 
      jobsheet?.incomingDispatchList?.filter((val:any)=>val.isDeleted != true)?.map((val:any)=>({
        id: val?.id ? val?.id : 0,
        jobReceivedMode: val?.jobReceivedMode ? val?.jobReceivedMode : "",
        courierReceivedAt: val?.courierReceivedAt ? formatUtcNewForEditWithTime(val?.courierReceivedAt) : "",
        courierPackingCondition: val?.courierPackingCondition ? val?.courierPackingCondition : "",
        courierAmount: val?.courierAmount ? val?.courierAmount : "",
        courierChequeNumber: val?.courierChequeNumber ? val?.courierChequeNumber : "",
        courierBankName: val?.courierBankName ? val?.courierBankName : "",
        handCarriedPersonName: val?.handCarriedPersonName ? val?.handCarriedPersonName : "",
        handCarriedPersonDesignation: val?.handCarriedPersonDesignation ? val?.handCarriedPersonDesignation : "",
        creatorId: val?.creatorId ? val?.creatorId : "",
        lastModifierId: val?.lastModifierId ? val?.lastModifierId : "",
        jobId: val?.jobId ? val?.jobId : 0,
        receivedDate: val?.receivedDate ? formatUtcNewForEditWithTime(val?.receivedDate) : "",
        receivedId: val?.receivedId ? val?.receivedId : "",
        courierId: val?.courierId ? val?.courierId : "",
        notes: val?.notes ? val?.notes : "",
        receivedByName: val?.receivedByName ? val?.receivedByName : "",
      })) : [],
      incomingDispatchListNew:[
        {
          id:0,
          jobReceivedMode: "",
          courierReceivedAt: "",
          courierPackingCondition: "",
          courierAmount:"",
          courierChequeNumber:"",
          courierBankName: "",
          handCarriedPersonName: "",
          handCarriedPersonDesignation: "",
        creatorId: "",
        lastModifierId: "",
        jobId: 0,
        receivedDate: "",
        receivedId:"",
        courierId:"",
        notes: "",
        }
      ],
      faultySystemAvailableList:jobsheet?.faultySystemAvailableList?.length>0 ? jobsheet?.faultySystemAvailableList?.map((data:any)=>({
        "id": data?.id,
        "label":jobsheet?.outgoingDispatchList?.find((val:any)=>val.id==data?.outgoingDispatchId)?.number,
        "jobId": data?.jobId,
        "isFaultySystem": data?.isFaultySystem,
        "outgoingDispatchId": data?.outgoingDispatchId
      })):[]
    };
  }, [jobsheet, id, withoutQueryAdditionaDetails,proofDocumentDetails, franchiseDocumet, customerList, dealerList, customer_Id, customerProfile]);


  useEffect(() => {
    if (hasCustomerRoleHandler()) {
      dispatch(getCustomerProfile());
    }
  }, []);


  useEffect(() => {
    if (id && jobsheet) {
      if (jobsheet?.status as string == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Closed"]] ||
        jobsheet?.status as string == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Cancelled"]]) {
        navigate(ROUTER.JOB_SHEETS_VIEW + `/${jobsheet?.id}`);
      }
      // let data = jobsheet?.serviceLocation ? jobsheet?.serviceLocation : "";
      // setAddress(data);
      if (jobsheet?.franchiseArrivedLocation != null) {
        setFranchiseArrivedLocalState(true);
      } else {
        setFranchiseArrivedLocalState(false);
      }

      let validationAndFieldShow = GET_VALIDATION_AND_FIELD_SHOW(INITIAL, jobsheet?.status as string, GET_NEXT_STATUS(INITIAL, jobsheet?.status as string));
      setValidationConfiguration(validationAndFieldShow);
      setTabOptions(TABOPTIONS.filter((eachTab) => {
        return validationAndFieldShow.showTabs.some(showTab => showTab === eachTab.value);
      }));
    } else {

      let validationAndFieldShow = GET_VALIDATION_AND_FIELD_SHOW(INITIAL, JOB_SHEET_STATUS[JOB_SHEET_STATUS["New Job"]], NEXT_JOB_SHEET_STATUS[NEXT_JOB_SHEET_STATUS.GENERATE_JOB_REQUEST]);
      setValidationConfiguration(validationAndFieldShow);
      setTabOptions(TABOPTIONS.filter((eachTab) => {
        return validationAndFieldShow.showTabs.some(showTab => showTab === eachTab.value);
      }));
    }
  }, [isAdmin, isdealer, id, jobsheet]);


  useEffect(() => {
    if (jobsheet?.serviceLocation) {
      dispatch(customerOrDelaerLatLagLoading(jobsheet?.serviceLocation));
      dispatch(getUserCurretnAddressLatLngLoading(jobsheet?.serviceLocation));
    }
  }, [jobsheet]);

  const changeFlow = (value: any, currentStatus: string, setFieldError: any, setFieldTouched: any, setFieldValue: any) => {
    let nextStatusForCurrentStatus: string = GET_NEXT_STATUS(value, currentStatus);
    let validationAndFieldShow = GET_VALIDATION_AND_FIELD_SHOW(value, currentStatus, nextStatusForCurrentStatus, setFieldError, setFieldTouched, setFieldValue);
    console.log("validationAndFieldShow",validationAndFieldShow)
    setValidationConfiguration(validationAndFieldShow);
    setTabOptions(TABOPTIONS.filter((eachTab) => {
      return validationAndFieldShow.showTabs.some(showTab => showTab === eachTab.value);
    }));
  };


  useEffect(() => {
    let validation = Yup.object().shape({ ...validationConfiguration?.validation });
    setValidationError(validation);
  }, [validationConfiguration]);



  useEffect(() => {
    validationError;
    validationErrorMessage;
    if (isFormSubmitted) {
      setIsFormSubmitted(false);
      setVisibleValidation(true);
      formikSubmitHandler();
    }
  }, [validationError]);


  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);



  useEffect(() => {

    if (message || pendingCommandMessage) {
      setIsSubmitting(false);
      if (message != "Successfully deleted the additional expenses document") {
        showToast(message, { type: "success" });
        dispatch(jobSheetClearMessage());
        if (message != "Repeat Job") {
          if (id) {
            navigate(ROUTER.JOB_SHEETS_VIEW + '/' + id);
          }
        }
      } else {
        showToast(message, { type: "success" });
      }
      if (pendingCommandMessage) {
        showToast(pendingCommandMessage, { type: "success" });
        dispatch(jobSheetClearMessage());
      }
    }
    if (franchiseArrivedMessage) {
      showToast(franchiseArrivedMessage, { type: "success" });
      INITIAL.franchiseArrivedOrNot = true;
      setFranchiseArrivedLocalState(true);
      let validationAndFieldShow = GET_VALIDATION_AND_FIELD_SHOW(INITIAL, jobsheet?.status as string, GET_NEXT_STATUS(INITIAL, jobsheet?.status as string));
      setValidationConfiguration(validationAndFieldShow);
      setOtpScreen(false);
      dispatch(jobSheetClearMessage());
    }
    if (error) {
      setIsSubmitting(false);
      setVisible(true);
    }
  }, [message, error, pendingCommandMessage, franchiseArrivedMessage]);

  useEffect(() => {
    if (jobsheetId != -1) {
      navigate(ROUTER.JOB_SHEETS_VIEW + '/' + jobsheetId);
    }
  }, [jobsheetId]);



  const audioModelChangeHandler = (value: any, currentValueState: any) => {
    if (currentValueState.audioModel) {
      dispatch(audioModelDetailsForJobLoading(currentValueState.audioModel, value));
    }
  };


  const hidePopup = (setFieldValue: any) => {

    setVisible(false);
    dispatch(jobSheetClearMessage());
    setIsSubmitting(false);
    // let errorhandler: any = error
    // const resultString = errorhandler.message.replace(/([a-z])([A-Z])/g, '$1 $2');
    // if (resultString == "Invalid OTP" && INITIAL.uploadedFiles == false) {
    //   setFieldValue("franchiseArrived", false)
    // }
  };

  const dialogContainer = (error: any, setFieldValue?: any) => {

    return (<ValidationPopup error={error} visible={visible} onHide={() => hidePopup(setFieldValue)} />);
  };


  const [visibleValidation, setVisibleValidation] = useState(false);
  const [validationErrorMessage, setValidationErrorMessageError] = useState({ message: "" });
  const [filevalidationErrorMessage, setfilevalidationErrorMessage] = useState({ message: "" });

  const hideValidationPopup = () => {
    setVisibleValidation(false);
    setValidationErrorMessageError({ message: "" });
    setIsSubmitting(false);
  };

  const hideValidationService = () => {
    setServiceLocationConfirm(false);
    setIsSubmitting(false);
  };
  const hideFileValidationPopup = () => {
    setFileValidationError(false);
    setfilevalidationErrorMessage({ message: "" });
    setIsSubmitting(false);
  };

  const validationDialogContainer = (error: any) => {
    return (
      <div className='file_eeeeerrror121'>
        {/* <ValidationPopup  error={error} visible={visibleValidation && error?.message} onHide={hideValidationPopup} /> */}
        <JobsheetValidationPopup error={error} visible={visibleValidation && error?.message} onHide={hideValidationPopup} />
      </div>
    );
  };

  const validationForServiceLocation = (error: any) => {
    return (
      <div className='file_eeeeerrror121'>
        <JobsheetValidationPopup error={error} visible={serviceLocationConfirm && error} onHide={hideValidationService} />
      </div>
    );
  };

  const fileValidationDialogContainer = (error: any) => {
    return (
      <div className='file_eeeeerrror'>
        {/* <ValidationPopup  error={error} visible={fileValidationError} onHide={hideFileValidationPopup} /> */}
        <JobsheetValidationPopup error={error} visible={fileValidationError} onHide={hideFileValidationPopup} />
      </div>
    );
  };

  const [showSignature, setShowSignature] = useState(false);
  const [fileValidationError, setFileValidationError] = useState(false);
  const [submittedValue, setSubmittedValue] = useState<any>({});

  const hideSignatureContainer = () => {
    setShowSignature(false);
    setIsSubmitting(false);
  };

  const signatureSubmitHandler = (signature: any) => {
    const data: any = submittedValue;
    data['concernSignature'] = signature.split(',')[1];
    // Find the index of ':' and ';'
    const colonIndex = signature.indexOf(':');
    const semicolonIndex = signature.indexOf(';');

    // Extract the data between ':' and ';'
    const contentType: string = signature.substring(colonIndex + 1, semicolonIndex);
    data['contentType'] = contentType;

    setShowSignature(false);
    setVisibleValidation(false);

    if (!id) {
      postmethod(data);
    } else {
      updateMethod(data);
    }
  };

  const sigantureContainer = () => {
    return (<JobsheetSignatureModal visible={showSignature} onHide={hideSignatureContainer} onSubmit={signatureSubmitHandler} />);
  };

  const hideOtpContainer = (values: any, setFieldValue: any, setFieldError: any, setFieldTouched: any) => {
    setFieldValue("franchiseArrived", false);
    const data: any = values;
    data['franchiseArrived'] = false;
    changeFlow(data, jobsheet?.status ? jobsheet?.status : JOB_SHEET_STATUS[JOB_SHEET_STATUS["New Job"]], setFieldError, setFieldTouched, setFieldValue);
    setOtpScreen(false);
    setIsSubmitting(false);
  };

  const hideOtpContainerForJobClose = () => {
    setJobCloseOtpScreen(false);
    setIsSubmitting(false);
  };

  const otpSubmitHandler = (values: any) => {

    let franchiseArrivedPayload = {
      id: id,
      franchiseArrivedTime: null,
      franchiseArrivedLocation: `${latitude},${longitude}`,
      otpValue: String(values)
    };
    dispatch(franchiseArrivedLoading(franchiseArrivedPayload));
  };

  const otpJobSubmitHandler = (values: any) => {
    const data: any = submittedValue;
    data["jobCloseOtp"] = values;
    if ((franchiseuserJobclosed || franchiseSubUserJobclosed) && !isEmployee) {
      let getUserTechId = technicianList?.filter((datas) => datas.franchiseId == submittedValue["technicianId"])[0]?.userId;
      data["technicianGUId"] = getUserTechId;
    }
    setJobCloseOtpScreen(false);
    setVisibleValidation(false);
    if (!id) {
      postmethod(data);
    } else {
      updateMethod(data);
    }
  };

  const otpContainer = (values: any, setFieldValue: any, setFieldError: any, setFieldTouched: any) => {
    return (
      <OtpScreen visible={otpScreen} onHide={() => hideOtpContainer(values, setFieldValue, setFieldError, setFieldTouched)} onSubmit={otpSubmitHandler} />
    );
  };

  const otpContainerforJobClose = () => {
    return (
      <OtpScreen visible={jobCloseOtpScreen} onHide={hideOtpContainerForJobClose} onSubmit={otpJobSubmitHandler} />
    );
  };

  const hideRejectDialog = (values: any) => {
    setRejectConfirmation(false);
  };

  const rejectSubmit = (values: any, setFieldValue: any, setFieldError: any, setFieldTouched: any, submitForm: any) => {
    setRejectedSubmit(false);
    setFormikSubmitHandler(() => submitForm);
    values['IsAfcsGenerated'] = true;
    setFieldValue('IsAfcsGenerated', true);
    setFieldValue('approveStatus', REJECT);
    values["approveStatus"] = REJECT;
    changeFlow(values, jobsheet?.status ? jobsheet?.status : JOB_SHEET_STATUS[JOB_SHEET_STATUS["New Job"]], setFieldError, setFieldTouched, setFieldValue);
    setIsFormSubmitted(true);
  };

  const rejectDialogContainer = (values: any, setFieldValue: any, setFieldError: any, setFieldTouched: any, submitForm: any) => {
    return (
      <ApproveRejectConfirm onHide={hideRejectDialog} onSubmit={() => rejectSubmit(values, setFieldValue, setFieldError, setFieldTouched, submitForm)} visible={rejectConfirmation} />
    );
  };

  const handleTabClick = (value: string | number) => {
    setActiveTab(value);
  };

  const getAudioModelName = (audioModelId: number) => {
    if (!audioModelId) return null;
    const audiModel = audioModelList.find((item: any) => item.id === audioModelId);
    return audiModel?.audioModelName ?? null;
  };

  const postmethod = (values: any) => {

    dispatch(validationConfiguration?.url(FORMAT_VALUE_FOR_UPDATE(values, values.status), values?.id));
  };

  const updateMethod = (values: any) => {
    dispatch(validationConfiguration?.url(FORMAT_VALUE_FOR_UPDATE(values, values.status), values?.id));
  };

  const handleSubmit = async (values: any,) => {

    if (isSubmitting) return;
    if (rejectedSubmit) {
      return setRejectConfirmation(true);
    }
    // if (mapSelectedPlace == null || mapSelectedPlace == "") {
    //   return setServiceLocationConfirm(true);
    // }
    try {
      // values["serviceLocation"] = mapSelectedPlace;
      if (values["isFranchise"] == true) {
        values["franchiseCurrentLat"] = latitude;
        values["franchiseCurrentLag"] = longitude;
      }
      if (selectedFiles?.length > 0) {
        values["fileData"] = selectedFiles,
          values["fileData"]["type"] = "fault";
      }
      // if (correctedFiles?.length > 0) {
      //   values["correctedFiles"] = correctedFiles,
      //     values["correctedFiles"]["type"] = "perfection"
      // }
      if (approveDocuments?.length > 0) {
        values["approveDocument"] = approveDocuments;
      }
      if (addtionalExpensesFiles?.length > 0) {
        values["additionalExpenseFiles"] = addtionalExpensesFiles;
      }
      if (proofDocumentFiles?.length > 0) {
        values["proofDocumentFiles"] = proofDocumentFiles;
      }
      if (base64stringForUploadedFiles?.length > 0) {
        values["base64Files"] = base64stringForUploadedFiles?.map((data: any) => data?.split(",")[1]);
      }

      if (values?.franchiseArrived == false && (hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler()) && values.status == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Assigned To Technician"]]) {
        setfilevalidationErrorMessage({ message: "Franchise Arrived not completed Yet" });
        setFileValidationError(true);
        setIsSubmitting(true);
        return;
      }
      if (values?.uploadedFiles == false && selectedFiles?.length == 0 && (hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler()) && values.status == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Assigned To Technician"]]) {
        let errorTabName = "Additional Expenses";
        setErrorTabs((prevErrorTabs: string[]) => {
          if (!prevErrorTabs.includes(errorTabName)) {
            return [...prevErrorTabs, errorTabName];
          }
          return prevErrorTabs;
        });
        setfilevalidationErrorMessage({ message: "Franchise document required" });
        setFileValidationError(true);
        setIsSubmitting(true);
        return;
      } else {
        setFileValidationError(false);
      }

      // if ((values?.uploadedFiles == true && values?.perfectionFilesUploadStatus == false)  && correctedFiles?.length == 0 && (hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler()) && values.status == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Assigned To Technician"]] && values?.IsAfcsGenerated == false) {
      //   let errorTabName = "Additional Expenses";
      //   setErrorTabs((prevErrorTabs: string[]) => {
      //     if (!prevErrorTabs.includes(errorTabName)) {
      //       return [...prevErrorTabs, errorTabName];
      //     }
      //     return prevErrorTabs;
      //   });
      //   setfilevalidationErrorMessage({ message: "Franchise Correct document required" })
      //   setFileValidationError(true)
      //   setIsSubmitting(true)
      //   return
      // } else {
      //   setFileValidationError(false)
      // }
      if (values["isFranchise"] == true) {
        let getUserTechId = technicianList?.filter((data) => data.franchiseId == values["technicianId"])[0]?.userId;
        values["technicianGUId"] = getUserTechId;
      }
      if(values["carModelId"]){
        let data = carModelList?.find((data:any)=>data.id == values["carModelId"])?.carModelName
        values["vehicleModel"] = data
      }
      // if ((franchiseuserJobclosed || franchiseSubUserJobclosed) && !isEmployee) {
      //   let getUserTechId = technicianList?.filter((data) => data.franchiseId == values["technicianId"])[0]?.userId
      //   values["technicianGUId"] = getUserTechId
      // }else{
      //   let getUserTechId = technicianList?.filter((data) => data.franchiseId == values["technicianId"])[0]?.userId
      //   values["technicianGUId"] = getUserTechId
      // }

      if (values.status == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Job Estimated Awaiting Approval"]] && values.IsAfcsGenerated && values.approvalMode == "Portal" && values.approveStatus == APPROVE) {
        setSubmittedValue(values);
        setShowSignature(true);
      }
      // else if (values?.uploadedFiles == true && values?.perfectionFilesUploadStatus == true && (values.status == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Assigned To Technician"]] && (hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler())) && values?.IsAfcsGenerated == true) {
      //   setSubmittedValue(values)
      //   setJobCloseOtpScreen(true)
      // }
      else if ((values.status == JOB_SHEET_STATUS[JOB_SHEET_STATUS["Assigned To Technician"]] && (hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler())) && (values?.IsAfcsGenerated == true && values?.franchiseArrived == true) && (selectedFiles?.length > 0 || values?.uploadedFiles == true)) {
        setSubmittedValue(values);
        setJobCloseOtpScreen(true);
      }
      else {
        setVisibleValidation(false);
        setFileValidationError(false);
        if (!id) {
          postmethod(values);
        } else {
          updateMethod(values);
        }
      }
    } catch (error) {
    }
    setIsSubmitting(true);
    // if ((franchiseuserJobclosed || franchiseSubUserJobclosed) && !isEmployee) {
    //   let getUserTechId = technicianList?.filter((data) => data.franchiseId == values["technicianId"])[0]?.franchiseId
    //   values["technicianGUId"] = getUserTechId
    // }
  };


  const handleBack = () => {
    navigate(ROUTER.JOB_SHEETS_GRID);
  };

  const JobSheetView = {
    josheetDetails: [{ name: "Number", value: jobsheet?.number ? jobsheet?.number : 'To be generated', color: "#e0f1ce", border: "#84ac5a" },
    { name: "Brand", value: vehicleBrand?.filter((eachVehicle: any) => eachVehicle.value == jobsheet?.vehicleBrandId)?.[0]?.label ?? "-", color: "#dff0ff", border: "#749be0" },
    { name: "Status", value: jobsheet?.status, color: "#e0f5f1", border: "#2d97a6" },
    { name: "Created", value: jobsheet?.creationTime ? formatDateMEL(jobsheet?.creationTime) : "", color: "#ccf0f7", border: "#628bda" },
    ]
  };

  const handleDealerChange = (selectedValue: string, setFieldValue: any, setFieldError: any, setFieldTouched: any) => {
    if (selectedValue) {
      resetVehcileBrand(setFieldValue, setFieldError, setFieldTouched);
      dispatch(getVehiclebrandRelatedtoDealerLoading(selectedValue));
    }
  };

  const handleVehiclebrandChange = (selectedvehiclebrand: any, setFieldValue: any, setFieldError: any, setFieldTouched: any) => {
    resetAudioModelValue(setFieldValue, setFieldError, setFieldTouched);
    if (selectedvehiclebrand) {
      dispatch(getAudioModelJobdropdownListLoading(selectedvehiclebrand));
    }
  };

  // useEffect(() => {
  //   const handleConfirmation = (event:any) => {
  //     const confirmationMessage = 'You have unsaved changes. Are you sure you want to leave this page?';
  //     event.preventDefault();
  //     event.returnValue = confirmationMessage;
  //     return confirmationMessage;
  //   };

  //   const handleNavigate = (event:any) => {
  //     const confirmationMessage = 'You have unsaved changes. Are you sure you want to leave this page?';
  //     if (!window.confirm(confirmationMessage)) {
  //       event.preventDefault();
  //     }
  //   };

  //   // window.addEventListener('beforeunload', handleConfirmation);
  //   window.addEventListener('popstate', handleNavigate);

  //   return () => {
  //     // window.removeEventListener('beforeunload', handleConfirmation);
  //     window.removeEventListener('popstate', handleNavigate);
  //   };
  // }, []);


  const [accordion, setAccordion] = useState<any>(
    () => {
      let vehicleInformation = true;
      let complaintInformation = true;

      if (hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler()) {
        vehicleInformation = false;
        complaintInformation = false;
      }
      return {
        "customerInformation": true,
        "jobSheetInformation": true,
        "vehicleInformation": vehicleInformation,
        "complaintInformation": complaintInformation,
        "pendingInformation": true,
        "jobSheetPendingComments": true,
        "packagingDetails": true,
      };
    }
  );

  const changeAccorditionHandler = (tab: string) => {
    setAccordion((prev: any) => {
      let data = { ...prev };
      data[tab] = !data[tab];
      return data;
    });
  };



  const camelCaseToSpaced = (str: any) => {
    // Use regex to split camelCase string into words
    const words = str.split(/(?=[A-Z])/);

    // Capitalize the first letter of each word and join with spaces
    const spacedString = words.map((word: any) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

    return spacedString;
  };

  // Function to extract and merge error messages
  const extractErrorMessages = (errors: any) => {
    const errorMessages = [];
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        const errorArray = errors[key];
        if (Array.isArray(errorArray)) {
          errorArray.forEach(errorItem => {
            if (errorItem) {
              const errorItemMessages = Object.values(errorItem);

              errorMessages.push(...errorItemMessages.map(message => `${camelCaseToSpaced(key)}: ${message}`));
            }
          });
        } else {
          errorMessages.push(`${errorArray}`);
        }
      }
    }
    return errorMessages.join(".,");
  };



  const callFranchiseArrivedApi = (values: any) => {
    const payloadApi = {
      "id": values?.id,
      "franchiseArrivedTime": null,
      "franchiseArrivedLocation": `${latitude},${longitude}`
    };
    dispatch(franchiseArrivedLoading(payloadApi));
  };

  const getServiceLocation = (setFieldValue: any, dealerLocation: any, customerLocation: any) => {
    let options: any[] = [];
    if ((dealerLocation >= 0 && dealerLocation != "") || (customerLocation >= 0 && customerLocation != "")) {
      if (dealerLocation != undefined) {
        options = [...dealerListdropdown.filter((eachDealer: any) => eachDealer.value == dealerLocation).map((eachValue: any) => {
          let data: any = {};
          data['label'] = eachValue?.address;
          data['value'] = eachValue?.address;
          return data;
        })];
      }
      if ((customerLocation >= 0 && customerLocation != "")) {
        options = [...options, ...customerListdrop.filter((eachcustomer: any) => eachcustomer.id == customerLocation).map((eachValue: any) => {
          let data: any = {};
          data['label'] = eachValue?.address;
          data['value'] = eachValue?.address;
          return data;
        })];
      }
      return options.sort((a: any, b: any) => {
        const labelA = a.label.toUpperCase().trim();
        const labelB = b.label.toUpperCase().trim();
        if (labelA < labelB) {
          return -1;
        }
        if (labelA > labelB) {
          return 1;
        }
        return 0;
      });
    } else {
      return options;
    }
  };

  //get customer or delaer lat and lang

  const getcustomerDealerLatitude = (selectedLocation: any) => {
    dispatch(customerOrDelaerLatLagLoading(selectedLocation));
  };


  const findTabName = (fieldName: any) => {
    for (const tab of showTabError) {
   
      if (tab.fieldName.includes(fieldName)) {
        return tab.tabName;
      }
    }
    return null;
  };

  // const getErrorFieldTabs = (errors: any) => {
  //   const errorFieldTabs: any = {};
  //   for (const fieldName of Object.keys(errors)) {
  //     const tabName = findTabName(fieldName);
  //     if (tabName) {
  //       if (!errorFieldTabs[tabName]) {
  //         errorFieldTabs[tabName] = [];
  //       }
  //       errorFieldTabs[tabName].push(fieldName);
  //     }
  //   }
  //   return errorFieldTabs;
  // };

  const getErrorFieldTabs = (errors: { [key: string]: any; }) => {
    const errorFieldTabs: { [key: string]: string[]; } = {};
    for (const fieldName in errors) {
      if (errors.hasOwnProperty(fieldName)) {
        const tabName = findTabName(fieldName);
        if (tabName) {
          if (!errorFieldTabs[tabName]) {
            errorFieldTabs[tabName] = [];
          }
          const fieldErrors = errors[fieldName];
          if (Array.isArray(fieldErrors)) {
            for (const errorObject of fieldErrors) {
              for (const key in errorObject) {
                if (errorObject.hasOwnProperty(key)) {
                  errorFieldTabs[tabName].push(key);
                }
              }
            }
          } else {
            errorFieldTabs[tabName].push(fieldName);
          }
        }
      }
    }

    return errorFieldTabs;
  };

  function isValid(errors: any) {
    for (let key in errors) {
      if (errors[key] === "") {
        return false;
      }
    }
    return true;
  }

  const setCustomerAddress = (values: any, address: any, setFieldValue: any) => {
    if (INITIAL.customerAddress == "" && values.dealerId == "" && address && (hasCustomerRoleHandler() || id)) {
      setFieldValue('customerAddress', address);
    }
  };

  const handleChangeSreviceLocation = (value: any, event: any,) => {
    if (!event?.target?.value) return;
    if (event.key === 'Enter' || event.key === "Tab") {
      dispatch(getUserCurretnAddressLatLngLoading(value));
    }
  };
  const assignTechnician = (values: any,setFieldError:any,setFieldTouched:any,setFieldValue:any) => {
    setFieldTouched("technicianId", true)
    setFieldTouched("appointmentTime", true)
    setFieldTouched("franchiseNotes", true)
    setFieldTouched("technicianGUId", true)
    
    if (values["isFranchise"] == true && values.technicianId) {
      let getUserTechId = technicianList?.filter((data) => data.franchiseId == values["technicianId"])[0]?.userId;
      let techinicianPayload = {
        id: values.id,
        technicianId: values.technicianId,
        technicianGUId: getUserTechId,
        appointmentTime: values.appointmentTime,
        franchiseNotes: values.franchiseNotes,
        serviceLocation: values.serviceLocation,
      };
      
      dispatch(changeTechnicianLoading(techinicianPayload))
    }else if(values["isFranchise"] != true && values.technicianId) {
      let getUserTechId = employeeDropList?.filter((data) => data.id == values["technicianId"])[0]?.value;
      let techinicianPayload = {
        id: values.id,
        technicianId: values.technicianId,
        technicianGUId: getUserTechId,
        appointmentTime: values.appointmentTime,
        franchiseNotes: values.franchiseNotes,
        serviceLocation: values.serviceLocation,
      };
      
      dispatch(changeTechnicianLoading(techinicianPayload))
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        {visibleValidation && validationDialogContainer(validationErrorMessage)}
        {serviceLocationConfirm && validationForServiceLocation("Service Location is required")}
        {fileValidationError && fileValidationDialogContainer(filevalidationErrorMessage)}
        {showSignature && sigantureContainer()}
        {otpScreen && <div className='balckscreen'></div>}
        {serviceLocationConfirm && <div className='balckscreen'></div>}
        {jobCloseOtpScreen && <div className='balckscreen'></div>}
        {rejectConfirmation && <div className='balckscreen'></div>}
        {jobCloseOtpScreen && otpContainerforJobClose()}

        <Container fluid>
          {/* <div className='breadcrumb-job-basic-info border-bottom'> */}
          <div className={`${id && layoutType == "horizontal" ? "breadcrumb-job-basic-info_horizondal_edit" : id && layoutType == "vertical" ? "breadcrumb-job-basic-info" : !id && layoutType == "horizontal" ? "breadcrumb-job-basic-info_create_horizondal" : !id && layoutType == "vertical" && "breadcrumb-job-basic-info_create_vertical"} border-bottom`}>
            <Breadcrumb title="Job Sheets" titlePath={ROUTER.JOB_SHEETS_GRID} breadcrumbItem={id ? "Edit Job Sheet" : hasCustomerRoleHandler() ? "Generate Service Request" : "New Job Sheet"} />
            {id &&
              <Row className='mb-3'>
                {
                  JobSheetView.josheetDetails.map((data, index) => {
                    return (
                      <Col lg={3} md={6} key={index} className={`${index >= 4 ? "mt-4" : "mt-2"} pe-0 details`}>
                        <div className='job-basic-info'>
                          <div className='title text-center' style={{ background: data.color, color: data.border }}>{data.name}</div>
                          <b className={`m-auto text-center font-size-13 ${data.name == "Number" ? 'view-jobsheet-link' : ''}`} title={data?.value as string} onClick={() => {
                            if (data.name == "Number" && jobsheet?.id)
                              navigate(ROUTER.JOB_SHEETS_VIEW + `/${jobsheet?.id}`);
                          }} >{data?.value as string}</b>
                        </div>
                      </Col>
                    );
                  })
                }
              </Row>
            }
          </div>
          <Row>
            <React.Fragment>

              <Formik initialValues={INITIAL} onSubmit={handleSubmit}
                validationSchema={validationError} enableReinitialize >

                {({ values, handleChange, touched, errors, handleBlur, resetForm, reset, setFieldValue, setErrors, setFieldTouched, setFieldError, submitForm, ...rest }: any) => {
                  if (Object.keys(errors).length !== 0) {
                    const errorMessage = extractErrorMessages(errors);
                    let initialError = "";
                    const isVlidOrnotCheckError = isValid(errors);
                    if (isVlidOrnotCheckError == false) {
                      initialError = "";
                    } else {
                      initialError = "error";
                    }
                    if (visibleValidation && validationErrorMessage.message != errorMessage && initialError == "error") {
                      const errorFieldTabs = getErrorFieldTabs(errors);
                      const errorTabsName = Object.keys(errorFieldTabs);
                      setErrorTabs(errorTabsName);
                      if(errorTabsName?.includes("Job Sheet Information") && (hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler())){
                        changeAccorditionHandler('complaintInformation')
                        changeAccorditionHandler('vehicleInformation')
                      }
                      setValidationErrorMessageError({ message: errorMessage });
                    }
                  }
                  return (
                    <Form className='job-sheet-form'>
  
                      <div className={`${id && layoutType == "horizontal" ? "job-sheet-sub-menu_edit_horizontal" : id && layoutType == "vertical" ? "job-sheet-sub-menu" : (!id || id == undefined) && layoutType == "horizontal" ? "job-sheet-sub-menu_create_horizontal" : (!id || id == undefined) && layoutType == "vertical" && "job-sheet-sub-menu-new"} border-right border-bottom`}>
                        <div className="job-sheet-tab">

                          <ul className="nav nav-tabs tabs">
                            {tabOptions.map((tab, index) => (
                              <li className={`nav-item tab-new ${((errorTabs.includes(tab.label) && tab?.label === activeTab) || (errorTabs.includes(tab.label) && tab?.label !== activeTab)) ? 'error' : (errorTabs.length === 0 && tab.label !== activeTab) ? "" : (tab.label === activeTab && errorTabs.length == 0) && "active"}`} id={tab?.value}
                                data-tab={tab.value} key={index} onClick={() => { handleTabClick(tab?.label); }}>
                                {/* <li className={`nav-item tab-new ${tab?.label === activeTab ? 'active' : ''}`} id={tab?.value}
                                data-tab={tab.value} key={index} onClick={() => { handleTabClick(tab?.label) }}> */}
                                <JobTooltipMEL title={tab?.label == "Additional Expenses" ?( (hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler()) ? "Additional Expenses" : "Proof Document") :  tab?.label} position="right">
                                  <a className='nav-link'>
                                    {/* <FeatherIcon icon={tab?.icon} /> */}
                                    <tab.icon className="svg icon" />
                                    <div className={`${((errorTabs.includes(tab.label) && tab?.label === activeTab) || (errorTabs.includes(tab.label) && tab?.label !== activeTab)) ? 'custom_badges' : ""}`}></div>  <span >{tab?.label}</span></a> </JobTooltipMEL>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      {/* <div className='job-content'> */}
                      <div className={`${id && layoutType == "horizontal" ? "job-content-edit-horizondal" : id && layoutType == "vertical" ? "job-content" : !id && layoutType == "horizontal" ? "job-content-create-horizondal" : !id && layoutType == "vertical" && "job-content"}`}>
                        <div className={`${id ? "job-sheet-type" : "job-sheet-type-new"} border-right`}>

                          <CustomerDetails isdealer={isdealer} audioModel={audioModelList} touch={touched} error={errors} activeTab={activeTab} values={values} handleChange={handleChange} setFieldValue={setFieldValue}
                            validationConfiguration={validationConfiguration} onBlur={handleBlur} status={status}
                            setFieldTouched={setFieldTouched} setFieldError={setFieldError} accordion={accordion}
                            changeAccorditionHandler={changeAccorditionHandler} pastHistoryData={pastHistoryData} iniialvalue={INITIAL}
                            customerProfile={customerProfile} setAddress={setAddress} changeFlow={changeFlow}
                          />

                          <div className='job-sheet pe-0 ps-0 border-top '>
                            <div className={`${activeTab === "Job Sheet Information" ? '' : 'd-none'} job-sheet-tab-content`}>
                              <Card className='mb-0 ps-0 pe-0 border-leftrigh-none'>
                                <div className='jobsheet_tab_header border-bottom d-flex justify-content-between'>
                                  <div className='test'>
                                    <span><FeatherIcon className="pointer" icon={accordion['jobSheetInformation'] ? "chevron-down" : "chevron-right"} onClick={() => changeAccorditionHandler('jobSheetInformation')} /></span>  Job Sheet Information
                                  </div>
                                  {
                                    ((hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler()) && franchiseArrivedLocalState) && (
                                      <div className='franchise_reached'>Franchise Reached</div>
                                    )
                                  }
                                </div>

                                <AnimatePresence>
                                  {accordion['jobSheetInformation'] && (
                                    <motion.div
                                      className="accordion-content"
                                      initial={{ height: 0, opacity: 0 }}
                                      animate={{ height: 'auto', opacity: 1 }}
                                      exit={{ height: 0, opacity: 0 }}
                                      transition={{ duration: 0.3, ease: 'easeInOut' }}
                                    >
                                      <CardBody className='p-0' >
                                        <div className='px-3 pb-3'>
                                          {visible && dialogContainer(error, setFieldValue)}
                                          <Row>
                                            <Col lg={3} md={6} className='mt-mb'>
                                              <FloatInput
                                                type="text"
                                                name="number"
                                                label="Number"
                                                placeholder="To be generated"
                                                required={CHECK_VALUE_IN_ARRAY(validationConfiguration?.requiredField ? validationConfiguration?.requiredField : [], "number")}
                                                value={values.number}
                                                invalid={false}
                                                disabled={CHECK_VALUE_IN_ARRAY(validationConfiguration?.disableField ? validationConfiguration?.disableField : [], "number")}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                              />
                                            </Col>
                                            <Col lg={3} md={6} className="mt-mb">
                                              <FloatInput
                                                type="dropdown"
                                                name="dealerId"
                                                label="Dealer"
                                                options={dealerListdropdown}
                                                invalid={touched["dealerId"] && errors["dealerId"] ? true : false}
                                                value={values.dealerId}
                                                onChange={(e) => {
                                                  handleChange(e);
                                                  setLatitude(null);
                                                  setLongitude(null);
                                                  handleDealerChange(e.target.value, setFieldValue, setFieldError, setFieldTouched);
                                                  values['dealerId'] = e.target.value;
                                                  if (e.target.value != undefined) {
                                                    let dealerAddress = dealerListdropdown.filter((eachDealer: any) => {
                                                      return eachDealer.value == e.target.value;
                                                    })[0]['address'];
                                                    setFieldValue("dealerAddress", dealerAddress ? dealerAddress : "");
                                                    if (values.customerId && values.dealerId && dealerAddress) {
                                                      setFieldValue("serviceLocation", dealerAddress ? dealerAddress : "");
                                                      getcustomerDealerLatitude(dealerAddress);
                                                      dispatch(getUserCurretnAddressLatLngLoading(dealerAddress));
                                                    } else if (values.customerId && !values.dealerId) {
                                                      let customerAddress = customerListdrop.filter((eachCustomer: any) => {
                                                        return eachCustomer.value == values?.customerId;
                                                      })[0]['address'];
                                                      getcustomerDealerLatitude(customerAddress);
                                                      dispatch(getUserCurretnAddressLatLngLoading(customerAddress));
                                                    } else {
                                                      setFieldValue("serviceLocation", dealerAddress ? dealerAddress : "");
                                                      getcustomerDealerLatitude(dealerAddress);
                                                      dispatch(getUserCurretnAddressLatLngLoading(dealerAddress));
                                                    }
                                                  } else {
                                                    setFieldValue('dealerAddress', "");
                                                    setFieldValue('serviceLocation', "");
                                                    if (values.customerId) {
                                                      let customerAddress = customerListdrop.filter((eachCustomer: any) => {
                                                        return eachCustomer.value == values?.customerId;
                                                      })[0]['address'];
                                                      getcustomerDealerLatitude(customerAddress);
                                                      setFieldValue("serviceLocation", customerAddress ? customerAddress : "");
                                                      dispatch(getUserCurretnAddressLatLngLoading(customerAddress));
                                                    }
                                                  }

                                                  changeFlow(values, jobsheet?.status ? jobsheet?.status : JOB_SHEET_STATUS[JOB_SHEET_STATUS["New Job"]], setFieldError, setFieldTouched, setFieldValue);
                                                }}
                                                onBlur={handleBlur}
                                                required={CHECK_VALUE_IN_ARRAY(validationConfiguration?.requiredField ? validationConfiguration?.requiredField : [], "dealerId")}
                                                disabled={CHECK_VALUE_IN_ARRAY(validationConfiguration?.disableField ? validationConfiguration?.disableField : [], "dealerId")}
                                              />
                                            </Col>
                                            <Col lg={3} md={6} className="mt-mb">
                                              <FloatInput
                                                type="dropdown"
                                                name="vehicleBrandId"
                                                label="Vehicle brand"
                                                value={values.vehicleBrandId}
                                                options={values.dealerId ? dealerVehicleBrand : vehicleBrand}
                                                invalid={touched["vehicleBrandId"] && errors["vehicleBrandId"] ? true : false}
                                                // onChange={handleChange}
                                                onChange={(e) => {
                                                  handleVehiclebrandChange(e.target.value, setFieldValue, setFieldError, setFieldTouched);
                                                  setFieldValue("carModelId","")
                                                  dispatch(getCarModelDropdownLoading(e.target.value));
                                                  handleChange(e);
                                                  
                                                }}
                                                onBlur={handleBlur}
                                                required={CHECK_VALUE_IN_ARRAY(validationConfiguration?.requiredField ? validationConfiguration?.requiredField : [], "vehicleBrandId")}
                                                disabled={CHECK_VALUE_IN_ARRAY(validationConfiguration?.disableField ? validationConfiguration?.disableField : [], "vehicleBrandId")}
                                              />
                                            </Col>
                                            {/* <Col lg={3} md={6} className="mt-mb">
                                              <FloatInput
                                                type="date"
                                                label="Permit form received date"
                                                name="permitFormReceivedDate"
                                                value={values.permitFormReceivedDate}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={CHECK_VALUE_IN_ARRAY(validationConfiguration?.requiredField ? validationConfiguration?.requiredField : [], "permitFormReceivedDate")}
                                                disabled={CHECK_VALUE_IN_ARRAY(validationConfiguration?.disableField ? validationConfiguration?.disableField : [], "permitFormReceivedDate")}
                                              />
                                            </Col> */}
                                            <Col lg={3} md={6} className="mt-mb">
                                              <FloatInput
                                                type="dropdown"
                                                name="estimatorGUId"
                                                label="Estimator"
                                                options={employeeDropList}
                                                invalid={touched["estimatorGUId"] && errors["estimatorGUId"] ? true : false}
                                                onChange={(e) => {
                                                  if (e.target.value) {

                                                    setFieldValue("estimator", employeeDropList.filter((eachValue: any) => eachValue.value == e.target.value)?.[0]?.id ? employeeDropList.filter((eachValue: any) => eachValue.value == e.target.value)?.[0]?.id : "");
                                                  }
                                                  handleChange(e);
                                                }
                                                }
                                                onBlur={handleBlur}
                                                value={values.estimatorGUId}
                                                required={CHECK_VALUE_IN_ARRAY(validationConfiguration?.requiredField ? validationConfiguration?.requiredField : [], "estimatorGUId")}
                                                disabled={CHECK_VALUE_IN_ARRAY(validationConfiguration?.disableField ? validationConfiguration?.disableField : [], "estimatorGUId")}
                                              />
                                            </Col>
                                            <Col lg={3} md={6} className="mt-mb">
                                              <FloatInputGroup
                                                type="dropdown"
                                                name="technicianGUId"
                                                label={`Technician (${(values.isFranchise) ? "Franchise" : "Employee"})`}
                                                options={values.isFranchise ? technicianDropList : employeeDropList}
                                                invalid={touched["technicianGUId"] && errors["technicianGUId"] ? true : false}
                                                onChange={
                                                  (e: any) => {
                                                    if (e.target.value) {
                                                      setFieldValue("technicianId", technicianEmployeeDropList.filter((eachValue: any) => eachValue.value == e.target.value)?.[0]?.id ? technicianEmployeeDropList.filter((eachValue: any) => eachValue.value == e.target.value)?.[0]?.id : "");
                                                    } else {
                                                      setFieldValue("technicianId", "");
                                                    }
                                                    values['technicianGUId'] = technicianEmployeeDropList.filter((eachValue: any) => eachValue.value == e.target.value)?.[0]?.id ? technicianEmployeeDropList.filter((eachValue: any) => eachValue.value == e.target.value)?.[0]?.id : "";
                                                    changeFlow(values, jobsheet?.status ? jobsheet?.status : JOB_SHEET_STATUS[JOB_SHEET_STATUS["New Job"]], setFieldError, setFieldTouched, setFieldValue);
                                                    handleChange(e);
                                                  }
                                                }
                                                onBlur={handleBlur}
                                                value={values.technicianGUId}
                                                required={CHECK_VALUE_IN_ARRAY(validationConfiguration?.requiredField ? validationConfiguration?.requiredField : [], "technicianGUId")}
                                                disabled={technicianLabel ? false : CHECK_VALUE_IN_ARRAY(validationConfiguration?.disableField ? validationConfiguration?.disableField : [], "technicianGUId")}
                                              >
                                                <JobTooltipMEL title={values.isFranchise ? "Employee" : "Franchise"} position="right">
                                                  <CheckboxInput
                                                    className="mr-lf-4 mt-2"
                                                    name="isFranchise"
                                                    label=""
                                                    {...rest}
                                                    value={values.isFranchise}
                                                    onChange={(e: any) => {
                                                      handleChange(e);
                                                      setFieldValue("technicianGUId", "");
                                                      setFieldValue("technicianId", "");
                                                      if(!e.checked){
                                                        setFieldValue("appointmentTime", "");
                                                        setFieldValue("franchiseNotes", "");
                                                      }
                                                      values['isFranchise'] = e.target.checked;
                                                      
                                                      changeFlow(values, jobsheet?.status ? jobsheet?.status : JOB_SHEET_STATUS[JOB_SHEET_STATUS["New Job"]], setFieldError, setFieldTouched, setFieldValue);
                                                    }
                                                    }
                                                    onBlur={handleBlur}
                                                    disabled={technicianLabel ? false :  CHECK_VALUE_IN_ARRAY(validationConfiguration?.disableField ? validationConfiguration?.disableField : [], "technicianGUId")}
                                                  />

                                                </JobTooltipMEL>
                                              </FloatInputGroup>

                                            </Col>
                                            <Col lg={3} md={6} className="mt-mb"> <FloatInput
                                              label="Appointment Time"
                                              type="datetime"
                                              name="appointmentTime"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={values.appointmentTime}
                                              invalid={touched["appointmentTime"] && errors["appointmentTime"] ? true : false}
                                              required={CHECK_VALUE_IN_ARRAY(validationConfiguration?.requiredField ? validationConfiguration?.requiredField : [], "appointmentTime")}
                                              disabled={(technicianLabel && hasAdminOrEmployeeRoleHandler()  && values["isFranchise"]==true) ? false : CHECK_VALUE_IN_ARRAY(validationConfiguration?.disableField ? validationConfiguration?.disableField : [], "appointmentTime")}
                                            /> </Col>
                                            <Col lg={3} md={6} className="mt-mb">
                                              <FloatInput
                                                type="dropdown"
                                                name="warrantyStatus"
                                                label="Warranty status"
                                                options={WARRANTY_TYPES}
                                                invalid={touched["warrantyStatus"] && errors["warrantyStatus"] ? true : false}
                                                onChange={(e) => {
                                                  handleChange(e);
                                                  values['warrantyStatus'] = e.target.value;
                                                  changeFlow(values, jobsheet?.status ? jobsheet?.status : JOB_SHEET_STATUS[JOB_SHEET_STATUS["New Job"]], setFieldError, setFieldTouched, setFieldValue);
                                                  if (e.target.value == "In Warranty") {
                                                    setFieldValue("serviceCharge", 0);
                                                    setFieldValue("serviceChargeTaxAmount", 0);
                                                    setFieldValue("serviceChargeTotalWithTax", 0);
                                                  } else {
                                                    let detail = (audioModelDetails as AudioModelDetailsForJobSheet);
                                                   
                                                    setFieldValue("serviceCharge", detail?.serviceCharge ? detail?.serviceCharge : 0);
                                                    setFieldValue("serviceChargeTaxAmount", detail?.serviceChargeTaxAmount ? detail?.serviceChargeTaxAmount : 0);
                                                    setFieldValue("serviceChargeTotalWithTax", detail?.serviceChargeTotalWithTax ? detail?.serviceChargeTotalWithTax : 0);
                                                  }
                                                }}
                                                onBlur={handleBlur}
                                                value={values.warrantyStatus}
                                                required={CHECK_VALUE_IN_ARRAY(validationConfiguration?.requiredField ? validationConfiguration?.requiredField : [], "warrantyStatus")}
                                                disabled={CHECK_VALUE_IN_ARRAY(validationConfiguration?.disableField ? validationConfiguration?.disableField : [], "warrantyStatus")}
                                              />
                                            </Col>

                                            {/* <Col lg={3} md={6} className="mt-mb">
                                              <FloatInput
                                                type="dropdown"
                                                name="customerId"
                                                label="Customer"
                                                options={customerListdrop}
                                                onChange={(e) => {
                                                  handleChange(e);
                                                  values['customerId'] = e.target.value;
                                                  if (e.target.value != undefined) {
                                                    setFieldValue('customerAddress', customerListdrop.filter((eachCustomer: any) => {
                                                      return eachCustomer.value == e.target.value;
                                                    })[0]?.['address']);
                                                    let customerAddress = customerListdrop.filter((eachCustomer: any) => {
                                                      return eachCustomer.value == e.target.value;
                                                    })[0]?.['address'];
                                                    values['customerAddress'] = customerAddress ? customerAddress : null;
                                                    if (values.customerId && !values.dealerId) {
                                                      setAddress(customerListdrop.filter((eachCustomer: any) => {
                                                        return eachCustomer.value == e.target.value;
                                                      })[0]?.['address']);
                                                      let customerAddress = customerListdrop.filter((eachCustomer: any) => {
                                                        return eachCustomer.value == e.target.value;
                                                      })[0]?.['address'];
                                                      if (customerAddress) {
                                                        getcustomerDealerLatitude(customerAddress);
                                                      }
                                                    }
                                                    if (values.dealerId && !values.customerId) {
                                                      setAddress(dealerListdropdown.filter((eachDealer: any) => {
                                                        return eachDealer.value == e.target.value;
                                                      })[0]?.['address']);
                                                      let dealerAddress = dealerListdropdown.filter((eachDealer: any) => {
                                                        return eachDealer.value == e.target.value;
                                                      })[0]?.['address'];
                                                      if (dealerAddress) {
                                                        getcustomerDealerLatitude(dealerAddress);
                                                      }
                                                    }
                                                    if (values.customerId && values.dealerId) {
                                                      setAddress(dealerListdropdown.filter((eachDealer: any) => {
                                                        return eachDealer.value == values.dealerId;
                                                      })[0]?.['address']);
                                                      let dealerAddress = dealerListdropdown.filter((eachDealer: any) => {
                                                        return eachDealer.value == e.target.value;
                                                      })[0]?.['address'];
                                                      if (dealerAddress) {
                                                        getcustomerDealerLatitude(dealerAddress);
                                                      }
                                                    }
                                                  } else {
                                                    setFieldValue('customerAddress', "");
                                                    if (!values.dealerId) {
                                                      setFieldValue('serviceLocation', "");
                                                      setAddress("");
                                                    }
                                                  }

                                                  changeFlow(values, jobsheet?.status ? jobsheet?.status : JOB_SHEET_STATUS[JOB_SHEET_STATUS["New Job"]], setFieldError, setFieldTouched, setFieldValue);

                                                }}
                                                onBlur={handleBlur}
                                                value={values.customerId}
                                                invalid={touched["customerId"] && errors["customerId"] ? true : false}
                                                required={CHECK_VALUE_IN_ARRAY(validationConfiguration?.requiredField ? validationConfiguration?.requiredField : [], "customerId")}
                                                disabled={CHECK_VALUE_IN_ARRAY(validationConfiguration?.disableField ? validationConfiguration?.disableField : [], "customerId")}
                                              />
                                            </Col> */}

                                            <Col lg={3} md={6} className="mt-mb-textarea">
                                              <FloatInput
                                                type="textarea"
                                                label="Dealer Address"
                                                name="dealerAddress"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.dealerAddress}
                                                invalid={touched["dealerAddress"] && errors["dealerAddress"] ? true : false}
                                                required={CHECK_VALUE_IN_ARRAY(validationConfiguration?.requiredField ? validationConfiguration?.requiredField : [], "dealerAddress")}
                                                disabled={CHECK_VALUE_IN_ARRAY(validationConfiguration?.disableField ? validationConfiguration?.disableField : [], "dealerAddress")}
                                              />
                                            </Col>

                                            {/* <Col lg={3} md={6} className="mt-mb-textarea">
                                              <FloatInput
                                                type="textarea"
                                                label="Customer Address"
                                                name="customerAddress"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.customerAddress}
                                                invalid={touched["customerAddress"] && errors["customerAddress"] ? true : false}
                                                required={CHECK_VALUE_IN_ARRAY(validationConfiguration?.requiredField ? validationConfiguration?.requiredField : [], "customerAddress")}
                                                disabled={CHECK_VALUE_IN_ARRAY(validationConfiguration?.disableField ? validationConfiguration?.disableField : [], "customerAddress")}
                                              />
                                            </Col> */}




                                            {/* <Col lg={3} md={6} className="mt-mb-textarea">
                                              <FloatInput
                                                type="textarea"
                                                label="Service Location"
                                                name="serviceLocation"
                                                onChange={handleChange}
                                                onBlur={(e) => {
                                                  handleBlur(e)
                                                  if (e.target.value) {
                                                    getcustomerDealerLatitude(e.target.value)
                                                  }
                                                }}
                                                value={values.serviceLocation}
                                                required={CHECK_VALUE_IN_ARRAY(validationConfiguration?.requiredField ? validationConfiguration?.requiredField : [], "serviceLocation")}
                                                disabled={CHECK_VALUE_IN_ARRAY(validationConfiguration?.disableField ? validationConfiguration?.disableField : [], "serviceLocation")}
                                                map={concatinateLatandLng != "" ? true : false}
                                                concatinateLatandLng={concatinateLatandLng ? concatinateLatandLng : ""}
                                              />                                              
                                            </Col> */}

                                            <Col lg={3} md={6} className="mt-mb-textarea">
                                              <FloatInput
                                                type="textarea"
                                                label="Notes for franchise "
                                                name="franchiseNotes"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.franchiseNotes}
                                                invalid={touched["franchiseNotes"] && errors["franchiseNotes"] ? true : false}
                                                required={CHECK_VALUE_IN_ARRAY(validationConfiguration?.requiredField ? validationConfiguration?.requiredField : [], "franchiseNotes")}
                                                // disabled={values.isFranchise ? false : true}
                                                disabled={ (technicianLabel && hasAdminOrEmployeeRoleHandler() && values["isFranchise"]==true) ? false : CHECK_VALUE_IN_ARRAY(validationConfiguration?.disableField ? validationConfiguration?.disableField : [], "franchiseNotes")}
                                              />
                                            </Col>
                                            
                                            <Col lg={3} md={6} className="mt-mb-textarea">
                                              <FloatInput
                                                type="text"
                                                label="Supplier Ref No"
                                                name="supplierReferenceNumber"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.supplierReferenceNumber}
                                                invalid={touched["supplierReferenceNumber"] && errors["supplierReferenceNumber"] ? true : false}
                                                required={CHECK_VALUE_IN_ARRAY(validationConfiguration?.requiredField ? validationConfiguration?.requiredField : [], "supplierReferenceNumber")}
                                                // disabled={values.isFranchise ? false : true}
                                                disabled={CHECK_VALUE_IN_ARRAY(validationConfiguration?.disableField ? validationConfiguration?.disableField : [], "supplierReferenceNumber")}
                                              />
                                            </Col>
                                             <Col lg={2} md={6} className="mb-2  align-items-center">
                                             <CheckboxInput
                                               name="priority"
                                               label="Priority"
                                               {...rest}
                                               value={values.priority}
                                               onChange={(event) => {
                                                 handleChange(event);
                                                 }}
                                               onBlur={handleBlur}
                                               required={CHECK_VALUE_IN_ARRAY(validationConfiguration?.requiredField ? validationConfiguration?.requiredField : [], "priority")}
                                               disabled={CHECK_VALUE_IN_ARRAY(validationConfiguration?.disableField ? validationConfiguration?.disableField : [], "priority")}
                                             />
                                             <ErrorMessage name={"priority"} component={ErrorText} />
                                           </Col>
                                           
                                            {/* <Col className='mt-mb' lg={6}></Col> */}
                                            {hasAdminOrEmployeeRoleHandler() && <>
                                             <Col lg={2} md={6} className="mb-2  align-items-center">
                                             <CheckboxInput
                                               name="isExchange"
                                               label="Is exchange"
                                               {...rest}
                                               value={values.isExchange}
                                               onChange={(event) => {
                                                 handleChange(event);
                                                 values['isExchange'] = event.target.checked;
                                                 values['isExchangeChangedNow'] = event.target.checked;
                                                 setFieldValue('isExchangeChangedNow',event.target.checked);
                                                 audioModelChangeHandler(event.target.checked, values);
                                                 changeFlow(values, jobsheet?.status ? jobsheet?.status : JOB_SHEET_STATUS[JOB_SHEET_STATUS["New Job"]], setFieldError, setFieldTouched, setFieldValue);

                                               }}
                                               onBlur={handleBlur}
                                               required={CHECK_VALUE_IN_ARRAY(validationConfiguration?.requiredField ? validationConfiguration?.requiredField : [], "isExchange")}
                                               disabled={CHECK_VALUE_IN_ARRAY(validationConfiguration?.disableField ? validationConfiguration?.disableField : [], "isExchange")}
                                             />
                                             <ErrorMessage name={"isExchange"} component={ErrorText} />
                                           </Col>
                                           <Col lg={2} md={6} className="mb-2  align-items-center">
                                             <CheckboxInput
                                               name="dispatched"
                                               label="Dispatched"
                                               {...rest}
                                               value={values.dispatched}
                                               onChange={(event) => {
                                                 handleChange(event);
                                                 values['dispatchedNow'] = event.target.checked;
                                                 setFieldValue('dispatchedNow',event.target.checked);
                                                 }}
                                               onBlur={handleBlur}
                                               required={CHECK_VALUE_IN_ARRAY(validationConfiguration?.requiredField ? validationConfiguration?.requiredField : [], "dispatched")}
                                               disabled={CHECK_VALUE_IN_ARRAY(validationConfiguration?.disableField ? validationConfiguration?.disableField : [], "dispatched")}
                                             />
                                             <ErrorMessage name={"dispatched"} component={ErrorText} />
                                           </Col>
                                           </>
                                            }
                                           
                                            <Col lg={2} md={6} className="mb-2  align-items-center">
                                              <CheckboxInput
                                                name="isRemoteService"
                                                label="Is remote service"
                                                {...rest}
                                                value={values.isRemoteService}
                                                onChange={(event) => {
                                                  handleChange(event);
                                                  values['isRemoteService'] = event.target.checked;
                                                  changeFlow(values, jobsheet?.status ? jobsheet?.status : JOB_SHEET_STATUS[JOB_SHEET_STATUS["New Job"]], setFieldError, setFieldTouched, setFieldValue);
                                                }}
                                                onBlur={handleBlur}
                                                required={CHECK_VALUE_IN_ARRAY(validationConfiguration?.requiredField ? validationConfiguration?.requiredField : [], "isRemoteService")}
                                                disabled={CHECK_VALUE_IN_ARRAY(validationConfiguration?.disableField ? validationConfiguration?.disableField : [], "isRemoteService")}
                                              />
                                              <ErrorMessage name={"isRemoteService"} component={ErrorText} />
                                            </Col>
                                            {(values.faultySystemAvailableList?.length > 0 && hasAdminOrEmployeeRoleHandler()) &&
                                            <Col lg={12} md={12} className="mb-2  align-items-center">
                                            <FieldArray name="faultySystemAvailableList">
                                            {({ insert, remove, push }) => (
                                              <fieldset className='faulty-system'>
                                                <div className="header">Is Faulty System Available</div>
                                                <div className="data-content">
                                                {values.faultySystemAvailableList?.length > 0 &&
                                                  values.faultySystemAvailableList.map(
                                                    (value: any, index: any) => {
                                                       return (
                                                      <Col lg={2} md={6} key={index}>
                                                        <CheckboxInput
                                                          name={`faultySystemAvailableList.[${index}].isFaultySystem`}
                                                          label={ values?.faultySystemAvailableList?.[index]?.label}
                                                          {...rest}
                                                          onChange={(e:any)=>{
                                                            handleChange(e)
                                                            if(e.checked){
                                                              dispatch(updateFaultySystemLoading(id,values?.faultySystemAvailableList?.[index]?.outgoingDispatchId,values?.faultySystemAvailableList?.[index]?.label))
                                                            }
                                                          }}
                                                          onBlur={handleBlur}
                                                          value={value.isFaultySystem}
                                                          required={CHECK_VALUE_IN_ARRAY(validationConfiguration?.requiredField ? validationConfiguration?.requiredField : [], `faultySystemAvailableList.[${index}].isFaultySystem`)}
                                                          disabled={CHECK_VALUE_IN_ARRAY(validationConfiguration?.disableField ? validationConfiguration?.disableField : [], `faultySystemAvailableList.[${index}].isFaultySystem`) || value.isFaultySystem}
                                                        />
                                                        <ErrorMessage name={`faultySystemAvailableList.[${index}].isFaultySystem`} component={ErrorText} />
                                                        </Col>
                                                        )})}
                                                        </div>
                                                        </fieldset>)}
                                                        </FieldArray>

                                            </Col>}
                                            {
                                              (!CHECK_VALUE_IN_ARRAY(validationConfiguration?.disableField ? validationConfiguration?.disableField : [], "franchiseArrived") && (hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler())) && false && <Col lg={3} md={6} className="mb-2  align-items-center">
                                                <CheckboxInput
                                                  name="franchiseArrived"
                                                  label="I am reached"
                                                  {...rest}
                                                  onChange={(e) => {
                                                    values['franchiseArrived'] = e.target.checked;
                                                    if (e.target.checked == true) {
                                                      setOtpScreen(true);
                                                      changeFlow(values, jobsheet?.status ? jobsheet?.status : JOB_SHEET_STATUS[JOB_SHEET_STATUS["New Job"]], setFieldError, setFieldTouched, setFieldValue);
                                                    } else {
                                                      setOtpScreen(false);
                                                      values['franchiseArrived'] = false;
                                                    }
                                                    handleChange(e);
                                                  }}
                                                  onBlur={handleBlur}
                                                  value={values.franchiseArrived}
                                                  required={CHECK_VALUE_IN_ARRAY(validationConfiguration?.requiredField ? validationConfiguration?.requiredField : [], "franchiseArrived")}
                                                  disabled={CHECK_VALUE_IN_ARRAY(validationConfiguration?.disableField ? validationConfiguration?.disableField : [], "franchiseArrived")}
                                                />
                                                <ErrorMessage name={"franchiseArrived"} component={ErrorText} />
                                                {otpScreen && otpContainer(values, setFieldValue, setFieldError, setFieldTouched)}
                                              </Col>
                                            }
                                            <Col className='mt-mb' lg={12} >
                                              <div className="d-flex justify-content-end" style={{ marginTop: "15px" }}>
                                                {
                                                  ((values.status == "Assigned To Technician")&&(hasAdminOrEmployeeRoleHandler())) && 
                                                  <Button
                                                    className="btn-primary"
                                                    label={technicianLabel ? "Update Technician" :"Change Technician"}
                                                    onClick={() => {
                                                      if (technicianLabel) {
                                                        assignTechnician(values,setFieldError,setFieldTouched,setFieldValue);
                                                      }else{
                                                        setTechnicianLabel(true);
                                                        values["technicianDropdown"] = true;
                                                        changeFlow(values, jobsheet?.status ? jobsheet?.status : JOB_SHEET_STATUS[JOB_SHEET_STATUS["New Job"]], setFieldError, setFieldTouched, setFieldValue);
                                                      }                                                      
                                                    }}
                                                    type="button"
                                                  />
                                                }

                                                {
                                                  (values.isFranchise && values.technicianId) && <div className="" style={{
                                                    fontSize: "14px",
                                                    textAlign: "start",
                                                    padding: "0px 0 0 0px"
                                                  }}>
                                                    <Link target='_blank' to={ROUTER.PARTS_SKU + "/" + values?.technicianId} className='btn btn-primary' style={{ fontWeight: "bold" }}>View Available stock</Link>
                                                  </div>
                                                }
                                                {/* {
                                                  (values.status == "Assigned To Technician") && <div className="" style={{
                                                    fontSize: "14px",
                                                    textAlign: "start",
                                                    padding: "0px 0 0 0px"
                                                  }}>
                                                    <div className='btn btn-primary' style={{ fontWeight: "bold" }}>Change Technician</div>
                                                  </div>
                                                } */}
                                              </div>
                                            </Col>
                                            <Col lg={12} md={12}>
                                              {/* {
                                                apiKey &&
                                                <GoogleMap editPage={id ? true : false} getByIdAddress={((hasCustomerRoleHandler() || hasDealerRoleHandler() || customer_Id || hasAdminOrEmployeeRoleHandler()) ? address : jobsheet?.serviceLocation ? jobsheet?.serviceLocation : "")} title='Service Location' setMapSelectedPlace={setMapSelectedPlace} setfieldValue={setFieldValue} jobsheet={true} onChange={() => setCustomerAddress(values, mapSelectedPlace, setFieldValue)} apiKey={apiKey} />
                                              } */}
                                              <FloatInput
                                                type="text"
                                                label="Service Location"
                                                name="serviceLocation"
                                                onChange={handleChange}
                                                // onBlur={(e) => {
                                                //   handleBlur(e);
                                                //   if (e.target.value) {
                                                //     dispatch(getUserCurretnAddressLatLngLoading(e.target.value));
                                                //   }
                                                // }}
                                                onBlur={handleBlur}
                                                invalid={touched["serviceLocation"] && errors["serviceLocation"] ? true : false}
                                                value={values.serviceLocation}
                                                onKeyDown={(e: any) => handleChangeSreviceLocation(e.target.value, e)}
                                                required={CHECK_VALUE_IN_ARRAY(validationConfiguration?.requiredField ? validationConfiguration?.requiredField : [], "serviceLocation")}
                                                disabled={CHECK_VALUE_IN_ARRAY(validationConfiguration?.disableField ? validationConfiguration?.disableField : [], "serviceLocation")}
                                              />
                                              <p style={{ fontSize: "12px" }}>Please press the Enter key after entering the service location.</p>
                                              {getcurrentUserLatLng &&
                                                <div className='mt-2'>
                                                  <iframe src={`https://maps.google.com/maps?q=${getcurrentUserLatLng.lat},${getcurrentUserLatLng.lng}&t=&z=15&ie=UTF8&iwloc=&output=embed`} width="100%" height="350" style={{ border: 0 }} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                                </div>
                                              }

                                            </Col>
                                         
                                          </Row>
                                        </div>
                                      </CardBody>
                                    </motion.div>)}
                                </AnimatePresence>
                              </Card>

                              <JobSheetDetails isdealer={isdealer} audioModel={audioModelList} touch={touched} error={errors} activeTab={activeTab} values={values} handleChange={handleChange} setFieldValue={setFieldValue}
                                validationConfiguration={validationConfiguration} onBlur={handleBlur} status={status}
                                setFieldTouched={setFieldTouched} setFieldError={setFieldError} accordion={accordion}
                                changeAccorditionHandler={changeAccorditionHandler} pastHistoryData={pastHistoryData} carModelIdDropdownOptions={carModelList}
                              />
                            </div>

                            <EstimateDetails activeTab={activeTab} status={status} handleChange={handleChange} onBlur={handleBlur}
                              touch={touched} error={errors} values={values} setFieldValue={setFieldValue} changeFlow={changeFlow}
                              validationConfiguration={validationConfiguration} setFieldTouched={setFieldTouched}
                              setFieldError={setFieldError} accordion={accordion}
                              changeAccorditionHandler={changeAccorditionHandler} setApproveDocuments={setApproveDocuments} approveDocuments={approveDocuments}
                            />
                            <IncomingCourier activeTab={activeTab} status={status} handleChange={handleChange} onBlur={handleBlur}
                              touch={touched} error={errors} values={values} setFieldValue={setFieldValue} changeFlow={changeFlow}
                              validationConfiguration={validationConfiguration} setFieldTouched={setFieldTouched}
                              setFieldError={setFieldError} accordion={accordion}
                              changeAccorditionHandler={changeAccorditionHandler} />

                            <AdditionalExpense activeTab={activeTab} status={status} handleChange={handleChange} onBlur={handleBlur}
                              touch={touched} error={errors} values={values} setFieldValue={setFieldValue} changeFlow={changeFlow}
                              validationConfiguration={validationConfiguration} setFieldTouched={setFieldTouched}
                              setFieldError={setFieldError} accordion={accordion}
                              changeAccorditionHandler={changeAccorditionHandler} setSelectedFiles={setSelectedFiles} selectedFiles={selectedFiles} setAddtionalExpensesFiles={setAddtionalExpensesFiles} addtionalExpensesFiles={addtionalExpensesFiles} setBase64stringForUploadedFiles={setBase64stringForUploadedFiles}
                              setCorrectedFiles={setCorrectedFiles} correctedFiles={correctedFiles} proofDocumentFiles={proofDocumentFiles} setProofDocumentFiles={setProofDocumentFiles}
                            />

                            <InspectionInformation activeTab={activeTab} status={status} handleChange={handleChange} onBlur={handleBlur} />

                            <OutgoingCourier activeTab={activeTab} status={status} handleChange={handleChange} onBlur={handleBlur}
                              touch={touched} error={errors} values={values} setFieldValue={setFieldValue} changeFlow={changeFlow}
                              validationConfiguration={validationConfiguration} setFieldTouched={setFieldTouched}
                              setFieldError={setFieldError} accordion={accordion} changeAccorditionHandler={changeAccorditionHandler} />

                            <PendingComments touch={touched} initialValues={values} error={errors} activeTab={activeTab} handleChange={handleChange} onBlur={handleBlur} status={status} changeFlow={changeFlow} setFieldError={setFieldError} setFieldTouched={setFieldTouched} setFieldValue={setFieldValue} jobId={id}/>
                            <DefectivePart touch={touched} initialValues={values} error={errors} activeTab={activeTab} handleChange={handleChange} onBlur={handleBlur} status={status} changeFlow={changeFlow} setFieldError={setFieldError} setFieldTouched={setFieldTouched} setFieldValue={setFieldValue} jobId={id} scanParts={scanParts} setScanParts={setScanParts} values={values}/>
                            <Row className='custom-checkbox-label mt-3 mb-2 transistive-state'>
                              {/* {!(!CHECK_VALUE_IN_ARRAY(validationConfiguration?.disableField ? validationConfiguration?.disableField : [], "franchiseArrived") && (hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler())) && */}
                              <Col sm={12} md={6} className="">
                                {otpScreen && otpContainer(values, setFieldValue, setFieldError, setFieldTouched)}
                                {/* {
                                  (!hasCustomerRoleHandler()) ? <>
                                    <Label className='label-btm mb-0'>{`Transition to next state ('${validationConfiguration?.nextStatus}')?`}</Label>
                                    <CheckboxInput
                                      name="IsAfcsGenerated"
                                      className='mt-0'
                                      onChange={(event) => {

                                        handleChange(event)
                                        values['IsAfcsGenerated'] = event.target.checked;
                                        values["franchiseArrivedOrNot"] = jobsheet?.franchiseArrivedTime !== null ? true : false;
                                        values['approveStatus'] = "";
                                        changeFlow(values, jobsheet?.status ? jobsheet?.status : JOB_SHEET_STATUS[JOB_SHEET_STATUS["New Job"]], setFieldError, setFieldTouched, setFieldValue)
                                      }}
                                      value={values.IsAfcsGenerated}
                                      label="Yes"
                                      {...rest}
                                      required={CHECK_VALUE_IN_ARRAY(validationConfiguration?.requiredField ? validationConfiguration?.requiredField : [], "IsAfcsGenerated")}
                                      disabled={CHECK_VALUE_IN_ARRAY(validationConfiguration?.disableField ? validationConfiguration?.disableField : [], "IsAfcsGenerated")}
                                    />
                                  </> : (hasCustomerRoleHandler() && validationConfiguration?.nextStatus != "Generate Job Request") && <>
                                    <Label className='label-btm mb-0'>{`Transition to next state ('${validationConfiguration?.nextStatus}')?`}</Label>
                                    <CheckboxInput
                                      name="IsAfcsGenerated"
                                      className='mt-0'
                                      onChange={(event) => {

                                        handleChange(event)
                                        values["franchiseArrivedOrNot"] = jobsheet?.franchiseArrivedTime !== null ? true : false;
                                        values['IsAfcsGenerated'] = event.target.checked;
                                        values['approveStatus'] = "";
                                        changeFlow(values, jobsheet?.status ? jobsheet?.status : JOB_SHEET_STATUS[JOB_SHEET_STATUS["New Job"]], setFieldError, setFieldTouched, setFieldValue)
                                      }}
                                      value={values.IsAfcsGenerated}
                                      label="Yes"
                                      {...rest}
                                      required={CHECK_VALUE_IN_ARRAY(validationConfiguration?.requiredField ? validationConfiguration?.requiredField : [], "IsAfcsGenerated")}
                                      disabled={CHECK_VALUE_IN_ARRAY(validationConfiguration?.disableField ? validationConfiguration?.disableField : [], "IsAfcsGenerated")}
                                    />
                                  </>
                                } */}
                              </Col>
                              {/* } */}
                              {/* {(!CHECK_VALUE_IN_ARRAY(validationConfiguration?.disableField ? validationConfiguration?.disableField : [], "franchiseArrived") && (hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler())) &&
                                <Col sm={12} md={6} className="">
                                  <CheckboxInput
                                    name="franchiseArrived"
                                    label="Reached"
                                    className="mt-2 checkbox-highlight"
                                    {...rest}
                                    onChange={(e) => {
                                      values['franchiseArrived'] = e.target.checked
                                      if (e.target.checked == true) {
                                        setOtpScreen(true)
                                        changeFlow(values, jobsheet?.status ? jobsheet?.status : JOB_SHEET_STATUS[JOB_SHEET_STATUS["New Job"]], setFieldError, setFieldTouched, setFieldValue)
                                      } else {
                                        setOtpScreen(false)
                                        values['franchiseArrived'] = false
                                      }
                                      handleChange(e)
                                    }}
                                    onBlur={handleBlur}
                                    value={values.franchiseArrived}
                                    required={CHECK_VALUE_IN_ARRAY(validationConfiguration?.requiredField ? validationConfiguration?.requiredField : [], "franchiseArrived")}
                                    disabled={CHECK_VALUE_IN_ARRAY(validationConfiguration?.disableField ? validationConfiguration?.disableField : [], "franchiseArrived")}
                                  />
                                  <ErrorMessage name={"franchiseArrived"} component={ErrorText} />
                                  {otpScreen && otpContainer(values, setFieldValue, setFieldError, setFieldTouched)}
                                </Col>
                              } */}
                              <Col sm={12} md={6}>
                                <div className="d-flex justify-content-end mt-2 mb-2">
                                  <Button
                                    className="secondary-btn me-2"
                                    label="Cancel"
                                    type="button"
                                    onClick={() => {
                                      handleBack();
                                    }
                                    }
                                  />

                                  {
                                    (!CHECK_VALUE_IN_ARRAY(validationConfiguration?.disableField ? validationConfiguration?.disableField : [], "franchiseArrived") && (hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler())) ?
                                      <>
                                        <Button
                                          className="btn-primary me-2"
                                          label={`Reached`}
                                          onClick={() => {
                                            values['franchiseArrived'] = true;
                                            setFieldValue('franchiseArrived', true);
                                            setOtpScreen(true);
                                            changeFlow(values, jobsheet?.status ? jobsheet?.status : JOB_SHEET_STATUS[JOB_SHEET_STATUS["New Job"]], setFieldError, setFieldTouched, setFieldValue);
                                          }}
                                          type="button"
                                        />
                                      </>
                                      :
                                      <>
                                        {validationConfiguration?.nextStatus != "Cancel Jobsheet" && <Button
                                          className="btn-primary me-2 btn-primary-shadow"
                                          label={id ? "Update Job Sheet" : hasCustomerRoleHandler() ? "Submit" : "Create New Job"}
                                          onClick={() => {
                                            // validationErrorMessage
                                            setFormikSubmitHandler(() => submitForm);
                                            values['IsAfcsGenerated'] = false;
                                            values["franchiseArrivedOrNot"] = jobsheet?.franchiseArrivedTime !== null ? true : false;
                                            values['approveStatus'] = "";
                                            values['recentInPending'] = (values?.inPending && (!values['isOldPending']))
                                            values['defectiveParts'] = scanParts
                                            setFieldValue('IsAfcsGenerated', false);
                                            changeFlow(values, jobsheet?.status ? jobsheet?.status : JOB_SHEET_STATUS[JOB_SHEET_STATUS["New Job"]], setFieldError, setFieldTouched, setFieldValue);
                                            setIsFormSubmitted(true);
                                            // setVisibleValidation(true)

                                          }}
                                          type="button"
                                        />}
                                        {validationConfiguration?.nextStatus != "Mark as Approved / Rejected" && <Button
                                          className="btn-primary me-2 next-status-btn"
                                          label={`${validationConfiguration?.nextStatus}`}
                                          onClick={() => {
                                            // validationErrorMessage
                                            setFormikSubmitHandler(() => submitForm);
                                            values['IsAfcsGenerated'] = true;
                                            values["franchiseArrivedOrNot"] = jobsheet?.franchiseArrivedTime !== null ? true : false;
                                            values['defectiveParts'] = scanParts
                                            setFieldValue('IsAfcsGenerated', true);
                                            changeFlow(values, jobsheet?.status ? jobsheet?.status : JOB_SHEET_STATUS[JOB_SHEET_STATUS["New Job"]], setFieldError, setFieldTouched, setFieldValue);
                                            setIsFormSubmitted(true);
                                            // setVisibleValidation(true)
                                          }}
                                          type="button"
                                        />}

                                        {validationConfiguration?.nextStatus == "Mark as Approved / Rejected" && <>
                                          <Button
                                            className="btn-success me-2"
                                            label="Approve"
                                            onClick={() => {
                                              setFormikSubmitHandler(() => submitForm);
                                              values['IsAfcsGenerated'] = true;
                                              setFieldValue('IsAfcsGenerated', true);
                                              setFieldValue('approveStatus', APPROVE);
                                              values["approveStatus"] = APPROVE;
                                              changeFlow(values, jobsheet?.status ? jobsheet?.status : JOB_SHEET_STATUS[JOB_SHEET_STATUS["New Job"]], setFieldError, setFieldTouched, setFieldValue);
                                              setIsFormSubmitted(true);
                                            }}
                                            type="button"
                                          />
                                          {rejectConfirmation && rejectDialogContainer(values, setFieldValue, setFieldError, setFieldTouched, submitForm)}
                                          <Button
                                            className="btn-danger me-2"
                                            label="Reject"
                                            onClick={() => {
                                              setFormikSubmitHandler(() => submitForm);
                                              values['IsAfcsGenerated'] = true;
                                              setFieldValue('IsAfcsGenerated', true);
                                              setFieldValue('approveStatus', REJECT);
                                              values["approveStatus"] = REJECT;
                                              changeFlow(values, jobsheet?.status ? jobsheet?.status : JOB_SHEET_STATUS[JOB_SHEET_STATUS["New Job"]], setFieldError, setFieldTouched, setFieldValue);
                                              setRejectedSubmit(true);
                                              setIsFormSubmitted(true);

                                            }}
                                            type="button"
                                          />
                                        </>

                                        }
                                      </>

                                  }



                                </div>
                              </Col>
                            </Row>

                          </div>
                        </div>

                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </React.Fragment>
          </Row>
        </Container>
      </div >
    </React.Fragment >
  );
};

export default JobSheetEditForm;

