import { Formik, Form } from 'formik';
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import Breadcrumb from 'src/components/Common/Breadcrumb'
import InputCom from 'src/components/Common/Input';
import * as Yup from 'yup';
import { ROUTER } from 'src/constants/routes';
import Dropzone from "react-dropzone";
import Button from 'src/components/UI/Button';
import { FieldModal } from 'src/models/components/inputWrapperModel';
import { vehicleBrandsDetails } from 'src/models/pages/vehicleBrandsModel';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from 'src/store/reducers';
import { clearVehicleBrandsMessage, createVehicleBrands, deleteVehicleBrandImageLoading, getVehicleBrandImageLoading, getVehicleBrandsBYID, resetVehicleBrand, updateVehicleBrands, uploadVehicleBrandFileLoading } from 'src/store/actions';
import { useToast } from "src/components/Common/ReactToaster";
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';
import { FILE_UPLOAD_SIZE } from 'src/constants/commonConstants';
import ImagePreviewer from 'src/components/Common/ImagePreviewer/ImagePreviewer';
import { onDropRejectedFileHandler } from 'src/helpers/common_helpers';
import DeleteModal from 'src/pages/Calendar/DeleteModal';
import { vehicleBrandImageDeleteSuccess } from 'src/store/vehicleBrands/reducer';
import { clearCarBrandsMessage, createCarBrands, getCarBrandsBYID, getVehicleBrandDropdown, resetCarBrand, updateCarBrands } from 'src/store/carbrand/action';
import { carBrandsDetails } from 'src/models/pages/carBrandInitialState';
import DropdownInput from 'src/components/UI/DropdownInput';

const AddEditCarModel: React.FC = () => {
  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [localFormFields, setLocalFormFields] = useState<FieldModal[]>([]);
  const { id } = useParams()
  const { showToast } = useToast();



  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    vehicleBrandId: Yup.string().required('Vehicle brand is required'),

  });
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      dispatch(getCarBrandsBYID(id));
    }
    dispatch(getVehicleBrandDropdown())
    
    return () => {
      dispatch(resetCarBrand())
    }
  }, [id, dispatch]);

  const formValue: carBrandsDetails = useSelector(
    (state: RootReducerState) => state.carBrandReducer?.carBrandsDetails as carBrandsDetails
  );

  const { error, message, loading,vehicleBrandDropdown } = useSelector(
    (state: RootReducerState) => state.carBrandReducer
  );

  const initialvalue = {
    name: (formValue?.['carModelName'] || ""),
    vehicleBrandId: (formValue?.['vehicleBrandId'] || ""),
  }
  const [visible, setVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false)

  const vehicleBrandDropdownOptions = useMemo(()=>{
    let data=vehicleBrandDropdown?.length>0 ? vehicleBrandDropdown?.map((val:any)=>({label:val.name,value:val.id})) : []
    return data
  },[vehicleBrandDropdown])

  useEffect(() => {
    if (error) {
      setVisible(true)
      setIsSubmitting(false)
    }
    if (message !== "" && message != undefined) {
      showToast(message, { type: "success" });
      if (message != vehicleBrandImageDeleteSuccess) {
        dispatch(clearCarBrandsMessage())
        setTimeout(() => {
          setIsSubmitting(false)
          navigate(ROUTER.CAR_MODEL_GRID)
        }, 500)
        if (id) {
          dispatch(getCarBrandsBYID(id))
        } else {
          navigate(ROUTER.CAR_MODEL_GRID);
        }
      }else{
        dispatch(clearCarBrandsMessage())
        if (id) {
          dispatch(getCarBrandsBYID(id))
        }

 
      }
    

    }
  }, [error, message])

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }

  }, [loading]);

  const hidePopup = () => {
    setVisible(false);
    dispatch(clearCarBrandsMessage())
  }

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    )
  }

  const handleSubmit = async (values: any) => {
    if (isSubmitting) return
    const submittedData: any = {
      id:id ? id :0 ,
      carModelName: values.name as string,
      vehicleBrandId: values.vehicleBrandId
    }
    setIsSubmitting(true)
    id ? update(submittedData) : post(submittedData);
  };

  const update = (formValue: vehicleBrandsDetails) => {
    dispatch(updateCarBrands(formValue));
  };


  const post = (formValue: vehicleBrandsDetails) => {
    dispatch(createCarBrands(formValue));
  };
  
  const handleReset = (resetForm: any) => {
    resetForm(getEmptyInitialValues());
    if (id) {
      navigate(ROUTER.CAR_MODEL_GRID)
    }
  }

  const getEmptyInitialValues = () => {
    const initialValue = localFormFields?.reduce((acc: any, field: any) => {
      if (field.type !== 'checkbox') {
        acc[field.name] = '';
      } else {
        acc[field.name] = false;
      }
      return acc;
    }, {});
    return initialValue;
  };

  return (
    <React.Fragment>

      {visible && dialogContainer(error)}
      <div className="page-content" id="view-brand">
        <Container fluid>
          <Breadcrumb title="Vehicle Model" titlePath={ROUTER.CAR_MODEL_GRID} breadcrumbItem={`${id ? "Edit Vehicle Model" : "Create Vehicle Model"}`} />
          <Row>
            <Card>
              <CardBody>
                <React.Fragment>
                  <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialvalue}
                    validationSchema={validationSchema}
                    enableReinitialize>
                    {({ values, handleChange, touched, errors, handleBlur, resetForm, reset, ...rest }: any) => {
                      return (
                        <Form onChange={handleChange}>
                          <Row>
                            <Col lg={4} >
                              <InputCom labelName="Name"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="name"
                                onBlur={handleBlur}
                                value={values.name}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={touched["name"] && errors["name"] ? true : false}
                              />

                            </Col>
                            <Col lg={4} >
                            <DropdownInput
                                options={vehicleBrandDropdownOptions}
                                name="vehicleBrandId"
                                label="Vehicle Brand"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required={true}
                                value={values.vehicleBrandId}
                                invalid={
                                  touched["vehicleBrandId"] && errors["vehicleBrandId"]
                                    ? true
                                    : false
                                } />

                            </Col>
                            <div className="d-flex justify-content-end mt-2">
                              <Button
                                className="secondary-btn me-2"
                                label={id ? "Cancel" : "Reset"}
                                type="button"
                                onClick={() => handleReset(resetForm)}
                              />
                              <Button
                                className="btn-primary me-2 btn-primary-shadow"
                                label={id ? "Update Vehicle Model" : "Create Vehicle Model"}
                                type="submit"
                              />
                            </div>

                          </Row>


                        </Form>
                      )
                    }}
                  </Formik>
                </React.Fragment>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddEditCarModel