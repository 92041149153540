import { partsInitialState } from "src/models/pages/partsModel";
import { StockInspectionTypes } from "./actionTypes";
import { StockInspection } from "src/models/pages/stockinspectionmodel";




const initialState: StockInspection = {
  error: "",
  loading: false,
  message: "",
  paginatorCount: 0,
  stockInspectionList: {
    values: [],
    totalRecords: 0
  },
  stockInspectionDetail: null,
  partSkuList: null,
  supplierList: null,
  initiatedByList: null,
  supplierPartSkuDetail: null,
  audioBrandList: null,
  audioBrandPartSkuDetail: null,
  scanResponse: null,
  skuNumberStatus: [],
  stockLocationList: null,
  Unused: [],
  Used: [],
  Rejected: [],
  Missing: [],
  Scrapped: [],
  Floating: [],
};



const StockInspections = (state = initialState, action: any) => {
  switch (action.type) {

    // Grid

    case StockInspectionTypes.GET_STOCK_INSPECTION_LIST:
    case StockInspectionTypes.FILTER_PAGE_LOADING:
    case StockInspectionTypes.UPDATE_STOCK_INSPECTION:
    case StockInspectionTypes.POST_STOCK_INSPECTION:
    case StockInspectionTypes.GET_STOCK_INSPECTION_BY_ID:
    case StockInspectionTypes.GET_SCAN_PART_SKU:
    case StockInspectionTypes.SENT_EMAIL_TO_STOCK_INSPECTION:
    case StockInspectionTypes.DOWNLOAD_STOCK_INSPECTION:
    case StockInspectionTypes.DOWNLOAD_UNSCANNED_SKU_LOADING:
      return {
        ...state,
        loading: true,
        paginatorCount: 0
      };

    case StockInspectionTypes.GET_SKU_NUMBER_WITH_STATUS:
      return {
        ...state,
        loading: true,
        Unused: [],
        Used: [],
        Rejected: [],
        Missing: [],
        Scrapped: [],
        Floating: [],
        paginatorCount: 0
      };

    case StockInspectionTypes.GET_STOCK_INSPECTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        stockInspectionList: {
          values: action.payload.data.items,
          totalRecords: action.payload.data.totalCount,
        },
        paginatorCount: action.payload.data.items?.length,
        message: "",
        error: "",
      };

    case StockInspectionTypes.GET_STOCK_INSPECTION_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "",
        error: "",
        stockInspectionDetail: action.payload
      };

    case StockInspectionTypes.GET_STOCK_INSPECTION_LIST_FAIL:
      return {
        ...state,
        loading: false,
      };

    case StockInspectionTypes.DELETE_STOCK_INSPECTION:
      return {
        ...state,
        loading: true,
      };

    case StockInspectionTypes.DELETE_STOCK_INSPECTION_SUCCESS:
      const url = new URL(action.payload.raw.url);
      const undoParam = url.searchParams.get('undo');
      let msg = " ";
      if (undoParam !== null) {
        const undoValue = undoParam === 'true';
        msg = undoValue ? "Stock Inspection is restored successfully" : "Stock Inspection is deleted successfully";
      } else {
        msg = "Stock Inspection is deleted successfully";
      }
      return {
        ...state,
        loading: false,
        error: "",
        message: msg
      };

    case StockInspectionTypes.UPDATE_STOCK_INSPECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "Stock Inspection updated successfully"
      };
    case StockInspectionTypes.FILTER_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        stockInspectionList: {
          ...state.stockInspectionList,
          values: action.payload.data,
          totalRecords: action.payload.data.length,
        }
      };
    case StockInspectionTypes.POST_STOCK_INSPECTION_SUCCESS:

      return {
        ...state,
        loading: false,
        error: "",
        message: action.formValue.id ? "Stock Inspection updated successfully" : "Stock Inspection created successfully",
      };

    case StockInspectionTypes.DELETE_STOCK_INSPECTION_FAIL:
      return {
        ...state,
        loading: false,
        message: "",
        error: action.payload
      };

      
    case StockInspectionTypes.SENT_EMAIL_TO_STOCK_INSPECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "Successfully emailed the stock inspection!"
        
      };
      
    case StockInspectionTypes.DOWNLOAD_STOCK_INSPECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: ""
      };
      
    case StockInspectionTypes.DOWNLOAD_UNSCANNED_SKU_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: ""
      };



    case StockInspectionTypes.FILTER_PAGE_ERROR:
    case StockInspectionTypes.UPDATE_STOCK_INSPECTION_FAIL:
    case StockInspectionTypes.POST_STOCK_INSPECTION_FAIL:
    case StockInspectionTypes.GET_STOCK_INSPECTION_BY_ID_FAIL:
    case StockInspectionTypes.GET_SUPPLIER_PART_SKU_DETAILS_ERROR:
    case StockInspectionTypes.GET_SUPPLIER_PART_SKU_DETAILS_ERROR:
    case StockInspectionTypes.GET_AUDIO_BRAND_PART_SKU_DETAILS_ERROR:
    case StockInspectionTypes.GET_SKU_NUMBER_WITH_STATUS_FAIL:
    case StockInspectionTypes.SENT_EMAIL_TO_STOCK_INSPECTION_FAIL:
    case StockInspectionTypes.DOWNLOAD_STOCK_INSPECTION_FAIL:
    case StockInspectionTypes.GET_SCAN_PART_SKU_ERROR:
    case StockInspectionTypes.DOWNLOAD_UNSCANNED_SKU_FAIL:
      return {
        ...state,
        loading: false,
        message: "",
        error: action.payload
      };
    case StockInspectionTypes.RESET_STOCK_INSPECTION:
      return {
        ...state,
        loading: false,
        message: "",
        error: ""
      };

    case StockInspectionTypes.GET_PART_SKU_LIST_SUCCESS:
      return {
        ...state,
        partSkuList: action.payload
      };
    case StockInspectionTypes.GET_SUPPLIER_DROPDOWN_SUCCESS:
      return {
        ...state,
        supplierList: action.payload.data
      };
    case StockInspectionTypes.GET_INITIATED_BY_DROPDOWN_SUCCESS:
      return {
        ...state,
        initiatedByList: action.payload.data?.filter((item:any)=>item.isDeleted != true)
      };
    case StockInspectionTypes.GET_SUPPLIER_PART_SKU_DETAILS_SUCCESS:
      return {
        ...state,
        audioBrandPartSkuDetail: "",
        supplierPartSkuDetail: action.payload.data
      };
    case StockInspectionTypes.GET_AUDIO_BRAND_PART_SKU_DETAILS_SUCCESS:
      return {
        ...state,
        supplierPartSkuDetail: "",
        audioBrandPartSkuDetail: action.payload.data
      };
    case StockInspectionTypes.GET_AUDIO_BRAND_DROPDOWN_LIST_SUCCESS:

      return {
        ...state,
        audioBrandList: action.payload
      };
    case StockInspectionTypes.GET_STOCK_LOCATION_DROPDOWN_SUCCESS:

      return {
        ...state,
        stockLocationList: action.payload
      };
    case StockInspectionTypes.GET_SCAN_PART_SKU_SUCCESS:
      // for previous API call
      // return {
      //   ...state,
      //   loading:false,
      //   error:"",
      //   scanResponse: action.payload
      // }

      // new API call from 28-02-2024
      return {
        ...state,
        loading: false,
        error: "",
        scanResponse: action.payload
      };
    case StockInspectionTypes.CLEAR_ERROR_MESSAGE:
      return {
        ...state,
        error: ""
      };
    case StockInspectionTypes.CLEAR_VALUES:
      return {
        error: "",
        loading: false,
        message: "",
        paginatorCount: 0,
        stockInspectionList: {
          values: [],
          totalRecords: 0
        },
        stockInspectionDetail: null,
        partSkuList: null,
        supplierList: null,
        initiatedByList: null,
        supplierPartSkuDetail: null,
        audioBrandList: null,
        audioBrandPartSkuDetail: null,
        scanResponse: null,
        skuNumberStatus: [],
        stockLocationList: null,
        Unused: [],
        Used: [],
        Rejected: [],
        Missing: [],
        Scrapped: [],
        Floating: [],
      };


    case StockInspectionTypes.SKUS_STATUS_SUCCESS:
      return {
        ...state,
        skuNumberStatus: action.payload?.data
      };
    case StockInspectionTypes.SKUS_STATUS_FAIL:
      return {
        ...state,
        error: action.payload
      };
    case StockInspectionTypes.GET_SKU_NUMBER_WITH_STATUS_SUCCESS:
      return {
        ...state, 
        loading: false,
        [action.payload.name]: action.payload?.data
      }
    default:
      return { ...state };
  }
};

export default StockInspections;
