import React, { useEffect, useMemo } from 'react';
import { Col, Row } from 'reactstrap';
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter';
import StatusHghlighter from 'src/components/Common/StatusHighlighter/StatusHighlighter';
import "./viewBrand.scss"
import { useNavigate, useParams } from 'react-router';
import { ROUTER } from 'src/constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from 'src/store/reducers';
import { vehicleBrandsDetails } from 'src/models/pages/vehicleBrandsModel';
import { getDealerNamesBYID, getVehicleBrandImageLoading, getVehicleBrandsBYID } from 'src/store/actions';
import { Image } from 'primereact/image';
import { Link } from 'react-router-dom';
import { carBrandsDetails } from 'src/models/pages/carBrandInitialState';
import { getCarBrandsBYID, getVehicleBrandDropdown, resetCarBrand } from 'src/store/carbrand/action';

const ViewCarModel = () => {

  const { id } = useParams()
  const navigate = useNavigate();
  const editCarModelClick = () => {
    navigate(`${ROUTER.CAR_MODEL_EDIT}/${id}`);
  };
  const archiveDealerClickHandler = () => { };
  const { error, message, loading , vehicleBrandDropdown} = useSelector((state: RootReducerState) => state.carBrandReducer);
  const formValue: carBrandsDetails = useSelector(
    (state: RootReducerState) => state.carBrandReducer?.carBrandsDetails as carBrandsDetails
  );

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      dispatch(getCarBrandsBYID(id));
      dispatch(getVehicleBrandDropdown());
    }
    return()=>{
      dispatch(resetCarBrand())
    }
  }, [id, dispatch]);


  const statusBodyTemplate = (value: string) => {
    let status = value as string;
    return (
      <StatusHghlighter status={status} />
    )

  }

  const carModelViewDetails =useMemo(()=> {
    return {
    personalDetails: [
      {
        label: "Name",
        value: formValue?.carModelName
      },
      {
        label: "Vehicle Brand",
        value: vehicleBrandDropdown?.length >0  ? vehicleBrandDropdown?.find((val:any)=>val?.id===formValue?.vehicleBrandId)?.name : ""
      },
    ],
  }
},[formValue,vehicleBrandDropdown])

  return (
    <div className="pagecontent " id="view-brand">
      <BreadcrumbWithFilter
        title="Vehicle Models"
        titlePath={ROUTER.CAR_MODEL_GRID}
        breadcrumbItem="Vehicle Model Details"
        isShowCreate={false}
        isShowFilter={false}
        filter={false}
        isShowFirstButton={true}
        firstButtonLabel='Edit Vehicle Model'
        onFirstButtonClick={editCarModelClick}
      />
      <Row>
        <div className="view">
          <div className="general-details">
            <b>General Details</b>
          </div>
          <div className="subview">
            <Row>
              {
                carModelViewDetails.personalDetails.map((data, index) => {
                  return (
                    <Col lg={12} key={index} className={"mt-4"}>
                      <div className='mb-2'>{data.label}</div>
                      <b>{data.value ? data.value : <div className='none-text'>-</div>}</b>
                    </Col>
                  )
                })
              }
            </Row>
          </div>
        </div>
      </Row>
    </div>
  );
};

export default ViewCarModel;
