import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import FloatInput from 'src/components/Common/FloatInput';
import { JOB_RECEIVED_MODE } from 'src/constants/commonConstants';
import { CHECK_VALUE_IN_ARRAY } from 'src/helpers/jobsheet_validation';
import { useDispatch, useSelector } from 'react-redux';
import { courierDropdownForJobLoading, employeeDropdownForJobLoading } from 'src/store/actions';
import { RootReducerState } from 'src/store/reducers';
import TextEditor from 'src/components/atoms/TextEditor';
import { FieldArray } from 'formik';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { formatDateTimeFormServerView } from 'src/helpers/common_helpers';
import HtmltoPlainText from 'src/components/atoms/HtmltoPlainText';
import FeatherIcon from "feather-icons-react"
import { getCourierDropdownLoading } from 'src/store/stockAdjustment/actions';

interface TabProps {
    activeTab: string | number;
    status: string;
    handleChange?: any;
    changeFlow: any;
    onBlur?: () => void;
    values: any;
    touch: any;
    error: any;
    validationConfiguration?: any;
    setFieldValue: any;
    setFieldTouched: any;
    setFieldError: any;
    accordion?: any;
    changeAccorditionHandler?: any;
}

const IncomingCourier = (props: TabProps) => {

    const dispatch = useDispatch()
    const { employeeList, courierList, jobsheet } = useSelector((state: RootReducerState) => state.jobsheetReducer);

    

    useEffect(() => {
        // dispatch(courierDropdownForJobLoading()) // old method call from job service
        dispatch(employeeDropdownForJobLoading())
    }, [])

    useEffect(() => {
        
        if (jobsheet?.dealerId && !jobsheet?.customerId) {
            dispatch(courierDropdownForJobLoading(jobsheet?.dealerId))
        } else if (!jobsheet?.dealerId && jobsheet?.customerId) {
            dispatch(courierDropdownForJobLoading(jobsheet?.customerId))
        } else if (jobsheet?.dealerId && jobsheet?.customerId) {
            dispatch(courierDropdownForJobLoading(jobsheet?.dealerId))
        }
    }, [jobsheet])

    const employeeDropList = employeeList?.length > 0 ? (employeeList.map((eachEmployee: any) => {
        let data: any = {};
        data['value'] = eachEmployee.userId;
        data['label'] = eachEmployee.name;
        return data;
    })).sort((a: any, b: any) => {
        const labelA = a.label.toUpperCase().trim();
        const labelB = b.label.toUpperCase().trim();
        if (labelA < labelB) {
            return -1;
        }
        if (labelA > labelB) {
            return 1;
        }
        return 0;
    }) : []

    // const courierDropList = courierList?.length > 0 ? (courierList.map((eachCourier: any) => {
    //     let data: any = {};
    //     data['value'] = eachCourier.id;
    //     data['label'] = eachCourier.courierMasterName == "Other" ? eachCourier.otherCompany : eachCourier.courierMasterName;
    //     return data;
    // })).sort((a: any, b: any) => {
    //     const labelA = a.label.toUpperCase().trim();
    //     const labelB = b.label.toUpperCase().trim();
    //     if (labelA < labelB) {
    //         return -1;
    //     }
    //     if (labelA > labelB) {
    //         return 1;
    //     }
    //     return 0;
    // }) : []

    const courierDropList = courierList?.length > 0 ? (courierList.map((eachCourier: any) => {
        let data: any = {};
        data['value'] = eachCourier.id;
         data['label'] = eachCourier.companyName == "Other" ? `${eachCourier.otherCompany} ${eachCourier?.packageReceiverName ? `courier :(${eachCourier?.packageReceiverName == "mel" ? "Mount Electronics" :eachCourier?.packageReceiverName  })` : ""} ${eachCourier?.awbNumber ? `(${eachCourier?.awbNumber})` : ""}` : `${eachCourier.companyName} ${eachCourier?.packageReceiverName ? `courier : (${eachCourier?.packageReceiverName == "mel" ? "Mount Electronics" :eachCourier?.packageReceiverName})` : ""} ${eachCourier?.awbNumber ? `(${eachCourier?.awbNumber})` : ""}`
        return data;
    })).sort((a: any, b: any) => {
        const labelA = a.label.toUpperCase().trim();
        const labelB = b.label.toUpperCase().trim();
        if (labelA < labelB) {
            return -1;
        }
        if (labelA > labelB) {
            return 1;
        }
        return 0;
    }) : []

    const EditValue = (rowData:any) => {
        debugger
        props.setFieldValue(`incomingDispatchListNew[0].receivedDate`,rowData.receivedDate)
        props.setFieldValue(`incomingDispatchListNew[0].id`,rowData.id)
        props.setFieldValue(`incomingDispatchListNew[0].courierId`,rowData.courierId)
        props.setFieldValue(`incomingDispatchListNew[0].receivedId`,rowData.receivedId)
        props.setFieldValue(`incomingDispatchListNew[0].notes`,rowData.notes)
        props.setFieldValue(`incomingDispatchListNew[0].jobReceivedMode`,rowData.jobReceivedMode)
        props.setFieldValue(`incomingDispatchListNew[0].courierReceivedAt`,rowData.courierReceivedAt)
        props.setFieldValue(`incomingDispatchListNew[0].courierPackingCondition`,rowData.courierPackingCondition)
        props.setFieldValue(`incomingDispatchListNew[0].courierAmount`,rowData.courierAmount)
        props.setFieldValue(`incomingDispatchListNew[0].courierChequeNumber`,rowData.courierChequeNumber)
        props.setFieldValue(`incomingDispatchListNew[0].courierBankName`,rowData.courierBankName)
        props.setFieldValue(`incomingDispatchListNew[0].handCarriedPersonName`,rowData.handCarriedPersonName)
        props.setFieldValue(`incomingDispatchListNew[0].handCarriedPersonDesignation`,rowData.handCarriedPersonDesignation)

        let values = {
            ...props.values,
            incomingDispatchListNew:[{
                receivedDate: rowData.receivedDate,
                jobReceivedMode: rowData.jobReceivedMode,
                courierReceivedAt: rowData.courierReceivedAt,
                courierPackingCondition: rowData.courierPackingCondition,
                courierAmount: rowData.courierAmount,
                courierChequeNumber: rowData.courierChequeNumber,
                courierBankName: rowData.courierBankName,
                handCarriedPersonName: rowData.handCarriedPersonName,
                handCarriedPersonDesignation0: rowData.handCarriedPersonDesignation0,
                receivedId: rowData.receivedId,
                courierId: rowData.courierId,
                notes: rowData.notes,
                id:rowData.id
            }]
        }
        props.changeFlow(values, props.values.status, props.setFieldError, props.setFieldTouched, props.setFieldValue)
      }

          const EditTemplate = (rowData:any) => {
            return(
                <>
                <FeatherIcon icon="edit" onClick={()=>EditValue(rowData)}/>
                </>
            )
          }
const getCourierName = (rowData:any)=>{
    return courierDropList?.find((data:any)=>data?.value == rowData?.courierId)?.label
}
    return (
        <div className={`job-sheet-tab-content ${props.activeTab === "Incoming Courier" ? "" : "d-none"}`}>
            <Card className='pe-0 ps-0 mb-0 border-leftrigh-none'>
                <div className='dealer-title-header font-size-15 border-bottom'>Incoming Courier Information</div>
                <CardBody className='p-0'>
                    <div className='px-3 pb-3'>
                    <FieldArray name="incomingDispatchListNew">
                                        {({ insert, remove, push }) => (
                                          <div >
                                            {props.values.incomingDispatchListNew?.length > 0 &&
                                              props.values.incomingDispatchListNew.map(
                                                (value: any, index: any) => {
                                                  return (
                                                  <div key={index}>
                        <Row>
                            <Col lg={3} md={6} className="mt-mb">
                                <FloatInput
                                    type="dropdown"
                                    name={`incomingDispatchListNew[${index}].jobReceivedMode`}
                                    label="Job received mode"
                                    options={JOB_RECEIVED_MODE}
                                    onChange={(e) => {
                                        props.handleChange(e);
                                        let modifiedValues = {
                                            ...props.values,
                                            incomingDispatchListNew:[
                                                {
                                                    ...props.values.incomingDispatchListNew[0],
                                                    jobReceivedMode:e.value
                                                }
                                            ]

                                        }
                                        props.changeFlow(modifiedValues, props.values?.status, props.setFieldError, props.setFieldTouched, props.setFieldValue)

                                    }}
                                    // onChange={props.handleChange}
                                    onBlur={props.onBlur}
                                    value={value.jobReceivedMode}
                                    invalid={props.touch.incomingDispatchListNew?.[index].jobReceivedMode && props.error?.incomingDispatchListNew?.[index]?.jobReceivedMode ? true : false}
                                    required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], `incomingDispatchListNew[${index}].jobReceivedMode`)}
                                    disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], `incomingDispatchListNew[${index}].jobReceivedMode`)}
                                />
                            </Col>

                            {/* {JSON.stringify(props.values.handCarriedPersonName)} */}
                            {!CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.hideField ? props.validationConfiguration?.hideField : [], `incomingDispatchListNew[${index}].handCarriedPersonName`) && <Col lg={3} md={6} className="mt-mb">  <FloatInput
                                label="Hand carried person name"
                                type="text"
                                name={`incomingDispatchListNew[${index}].handCarriedPersonName`}
                                onChange={props.handleChange}
                                onBlur={props.onBlur}
                                value={value.handCarriedPersonName}
                                invalid={props.touch.incomingDispatchListNew?.[index].handCarriedPersonName && props.error?.incomingDispatchListNew?.[index]?.handCarriedPersonName ? true : false}
                                required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], `incomingDispatchListNew[${index}].handCarriedPersonName`)}
                                disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], `incomingDispatchListNew[${index}].handCarriedPersonName`)}
                            />
                            </Col>
                            }



                            {/* {JSON.stringify(props.values.handCarriedPersonDesignation)} */}
                            {!CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.hideField ? props.validationConfiguration?.hideField : [], `incomingDispatchListNew[${index}].handCarriedPersonDesignation`) && <Col lg={3} md={6} className="mt-mb"> <FloatInput
                                label="Hand carried person designation"
                                type="text"
                                name={`incomingDispatchListNew[${index}].handCarriedPersonDesignation`}
                                onChange={props.handleChange}
                                onBlur={props.onBlur}
                                value={value.handCarriedPersonDesignation}
                                invalid={props.touch.incomingDispatchListNew?.[index].handCarriedPersonDesignation && props.error?.incomingDispatchListNew?.[index]?.handCarriedPersonDesignation ? true : false}
                                required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], `incomingDispatchListNew[${index}].handCarriedPersonDesignation`)}
                                disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], `incomingDispatchListNew[${index}].handCarriedPersonDesignation`)}
                            />  </Col>}


                            {/* {JSON.stringify(props.values.courierReceivedAt)} */}
                            {!CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.hideField ? props.validationConfiguration?.hideField : [],`incomingDispatchListNew[${index}].courierReceivedAt`) && <Col lg={3} md={6} className="mt-mb"> <FloatInput
                                label="Courier received at"
                                type="datetime"
                                name={`incomingDispatchListNew[${index}].courierReceivedAt`}
                                onChange={props.handleChange}
                                onBlur={props.onBlur}
                                value={value.courierReceivedAt}
                                invalid={props.touch.incomingDispatchListNew?.[index].courierReceivedAt && props.error?.incomingDispatchListNew?.[index]?.courierReceivedAt ? true : false}
                                required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], `incomingDispatchListNew[${index}].courierReceivedAt`)}
                                disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], `incomingDispatchListNew[${index}].courierReceivedAt`)}
                            /> </Col>}


                            {/* {JSON.stringify(props.values.courierPackingCondition)} */}
                            {!CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.hideField ? props.validationConfiguration?.hideField : [], `incomingDispatchListNew[${index}].courierPackingCondition`) && <Col lg={3} md={6} className="mt-mb">  <FloatInput
                                label="Courier packing condition"
                                type="text"
                                name={`incomingDispatchListNew[${index}].courierPackingCondition`}
                                onChange={props.handleChange}
                                onBlur={props.onBlur}
                                value={value.courierPackingCondition}
                                invalid={props.touch.incomingDispatchListNew?.[index].courierPackingCondition && props.error?.incomingDispatchListNew?.[index]?.courierPackingCondition ? true : false}
                                required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], `incomingDispatchListNew[${index}].courierPackingCondition`)}
                                disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], `incomingDispatchListNew[${index}].courierPackingCondition`)}
                            /> </Col>}


                            {/* {JSON.stringify(props.values.courierAmount)} */}
                            {!CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.hideField ? props.validationConfiguration?.hideField : [], `incomingDispatchListNew[${index}].courierAmount`) && <Col lg={3} md={6} className="mt-mb"> <FloatInput
                                label="Courier amount"
                                type="number"
                                name={`incomingDispatchListNew[${index}].courierAmount`}
                                onChange={props.handleChange}
                                onBlur={props.onBlur}
                                value={value.courierAmount}
                                invalid={props.touch.incomingDispatchListNew?.[index].courierAmount && props.error?.incomingDispatchListNew?.[index]?.courierAmount ? true : false}
                                required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], `incomingDispatchListNew[${index}].courierAmount`)}
                                disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], `incomingDispatchListNew[${index}].courierAmount`)}
                            /> </Col>}


                            {/* {JSON.stringify(props.values.courierChequeNumber)} */}
                            {!CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.hideField ? props.validationConfiguration?.hideField : [], `incomingDispatchListNew[${index}].courierChequeNumber`) && <Col lg={3} md={6} className="mt-mb"> <FloatInput
                                label="Courier cheque number"
                                type="text"
                                name={`incomingDispatchListNew[${index}].courierChequeNumber`}
                                onChange={props.handleChange}
                                onBlur={props.onBlur}
                                value={value.courierChequeNumber}
                                invalid={props.touch.incomingDispatchListNew?.[index].courierChequeNumber && props.error?.incomingDispatchListNew?.[index]?.courierChequeNumber ? true : false}
                                required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], `incomingDispatchListNew[${index}].courierChequeNumber`)}
                                disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], `incomingDispatchListNew[${index}].courierChequeNumber`)}
                            /> </Col>}


                            {/* {JSON.stringify(props.values.courierBankName)} */}
                            {!CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.hideField ? props.validationConfiguration?.hideField : [], `incomingDispatchListNew[${index}].courierBankName`) && <Col lg={3} md={6} className="mt-mb"> <FloatInput
                                label="Courier bank name"
                                type="text"
                                name={`incomingDispatchListNew[${index}].courierBankName`}
                                onChange={props.handleChange}
                                onBlur={props.onBlur}
                                value={value.courierBankName}
                                invalid={props.touch.incomingDispatchListNew?.[index].courierBankName && props.error?.incomingDispatchListNew?.[index]?.courierBankName ? true : false}
                                required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], `incomingDispatchListNew[${index}].courierBankName`)}
                                disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], `incomingDispatchListNew[${index}].courierBankName`)}
                            /></Col>}

                        </Row>
                        <Row className='mt-2'>
                            <div className='dealer-title-header border-bottom font-size-15'>
                                <span className='pull-left'>Incoming Dispatch</span>
                                {/* {!showIncomingDispatchAddPanel && (
                                    <div className="add-btn-container mt-0 pull-right" onClick={() => { setShowIncomingDispatchAddPanel(true) }}>
                                        <i className="fas fa-plus-circle"></i>
                                    </div>)} */}
                            </div>
                        </Row>
                 
                        <Row id="customer-add-panel">
                            <Col lg={3} md={6} className="mt-mb">
                                {/* {JSON.stringify(props.values.incomingDispatchDto_receivedDate)} */}
                                <FloatInput
                                    label="Received date"
                                    type="date"
                                    name={`incomingDispatchListNew[${index}].receivedDate`}
                                    onChange={props.handleChange}
                                    onBlur={props.onBlur}
                                    value={value.receivedDate}
                                    invalid={props.touch.incomingDispatchListNew?.[index]?.receivedDate && props.error.incomingDispatchListNew?.[index]?.receivedDate ? true : false}
                                    required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], `incomingDispatchListNew[${index}].receivedDate`)}
                                    disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], `incomingDispatchListNew[${index}].receivedDate`)}
                                />
                            </Col>
                            <Col lg={3} md={6} className="mt-mb">
                                {/* {JSON.stringify(value.incomingDispatchDto_receivedId)}
                            {JSON.stringify(employeeDropList)} */}
                                <FloatInput
                                    type="dropdown"
                                    name={`incomingDispatchListNew[${index}].receivedId`}
                                    label="Received by"
                                    options={employeeDropList}
                                    onChange={props.handleChange}
                                    onBlur={props.onBlur}
                                    value={value.receivedId}
                                    invalid={props.touch.incomingDispatchListNew?.[index]?.receivedId && props.error.incomingDispatchListNew?.[index]?.receivedId ? true : false}
                                    required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], `incomingDispatchListNew[${index}].receivedId`)}
                                    disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], `incomingDispatchListNew[${index}].receivedId`)}
                                />
                            </Col>

                            {/* {JSON.stringify(value.incomingDispatchDto_courierId)} */}
                            {!CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.hideField ? props.validationConfiguration?.hideField : [], `incomingDispatchListNew[${index}].courierId`) && <Col lg={3} md={6} className="mt-mb">  <FloatInput
                                type="dropdown"
                                name={`incomingDispatchListNew[${index}].courierId`}
                                label="Courier"
                                options={courierDropList}
                                onChange={props.handleChange}
                                onBlur={props.onBlur}
                                value={value.courierId}
                                invalid={props.touch.incomingDispatchListNew?.[index]?.courierId && props.error.incomingDispatchListNew?.[index]?.courierId ? true : false}
                                required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], `incomingDispatchListNew[${index}].courierId`)}
                                disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], `incomingDispatchListNew[${index}].courierId`)}
                            />  </Col>}

                            <Col lg={3} md={6} className="mt-mb-textarea">
                                {/* {JSON.stringify(value.incomingDispatchDto_notes)} */}
                                <div className="d-flex align-items-start">
                                    <div className="d-block  w-100">
                                        {/* <FloatInput
                                            label="Notes"
                                            type="textarea"
                                            name="incomingDispatchDto_notes"
                                            onChange={props.handleChange}
                                            onBlur={props.onBlur}
                                            value={value.incomingDispatchDto_notes}
                                            invalid={props.touch["incomingDispatchDto_notes"] && props.error["incomingDispatchDto_notes"] ? true : false}
                                            required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], "incomingDispatchDto_notes")}
                                            disabled={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], "incomingDispatchDto_notes")}
                                        /> */}

                                        <TextEditor
                                        onChange={props.handleChange}
                                        labelName='Notes'
                                        name={`incomingDispatchListNew[${index}].notes`}
                                        required={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.requiredField ? props.validationConfiguration?.requiredField : [], `incomingDispatchListNew[${index}].notes`) ? true : false}
                                        value={value.notes}
                                        invalid={props.touch.incomingDispatchListNew?.[index]?.notes && props.error.incomingDispatchListNew?.[index]?.notes ? true : false}
                                        setFieldTouched={props.setFieldTouched}
                                        setFieldValue={props.setFieldValue}
                                        onBlur={props.onBlur}
                                        readOnly={CHECK_VALUE_IN_ARRAY(props.validationConfiguration?.disableField ? props.validationConfiguration?.disableField : [], `incomingDispatchListNew[${index}].notes`) ? true : false}
                                        />
                                    </div>
                                    {/* <div className="justify-content-center" style={{ marginLeft: "10px" }}>
                                        <TooltipMEL title="Remove this Part" position="left">
                                            <i className="fas fa-times-circle" onClick={() => { setShowIncomingDispatchAddPanel(false) }}></i>
                                        </TooltipMEL>
                                    </div> */}
                                </div>
                            </Col>
                        </Row>
                        
                    </div>
                                                  )})}
                                                  </div>)}
                                                  </FieldArray>
            </div>
                </CardBody>
            </Card>

            {props.values.incomingDispatchList.length>0 ?
                <div className="card ps-0 pe-0 mt-2" id="table-border">
                  <DataTable size='large' value={props.values.incomingDispatchList} showGridlines tableStyle={{ minWidth: '50rem', padding: "30px" }}>
                    <Column field="jobReceivedMode" header="Job Received Mode" className=''></Column>
                    <Column field="receivedDate" header="Received Date" className='' body={(rowData:any)=>formatDateTimeFormServerView(rowData.receivedDate,false)}></Column>
                    <Column field="receivedByName" header="Received by" className=''></Column>
                    <Column field="courierId" header="Courier" className='partSku' body={(rowData:any)=>getCourierName(rowData)}></Column>
                    <Column field="notes" header="Notes" className='' body={(rowData:any)=><HtmltoPlainText htmlString={rowData.notes}/>}></Column>
                    <Column field="notes" header="Edit" className='' body={(rowData:any)=>EditTemplate(rowData)}></Column>
                  </DataTable>
                </div>
                : null }
        </div>
    )
}

export default IncomingCourier