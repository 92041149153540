import {
Dashboard
} from "./actiontype";

export const getMarketoverview = (data: any) => ({
  type: Dashboard.GET_MARKET_OVERVIEW,
  payload: data,
});

export const getMarketoverviewSuccess = (actionType : any, invoices : any) => ({
  type: Dashboard.GET_MARKET_OVERVIEW_SUCCESS,
  payload: {actionType,invoices},
})

export const getMarketoverviewFail = (actionType : any, error : any) => ({
  type: Dashboard.GET_MARKET_OVERVIEW_FAIL,
  payload: {actionType,error},
})

export const getWalletBalance = (data: any) => ({
  type: Dashboard.GET_WALLENT_BALANCE,
  payload: data,
})

export const getWalletBalanceSuccess = (actionType : any, data : any) => ({
  type: Dashboard.GET_WALLENT_BALANCE_SUCCESS,
  payload: {actionType,data},
})

export const getWalletBalanceFail = (actionType : any, error : any) => ({
  type: Dashboard.GET_WALLENT_BALANCE_FAIL,
  payload: {actionType,error},
})

export const getInvestedOverview = (data: any) => ({
  type: Dashboard.GET_Invested_Overview,
  payload: data,
})

export const getInvestedOverviewSuccess = (actionType : any, data : any) => ({
type: Dashboard.GET_Invested_Overview_SUCCESS,
  payload: {actionType,data}
})

export const getInvestedOverviewFail = (actionType : any, error : any) => ({
  type: Dashboard.GET_Invested_Overview_FAIL,
  payload: {actionType,error},
})

export const getDealerCount=()=>({
  type:Dashboard.GET_DEALER_COUNT,
})

export const getDealerCountSuccess=(response:any)=>({
  type:Dashboard.GET_DEALER_COUNT_SUCCESS,
  payload:response
})

export const getDealerCountError=(response:any)=>({
  type:Dashboard.GET_DEALER_COUNT_FAIL,
  payload:response
})

export const getSupplierCount=()=>({
  type:Dashboard.GET_SUPPLIER_COUNT,
})

export const getSupplierCountSuccess=(response:any)=>({
  type:Dashboard.GET_SUPPLIER_COUNT_SUCCESS,
  payload:response
})

export const getSupplierCountError=(response:any)=>({
  type:Dashboard.GET_SUPPLIER_COUNT_FAIL,
  payload:response
})

export const getFranchiseeCount=()=>({
  type:Dashboard.GET_FRANCHISEE_COUNT,
})

export const getFranchiseeCountSuccess=(response:any)=>({
  type:Dashboard.GET_FRANCHISEE_COUNT_SUCCESS,
  payload:response
})

export const getFranchiseeCountError=(response:any)=>({
  type:Dashboard.GET_FRANCHISEE_COUNT_FAIL,
  payload:response
})

export const getCustomerCount=()=>({
  type:Dashboard.GET_CUSTOMER_COUNT,
})

export const getCustomerCountSuccess=(response:any)=>({
  type:Dashboard.GET_CUSTOMER_COUNT_SUCCESS,
  payload:response
})

export const getCustomerCountError=(response:any)=>({
  type:Dashboard.GET_CUSTOMER_COUNT_FAIL,
  payload:response
})

export const getJobsheetStatus=(payload:any)=>({
  type:Dashboard.GET_JOBSHEET_STATUS_COUNT,
  payload:payload
})

export const getJobsheetStatusSuccess=(response:any)=>({
  type:Dashboard.GET_JOBSHEET_STATUS_COUNT_SUCCESS,
  payload:response
})

export const getJobsheetStatusError=(response:any)=>({
  type:Dashboard.GET_JOBSHEET_STATUS_COUNT_FAIL,
  payload:response
})

export const getJobsheetTrend=(month:any,year :any)=>({
  type:Dashboard.GET_JOBSHEET_TREND_COUNT,
  payload:{month,year}
})

export const getJobsheetTrendSuccess=(response:any)=>({
  type:Dashboard.GET_JOBSHEET_TREND_COUNT_SUCCESS,
  payload:response
})

export const getJobsheetTrendError=(response:any)=>({
  type:Dashboard.GET_JOBSHEET_TREND_COUNT_FAIL,
  payload:response
})

// export const getJobsheetTrend=(payload:any)=>({
//   type:Dashboard.GET_JOBSHEET_TREND_COUNT,
//   payload:payload
// })

// export const getJobsheetTrendSuccess=(response:any)=>({
//   type:Dashboard.GET_JOBSHEET_TREND_COUNT_SUCCESS,
//   payload:response
// })

// export const getJobsheetTrendError=(response:any)=>({
//   type:Dashboard.GET_JOBSHEET_TREND_COUNT_FAIL,
//   payload:response
// })



export const getStockAdjustmentDashboardCountLoading=(month : any,year : any)=>{
  
  return{
    type : Dashboard.GET_STOCK_ADJUSTMENT_DASHBOARD_DETAILS_LOADING,
    payload : {month,year}
  }
}
export const getStockAdjustmentDashboardCountSuccess=(resp : any)=>{
  return{
    type : Dashboard.GET_STOCK_ADJUSTMENT_DASHBOARD_DETAILS_SUCCESS,
    payload : resp
  }
}
export const getStockAdjustmentDashboardCountError=(error : any)=>{
  return{
    type : Dashboard.GET_STOCK_ADJUSTMENT_DASHBOARD_DETAILS_ERROR,
    payload : error
  }
}

export const getPurchaseOrderDashboardLoading=(month : any,year : any)=>({
  type:Dashboard.GET_PURCHASE_ORDER_DASHBOARD_DETAILS_LOADING,
  payload:{month,year}
})

export const getPurchaseOrderDashboardSuccess=(response:any)=>({
  type:Dashboard.GET_PURCHASE_ORDER_DASHBOARD_DETAILS_SUCCESS,
  payload:response
})

export const getPurchaseOrderDashboardError=(response:any)=>({
  type:Dashboard.GET_PURCHASE_ORDER_DASHBOARD_DETAILS_ERROR,
  payload:response
})

export const getJobsheetGraphDetailLoading=(lazyState : any)=>({
  type:Dashboard.GET_JOBSHEET_GRAPH_DASHBOARD_DETAILS_LOADING,
  payload:lazyState
})

export const getJobsheetGraphDetailSuccess=(response:any)=>({
  type:Dashboard.GET_JOBSHEET_GRAPH_DASHBOARD_DETAILS_SUCCESS,
  payload:response
})

export const getJobsheetGraphDetailError=(response:any)=>({
  type:Dashboard.GET_JOBSHEET_GRAPH_DASHBOARD_DETAILS_ERROR,
  payload:response
})


export const getInvoiceCountLoading=(payload:any)=>{
  return {
    type : Dashboard.GET_INVOICE_COUNT_DASHBOARD_LOADING,
    payload : payload
  }
}
export const getInvoiceCountSuccess=(resp : any)=>{
  return {
    type : Dashboard.GET_INVOICE_COUNT_DASHBOARD_SUCCESS,
    payload : resp
  }
}
export const getInvoiceCountError=(error : any)=>{
  return {
    type : Dashboard.GET_INVOICE_COUNT_DASHBOARD_ERROR,
    payload : error
  }
}


//get received payment count 

export const getReceivedPaymentLoading=(payload:any)=>{
  return {
    type : Dashboard.GET_RECEIVED_PAYMENT_COUNT_LOADING,
    payload:payload
  }
}
export const getReceivedPaymentSuccess=(resp : any)=>{
  return {
    type : Dashboard.GET_RECEIVED_PAYMENT_COUNT_SUCCESS,
    payload : resp
  }
}
export const getReceivedPaymentError=(error : any)=>{
  return {
    type : Dashboard.GET_RECEIVED_PAYMENT_COUNT_ERROR,
    payload :error
  }
}


export const getPendingPaymentLoading=(lazyState:any)=>{
  return {
    type : Dashboard.GET_PENDINGPAYMENT_COUNT_LOADING,
    payload : lazyState
  }
}
export const getPendingPaymentSuccess=(resp : any)=>{
  return {
    type : Dashboard.GET_PENDINGPAYMENT_COUNT_SUCCESS,
    payload : resp
  }
}
export const getPendingPaymentError=(error : any)=>{
  return {
    type : Dashboard.GET_PENDINGPAYMENT_COUNT_ERROR,
    payload : error
  }
}


export const getFranchiseAppointmentDetailLoading=(lazyState:any,newLazyState:any)=>{
  return {
    type : Dashboard.GET_FRANCHISE_APPOINTMENT_DETAIL_LOADING,
    payload : lazyState,
    newLazyState:newLazyState
  }
}
export const getFranchiseAppointmentDetailSuccess=(resp : any)=>{
  return {
    type : Dashboard.GET_FRANCHISE_APPOINTMENT_DETAIL_SUCCESS,
    payload : resp
  }
}
export const getFranchiseAppointmentDetailError=(error : any)=>{
  return {
    type : Dashboard.GET_FRANCHISE_APPOINTMENT_DETAIL_ERROR,
    payload : error
  }
}

