export enum CarBrandsTypes {

    // Grid
    GET_CAR_BRANDS_LIST = "@@CARBrands/GET_CAR_BRANDS_LIST",
    GET_CAR_BRANDS_LIST_SUCCESS = "@@CARBrands/GET_CAR_BRANDS_LIST_SUCCESS",
    GET_CAR_BRANDS_LIST_FAIL = "@@CARBrands/GET_CAR_BRANDS_LIST_FAIL",
  
    DELETE_CAR_BRANDS = "@@CARBrands/DELETE_CAR_BRANDS",
    DELETE_CAR_BRANDS_SUCCESS = "@@CARBrands/DELETE_CAR_BRANDS_SUCCESS",
    DELETE_CAR_BRANDS_FAIL = "@@CARBrands/DELETE_CAR_BRANDS_FAIL",

    // //reset
    RESET_CAR_BRANDS = "@@CARBrands/RESET_CAR_BRANDS",
    CLEAR_CAR_BRANDS = "@@CARBrands/CLEAR_CAR_BRANDS",


    UPDATE_CAR_BRANDS = "@@CARBrands/UPDATE_CAR_BRANDS",
    UPDATE_CAR_BRANDS_SUCCESS = "@@CARBrands/UPDATE_CAR_BRANDS_SUCCESS",
    UPDATE_CAR_BRANDS_FAIL = "@@CARBrands/UPDATE_CAR_BRANDS_FAIL",

    // POST

    POST_CAR_BRANDS = "@@CARBrands/POST_CAR_BRANDS",
    POST_CAR_BRANDS_SUCCESS = "@@CARBrands/POST_CAR_BRANDS_SUCCESS",
    POST_CAR_BRANDS_FAIL = "@@CARBrands/POST_CAR_BRANDS_FAIL",

    // GET

    GET_CAR_BRANDS_BY_ID = "@@CARBrands/GET_CAR_BRANDS_BY_ID",
    GET_CAR_BRANDS_BY_ID_SUCCESS = "@@CARBrands/GET_CAR_BRANDS_BY_ID_SUCCESS",
    GET_CAR_BRANDS_BY_ID_FAIL = "@@CARBrands/GET_CAR_BRANDS_BY_ID_FAIL",

    GET_VEHICLE_BRAND_DROPDOWN_LOADING = "@@CARBrands/GET_VEHICLE_BRAND_DROPDOWN_LOADING",
    GET_VEHICLE_BRAND_DROPDOWN_SUCCESS = "@@CARBrands/GET_VEHICLE_BRAND_DROPDOWN_SUCCESS",
    GET_VEHICLE_BRAND_DROPDOWN_FAIL = "@@CARBrands/GET_VEHICLE_BRAND_DROPDOWN_FAIL",

  }
  