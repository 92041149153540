import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { API } from "src/util/api";
import { CarBrandsTypes } from "./actionTypes";
import { createCarBrandsError, createCarBrandsSuccess, deleteCarBrandsFromListError, deleteCarBrandsFromListSuccess, getCarBrandsBYIDError, getCarBrandsBYIDSuccess, getCarBrandsListError, getCarBrandsListSuccess, getVehicleBrandDropdownError, getVehicleBrandDropdownSuccess, updateCarBrandsError, updateCarBrandsSuccess } from "./action";
import { carBrandsDetails } from "src/models/pages/carBrandInitialState";

function* getCarBrandsListAsync(action : any) {
  try {
    const response : Promise<any> = yield call(
        API.getCarModelListAPI,{
          ...action.lazyState,
        }
    )
    yield put(getCarBrandsListSuccess(response));
  } catch (error) {
    yield put(getCarBrandsListError(error));
  }
}

function* deleteCarBrandsAsync(action : any) {
  try {
    const response : Promise<any> = yield call(
        API.deleteCarModelListAPI,{id : action.payload.id ,undo: action.payload.undo}
    )
    yield put(deleteCarBrandsFromListSuccess(response,action.payload.undo))  
  } catch (error) {
    yield put(deleteCarBrandsFromListError(error));
  }
}

function* createCarBrandsAsync({ payload }: any) {
  try {
    const response: carBrandsDetails = yield call(API.postCarModelAPI, payload);
    yield put(createCarBrandsSuccess(response));
    
  } catch (error) {
    // yield put(CreateMelUserError(error));
    yield put(createCarBrandsError(error));
  }
}

function* updateCarBrandsAsync({payload}: any): Generator<any, void, any> {
    try {
      const { data } = yield call(API.postCarModelAPI, payload);
   
      yield put(updateCarBrandsSuccess(data));
  
    } catch (error) {
      yield put(updateCarBrandsError(error));
    }
  }

  function* getCarBrandsByIDAsync({ payload }: any): Generator<any, void, any> {
    try {
      let { data } = yield call(API.getCarModelByIdAPI, payload);
      yield put(getCarBrandsBYIDSuccess(data));
  
    } catch (error) {
      yield put(getCarBrandsBYIDError(error));
    }
  }

  function* getVehicleBrandDropdownAsync({ payload }: any): Generator<any, void, any> {
    try {
      let { data } = yield call(API.getVehicleBrandListFromMasterManagement, "");
      yield put(getVehicleBrandDropdownSuccess(data));
  
    } catch (error) {
      yield put(getVehicleBrandDropdownError(error));
    }
  }


function* carBrandSaga() {
  yield takeLatest(CarBrandsTypes.GET_CAR_BRANDS_LIST, getCarBrandsListAsync);
  yield takeLatest(CarBrandsTypes.DELETE_CAR_BRANDS, deleteCarBrandsAsync);
  yield takeLatest(CarBrandsTypes.POST_CAR_BRANDS, createCarBrandsAsync);
  yield takeLatest(CarBrandsTypes.UPDATE_CAR_BRANDS, updateCarBrandsAsync);
  yield takeLatest(CarBrandsTypes.GET_CAR_BRANDS_BY_ID, getCarBrandsByIDAsync);
  yield takeLatest(CarBrandsTypes.GET_VEHICLE_BRAND_DROPDOWN_LOADING, getVehicleBrandDropdownAsync);
}

export default carBrandSaga;